export const LIST_CAR_REPAIR = "LIST_CAR_REPAIR";
export const GET_CAR_REPAIR = "GET_CAR_REPAIR";
export const CREATE_CAR_REPAIR = "CREATE_CAR_REPAIR";
export const UPDATE_CAR_REPAIR = "UPDATE_CAR_REPAIR";
export const DELETE_CAR_REPAIR = "DELETE_CAR_REPAIR";
export const UPDATE_ACTIVE_CAR_REPAIR = "UPDATE_ACTIVE_CAR_REPAIR";

export const TOGGLE_CREATE_CAR_REPAIR = "TOGGLE_CREATE_CAR_REPAIR";
export const TOGGLE_UPDATE_CAR_REPAIR = "TOGGLE_UPDATE_CAR_REPAIR";
export const TOGGLE_DELETE_CAR_REPAIR = "TOGGLE_DELETE_CAR_REPAIR";
export const TOGGLE_DETAIL_CAR_REPAIR = "TOGGLE_DETAIL_CAR_REPAIR";

export const RESET_CAR_REPAIR_PARAMETERES = "RESET_CAR_REPAIR_PARAMETERES";
export const UPDATE_CAR_REPAIR_PARAMETERS = "UPDATE_CAR_REPAIR_PARAMETERS";
export const DELETE_CAR_REPAIR_PARAMETERS = "DELETE_CAR_REPAIR_PARAMETERS";
export const DETAIL_CAR_REPAIR_PARAMETERS = "DETAIL_CAR_REPAIR_PARAMETERS";

export const HANDLE_CHANGE_CAR_REPAIR = "HANDLE_CHANGE_CAR_REPAIR";

export const SHOW_EMPTY_FIELD_CAR_REPAIR = "SHOW_EMPTY_FIELD_CAR_REPAIR";
