const brandModelMapping = {
  brandList: [
    { groupId: "TOYOTA", title: "TOYOTA" },
    { groupId: "MERCEDES-BENZ", title: "MERCEDES-BENZ" },
    { groupId: "HONDA", title: "HONDA" },
    { groupId: "PERODUA", title: "PERODUA" },
    { groupId: "PROTON", title: "PROTON" },
    { groupId: "NISSAN", title: "NISSAN" },
    { groupId: "BMW", title: "BMW" },
    { groupId: "PORSCHE", title: "PORSCHE" },
    { groupId: "VOLKSWAGEN", title: "VOLKSWAGEN" },
    { groupId: "MAZDA", title: "MAZDA" },
    { groupId: "ALFA ROMEO", title: "ALFA ROMEO" },
    { groupId: "ASTON MARTIN", title: "ASTON MARTIN" },
    { groupId: "AUDI", title: "AUDI" },
    { groupId: "AUSTIN", title: "AUSTIN" },
    { groupId: "BENTLEY", title: "BENTLEY" },
    { groupId: "BUFORI", title: "BUFORI" },
    { groupId: "CAM", title: "CAM" },
    { groupId: "CADILLAC", title: "CADILLAC" },
    { groupId: "CHANA", title: "CHANA" },
    { groupId: "CHANGAN", title: "CHANGAN" },
    { groupId: "CHERY", title: "CHERY" },
    { groupId: "CHEVROLET", title: "CHEVROLET" },
    { groupId: "CHRYSLER", title: "CHRYSLER" },
    { groupId: "CITROEN", title: "CITROEN" },
    { groupId: "DAIHATSU", title: "DAIHATSU" },
    { groupId: "DODGE", title: "DODGE" },
    { groupId: "FERRARI", title: "FERRARI" },
    { groupId: "FIAT", title: "FIAT" },
    { groupId: "FODAY", title: "FODAY" },
    { groupId: "FORD", title: "FORD" },
    { groupId: "FOTON", title: "FOTON" },
    { groupId: "GOLDEN DRAGON", title: "GOLDEN DRAGON" },
    { groupId: "GREAT WALL", title: "GREAT WALL" },
    { groupId: "HAVAL", title: "HAVAL" },
    { groupId: "HUMMER", title: "HUMMER" },
    { groupId: "HYUNDAI", title: "HYUNDAI" },
    { groupId: "INFINITI", title: "INFINITI" },
    { groupId: "INOKOM", title: "INOKOM" },
    { groupId: "ISUZU", title: "ISUZU" },
    { groupId: "JAGUAR", title: "JAGUAR" },
    { groupId: "JEEP", title: "JEEP" },
    { groupId: "JOYLONG", title: "JOYLONG" },
    { groupId: "KIA", title: "KIA" },
    { groupId: "LMG", title: "LMG" },
    { groupId: "LAMBORGHINI", title: "LAMBORGHINI" },
    { groupId: "LAND ROVER", title: "LAND ROVER" },
    { groupId: "LEXUS", title: "LEXUS" },
    { groupId: "LOTUS", title: "LOTUS" },
    { groupId: "MG", title: "MG" },
    { groupId: "MINI", title: "MINI" },
    { groupId: "MAHINDRA", title: "MAHINDRA" },
    { groupId: "MASERATI", title: "MASERATI" },
    { groupId: "MAXUS", title: "MAXUS" },
    { groupId: "MCLAREN", title: "MCLAREN" },
    { groupId: "MITSUBISHI", title: "MITSUBISHI" },
    { groupId: "MITSUOKA", title: "MITSUOKA" },
    { groupId: "MORRIS", title: "MORRIS" },
    { groupId: "NAZA", title: "NAZA" },
    { groupId: "PEUGEOT", title: "PEUGEOT" },
    { groupId: "RENAULT", title: "RENAULT" },
    { groupId: "ROLLS-ROYCE", title: "ROLLS-ROYCE" },
    { groupId: "ROVER", title: "ROVER" },
    { groupId: "SAAB", title: "SAAB" },
    { groupId: "SHENYANG BRILLIANCE", title: "SHENYANG BRILLIANCE" },
    { groupId: "SKODA", title: "SKODA" },
    { groupId: "SMART", title: "SMART" },
    { groupId: "SSANGYONG", title: "SSANGYONG" },
    { groupId: "SUBARU", title: "SUBARU" },
    { groupId: "SUZUKI", title: "SUZUKI" },
    { groupId: "TD2000", title: "TD2000" },
    { groupId: "TESLA", title: "TESLA" },
    { groupId: "VOLVO", title: "VOLVO" },
    { groupId: "YANGTSE", title: "YANGTSE" },
    { groupId: "AC", title: "AC" },
    { groupId: "ARIEL", title: "ARIEL" },
    { groupId: "ASIA MOTORS", title: "ASIA MOTORS" },
    { groupId: "BAW", title: "BAW" },
    { groupId: "BISON", title: "BISON" },
    { groupId: "BOON KOON", title: "BOON KOON" },
    { groupId: "BORGWARD", title: "BORGWARD" },
    { groupId: "BUGATTI", title: "BUGATTI" },
    { groupId: "BUICK", title: "BUICK" },
    { groupId: "DAEWOO", title: "DAEWOO" },
    { groupId: "DAIMLER", title: "DAIMLER" },
    { groupId: "DATSUN", title: "DATSUN" },
    { groupId: "DS AUTOMOBILES", title: "DS AUTOMOBILES" },
    { groupId: "DURANT", title: "DURANT" },
    { groupId: "FACTORY FIVE", title: "FACTORY FIVE" },
    { groupId: "GMC", title: "GMC" },
    { groupId: "HOLDEN", title: "HOLDEN" },
    { groupId: "HUANGHAI", title: "HUANGHAI" },
    { groupId: "KING LONG", title: "KING LONG" },
    { groupId: "LANCIA", title: "LANCIA" },
    { groupId: "MORGAN", title: "MORGAN" },
    { groupId: "OLDSMOBILE", title: "OLDSMOBILE" },
    { groupId: "OPEL", title: "OPEL" },
    { groupId: "OTHERS", title: "OTHERS" },
    { groupId: "PONTIAC", title: "PONTIAC" },
    { groupId: "SHELBY", title: "SHELBY" },
    { groupId: "SUNBEAM", title: "SUNBEAM" },
    { groupId: "SUTTON", title: "SUTTON" },
    { groupId: "TATA", title: "TATA" },
    { groupId: "TRIUMPH", title: "TRIUMPH" },
    { groupId: "TVR", title: "TVR" },
    { groupId: "WOLSELEY", title: "WOLSELEY" },
    { groupId: "ZENVO", title: "ZENVO" },
    { groupId: "ZNA", title: "ZNA" },
    { groupId: "ZXAUTO", title: "ZXAUTO" },
  ],
  modelList: [
    ["TOYOTA", "AVANZA"],
    ["TOYOTA", "VIOS"],
    ["TOYOTA", "RUSH"],
    ["TOYOTA", "YARIS"],
    ["TOYOTA", "INNOVA"],
    ["TOYOTA", "ALTIS"],
    ["TOYOTA", "CAMRY"],
    ["TOYOTA", "WISH"],
    ["TOYOTA", "HILUX"],
    ["TOYOTA", "FORTUNER"],
    ["TOYOTA", "CORONA"],
    ["TOYOTA", "COROLLA"],
    ["TOYOTA", "PRIUS"],
    ["TOYOTA", "ALPHARD"],
    ["TOYOTA", "ALTEZZA"],
    ["TOYOTA", "CELICA"],
    ["TOYOTA", "CROWN"],
    ["TOYOTA", "ESTIMA"],
    ["TOYOTA", "HARRIER"],
    ["TOYOTA", "LITEACE"],
    ["TOYOTA", "MARK II"],
    ["TOYOTA", "MARK V"],
    ["TOYOTA", "PREMIO"],
    ["TOYOTA", "RAV 4"],
    ["TOYOTA", "UNSER"],
    ["TOYOTA", "DX"],
    ["TOYOTA", "KE70"],
    ["TOYOTA", "CALDINA"],
    ["TOYOTA", "KE25"],
    ["TOYOTA", "LC II"],
    ["TOYOTA", "MARK X"],
    ["TOYOTA", "SPRINTER"],
    ["TOYOTA", "CYGNUS"],
    ["TOYOTA", "IPSUM"],
    ["TOYOTA", "LE"],
    ["TOYOTA", "PRADO"],
    ["TOYOTA", "MRS"],
    ["TOYOTA", "MR2"],
    ["TOYOTA", "SPACIO"],
    ["TOYOTA", "VELLFIRE"],
    ["TOYOTA", "86"],
    ["TOYOTA", "AURIS"],
    ["TOYOTA", "CARINA"],
    ["TOYOTA", "FJ CRUISER"],
    ["TOYOTA", "ISIS"],
    ["TOYOTA", "IST"],
    ["TOYOTA", "LAND CRUISER"],
    ["TOYOTA", "PRIUS C"],
    ["TOYOTA", "AE86"],
    ["TOYOTA", "2000GT"],
    ["TOYOTA", "ALLEX"],
    ["TOYOTA", "ALLION"],
    ["TOYOTA", "ARISTO"],
    ["TOYOTA", "AVALON"],
    ["TOYOTA", "AVENSIS"],
    ["TOYOTA", "BB"],
    ["TOYOTA", "BELTA"],
    ["TOYOTA", "BLADE"],
    ["TOYOTA", "BLIZZARD"],
    ["TOYOTA", "BREVIS"],
    ["TOYOTA", "BU"],
    ["TOYOTA", "CAMI"],
    ["TOYOTA", "CAVALIER"],
    ["TOYOTA", "CELSIOR"],
    ["TOYOTA", "CENTURY"],
    ["TOYOTA", "CHASER"],
    ["TOYOTA", "COASTER"],
    ["TOYOTA", "COMFORT"],
    ["TOYOTA", "CORSA"],
    ["TOYOTA", "CRESTA"],
    ["TOYOTA", "CURREN"],
    ["TOYOTA", "DUET"],
    ["TOYOTA", "FT-86"],
    ["TOYOTA", "GAIA"],
    ["TOYOTA", "GRANVIA"],
    ["TOYOTA", "IQ"],
    ["TOYOTA", "KLUGER"],
    ["TOYOTA", "NADIA"],
    ["TOYOTA", "NOAH"],
    ["TOYOTA", "OPA"],
    ["TOYOTA", "ORIGIN"],
    ["TOYOTA", "PASSO"],
    ["TOYOTA", "PIXIS"],
    ["TOYOTA", "PLATZ"],
    ["TOYOTA", "PORTE"],
    ["TOYOTA", "PROGRES"],
    ["TOYOTA", "PRONARD"],
    ["TOYOTA", "RACTIS"],
    ["TOYOTA", "RAUM"],
    ["TOYOTA", "SAI"],
    ["TOYOTA", "SCEPTER"],
    ["TOYOTA", "SEG"],
    ["TOYOTA", "SERA"],
    ["TOYOTA", "SIENTA"],
    ["TOYOTA", "SOARER"],
    ["TOYOTA", "SPARKY"],
    ["TOYOTA", "SPORT 800"],
    ["TOYOTA", "STARLET"],
    ["TOYOTA", "STOUT"],
    ["TOYOTA", "SUPRA"],
    ["TOYOTA", "TERCEL"],
    ["TOYOTA", "VEROSSA"],
    ["TOYOTA", "VISTA"],
    ["TOYOTA", "VITZ"],
    ["TOYOTA", "VOLTZ"],
    ["TOYOTA", "WILL"],
    ["TOYOTA", "WINDOM"],
    ["TOYOTA", "HIACE"],
    ["TOYOTA", "VANGUARD"],
    ["TOYOTA", "VOXY"],
    ["TOYOTA", "AXIO"],
    ["TOYOTA", "KE20"],
    ["TOYOTA", "C-HR"],
    ["TOYOTA", "PREVIA"],
    ["TOYOTA", "CRESSIDA"],
    ["TOYOTA", "MEGA CRUISER"],
    ["TOYOTA", "GR SUPRA"],
    ["TOYOTA", "ESQUIRE"],
    ["TOYOTA", "CYNOS"],
    ["TOYOTA", "TANK"],
    ["MERCEDES-BENZ", "A170"],
    ["MERCEDES-BENZ", "B170"],
    ["MERCEDES-BENZ", "B200"],
    ["MERCEDES-BENZ", "C180"],
    ["MERCEDES-BENZ", "C200"],
    ["MERCEDES-BENZ", "C230"],
    ["MERCEDES-BENZ", "CLK200"],
    ["MERCEDES-BENZ", "SLK200"],
    ["MERCEDES-BENZ", "SLK280"],
    ["MERCEDES-BENZ", "SL350"],
    ["MERCEDES-BENZ", "CLS350"],
    ["MERCEDES-BENZ", "CLS55"],
    ["MERCEDES-BENZ", "E200"],
    ["MERCEDES-BENZ", "E260"],
    ["MERCEDES-BENZ", "E230"],
    ["MERCEDES-BENZ", "E280"],
    ["MERCEDES-BENZ", "S300L"],
    ["MERCEDES-BENZ", "S350L"],
    ["MERCEDES-BENZ", "S500L"],
    ["MERCEDES-BENZ", "ML350"],
    ["MERCEDES-BENZ", "R280L"],
    ["MERCEDES-BENZ", "R350L"],
    ["MERCEDES-BENZ", "C240"],
    ["MERCEDES-BENZ", "E240"],
    ["MERCEDES-BENZ", "W115"],
    ["MERCEDES-BENZ", "W126"],
    ["MERCEDES-BENZ", "190E"],
    ["MERCEDES-BENZ", "300SE"],
    ["MERCEDES-BENZ", "CL-CLASS"],
    ["MERCEDES-BENZ", "CLC-CLASS"],
    ["MERCEDES-BENZ", "G-CLASS"],
    ["MERCEDES-BENZ", "GL-CLASS"],
    ["MERCEDES-BENZ", "GLK-CLASS"],
    ["MERCEDES-BENZ", "SEL"],
    ["MERCEDES-BENZ", "SLS AMG"],
    ["MERCEDES-BENZ", "300CE"],
    ["MERCEDES-BENZ", "280SE"],
    ["MERCEDES-BENZ", "A160"],
    ["MERCEDES-BENZ", "B160"],
    ["MERCEDES-BENZ", "C220"],
    ["MERCEDES-BENZ", "C250"],
    ["MERCEDES-BENZ", "C280"],
    ["MERCEDES-BENZ", "C300"],
    ["MERCEDES-BENZ", "C320"],
    ["MERCEDES-BENZ", "CL 63"],
    ["MERCEDES-BENZ", "CL 65"],
    ["MERCEDES-BENZ", "CL203"],
    ["MERCEDES-BENZ", "CL500"],
    ["MERCEDES-BENZ", "CL550"],
    ["MERCEDES-BENZ", "CL600"],
    ["MERCEDES-BENZ", "CLC160"],
    ["MERCEDES-BENZ", "CLC180"],
    ["MERCEDES-BENZ", "CLC200"],
    ["MERCEDES-BENZ", "CLC220"],
    ["MERCEDES-BENZ", "CLK230"],
    ["MERCEDES-BENZ", "CLK240"],
    ["MERCEDES-BENZ", "CLK280"],
    ["MERCEDES-BENZ", "CLK320"],
    ["MERCEDES-BENZ", "CLK250"],
    ["MERCEDES-BENZ", "CLK430"],
    ["MERCEDES-BENZ", "CLK500"],
    ["MERCEDES-BENZ", "CLS500"],
    ["MERCEDES-BENZ", "E250"],
    ["MERCEDES-BENZ", "G350"],
    ["MERCEDES-BENZ", "G55"],
    ["MERCEDES-BENZ", "GL320"],
    ["MERCEDES-BENZ", "GL420"],
    ["MERCEDES-BENZ", "GL500"],
    ["MERCEDES-BENZ", "ML-CLASS"],
    ["MERCEDES-BENZ", "ML280"],
    ["MERCEDES-BENZ", "ML320"],
    ["MERCEDES-BENZ", "ML55"],
    ["MERCEDES-BENZ", "S-CLASS"],
    ["MERCEDES-BENZ", "S280"],
    ["MERCEDES-BENZ", "S320L"],
    ["MERCEDES-BENZ", "SL280"],
    ["MERCEDES-BENZ", "SL500"],
    ["MERCEDES-BENZ", "SL55"],
    ["MERCEDES-BENZ", "SL63"],
    ["MERCEDES-BENZ", "SLR MCLAREN"],
    ["MERCEDES-BENZ", "SLS 63"],
    ["MERCEDES-BENZ", "TRANSPORTER"],
    ["MERCEDES-BENZ", "URBAN MINIMOG"],
    ["MERCEDES-BENZ", "VANOE"],
    ["MERCEDES-BENZ", "V-CLASS"],
    ["MERCEDES-BENZ", "VIANO"],
    ["MERCEDES-BENZ", "CLK55"],
    ["MERCEDES-BENZ", "A45"],
    ["MERCEDES-BENZ", "A200"],
    ["MERCEDES-BENZ", "A250"],
    ["MERCEDES-BENZ", "GLA45"],
    ["MERCEDES-BENZ", "GLA200"],
    ["MERCEDES-BENZ", "GLA250"],
    ["MERCEDES-BENZ", "E300"],
    ["MERCEDES-BENZ", "CLA200"],
    ["MERCEDES-BENZ", "CLA250"],
    ["MERCEDES-BENZ", "CLA45"],
    ["MERCEDES-BENZ", "CLS63"],
    ["MERCEDES-BENZ", "C43"],
    ["MERCEDES-BENZ", "C36"],
    ["MERCEDES-BENZ", "E55"],
    ["MERCEDES-BENZ", "E63"],
    ["MERCEDES-BENZ", "300SL"],
    ["MERCEDES-BENZ", "B180"],
    ["MERCEDES-BENZ", "E400"],
    ["MERCEDES-BENZ", "VITO"],
    ["MERCEDES-BENZ", "G280"],
    ["MERCEDES-BENZ", "230CE"],
    ["MERCEDES-BENZ", "R350"],
    ["MERCEDES-BENZ", "380 SLC"],
    ["MERCEDES-BENZ", "E350"],
    ["MERCEDES-BENZ", "230E"],
    ["MERCEDES-BENZ", "280TE"],
    ["MERCEDES-BENZ", "S400L HYBRID"],
    ["MERCEDES-BENZ", "C63"],
    ["MERCEDES-BENZ", "S63"],
    ["MERCEDES-BENZ", "AMG GT"],
    ["MERCEDES-BENZ", "AMG GT S"],
    ["MERCEDES-BENZ", "MB100"],
    ["MERCEDES-BENZ", "S320"],
    ["MERCEDES-BENZ", "E270"],
    ["MERCEDES-BENZ", "A180"],
    ["MERCEDES-BENZ", "E220"],
    ["MERCEDES-BENZ", "SLK230"],
    ["MERCEDES-BENZ", "SLK250"],
    ["MERCEDES-BENZ", "C270"],
    ["MERCEDES-BENZ", "E320"],
    ["MERCEDES-BENZ", "200D"],
    ["MERCEDES-BENZ", "GLC250"],
    ["MERCEDES-BENZ", "GLE250D"],
    ["MERCEDES-BENZ", "GLE400"],
    ["MERCEDES-BENZ", "GLE400 COUPE"],
    ["MERCEDES-BENZ", "GLE450 COUPE"],
    ["MERCEDES-BENZ", "260E"],
    ["MERCEDES-BENZ", "CLA220"],
    ["MERCEDES-BENZ", "MB140D"],
    ["MERCEDES-BENZ", "280SL"],
    ["MERCEDES-BENZ", "C350E"],
    ["MERCEDES-BENZ", "A190"],
    ["MERCEDES-BENZ", "B SPORTS TOURER"],
    ["MERCEDES-BENZ", "CLK270"],
    ["MERCEDES-BENZ", "CLS400"],
    ["MERCEDES-BENZ", "SLC 200"],
    ["MERCEDES-BENZ", "GLC200"],
    ["MERCEDES-BENZ", "CLA180"],
    ["MERCEDES-BENZ", "E350E AMG"],
    ["MERCEDES-BENZ", "E350E EXCLUSIVE"],
    ["MERCEDES-BENZ", "C200 AMG"],
    ["MERCEDES-BENZ", "S400 HYBRID"],
    ["MERCEDES-BENZ", "CLS220"],
    ["MERCEDES-BENZ", "E250 SE"],
    ["MERCEDES-BENZ", "SLK350"],
    ["MERCEDES-BENZ", "E250 CGI"],
    ["MERCEDES-BENZ", "GLC43"],
    ["MERCEDES-BENZ", "GLC43 COUPE"],
    ["MERCEDES-BENZ", "200E"],
    ["MERCEDES-BENZ", "230TE"],
    ["MERCEDES-BENZ", "GLE43"],
    ["MERCEDES-BENZ", "280SLC"],
    ["MERCEDES-BENZ", "300E"],
    ["MERCEDES-BENZ", "GLS350"],
    ["MERCEDES-BENZ", "E43"],
    ["MERCEDES-BENZ", "GLA180"],
    ["MERCEDES-BENZ", "S600"],
    ["MERCEDES-BENZ", "200"],
    ["MERCEDES-BENZ", "S450L"],
    ["MERCEDES-BENZ", "SL400 AMG"],
    ["MERCEDES-BENZ", "GLE63 S AMG"],
    ["MERCEDES-BENZ", "420SL"],
    ["MERCEDES-BENZ", "SPRINTER"],
    ["MERCEDES-BENZ", "X250D"],
    ["MERCEDES-BENZ", "R500L"],
    ["MERCEDES-BENZ", "E500"],
    ["MERCEDES-BENZ", "300TE"],
    ["MERCEDES-BENZ", "500 SEC"],
    ["MERCEDES-BENZ", "350 SLC"],
    ["MERCEDES-BENZ", "240D"],
    ["MERCEDES-BENZ", "S280V"],
    ["MERCEDES-BENZ", "230"],
    ["MERCEDES-BENZ", "250S"],
    ["MERCEDES-BENZ", "SLC 43"],
    ["MERCEDES-BENZ", "A150"],
    ["MERCEDES-BENZ", "CLS53"],
    ["MERCEDES-BENZ", "A35"],
    ["MERCEDES-BENZ", "CLS450"],
    ["MERCEDES-BENZ", "E53"],
    ["MERCEDES-BENZ", "GLC300"],
    ["MERCEDES-BENZ", "GLC63"],
    ["MERCEDES-BENZ", "S560"],
    ["MERCEDES-BENZ", "S650"],
    ["MERCEDES-BENZ", "SLC300"],
    ["MERCEDES-BENZ", "200TE"],
    ["MERCEDES-BENZ", "SL320"],
    ["MERCEDES-BENZ", "320CE"],
    ["MERCEDES-BENZ", "300GE"],
    ["MERCEDES-BENZ", "GLE350D"],
    ["HONDA", "CITY"],
    ["HONDA", "JAZZ"],
    ["HONDA", "CIVIC"],
    ["HONDA", "ACCORD"],
    ["HONDA", "STREAM"],
    ["HONDA", "ODYSSEY"],
    ["HONDA", "CR-V"],
    ["HONDA", "PRELUDE"],
    ["HONDA", "INSPIRE"],
    ["HONDA", "INTEGRA"],
    ["HONDA", "LEGEND"],
    ["HONDA", "NSX"],
    ["HONDA", "HYBRID"],
    ["HONDA", "INSIGHT"],
    ["HONDA", "CR-Z"],
    ["HONDA", "CIVIC TYPE R"],
    ["HONDA", "CROSSROAD"],
    ["HONDA", "EDIX"],
    ["HONDA", "FREED"],
    ["HONDA", "JAZZ HYBRID"],
    ["HONDA", "MDX"],
    ["HONDA", "S2000"],
    ["HONDA", "VIGOR"],
    ["HONDA", "1300"],
    ["HONDA", "145"],
    ["HONDA", "ACTY TRUCK"],
    ["HONDA", "ACTY VAN"],
    ["HONDA", "AIRWAVE"],
    ["HONDA", "ASCOT"],
    ["HONDA", "AVANCIER"],
    ["HONDA", "BALLADE"],
    ["HONDA", "BEAT"],
    ["HONDA", "CAPA"],
    ["HONDA", "CONCERTO"],
    ["HONDA", "CR-X"],
    ["HONDA", "DOMANI"],
    ["HONDA", "ELEMENT"],
    ["HONDA", "ELYSION"],
    ["HONDA", "HORIZON"],
    ["HONDA", "HR-V"],
    ["HONDA", "LAGREAT"],
    ["HONDA", "LIFE"],
    ["HONDA", "LOGO"],
    ["HONDA", "MOBILIO"],
    ["HONDA", "N360"],
    ["HONDA", "ORTHIA"],
    ["HONDA", "PERTNER"],
    ["HONDA", "QUINT"],
    ["HONDA", "RAFAGA"],
    ["HONDA", "S500"],
    ["HONDA", "S600"],
    ["HONDA", "S800"],
    ["HONDA", "SABER"],
    ["HONDA", "S-MX"],
    ["HONDA", "STEPWAGON"],
    ["HONDA", "STREET"],
    ["HONDA", "TODAY"],
    ["HONDA", "TORNEO"],
    ["HONDA", "VANOS"],
    ["HONDA", "Z"],
    ["HONDA", "ZEST"],
    ["HONDA", "INTEGRA TYPE R"],
    ["HONDA", "FIT"],
    ["HONDA", "S660"],
    ["HONDA", "BR-V"],
    ["HONDA", "JADE"],
    ["HONDA", "N BOX"],
    ["PERODUA", "KANCIL"],
    ["PERODUA", "KEMBARA"],
    ["PERODUA", "KENARI"],
    ["PERODUA", "KELISA"],
    ["PERODUA", "MYVI"],
    ["PERODUA", "VIVA"],
    ["PERODUA", "NAUTICA"],
    ["PERODUA", "ALZA"],
    ["PERODUA", "AXIA"],
    ["PERODUA", "RUSA"],
    ["PERODUA", "BEZZA"],
    ["PERODUA", "ARUZ"],
    ["PERODUA", "ATIVA"],
    ["PROTON", "SAGA"],
    ["PROTON", "ISWARA"],
    ["PROTON", "WIRA"],
    ["PROTON", "WAJA"],
    ["PROTON", "SATRIA"],
    ["PROTON", "SAVVY"],
    ["PROTON", "PERDANA"],
    ["PROTON", "GEN2"],
    ["PROTON", "PERSONA"],
    ["PROTON", "EXORA"],
    ["PROTON", "PUTRA"],
    ["PROTON", "IRIZ"],
    ["PROTON", "SATRIA NEO"],
    ["PROTON", "SATRIA GTI"],
    ["PROTON", "INSPIRA"],
    ["PROTON", "TIARA"],
    ["PROTON", "ARENA"],
    ["PROTON", "PREVE"],
    ["PROTON", "JUARA"],
    ["PROTON", "SUPRIMA S"],
    ["PROTON", "ERTIGA"],
    ["PROTON", "X70"],
    ["PROTON", "X50"],
    ["NISSAN", "SENTRA"],
    ["NISSAN", "LATIO"],
    ["NISSAN", "IMPUL"],
    ["NISSAN", "SYLPHY"],
    ["NISSAN", "SERENA"],
    ["NISSAN", "FRONTIER"],
    ["NISSAN", "NAVARA"],
    ["NISSAN", "X-TRAIL"],
    ["NISSAN", "SUNNY"],
    ["NISSAN", "ALTIMA"],
    ["NISSAN", "BLUEBIRD"],
    ["NISSAN", "CEFIRO"],
    ["NISSAN", "CEDRIC"],
    ["NISSAN", "MURANO"],
    ["NISSAN", "TERRANO"],
    ["NISSAN", "SKYLINE"],
    ["NISSAN", "MICRA"],
    ["NISSAN", "SILVIA"],
    ["NISSAN", "MARCH"],
    ["NISSAN", "AD RESORT"],
    ["NISSAN", "GRAND LIVINA"],
    ["NISSAN", "PATROL"],
    ["NISSAN", "TEANA"],
    ["NISSAN", "SAFARI"],
    ["NISSAN", "X-GEAR"],
    ["NISSAN", "350Z"],
    ["NISSAN", "370Z"],
    ["NISSAN", "ELGRAND"],
    ["NISSAN", "FAIRLADY Z"],
    ["NISSAN", "GTR"],
    ["NISSAN", "JUKE"],
    ["NISSAN", "PRESAGE"],
    ["NISSAN", "180SX"],
    ["NISSAN", "280ZX"],
    ["NISSAN", "ALMERA"],
    ["NISSAN", "ATLAS"],
    ["NISSAN", "AUSTER"],
    ["NISSAN", "AVENIR"],
    ["NISSAN", "BASSARA"],
    ["NISSAN", "BE-1"],
    ["NISSAN", "CHERRY"],
    ["NISSAN", "CIMA"],
    ["NISSAN", "CONDOR"],
    ["NISSAN", "CREW"],
    ["NISSAN", "EXA"],
    ["NISSAN", "EXPERT"],
    ["NISSAN", "FIRAGO"],
    ["NISSAN", "FUGA"],
    ["NISSAN", "GAZELLA"],
    ["NISSAN", "GLORIA"],
    ["NISSAN", "GT"],
    ["NISSAN", "GTT"],
    ["NISSAN", "INFINITI"],
    ["NISSAN", "KIX"],
    ["NISSAN", "LAFESTA"],
    ["NISSAN", "LANGLEY"],
    ["NISSAN", "LAUREL"],
    ["NISSAN", "LEAF"],
    ["NISSAN", "LEOPARD"],
    ["NISSAN", "LIBERTY"],
    ["NISSAN", "LUCINO"],
    ["NISSAN", "MAXIMA"],
    ["NISSAN", "MISTRAL"],
    ["NISSAN", "MOCO"],
    ["NISSAN", "NOTE"],
    ["NISSAN", "OTTI"],
    ["NISSAN", "PAO"],
    ["NISSAN", "PINO"],
    ["NISSAN", "PRAIRIE JOY"],
    ["NISSAN", "PRESEA"],
    ["NISSAN", "PRESIDENT"],
    ["NISSAN", "PRIMERA"],
    ["NISSAN", "PULSAR"],
    ["NISSAN", "QUEST"],
    ["NISSAN", "RASHEEN"],
    ["NISSAN", "RNESSA"],
    ["NISSAN", "ROOX"],
    ["NISSAN", "STAGEA"],
    ["NISSAN", "STANZA"],
    ["NISSAN", "TILDA"],
    ["NISSAN", "TINO"],
    ["NISSAN", "VIOLET"],
    ["NISSAN", "VW SANTANA"],
    ["NISSAN", "WINGROAD"],
    ["NISSAN", "VANETTE"],
    ["NISSAN", "URVAN"],
    ["NISSAN", "NV200"],
    ["NISSAN", "DUALIS"],
    ["NISSAN", "CUBE"],
    ["NISSAN", "130Y"],
    ["NISSAN", "ELGRAND 2.5 HIGHWAY STAR"],
    ["NISSAN", "ELGRAND 2.5 RIDER"],
    ["NISSAN", "LIBERTY S"],
    ["NISSAN", "FAIRLADY 280Z"],
    ["NISSAN", "720"],
    ["BMW", "116I"],
    ["BMW", "118I"],
    ["BMW", "120I"],
    ["BMW", "125I"],
    ["BMW", "135I"],
    ["BMW", "320I"],
    ["BMW", "323I"],
    ["BMW", "325I"],
    ["BMW", "335I"],
    ["BMW", "M3"],
    ["BMW", "523I"],
    ["BMW", "525I"],
    ["BMW", "530I"],
    ["BMW", "540I"],
    ["BMW", "M5"],
    ["BMW", "630I"],
    ["BMW", "M6"],
    ["BMW", "X3"],
    ["BMW", "X5"],
    ["BMW", "X6"],
    ["BMW", "Z4"],
    ["BMW", "728I"],
    ["BMW", "730I"],
    ["BMW", "735I"],
    ["BMW", "740LI"],
    ["BMW", "750LI"],
    ["BMW", "E21"],
    ["BMW", "E30"],
    ["BMW", "E36"],
    ["BMW", "E46"],
    ["BMW", "E90"],
    ["BMW", "E12"],
    ["BMW", "E28"],
    ["BMW", "E34"],
    ["BMW", "E39"],
    ["BMW", "E60"],
    ["BMW", "E23"],
    ["BMW", "E32"],
    ["BMW", "E38"],
    ["BMW", "E65"],
    ["BMW", "E66"],
    ["BMW", "E67"],
    ["BMW", "520I"],
    ["BMW", "528I"],
    ["BMW", "535I"],
    ["BMW", "535 GT"],
    ["BMW", "320D"],
    ["BMW", "328I"],
    ["BMW", "520D"],
    ["BMW", "640I"],
    ["BMW", "650I"],
    ["BMW", "730LI"],
    ["BMW", "730LD"],
    ["BMW", "X1"],
    ["BMW", "123D"],
    ["BMW", "128I"],
    ["BMW", "130I"],
    ["BMW", "645CI"],
    ["BMW", "740I"],
    ["BMW", "738I"],
    ["BMW", "750I"],
    ["BMW", "760I"],
    ["BMW", "760LI"],
    ["BMW", "E81"],
    ["BMW", "E93"],
    ["BMW", "M4"],
    ["BMW", "M-SPORT"],
    ["BMW", "Z1"],
    ["BMW", "Z3"],
    ["BMW", "Z8"],
    ["BMW", "1 M"],
    ["BMW", "318I"],
    ["BMW", "318CI"],
    ["BMW", "320CI"],
    ["BMW", "325CI"],
    ["BMW", "330CI"],
    ["BMW", "X6 M"],
    ["BMW", "Z4 M"],
    ["BMW", "Z4 COUPE"],
    ["BMW", "428I GRAN COUPE"],
    ["BMW", "316I"],
    ["BMW", "335CI"],
    ["BMW", "640I GRAN COUPE"],
    ["BMW", "M6 GRAN COUPE"],
    ["BMW", "420I"],
    ["BMW", "428I"],
    ["BMW", "435I"],
    ["BMW", "320D GT"],
    ["BMW", "328I GT"],
    ["BMW", "220I"],
    ["BMW", "218I ACTIVE TOURER"],
    ["BMW", "220I GRAN TOURER"],
    ["BMW", "235I"],
    ["BMW", "I8"],
    ["BMW", "840CI"],
    ["BMW", "850I"],
    ["BMW", "850CI"],
    ["BMW", "850CSI"],
    ["BMW", "X4"],
    ["BMW", "I3"],
    ["BMW", "530D"],
    ["BMW", "340I"],
    ["BMW", "2002"],
    ["BMW", "M2"],
    ["BMW", "330E"],
    ["BMW", "328CI"],
    ["BMW", "ACTIVEHYBRID 3"],
    ["BMW", "430I"],
    ["BMW", "545I"],
    ["BMW", "ACTIVEHYBRID 5"],
    ["BMW", "630CI"],
    ["BMW", "640CI"],
    ["BMW", "650CI"],
    ["BMW", "740LE"],
    ["BMW", "745LI"],
    ["BMW", "ACTIVEHYBRID 7 L"],
    ["BMW", "323CI"],
    ["BMW", "525D"],
    ["BMW", "330I"],
    ["BMW", "530E"],
    ["BMW", "635CSI"],
    ["BMW", "628CSI"],
    ["BMW", "X2"],
    ["BMW", "735IL"],
    ["BMW", "X7"],
    ["PORSCHE", "BOXSTER"],
    ["PORSCHE", "CAYMAN"],
    ["PORSCHE", "911 CARRERA"],
    ["PORSCHE", "911 TARGA"],
    ["PORSCHE", "911 GT2"],
    ["PORSCHE", "911 TURBO"],
    ["PORSCHE", "CAYENNE"],
    ["PORSCHE", "PANAMERA"],
    ["PORSCHE", "356"],
    ["PORSCHE", "550"],
    ["PORSCHE", "914"],
    ["PORSCHE", "924"],
    ["PORSCHE", "928"],
    ["PORSCHE", "944"],
    ["PORSCHE", "959"],
    ["PORSCHE", "968"],
    ["PORSCHE", "991"],
    ["PORSCHE", "997"],
    ["PORSCHE", "C2S"],
    ["PORSCHE", "C4S"],
    ["PORSCHE", "CARERRA"],
    ["PORSCHE", "911 GT3"],
    ["PORSCHE", "911 CARRERA S"],
    ["PORSCHE", "911 CARRERA 4S"],
    ["PORSCHE", "911 GTS"],
    ["PORSCHE", "911 GT3 RS"],
    ["PORSCHE", "MACAN"],
    ["PORSCHE", "MACAN S"],
    ["PORSCHE", "MACAN TURBO"],
    ["PORSCHE", "CAYENNE DIESEL"],
    ["PORSCHE", "CAYENNE TURBO"],
    ["PORSCHE", "CAYENNE S"],
    ["PORSCHE", "CAYENNE TURBO S"],
    ["PORSCHE", "CAYENNE GTS"],
    ["PORSCHE", "BOXSTER S"],
    ["PORSCHE", "BOXSTER GTS"],
    ["PORSCHE", "CAYMAN S"],
    ["PORSCHE", "CAYMAN GTS"],
    ["PORSCHE", "CAYMAN GT4"],
    ["PORSCHE", "911S"],
    ["PORSCHE", "MACAN GTS"],
    ["VOLKSWAGEN", "POLO"],
    ["VOLKSWAGEN", "CROSSPOLO"],
    ["VOLKSWAGEN", "BEETLE"],
    ["VOLKSWAGEN", "GOLF"],
    ["VOLKSWAGEN", "JETTA"],
    ["VOLKSWAGEN", "PASSAT"],
    ["VOLKSWAGEN", "EOS"],
    ["VOLKSWAGEN", "TIGUAN"],
    ["VOLKSWAGEN", "TOUAREG"],
    ["VOLKSWAGEN", "SCIROCCO"],
    ["VOLKSWAGEN", "CC"],
    ["VOLKSWAGEN", "CROSS TOURAN"],
    ["VOLKSWAGEN", "GOLF GTI"],
    ["VOLKSWAGEN", "GOLF R"],
    ["VOLKSWAGEN", "PASSAT CC"],
    ["VOLKSWAGEN", "POLO GTI"],
    ["VOLKSWAGEN", "POLO SEDAN"],
    ["VOLKSWAGEN", "SCIROCCO R"],
    ["VOLKSWAGEN", "SHARAN"],
    ["VOLKSWAGEN", "BORA"],
    ["VOLKSWAGEN", "CARAVEL"],
    ["VOLKSWAGEN", "CORRADO"],
    ["VOLKSWAGEN", "CROSS GOLF"],
    ["VOLKSWAGEN", "KARMANN GHIA"],
    ["VOLKSWAGEN", "LUPO"],
    ["VOLKSWAGEN", "SANTANA"],
    ["VOLKSWAGEN", "TRANSPORTER"],
    ["VOLKSWAGEN", "TYPE"],
    ["VOLKSWAGEN", "VANAGON"],
    ["VOLKSWAGEN", "VENTO"],
    ["VOLKSWAGEN", "TOURAN"],
    ["VOLKSWAGEN", "KOMBI"],
    ["VOLKSWAGEN", "UP"],
    ["VOLKSWAGEN", "ARTEON"],
    ["VOLKSWAGEN", "PHAETON"],
    ["MAZDA", "BT-50"],
    ["MAZDA", "B2500"],
    ["MAZDA", "FIGHTER"],
    ["MAZDA", "MAZDA3"],
    ["MAZDA", "RX-8"],
    ["MAZDA", "MX-5"],
    ["MAZDA", "CX-9"],
    ["MAZDA", "323"],
    ["MAZDA", "FAMILIA"],
    ["MAZDA", "626"],
    ["MAZDA", "929"],
    ["MAZDA", "RX-7"],
    ["MAZDA", "PREMACY"],
    ["MAZDA", "CAPELLA"],
    ["MAZDA", "626 CRONOS"],
    ["MAZDA", "JUMBO"],
    ["MAZDA", "LANTIS"],
    ["MAZDA", "MPV"],
    ["MAZDA", "2"],
    ["MAZDA", "6"],
    ["MAZDA", "CX-5"],
    ["MAZDA", "CX-7"],
    ["MAZDA", "MAZDA3 MPS"],
    ["MAZDA", "MAZDA2"],
    ["MAZDA", "MAZDA5"],
    ["MAZDA", "MAZDA6"],
    ["MAZDA", "MAZDA8"],
    ["MAZDA", "3"],
    ["MAZDA", "5"],
    ["MAZDA", "8"],
    ["MAZDA", "ATENZA"],
    ["MAZDA", "AUTOZAM"],
    ["MAZDA", "AUTOZAM AZ-1"],
    ["MAZDA", "AUTOZAM AZ-3"],
    ["MAZDA", "AXELA"],
    ["MAZDA", "AZ"],
    ["MAZDA", "BIANTE"],
    ["MAZDA", "CAROL"],
    ["MAZDA", "CHANTEZ"],
    ["MAZDA", "COSMO"],
    ["MAZDA", "CRONOS"],
    ["MAZDA", "DEMIO"],
    ["MAZDA", "EFINI"],
    ["MAZDA", "ETUDE"],
    ["MAZDA", "LAPUTA"],
    ["MAZDA", "MILLENIA"],
    ["MAZDA", "MX-6"],
    ["MAZDA", "PARKWAY"],
    ["MAZDA", "PERSONA"],
    ["MAZDA", "PORTER"],
    ["MAZDA", "PROCEED"],
    ["MAZDA", "REVUE"],
    ["MAZDA", "ROAD PACER"],
    ["MAZDA", "ROADSTER"],
    ["MAZDA", "SAVANNA"],
    ["MAZDA", "SCRUM"],
    ["MAZDA", "SENTIA"],
    ["MAZDA", "SPEED"],
    ["MAZDA", "SPIANO"],
    ["MAZDA", "TRIBUTE"],
    ["MAZDA", "VERISA"],
    ["MAZDA", "808"],
    ["MAZDA", "E2000"],
    ["MAZDA", "CX-3"],
    ["MAZDA", "B2200"],
    ["MAZDA", "BONGO"],
    ["MAZDA", "LUCE"],
    ["MAZDA", "CX-8"],
    ["MAZDA", "CX-30"],
    ["ALFA ROMEO", "146"],
    ["ALFA ROMEO", "147"],
    ["ALFA ROMEO", "155"],
    ["ALFA ROMEO", "156"],
    ["ALFA ROMEO", "158"],
    ["ALFA ROMEO", "159"],
    ["ALFA ROMEO", "GT BLACKLINE"],
    ["ALFA ROMEO", "BRERA"],
    ["ALFA ROMEO", "SPIDER"],
    ["ALFA ROMEO", "145"],
    ["ALFA ROMEO", "GT"],
    ["ALFA ROMEO", "MITO"],
    ["ALFA ROMEO", "33"],
    ["ALFA ROMEO", "75"],
    ["ALFA ROMEO", "164"],
    ["ALFA ROMEO", "166"],
    ["ALFA ROMEO", "8C COMPETIZION"],
    ["ALFA ROMEO", "8C SPIDER"],
    ["ALFA ROMEO", "ALFASUD"],
    ["ALFA ROMEO", "ALFETTA"],
    ["ALFA ROMEO", "ALNA"],
    ["ALFA ROMEO", "GIULIA"],
    ["ALFA ROMEO", "GIULIETTA"],
    ["ALFA ROMEO", "GTV"],
    ["ALFA ROMEO", "MILANO"],
    ["ALFA ROMEO", "ZAGATO"],
    ["ALFA ROMEO", "4C"],
    ["ALFA ROMEO", "4C SPIDER"],
    ["ALFA ROMEO", "LANCIA"],
    ["ALFA ROMEO", "2000"],
    ["ALFA ROMEO", "STELVIO"],
    ["ASTON MARTIN", "DB9"],
    ["ASTON MARTIN", "RAPIDE"],
    ["ASTON MARTIN", "DB7"],
    ["ASTON MARTIN", "DBS"],
    ["ASTON MARTIN", "LAGONDA"],
    ["ASTON MARTIN", "VANQUISH"],
    ["ASTON MARTIN", "VANTAGE"],
    ["ASTON MARTIN", "VIRAGE"],
    ["ASTON MARTIN", "DB11"],
    ["ASTON MARTIN", "DB5"],
    ["ASTON MARTIN", "850"],
    ["AUDI", "A4"],
    ["AUDI", "A6"],
    ["AUDI", "A8"],
    ["AUDI", "TT"],
    ["AUDI", "Q5"],
    ["AUDI", "Q7"],
    ["AUDI", "A1"],
    ["AUDI", "A3"],
    ["AUDI", "A5"],
    ["AUDI", "A7"],
    ["AUDI", "R8"],
    ["AUDI", "RS3"],
    ["AUDI", "RS4"],
    ["AUDI", "RS5"],
    ["AUDI", "RS6"],
    ["AUDI", "S4"],
    ["AUDI", "S5"],
    ["AUDI", "80"],
    ["AUDI", "90"],
    ["AUDI", "100"],
    ["AUDI", "200"],
    ["AUDI", "TTS"],
    ["AUDI", "TTRS"],
    ["AUDI", "A2"],
    ["AUDI", "Q3"],
    ["AUDI", "S3"],
    ["AUDI", "S6"],
    ["AUDI", "S8"],
    ["AUDI", "S7"],
    ["AUDI", "RS7"],
    ["AUDI", "A8 L"],
    ["AUDI", "SQ5"],
    ["AUDI", "Q2"],
    ["AUDI", "Q8"],
    ["AUDI", "SQ7"],
    ["AUSTIN", "MORRIS"],
    ["AUSTIN", "1300"],
    ["AUSTIN", "A50"],
    ["AUSTIN", "MAESTRO"],
    ["AUSTIN", "METRO"],
    ["AUSTIN", "MINI"],
    ["AUSTIN", "MONTEGO"],
    ["AUSTIN", "A35"],
    ["AUSTIN", "HEALEY SPRITE"],
    ["BENTLEY", "AZURE"],
    ["BENTLEY", "CONTINENTAL"],
    ["BENTLEY", "CONTINENTAL GT"],
    ["BENTLEY", "CONTINENTAL GTC"],
    ["BENTLEY", "CONTINENTAL SUPERSPORTS"],
    ["BENTLEY", "BROOKLANDS"],
    ["BENTLEY", "EIGHT"],
    ["BENTLEY", "FLYING SPUR"],
    ["BENTLEY", "MULSANNE"],
    ["BENTLEY", "TURBO"],
    ["BENTLEY", "ARNAGE"],
    ["BENTLEY", "CONTINENTAL GT SPEED"],
    ["BENTLEY", "BENTAYGA"],
    ["BENTLEY", "TURBO R AUTO"],
    ["BUFORI", "LA JOYA MK"],
    ["BUFORI", "LA JOYA"],
    ["CAM", "PLACER-X"],
    ["CADILLAC", "ALLANTE"],
    ["CADILLAC", "CIMARRON"],
    ["CADILLAC", "CONCOURS"],
    ["CADILLAC", "CTS"],
    ["CADILLAC", "DEVILLE"],
    ["CADILLAC", "DTS"],
    ["CADILLAC", "ELDORADO"],
    ["CADILLAC", "ESCALADE"],
    ["CADILLAC", "FLEETWOOD"],
    ["CADILLAC", "LOMOUSINE"],
    ["CADILLAC", "SEVILE"],
    ["CADILLAC", "SRX"],
    ["CADILLAC", "STS"],
    ["CADILLAC", "XLR"],
    ["CHANA", "ERA CM8"],
    ["CHANA", "ERA CV6"],
    ["CHANA", "ERA KOMUTER"],
    ["CHANA", "ERA STAR"],
    ["CHANGAN", "ERA CV6"],
    ["CHANGAN", "ERA CM8"],
    ["CHANGAN", "ALSVIN"],
    ["CHERY", "A520"],
    ["CHERY", "EASTAR"],
    ["CHERY", "TIGGO"],
    ["CHERY", "A160"],
    ["CHERY", "QQ"],
    ["CHERY", "MAXIME"],
    ["CHERY", "TRANSCOM"],
    ["CHERY", "B240"],
    ["CHEVROLET", "AVEO"],
    ["CHEVROLET", "OPTRA"],
    ["CHEVROLET", "CAPTIVA"],
    ["CHEVROLET", "CRUZE"],
    ["CHEVROLET", "CAMARO"],
    ["CHEVROLET", "COLORADO"],
    ["CHEVROLET", "NABIRA"],
    ["CHEVROLET", "OPTRA 5"],
    ["CHEVROLET", "ASTRO"],
    ["CHEVROLET", "AVALANCE"],
    ["CHEVROLET", "BELAIR"],
    ["CHEVROLET", "BERETA"],
    ["CHEVROLET", "BLAZER"],
    ["CHEVROLET", "BLAZER SILVERRADO"],
    ["CHEVROLET", "C-10"],
    ["CHEVROLET", "C-1500"],
    ["CHEVROLET", "C-2500"],
    ["CHEVROLET", "C-3500"],
    ["CHEVROLET", "CAPRICE"],
    ["CHEVROLET", "CAVALIER"],
    ["CHEVROLET", "CELEBRITY"],
    ["CHEVROLET", "CHEVELLE"],
    ["CHEVROLET", "CHEVY"],
    ["CHEVROLET", "CHEVYVAN"],
    ["CHEVROLET", "CITATION"],
    ["CHEVROLET", "CIVET"],
    ["CHEVROLET", "CORSIA"],
    ["CHEVROLET", "CORVETTE"],
    ["CHEVROLET", "ELCAMINO"],
    ["CHEVROLET", "EXPRESS"],
    ["CHEVROLET", "HHR"],
    ["CHEVROLET", "IMPARA"],
    ["CHEVROLET", "K10"],
    ["CHEVROLET", "K-2500"],
    ["CHEVROLET", "K-3500"],
    ["CHEVROLET", "K-5"],
    ["CHEVROLET", "LUMINA"],
    ["CHEVROLET", "MALIBU"],
    ["CHEVROLET", "MONTECARLO"],
    ["CHEVROLET", "MONZA"],
    ["CHEVROLET", "MW"],
    ["CHEVROLET", "NOVA"],
    ["CHEVROLET", "S-10"],
    ["CHEVROLET", "S-10 BLAZER"],
    ["CHEVROLET", "SILVERADO"],
    ["CHEVROLET", "SONIC"],
    ["CHEVROLET", "SSR"],
    ["CHEVROLET", "SUBURBAN"],
    ["CHEVROLET", "TAHOE"],
    ["CHEVROLET", "TRAILBLAZER"],
    ["CHEVROLET", "TRAVERSE"],
    ["CHEVROLET", "TYPHOON"],
    ["CHEVROLET", "VEGA"],
    ["CHEVROLET", "VENTURE"],
    ["CHEVROLET", "ORLANDO"],
    ["CHEVROLET", "SPARK"],
    ["CHRYSLER", "300"],
    ["CHRYSLER", "ACCLAIM"],
    ["CHRYSLER", "ASPEN"],
    ["CHRYSLER", "CORDOBA"],
    ["CHRYSLER", "CROSSFIRE"],
    ["CHRYSLER", "DAYTONA"],
    ["CHRYSLER", "E CLASS"],
    ["CHRYSLER", "GRAND VOYAGER"],
    ["CHRYSLER", "IMPERIAL"],
    ["CHRYSLER", "LE BARON"],
    ["CHRYSLER", "LHS NEON"],
    ["CHRYSLER", "NEWPORT"],
    ["CHRYSLER", "NEW YORKER"],
    ["CHRYSLER", "PACIFICA"],
    ["CHRYSLER", "PT CRUISER"],
    ["CHRYSLER", "SIMCA"],
    ["CHRYSLER", "STRATUS"],
    ["CHRYSLER", "TOWN & COUNTRY"],
    ["CHRYSLER", "VIPER"],
    ["CHRYSLER", "VISION"],
    ["CHRYSLER", "VOYAGER"],
    ["CITROEN", "GRAND C4 PICASSO"],
    ["CITROEN", "C6"],
    ["CITROEN", "ZX"],
    ["CITROEN", "EVASION"],
    ["CITROEN", "XSARA"],
    ["CITROEN", "C3"],
    ["CITROEN", "C4"],
    ["CITROEN", "DS3"],
    ["CITROEN", "DS4"],
    ["CITROEN", "XANTIA"],
    ["CITROEN", "C2"],
    ["CITROEN", "C8"],
    ["CITROEN", "CHANSON"],
    ["CITROEN", "CX"],
    ["CITROEN", "DIANE"],
    ["CITROEN", "GS"],
    ["CITROEN", "GSA"],
    ["CITROEN", "JUMPY"],
    ["CITROEN", "PICASSO"],
    ["CITROEN", "SAXO"],
    ["CITROEN", "SM"],
    ["CITROEN", "VISA"],
    ["CITROEN", "XM"],
    ["CITROEN", "DS5"],
    ["CITROEN", "BERLINGO"],
    ["CITROEN", "C5"],
    ["CITROEN", "BX"],
    ["CITROEN", "GRAND C4 SPACETOURER"],
    ["DAIHATSU", "AURA"],
    ["DAIHATSU", "CHARADE"],
    ["DAIHATSU", "CHARMANT"],
    ["DAIHATSU", "ESPRI"],
    ["DAIHATSU", "MIRA"],
    ["DAIHATSU", "ROCKY"],
    ["DAIHATSU", "ASCENT"],
    ["DAIHATSU", "ALTIS"],
    ["DAIHATSU", "APPLAUSE"],
    ["DAIHATSU", "ATRAI"],
    ["DAIHATSU", "BEGO"],
    ["DAIHATSU", "BOON"],
    ["DAIHATSU", "COMPAGNO"],
    ["DAIHATSU", "CONSORTE"],
    ["DAIHATSU", "COO"],
    ["DAIHATSU", "COPEN"],
    ["DAIHATSU", "CUORE"],
    ["DAIHATSU", "ESSE"],
    ["DAIHATSU", "GRAN MAX"],
    ["DAIHATSU", "LEEZA"],
    ["DAIHATSU", "MAX"],
    ["DAIHATSU", "MIDGET"],
    ["DAIHATSU", "MOVE"],
    ["DAIHATSU", "NAKED"],
    ["DAIHATSU", "OPTI"],
    ["DAIHATSU", "PYZAR"],
    ["DAIHATSU", "RUGGER"],
    ["DAIHATSU", "SONICA"],
    ["DAIHATSU", "STORIA"],
    ["DAIHATSU", "TANTO"],
    ["DAIHATSU", "TERIOS"],
    ["DAIHATSU", "YRV"],
    ["DAIHATSU", "FEROZA"],
    ["DAIHATSU", "HIJET"],
    ["DAIHATSU", "HIJET MAXX"],
    ["DAIHATSU", "TAFT"],
    ["DODGE", "RAM"],
    ["DODGE", "NITRO"],
    ["DODGE", "CHALLENGER"],
    ["DODGE", "CHARGER"],
    ["DODGE", "MAGNUM"],
    ["DODGE", "VIPER"],
    ["FERRARI", "360"],
    ["FERRARI", "430"],
    ["FERRARI", "458"],
    ["FERRARI", "599"],
    ["FERRARI", "612"],
    ["FERRARI", "F355"],
    ["FERRARI", "308"],
    ["FERRARI", "328"],
    ["FERRARI", "348"],
    ["FERRARI", "355"],
    ["FERRARI", "365"],
    ["FERRARI", "400"],
    ["FERRARI", "412"],
    ["FERRARI", "512"],
    ["FERRARI", "550"],
    ["FERRARI", "575"],
    ["FERRARI", "CALIFORNIA"],
    ["FERRARI", "CHALLENGE STRADALE"],
    ["FERRARI", "DINO"],
    ["FERRARI", "ENZO"],
    ["FERRARI", "MONDIAL"],
    ["FERRARI", "SUPERAMERICA"],
    ["FERRARI", "TESTAROSSA"],
    ["FERRARI", "488"],
    ["FERRARI", "F12"],
    ["FERRARI", "FF"],
    ["FERRARI", "328 GTS"],
    ["FIAT", "124"],
    ["FIAT", "127"],
    ["FIAT", "128"],
    ["FIAT", "500"],
    ["FIAT", "UNO"],
    ["FIAT", "PUNTO"],
    ["FIAT", "BRAVA"],
    ["FIAT", "PANDA"],
    ["FIAT", "LADA NIVA"],
    ["FIAT", "ULYSSE"],
    ["FIAT", "MAREA"],
    ["FIAT", "MULTIPLA"],
    ["FIAT", "126"],
    ["FIAT", "131"],
    ["FIAT", "132"],
    ["FIAT", "850"],
    ["FIAT", "ARGENTA"],
    ["FIAT", "BARCHETTA"],
    ["FIAT", "BRAVISSIMO"],
    ["FIAT", "CINQUECENTO"],
    ["FIAT", "COUPE"],
    ["FIAT", "CROMA"],
    ["FIAT", "DINO"],
    ["FIAT", "GRANDE PUNTO"],
    ["FIAT", "REGATA"],
    ["FIAT", "RITMO"],
    ["FIAT", "TEMPRA"],
    ["FIAT", "TIPO"],
    ["FIAT", "BRAVO"],
    ["FIAT", "ABARTH"],
    ["FIAT", "600"],
    ["FIAT", "DOBLO"],
    ["FIAT", "STILO"],
    ["FIAT", "125"],
    ["FODAY", "NHQ1029"],
    ["FORD", "EVEREST"],
    ["FORD", "ESCAPE"],
    ["FORD", "RANGER"],
    ["FORD", "FOCUS"],
    ["FORD", "LASER"],
    ["FORD", "TELSTAR"],
    ["FORD", "LYNX"],
    ["FORD", "ESCORT"],
    ["FORD", "MONDEO"],
    ["FORD", "FIESTA"],
    ["FORD", "S-MAX"],
    ["FORD", "AEROSTAR"],
    ["FORD", "BRONCO"],
    ["FORD", "CAPRI"],
    ["FORD", "CONTOUR"],
    ["FORD", "CORTINA"],
    ["FORD", "CROWN VICTORIA"],
    ["FORD", "ECONOLINE"],
    ["FORD", "ECONOVAN"],
    ["FORD", "EDGE"],
    ["FORD", "EXCURSION"],
    ["FORD", "EXPEDITION"],
    ["FORD", "EXPLORER"],
    ["FORD", "FAIRLANE"],
    ["FORD", "FAIRMONT"],
    ["FORD", "FALCON"],
    ["FORD", "FLEX"],
    ["FORD", "KUGA"],
    ["FORD", "MUSTANG"],
    ["FORD", "PINT"],
    ["FORD", "ECOSPORT"],
    ["FORD", "FOCUS ST"],
    ["FORD", "ANGLIA"],
    ["FORD", "TRANSIT"],
    ["FORD", "SPECTRON"],
    ["FORD", "COURIER"],
    ["FORD", "GALAXIE"],
    ["FORD", "F150"],
    ["FOTON", "VIEW"],
    ["FOTON", "BISON SAVANNA"],
    ["GOLDEN DRAGON", "RK COMMUTER"],
    ["GOLDEN DRAGON", "X5"],
    ["GREAT WALL", "HAVAL"],
    ["GREAT WALL", "WINGLE"],
    ["GREAT WALL", "M4"],
    ["GREAT WALL", "C30"],
    ["GREAT WALL", "H3"],
    ["GREAT WALL", "H5"],
    ["GREAT WALL", "H6"],
    ["GREAT WALL", "C50"],
    ["GREAT WALL", "C20R"],
    ["HAVAL", "H1"],
    ["HAVAL", "H2"],
    ["HAVAL", "H9"],
    ["HUMMER", "H3"],
    ["HUMMER", "H1"],
    ["HUMMER", "H2"],
    ["HYUNDAI", "GETZ"],
    ["HYUNDAI", "KONA"],
    ["HYUNDAI", "MATRIX"],
    ["HYUNDAI", "TRAJET"],
    ["HYUNDAI", "COUPE"],
    ["HYUNDAI", "TUCSON"],
    ["HYUNDAI", "XG250"],
    ["HYUNDAI", "NF"],
    ["HYUNDAI", "AZERA"],
    ["HYUNDAI", "ACCENT"],
    ["HYUNDAI", "ELANTRA"],
    ["HYUNDAI", "SONATA"],
    ["HYUNDAI", "I10"],
    ["HYUNDAI", "I20"],
    ["HYUNDAI", "I30"],
    ["HYUNDAI", "ATOS"],
    ["HYUNDAI", "GRANDEUR XG"],
    ["HYUNDAI", "TERRACAN"],
    ["HYUNDAI", "SANTA FE"],
    ["HYUNDAI", "SCOUPE"],
    ["HYUNDAI", "AMICA"],
    ["HYUNDAI", "AVANTE"],
    ["HYUNDAI", "EXCEL"],
    ["HYUNDAI", "GALLOPER"],
    ["HYUNDAI", "GRAND STAREX"],
    ["HYUNDAI", "I40"],
    ["HYUNDAI", "I800"],
    ["HYUNDAI", "IX20"],
    ["HYUNDAI", "IX35"],
    ["HYUNDAI", "JM"],
    ["HYUNDAI", "LANTRA"],
    ["HYUNDAI", "PONY"],
    ["HYUNDAI", "SCOOP"],
    ["HYUNDAI", "STAREX"],
    ["HYUNDAI", "STELLAR"],
    ["HYUNDAI", "TB"],
    ["HYUNDAI", "X2"],
    ["HYUNDAI", "XG"],
    ["HYUNDAI", "VELOSTER"],
    ["HYUNDAI", "IONIQ"],
    ["HYUNDAI", "GENESIS"],
    ["INFINITI", "G COUPE"],
    ["INFINITI", "M"],
    ["INFINITI", "M HYBRID"],
    ["INFINITI", "EX"],
    ["INFINITI", "FX"],
    ["INFINITI", "Q50"],
    ["INFINITI", "QX60"],
    ["INFINITI", "QX70"],
    ["INFINITI", "QX50"],
    ["INFINITI", "QX80"],
    ["INFINITI", "Q60"],
    ["INFINITI", "Q70"],
    ["INOKOM", "I10"],
    ["INOKOM", "ATOS"],
    ["INOKOM", "GETZ"],
    ["INOKOM", "MATRIX"],
    ["INOKOM", "SANTA"],
    ["INOKOM", "SANTA FE"],
    ["INOKOM", "ELANTRA"],
    ["INOKOM", "LORIMAS"],
    ["INOKOM", "PERMAS"],
    ["ISUZU", "D-MAX"],
    ["ISUZU", "TROOPER"],
    ["ISUZU", "INVADER"],
    ["ISUZU", "RODEO"],
    ["ISUZU", "VEHICROSS"],
    ["ISUZU", "117 COUPE"],
    ["ISUZU", "ASKA"],
    ["ISUZU", "BELLET"],
    ["ISUZU", "BIGHORN"],
    ["ISUZU", "COMO"],
    ["ISUZU", "FASTER"],
    ["ISUZU", "FILLY"],
    ["ISUZU", "FLORIAN"],
    ["ISUZU", "FORWARD"],
    ["ISUZU", "F SERIES"],
    ["ISUZU", "GEMINETT"],
    ["ISUZU", "GEMINI"],
    ["ISUZU", "HILLMAN MINX"],
    ["ISUZU", "MU"],
    ["ISUZU", "PIAZZA"],
    ["ISUZU", "WIZARD"],
    ["ISUZU", "ELF"],
    ["ISUZU", "KBD25"],
    ["ISUZU", "MU-X"],
    ["ISUZU", "CITATION"],
    ["ISUZU", "FARGO"],
    ["JAGUAR", "X-TYPE"],
    ["JAGUAR", "XF"],
    ["JAGUAR", "XJ"],
    ["JAGUAR", "DAIMLER"],
    ["JAGUAR", "XK"],
    ["JAGUAR", "XKR"],
    ["JAGUAR", "XJ6"],
    ["JAGUAR", "XJ12"],
    ["JAGUAR", "XJ8"],
    ["JAGUAR", "S-TYPE"],
    ["JAGUAR", "420"],
    ["JAGUAR", "240"],
    ["JAGUAR", "340"],
    ["JAGUAR", "XFR"],
    ["JAGUAR", "XJR"],
    ["JAGUAR", "XJS"],
    ["JAGUAR", "XJ18"],
    ["JAGUAR", "F-TYPE"],
    ["JAGUAR", "F-TYPE S"],
    ["JAGUAR", "XJL"],
    ["JAGUAR", "F-PACE"],
    ["JAGUAR", "SOVEREIGN"],
    ["JAGUAR", "XE"],
    ["JAGUAR", "E-TYPE"],
    ["JAGUAR", "MK9"],
    ["JEEP", "CHEROKEE"],
    ["JEEP", "GRAND CHEROKEE"],
    ["JEEP", "WRANGLER"],
    ["JEEP", "COMMANDER"],
    ["JEEP", "COMPASS"],
    ["JEEP", "LIBERTY"],
    ["JEEP", "PATRIOT"],
    ["JEEP", "RENEGADE"],
    ["JEEP", "WILLYS"],
    ["JOYLONG", "ERA KOMUTER"],
    ["JOYLONG", "MINI VAN"],
    ["KIA", "PICANTO"],
    ["KIA", "RIO"],
    ["KIA", "SPECTRA"],
    ["KIA", "OPTIMA"],
    ["KIA", "SPORTAGE"],
    ["KIA", "RONDO"],
    ["KIA", "FORTE"],
    ["KIA", "FORTE COUP"],
    ["KIA", "CARENS"],
    ["KIA", "SORENTO"],
    ["KIA", "CARNIVAL"],
    ["KIA", "SEPHIA"],
    ["KIA", "OPIRUS"],
    ["KIA", "CEED"],
    ["KIA", "CERATO"],
    ["KIA", "CITRA"],
    ["KIA", "CLARUS"],
    ["KIA", "ELAN"],
    ["KIA", "FESTIVA"],
    ["KIA", "FIESTA"],
    ["KIA", "MAGENTIS"],
    ["KIA", "MENTOR"],
    ["KIA", "PRIDE"],
    ["KIA", "VIGATO"],
    ["KIA", "PREGIO"],
    ["KIA", "ROCSTA"],
    ["KIA", "STINGER"],
    ["LMG", "LMS31SLE"],
    ["LAMBORGHINI", "GALLARDO"],
    ["LAMBORGHINI", "MURCIELAGO"],
    ["LAMBORGHINI", "AVENTADOR"],
    ["LAMBORGHINI", "COUNTACH"],
    ["LAMBORGHINI", "DIABLO"],
    ["LAMBORGHINI", "JALPA"],
    ["LAMBORGHINI", "LM002"],
    ["LAMBORGHINI", "SUPERLEGGERA"],
    ["LAMBORGHINI", "ESPADA"],
    ["LAMBORGHINI", "HURACAN"],
    ["LAMBORGHINI", "URUS"],
    ["LAND ROVER", "DEFENDER"],
    ["LAND ROVER", "FREELANDER"],
    ["LAND ROVER", "DISCOVERY"],
    ["LAND ROVER", "RANGE ROVER"],
    ["LAND ROVER", "RANGE ROVER SPORT"],
    ["LAND ROVER", "RANGE ROVER EVOQUE"],
    ["LAND ROVER", "RANGE ROVER VOGUE"],
    ["LAND ROVER", "DISCOVERY SPORT"],
    ["LAND ROVER", "RANGE ROVER VELAR"],
    ["LEXUS", "IS 250"],
    ["LEXUS", "RX 350"],
    ["LEXUS", "GS 300"],
    ["LEXUS", "LS 460"],
    ["LEXUS", "CALSIOR"],
    ["LEXUS", "CT HYBRID"],
    ["LEXUS", "IS F"],
    ["LEXUS", "LS HYBRID"],
    ["LEXUS", "LX"],
    ["LEXUS", "RX HYBRID"],
    ["LEXUS", "CT200"],
    ["LEXUS", "ES300"],
    ["LEXUS", "ES330"],
    ["LEXUS", "GS250"],
    ["LEXUS", "GS300"],
    ["LEXUS", "GS350"],
    ["LEXUS", "GS400"],
    ["LEXUS", "GS430"],
    ["LEXUS", "GS450"],
    ["LEXUS", "GS460"],
    ["LEXUS", "GX470"],
    ["LEXUS", "IS 350"],
    ["LEXUS", "LS 400"],
    ["LEXUS", "LS 430"],
    ["LEXUS", "LS 600"],
    ["LEXUS", "LX 450"],
    ["LEXUS", "LX 470"],
    ["LEXUS", "LX 570"],
    ["LEXUS", "RX"],
    ["LEXUS", "RX 330"],
    ["LEXUS", "RX 400"],
    ["LEXUS", "RX 450"],
    ["LEXUS", "SC300"],
    ["LEXUS", "SC400"],
    ["LEXUS", "SC430"],
    ["LEXUS", "GS HYBRID"],
    ["LEXUS", "IS HYBRID"],
    ["LEXUS", "ES250"],
    ["LEXUS", "NX200T"],
    ["LEXUS", "NX F SPORT"],
    ["LEXUS", "NX HYBRID"],
    ["LEXUS", "CT F SPORT"],
    ["LEXUS", "RC350"],
    ["LEXUS", "RC F"],
    ["LEXUS", "IS250 F SPORT"],
    ["LEXUS", "IS 200"],
    ["LEXUS", "RX 350 F SPORT"],
    ["LEXUS", "RX 200T"],
    ["LEXUS", "GS F"],
    ["LEXUS", "GS200"],
    ["LEXUS", "RX270"],
    ["LEXUS", "RX300"],
    ["LEXUS", "IS 300"],
    ["LEXUS", "RX 300"],
    ["LEXUS", "NX 300"],
    ["LEXUS", "LC 500"],
    ["LEXUS", "LS 500"],
    ["LEXUS", "UX200"],
    ["LOTUS", "ELISE"],
    ["LOTUS", "EUROPA"],
    ["LOTUS", "EXIGE"],
    ["LOTUS", "340R"],
    ["LOTUS", "ELAN"],
    ["LOTUS", "ESPRIT"],
    ["LOTUS", "EVORA"],
    ["LOTUS", "EXCEL"],
    ["LOTUS", "SUPER SEVEN"],
    ["MG", "F"],
    ["MG", "TF"],
    ["MG", "ZT"],
    ["MG", "MAESTRO"],
    ["MG", "METRO"],
    ["MG", "MGA"],
    ["MG", "MGB"],
    ["MG", "MGF"],
    ["MG", "MIDGET"],
    ["MG", "MONTEGO"],
    ["MG", "RV 8"],
    ["MINI", "COOPER"],
    ["MINI", "COOPER S"],
    ["MINI", "COOPER CLUBMAN"],
    ["MINI", "COOPER S CLUBMAN"],
    ["MINI", "COUNTRYMAN"],
    ["MINI", "COUNTRYMAN S"],
    ["MINI", "JOHN COOPER WORKS"],
    ["MINI", "PICKUP (CLASSIC)"],
    ["MINI", "ONE"],
    ["MINI", "COOPER CABRIO"],
    ["MINI", "PACEMAN"],
    ["MINI", "CLUBMAN DLX"],
    ["MAHINDRA", "SCORPIO"],
    ["MAHINDRA", "CJ"],
    ["MAHINDRA", "MARKMAN"],
    ["MASERATI", "GRANTURISMO"],
    ["MASERATI", "GRANCABRIO"],
    ["MASERATI", "QUATTROPORTE"],
    ["MASERATI", "222"],
    ["MASERATI", "228"],
    ["MASERATI", "3200GT"],
    ["MASERATI", "430"],
    ["MASERATI", "BITURBO"],
    ["MASERATI", "COUPE"],
    ["MASERATI", "GHIBLI"],
    ["MASERATI", "GRAN SPORT"],
    ["MASERATI", "KARIF"],
    ["MASERATI", "KHAMSIN"],
    ["MASERATI", "KYALAMI"],
    ["MASERATI", "MERAK"],
    ["MASERATI", "ROYAL"],
    ["MASERATI", "SHAMAL"],
    ["MASERATI", "SPIDER ZAGATO"],
    ["MASERATI", "LEVANTE"],
    ["MAXUS", "G10"],
    ["MAXUS", "ISTANA"],
    ["MAXUS", "V80"],
    ["MAXUS", "T60"],
    ["MCLAREN", "12C SPIDER"],
    ["MCLAREN", "650S"],
    ["MCLAREN", "MP4-12C"],
    ["MCLAREN", "540C"],
    ["MCLAREN", "570"],
    ["MCLAREN", "675LT"],
    ["MCLAREN", "720S"],
    ["MCLAREN", "F1"],
    ["MITSUBISHI", "LANCER"],
    ["MITSUBISHI", "GRANDIS"],
    ["MITSUBISHI", "TRITON"],
    ["MITSUBISHI", "PAJERO"],
    ["MITSUBISHI", "COLT"],
    ["MITSUBISHI", "GALANT"],
    ["MITSUBISHI", "TREDIA"],
    ["MITSUBISHI", "STORM"],
    ["MITSUBISHI", "EVO LANCER"],
    ["MITSUBISHI", "SUPER TOURING"],
    ["MITSUBISHI", "SPACEWAGON"],
    ["MITSUBISHI", "OUTLANDER"],
    ["MITSUBISHI", "ASX"],
    ["MITSUBISHI", "AIRTREK"],
    ["MITSUBISHI", "CHALLENGER"],
    ["MITSUBISHI", "COLT RALLIART"],
    ["MITSUBISHI", "PAJERO SPORT"],
    ["MITSUBISHI", "ASPIRE"],
    ["MITSUBISHI", "ATTRAGE"],
    ["MITSUBISHI", "BRAVO"],
    ["MITSUBISHI", "CARISMA"],
    ["MITSUBISHI", "CHARIOT"],
    ["MITSUBISHI", "CORDIA"],
    ["MITSUBISHI", "DEBONAIR"],
    ["MITSUBISHI", "DIAMANTE"],
    ["MITSUBISHI", "DIGNITY"],
    ["MITSUBISHI", "DINGO"],
    ["MITSUBISHI", "DION"],
    ["MITSUBISHI", "ECLIPSE"],
    ["MITSUBISHI", "EK"],
    ["MITSUBISHI", "EMERAUDE"],
    ["MITSUBISHI", "ETERNA"],
    ["MITSUBISHI", "EURO"],
    ["MITSUBISHI", "EVO"],
    ["MITSUBISHI", "FE"],
    ["MITSUBISHI", "FORTE"],
    ["MITSUBISHI", "FTO"],
    ["MITSUBISHI", "GTO"],
    ["MITSUBISHI", "JEEP"],
    ["MITSUBISHI", "LEGNUM"],
    ["MITSUBISHI", "LIBERO"],
    ["MITSUBISHI", "MAGNA"],
    ["MITSUBISHI", "MINICA"],
    ["MITSUBISHI", "MIRAGE"],
    ["MITSUBISHI", "PISTACHIO"],
    ["MITSUBISHI", "PROUDIA"],
    ["MITSUBISHI", "RVR"],
    ["MITSUBISHI", "SHOGUN"],
    ["MITSUBISHI", "SIGMA"],
    ["MITSUBISHI", "SPACE GEAR"],
    ["MITSUBISHI", "STRADA"],
    ["MITSUBISHI", "TOPPO"],
    ["MITSUBISHI", "DELICA"],
    ["MITSUBISHI", "TRITON LITE"],
    ["MITSUBISHI", "SAPPORO"],
    ["MITSUBISHI", "I-MIEV"],
    ["MITSUOKA", "GALUE"],
    ["MITSUOKA", "HIMIKO"],
    ["MITSUOKA", "LE SEYDE"],
    ["MITSUOKA", "LIKE"],
    ["MITSUOKA", "MC-1"],
    ["MITSUOKA", "NOUERA"],
    ["MITSUOKA", "OROCHI"],
    ["MITSUOKA", "RAY"],
    ["MITSUOKA", "RYOGA"],
    ["MITSUOKA", "VIEWT"],
    ["MITSUOKA", "YUGA"],
    ["MITSUOKA", "ZERO1"],
    ["MITSUOKA", "CUTE"],
    ["MITSUOKA", "M-BOX"],
    ["MITSUOKA", "LX460"],
    ["MORRIS", "1300"],
    ["MORRIS", "ITAL"],
    ["MORRIS", "MINI"],
    ["MORRIS", "MINOR"],
    ["MORRIS", "TEN"],
    ["MORRIS", "OXFORD"],
    ["NAZA", "FORZA"],
    ["NAZA", "CITRA"],
    ["NAZA", "RIA"],
    ["NAZA", "SORENTO"],
    ["NAZA", "206"],
    ["NAZA", "SUTERA"],
    ["NAZA", "FORTE"],
    ["NAZA", "BESTARI"],
    ["NAZA", "PICANTO"],
    ["NAZA", "SURIA"],
    ["PEUGEOT", "206 COUPE"],
    ["PEUGEOT", "308 VTI"],
    ["PEUGEOT", "308 TURBO PANORAMIC"],
    ["PEUGEOT", "308 GT"],
    ["PEUGEOT", "405"],
    ["PEUGEOT", "406"],
    ["PEUGEOT", "407"],
    ["PEUGEOT", "207"],
    ["PEUGEOT", "207 SV"],
    ["PEUGEOT", "308 TURBO"],
    ["PEUGEOT", "5008"],
    ["PEUGEOT", "508"],
    ["PEUGEOT", "RCZ"],
    ["PEUGEOT", "408"],
    ["PEUGEOT", "806"],
    ["PEUGEOT", "807"],
    ["PEUGEOT", "1007"],
    ["PEUGEOT", "104"],
    ["PEUGEOT", "106"],
    ["PEUGEOT", "205"],
    ["PEUGEOT", "3008"],
    ["PEUGEOT", "304"],
    ["PEUGEOT", "305"],
    ["PEUGEOT", "306"],
    ["PEUGEOT", "307"],
    ["PEUGEOT", "309"],
    ["PEUGEOT", "504"],
    ["PEUGEOT", "505"],
    ["PEUGEOT", "604"],
    ["PEUGEOT", "605"],
    ["PEUGEOT", "607"],
    ["PEUGEOT", "208"],
    ["PEUGEOT", "208 GTI"],
    ["PEUGEOT", "205 GTI"],
    ["PEUGEOT", "206 GTI"],
    ["PEUGEOT", "207 GTI"],
    ["PEUGEOT", "108"],
    ["PEUGEOT", "2008"],
    ["PEUGEOT", "TRAVELLER"],
    ["PEUGEOT", "308"],
    ["RENAULT", "KANGOO"],
    ["RENAULT", "CLIO"],
    ["RENAULT", "MEGANE"],
    ["RENAULT", "GRAND ESPACE"],
    ["RENAULT", "RT19"],
    ["RENAULT", "SCENIC"],
    ["RENAULT", "LAGUNA"],
    ["RENAULT", "KOLEOS"],
    ["RENAULT", "11"],
    ["RENAULT", "14"],
    ["RENAULT", "15"],
    ["RENAULT", "16"],
    ["RENAULT", "17"],
    ["RENAULT", "18"],
    ["RENAULT", "19"],
    ["RENAULT", "20"],
    ["RENAULT", "21"],
    ["RENAULT", "25"],
    ["RENAULT", "30"],
    ["RENAULT", "4"],
    ["RENAULT", "5"],
    ["RENAULT", "ALPHINE"],
    ["RENAULT", "AVANTIME"],
    ["RENAULT", "EXPRESS"],
    ["RENAULT", "FUEGO"],
    ["RENAULT", "GRAND SCENIC"],
    ["RENAULT", "LUTECIA"],
    ["RENAULT", "SAFRANE"],
    ["RENAULT", "SPORT SPIDER"],
    ["RENAULT", "TWINGO"],
    ["RENAULT", "WIND"],
    ["RENAULT", "FLUENCE"],
    ["RENAULT", "CAPTUR"],
    ["RENAULT", "TWIZY"],
    ["RENAULT", "ZOE"],
    ["ROLLS-ROYCE", "GHOST"],
    ["ROLLS-ROYCE", "PHANTOM"],
    ["ROLLS-ROYCE", "CORNICHE"],
    ["ROLLS-ROYCE", "LIMOUSINE"],
    ["ROLLS-ROYCE", "PARK WARD"],
    ["ROLLS-ROYCE", "SILVER CLOUD"],
    ["ROLLS-ROYCE", "SILVER DAWN"],
    ["ROLLS-ROYCE", "SILVER SERAPH"],
    ["ROLLS-ROYCE", "SILVER SHADOW"],
    ["ROLLS-ROYCE", "SILVER SPIRIT"],
    ["ROLLS-ROYCE", "SILVER SPUR"],
    ["ROLLS-ROYCE", "SILVER WRAITH"],
    ["ROLLS-ROYCE", "TOURING LIMOUSINE"],
    ["ROLLS-ROYCE", "CAMARGUE"],
    ["ROLLS-ROYCE", "DAWN"],
    ["ROLLS-ROYCE", "CULLINAN"],
    ["ROVER", "100"],
    ["ROVER", "200"],
    ["ROVER", "2600"],
    ["ROVER", "3500"],
    ["ROVER", "400"],
    ["ROVER", "600"],
    ["ROVER", "75"],
    ["ROVER", "800"],
    ["ROVER", "GSI ESTATE"],
    ["ROVER", "MINI"],
    ["ROVER", "VANDEN PLAS"],
    ["ROVER", "TOURER"],
    ["ROVER", "VITESSE"],
    ["ROVER", "P4 60"],
    ["SAAB", "900"],
    ["SAAB", "600"],
    ["SAAB", "9000"],
    ["SAAB", "9-2"],
    ["SAAB", "9-3"],
    ["SAAB", "9-5"],
    ["SAAB", "96"],
    ["SAAB", "99"],
    ["SAAB", "TURBO X"],
    ["SHENYANG BRILLIANCE", "ERA JINBEI"],
    ["SKODA", "OCTAVIA"],
    ["SKODA", "FABIA"],
    ["SKODA", "SUPERB"],
    ["SKODA", "YETI"],
    ["SKODA", "ROOMSTER"],
    ["SMART", "FORTWO"],
    ["SMART", "FORFOUR"],
    ["SMART", "ROADSTER"],
    ["SSANGYONG", "KYRON"],
    ["SSANGYONG", "REXTON"],
    ["SSANGYONG", "STAVIC"],
    ["SSANGYONG", "ACTYON"],
    ["SSANGYONG", "KORANDO"],
    ["SSANGYONG", "CHAIRMAN"],
    ["SSANGYONG", "MUSSO"],
    ["SSANGYONG", "RODIUS"],
    ["SUBARU", "IMPREZA"],
    ["SUBARU", "FORESTER"],
    ["SUBARU", "LEGACY"],
    ["SUBARU", "WRX"],
    ["SUBARU", "360"],
    ["SUBARU", "ALCRYONE"],
    ["SUBARU", "BIGHORN"],
    ["SUBARU", "DEX"],
    ["SUBARU", "DIAS WAGON"],
    ["SUBARU", "DOMINGO"],
    ["SUBARU", "EXIGA"],
    ["SUBARU", "JUSTY"],
    ["SUBARU", "LEONE"],
    ["SUBARU", "LUCRA"],
    ["SUBARU", "OUTBACK"],
    ["SUBARU", "PLEO"],
    ["SUBARU", "R1"],
    ["SUBARU", "R2"],
    ["SUBARU", "REX"],
    ["SUBARU", "STELLA"],
    ["SUBARU", "TRAVIQ"],
    ["SUBARU", "TREZIA"],
    ["SUBARU", "VIVIO"],
    ["SUBARU", "BRZ"],
    ["SUBARU", "XV"],
    ["SUBARU", "BRAT"],
    ["SUBARU", "LEVORG"],
    ["SUZUKI", "SWIFT"],
    ["SUZUKI", "SX4"],
    ["SUZUKI", "GRAND VITARA"],
    ["SUZUKI", "JIMNY"],
    ["SUZUKI", "VITARA"],
    ["SUZUKI", "ERV"],
    ["SUZUKI", "ALTO"],
    ["SUZUKI", "KIZASHI"],
    ["SUZUKI", "AERIO"],
    ["SUZUKI", "APV"],
    ["SUZUKI", "CAPPUCCINO"],
    ["SUZUKI", "CARA"],
    ["SUZUKI", "CERVO"],
    ["SUZUKI", "CULTUS"],
    ["SUZUKI", "ESCUDO"],
    ["SUZUKI", "EVERY"],
    ["SUZUKI", "FRONTE"],
    ["SUZUKI", "GRAND ESCUDO"],
    ["SUZUKI", "IGNIS"],
    ["SUZUKI", "KEI"],
    ["SUZUKI", "LANDY"],
    ["SUZUKI", "LIANA"],
    ["SUZUKI", "MIGHTY BOY"],
    ["SUZUKI", "MR WAGON"],
    ["SUZUKI", "PALETTE"],
    ["SUZUKI", "SOLIO"],
    ["SUZUKI", "SPLASH"],
    ["SUZUKI", "TWIN"],
    ["SUZUKI", "X-90"],
    ["SUZUKI", "CARRY"],
    ["TD2000", "SILVERSTONE"],
    ["TESLA", "MODEL S 70D"],
    ["TESLA", "MODEL S 85"],
    ["TESLA", "MODEL S 70"],
    ["TESLA", "MODEL S 85D"],
    ["TESLA", "MODEL S P85D"],
    ["TESLA", "MODEL S 90D"],
    ["TESLA", "MODEL S P90D"],
    ["TESLA", "MODEL X 70D"],
    ["TESLA", "MODEL X 90D"],
    ["TESLA", "MODEL X P90D"],
    ["TESLA", "ROADSTER"],
    ["TESLA", "MODEL S 75"],
    ["TESLA", "MODEL S 75D"],
    ["TESLA", "MODEL S P85"],
    ["TESLA", "MODEL 3"],
    ["TESLA", "MODEL S 60"],
    ["TESLA", "MODEL S 100D"],
    ["TESLA", "MODEL S P100D"],
    ["TESLA", "MODEL X 60D"],
    ["TESLA", "MODEL X 75D"],
    ["TESLA", "MODEL X P100D"],
    ["VOLVO", "S40"],
    ["VOLVO", "S70"],
    ["VOLVO", "V50"],
    ["VOLVO", "V70"],
    ["VOLVO", "C30"],
    ["VOLVO", "C70"],
    ["VOLVO", "S80"],
    ["VOLVO", "XC60"],
    ["VOLVO", "XC90"],
    ["VOLVO", "240"],
    ["VOLVO", "740"],
    ["VOLVO", "760"],
    ["VOLVO", "850"],
    ["VOLVO", "940"],
    ["VOLVO", "960"],
    ["VOLVO", "S60"],
    ["VOLVO", "240GL"],
    ["VOLVO", "245"],
    ["VOLVO", "V40"],
    ["VOLVO", "XC70"],
    ["VOLVO", "144"],
    ["VOLVO", "1800"],
    ["VOLVO", "244"],
    ["VOLVO", "260"],
    ["VOLVO", "264"],
    ["VOLVO", "340"],
    ["VOLVO", "343"],
    ["VOLVO", "345"],
    ["VOLVO", "360"],
    ["VOLVO", "440"],
    ["VOLVO", "460"],
    ["VOLVO", "480"],
    ["VOLVO", "780"],
    ["VOLVO", "AMAZON"],
    ["VOLVO", "CROSS COUNTRY"],
    ["VOLVO", "S90"],
    ["VOLVO", "V60"],
    ["VOLVO", "V90"],
    ["VOLVO", "121"],
    ["VOLVO", "122S"],
    ["VOLVO", "123GT"],
    ["VOLVO", "142"],
    ["VOLVO", "164"],
    ["VOLVO", "P1800"],
    ["VOLVO", "XC40"],
    ["YANGTSE", "WG6600CQ4"],
    ["AC", "COBRA 427"],
    ["AC", "COBRA CHALLENGER"],
    ["ARIEL", "ATOM"],
    ["ASIA MOTORS", "ASIA ROCSTA"],
    ["BAW", "HIACE"],
    ["BISON", "SAVANNA"],
    ["BOON KOON", "CERGAS"],
    ["BORGWARD", "BX5"],
    ["BUGATTI", "B110"],
    ["BUGATTI", "GRAND SPORT"],
    ["BUGATTI", "SUPER SPORT"],
    ["BUGATTI", "VEYRON"],
    ["BUICK", "APOLLO"],
    ["BUICK", "CENTURY"],
    ["BUICK", "ELECTRA"],
    ["BUICK", "LESABRE"],
    ["BUICK", "PARK AVENUE"],
    ["BUICK", "REATTA"],
    ["BUICK", "REGAL"],
    ["BUICK", "RIVIERA"],
    ["BUICK", "ROADMASTER"],
    ["BUICK", "RUXSUS"],
    ["BUICK", "SKYHAWK"],
    ["BUICK", "SKYLARK"],
    ["DAEWOO", "LACETTI"],
    ["DAIMLER", "LIMOUSINE"],
    ["DATSUN", "120Y"],
    ["DATSUN", "620"],
    ["DATSUN", "240Z"],
    ["DATSUN", "260Z"],
    ["DATSUN", "280Z"],
    ["DATSUN", "KE55"],
    ["DATSUN", "1600 SSS"],
    ["DATSUN", "CEDRIC 260C"],
    ["DS AUTOMOBILES", "DS 3"],
    ["DS AUTOMOBILES", "DS 4"],
    ["DS AUTOMOBILES", "DS 4S"],
    ["DS AUTOMOBILES", "DS 5"],
    ["DS AUTOMOBILES", "DS 5LS"],
    ["DS AUTOMOBILES", "DS 6"],
    ["DS AUTOMOBILES", "DS 7 CROSSBACK"],
    ["DURANT", "RUGBY TOURER"],
    ["FACTORY FIVE", "33 HOT ROD"],
    ["FACTORY FIVE", "33 HOT ROD PICK UP"],
    ["GMC", "SAVANA"],
    ["HOLDEN", "KINGSWOOD"],
    ["HOLDEN", "MONARO"],
    ["HOLDEN", "GEMINI"],
    ["HOLDEN", "STATESMAN"],
    ["HOLDEN", "PREMIER"],
    ["HOLDEN", "BARINA"],
    ["HOLDEN", "CRUZE"],
    ["HOLDEN", "ASTRA"],
    ["HOLDEN", "MALIBU"],
    ["HOLDEN", "INSIGNIA"],
    ["HUANGHAI", "PLUTUS"],
    ["KING LONG", "KINGO"],
    ["KING LONG", "PLACER"],
    ["LANCIA", "BETA"],
    ["LANCIA", "FULVIA"],
    ["MORGAN", "3 WHEELER"],
    ["MORGAN", "4-4"],
    ["MORGAN", "PLUS 4"],
    ["MORGAN", "ROADSTER"],
    ["MORGAN", "PLUS 8"],
    ["MORGAN", "AERO 8"],
    ["OLDSMOBILE", "CUTLASS"],
    ["OPEL", "FRONTERA"],
    ["OPEL", "OMEGA"],
    ["OPEL", "COMMODORE"],
    ["OPEL", "KADETT"],
    ["OPEL", "VECTRA"],
    ["OPEL", "CALAIS"],
    ["OPEL", "ASTRA"],
    ["OPEL", "ZAFIRA"],
    ["OPEL", "RECORD"],
    ["OPEL", "GEMINI"],
    ["OPEL", "ASCONA"],
    ["OPEL", "CALIBRA"],
    ["OPEL", "CORSA"],
    ["OPEL", "MANTA"],
    ["OPEL", "MERIVA"],
    ["OPEL", "SENATOR"],
    ["OPEL", "SIGNUM"],
    ["OPEL", "SPEED STER"],
    ["OPEL", "TIGRA"],
    ["OPEL", "VITA"],
    ["OTHERS", "OTHERS"],
    ["PONTIAC", "FIREBIRD"],
    ["SHELBY", "TYPE 65 DAYTONA COUPE"],
    ["SHELBY", "TYPE 65 DAYTONE COUPE-R"],
    ["SUNBEAM", "ALPINE"],
    ["SUTTON", "CS3500"],
    ["TATA", "XENON"],
    ["TATA", "TELCOLINE"],
    ["TRIUMPH", "10"],
    ["TRIUMPH", "12"],
    ["TRIUMPH", "13"],
    ["TRIUMPH", "20"],
    ["TRIUMPH", "35"],
    ["TRIUMPH", "1300"],
    ["TRIUMPH", "1500"],
    ["TRIUMPH", "2000"],
    ["TRIUMPH", "2.5 PI"],
    ["TRIUMPH", "12-6 SCORPION"],
    ["TRIUMPH", "2500 TC & S"],
    ["TRIUMPH", "ACCLAIM"],
    ["TRIUMPH", "COURIER"],
    ["TRIUMPH", "DOLOMITE"],
    ["TRIUMPH", "DOVE GTR"],
    ["TRIUMPH", "FIFTEEN"],
    ["TRIUMPH", "GLORIA"],
    ["TRIUMPH", "GT"],
    ["TRIUMPH", "HERALD"],
    ["TRIUMPH", "ITALIA"],
    ["TRIUMPH", "MAYFLOWER"],
    ["TRIUMPH", "RENOWN"],
    ["TRIUMPH", "ROADSTER"],
    ["TRIUMPH", "SALOON"],
    ["TRIUMPH", "SOUTHERN CROSS"],
    ["TRIUMPH", "SPITFIRE"],
    ["TRIUMPH", "STAG"],
    ["TRIUMPH", "SUPER"],
    ["TRIUMPH", "TOLEDO"],
    ["TRIUMPH", "TR"],
    ["TRIUMPH", "VITESSE"],
    ["TVR", "3000S"],
    ["TVR", "350SE"],
    ["TVR", "390SE"],
    ["TVR", "420 SEAC"],
    ["TVR", "450 SEAC"],
    ["TVR", "CERBERA"],
    ["TVR", "CERBERA SPEED 12"],
    ["TVR", "CHIMAERA"],
    ["TVR", "GRANTURA"],
    ["TVR", "GRIFFITH"],
    ["TVR", "GRIFFITH 200"],
    ["TVR", "GRIFFITH 400"],
    ["TVR", "M SERIES"],
    ["TVR", "S SERIES"],
    ["TVR", "SAGARIS"],
    ["TVR", "T350"],
    ["TVR", "TAMORA"],
    ["TVR", "TASMIN"],
    ["TVR", "TUSCAN"],
    ["TVR", "TUSCAN SPEED 6"],
    ["TVR", "TYPHON"],
    ["TVR", "VIXEN"],
    ["WOLSELEY", "1660"],
    ["ZENVO", "TS1-GT"],
    ["ZENVO", "TSR"],
    ["ZENVO", "TSR-S"],
    ["ZNA", "SUCCESS"],
    ["ZXAUTO", "GRANDTIGER"],
  ],
};
export default brandModelMapping;
