import { myTukarFloorStock } from "../actionTypes";
import { extractStockData } from "../../firebase/helper";
import { getCountdownDate } from "../../helper";

const initialState = {
  tableItems: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,

  createMyTukarFloorStockToggle: false,
  updateMyTukarFloorStockToggle: false,
  deleteMyTukarFloorStockToggle: false,
  detailMyTukarFloorStockToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case myTukarFloorStock.LIST_MYTUKAR_FLOOR_STOCK: {
      const {
        tableItems,
        start,
        startSnapshot,
        query,
        searching,
      } = action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case myTukarFloorStock.GET_MYTUKAR_FLOOR_STOCK: {
      const { variables } = action.payload;
      return { ...state, variables };
    }
    case myTukarFloorStock.CREATE_MYTUKAR_FLOOR_STOCK: {
      const { result } = action.payload;
      const { stockData } = extractStockData(result, "myTukarFloorStock");
      result.stockData = stockData;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case myTukarFloorStock.UPDATE_MYTUKAR_FLOOR_STOCK: {
      const { result } = action.payload;
      result.stockData = state.stockData;
      result.assignedTo = state.stockData.assignedTo;
      result.inActive = state.stockData.inActive;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = result;
      return { ...state, tableItems: newTableItems };
    }
    case myTukarFloorStock.DELETE_MYTUKAR_FLOOR_STOCK: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      const {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      } = state.tableItems[index];
      newTableItems[index] = {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      };
      return { ...state, tableItems: newTableItems };
    }
    case myTukarFloorStock.TOGGLE_CREATE_MYTUKAR_FLOOR_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        createMyTukarFloorStockToggle: toggle
          ? !state.createMyTukarFloorStockToggle
          : false,
      };
    }
    case myTukarFloorStock.TOGGLE_UPDATE_MYTUKAR_FLOOR_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateMyTukarFloorStockToggle: toggle
          ? !state.updateMyTukarFloorStockToggle
          : false,
      };
    }
    case myTukarFloorStock.TOGGLE_DELETE_MYTUKAR_FLOOR_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteMyTukarFloorStockToggle: toggle
          ? !state.deleteMyTukarFloorStockToggle
          : false,
      };
    }
    case myTukarFloorStock.TOGGLE_DETAIL_MYTUKAR_FLOOR_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailMyTukarFloorStockToggle: toggle
          ? !state.detailMyTukarFloorStockToggle
          : false,
      };
    }
    case myTukarFloorStock.RESET_MYTUKAR_FLOOR_STOCK_PARAMETERES: {
      return {
        ...state,
        stockDataID: undefined,
        id: undefined,
        status: undefined,
        carInDate: undefined,
        brand: undefined,
        model: undefined,
        specs: undefined,
        numberPlate: undefined,
        interchange: undefined,
        yearMake: undefined,
        photo: undefined,
        myTukarFund: undefined,
        PL: undefined,
        sqlInvoice: undefined,
        myTukarFundStatus: undefined,
        remark: undefined,
        totalPaymentToMyTukar: undefined,
        paymentDate: undefined,
        totalMyTukarCharge: undefined,
        mytukarCharges: undefined,
        biddingResults: undefined,
      };
    }
    case myTukarFloorStock.UPDATE_MYTUKAR_FLOOR_STOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case myTukarFloorStock.UPDATE_ACTIVE_MYTUKAR_FLOOR_STOCK: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];

      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };
      return { ...state, tableItems: newTableItems };
    }
    case myTukarFloorStock.DELETE_MYTUKAR_FLOOR_STOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case myTukarFloorStock.DETAIL_MYTUKAR_FLOOR_STOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case myTukarFloorStock.HANDLE_CHANGE_MYTUKAR_FLOOR_STOCK: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
