import { customer } from "../actionTypes";
import {timeToUnixIfAvailable} from "../../helper"
const moment = require("moment");

const initialState = {
  tableItems: [],
  tableItemsComment: [],
  tableItemsActivity: [],
  tableItemsTask: [],
  start: undefined,
  startSnapshot: undefined,
  startChatSnapshot: undefined,
  stopPagination: false,
  searching: false,

  createCustomerToggle: false,
  createCustomerCommentToggle: false,
  createCustomerActivityToggle: false,
  createCustomerTaskToggle: false,
  updateCustomerToggle: false,
  updateCustomerCommentToggle: false,
  updateCustomerActivityToggle: false,
  updateCustomerTaskToggle: false,
  deleteCustomerToggle: false,
  deleteCustomerCommentToggle: false,
  deleteCustomerActivityToggle: false,
  deleteCustomerTaskToggle: false,
  detailCustomerToggle: false,
  detailCustomerCommentToggle: false,
  detailCustomerActivityToggle: false,
  detailCustomerTaskToggle: false,
  chatCustomerToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case customer.LIST_CUSTOMER: {
      const {
        tableItems,
        start,
        startSnapshot,
        query,
        searching,
      } = action.payload;
      var newTableItems = tableItems;
      console.log("newTableItems: ", newTableItems);
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case customer.LIST_CUSTOMER_SUB: {
      const {
        tableItems,
        start,
        startSnapshot,
        query,
        searching,
        specialQuery,
      } = action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      switch (specialQuery) {
        case "comment":
          var tableItemsKey = "tableItemsComment";
          break;
        case "activity":
          var tableItemsKey = "tableItemsActivity";
          break;
        case "task":
          var tableItemsKey = "tableItemsTask";
          break;
        default:
          return { ...state };
      }
      return {
        ...state,
        [tableItemsKey]: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case customer.GET_CUSTOMER: {
      const { variables } = action.payload;
      return { ...state, variables };
    }
    case customer.GET_CUSTOMER_SUB: {
      const { variables, docName } = action.payload;
      switch (docName) {
        case "comment":
          var variablesKey = "variablesComment";
          break;
        case "activity":
          var variablesKey = "variablesActivity";
          break;
        case "task":
          var variablesKey = "variablesTask";
          break;
        default:
          return { ...state };
      }
      return { ...state, [variablesKey]: variables };
    }
    case customer.CREATE_CUSTOMER: {
      const { result } = action.payload;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case customer.CREATE_CUSTOMER_SUB: {
      const { result, customerSub } = action.payload;
      switch (customerSub) {
        case "comment":
          var tableItemsKey = "tableItemsComment";
          var prevTableItems = state.tableItemsComment;
          break;
        case "activity":
          var tableItemsKey = "tableItemsActivity";
          var prevTableItems = state.tableItemsActivity;
          break;
        case "task":
          var tableItemsKey = "tableItemsTask";
          var prevTableItems = state.tableItemsTask;
          break;
        default:
          return { ...state };
      }
      var newTableItems = [result, ...prevTableItems];
      return { ...state, [tableItemsKey]: newTableItems };
    }
    case customer.UPDATE_CUSTOMER: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.customerID == result.customerID;
      });
      newTableItems[index] = result;
      return { ...state, tableItems: newTableItems };
    }
    case customer.UPDATE_CUSTOMER_SUB: {
      const { result, customerSub } = action.payload;
      switch (customerSub) {
        case "comment":
          var tableItemsKey = "tableItemsComment";
          var newTableItems = [...state.tableItemsComment];
          var idKey = "commentID";
          break;
        case "activity":
          var tableItemsKey = "tableItemsActivity";
          var newTableItems = [...state.tableItemsActivity];
          var idKey = "activityID";
          break;
        case "task":
          var tableItemsKey = "tableItemsTask";
          var newTableItems = [...state.tableItemsTask];
          var idKey = "taskID";
          break;
        default:
          return { ...state };
      }
      var index = newTableItems.findIndex((element) => {
        return element[idKey] == result[idKey];
      });
      newTableItems[index] = result;
      return { ...state, [tableItemsKey]: newTableItems };
    }
    case customer.DELETE_CUSTOMER: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.customerID == result.customerID;
      });
      newTableItems.splice(index, 1);
      return { ...state, tableItems: newTableItems };
    }
    case customer.DELETE_CUSTOMER_SUB: {
      const { result, customerSub } = action.payload;
      switch (customerSub) {
        case "comment":
          var tableItemsKey = "tableItemsComment";
          var newTableItems = [...state.tableItemsComment];
          var index = newTableItems.findIndex((element) => {
            return element.commentID == result.commentID;
          });
          break;
        case "activity":
          var tableItemsKey = "tableItemsActivity";
          var newTableItems = [...state.tableItemsActivity];
          var index = newTableItems.findIndex((element) => {
            return element.activityID == result.activityID;
          });
          break;
        case "task":
          var tableItemsKey = "tableItemsTask";
          var newTableItems = [...state.tableItemsTask];
          var index = newTableItems.findIndex((element) => {
            return element.taskID == result.taskID;
          });
          break;
        default:
          return { ...state };
      }
      newTableItems.splice(index, 1);
      return { ...state, [tableItemsKey]: newTableItems };
    }
    case customer.TOGGLE_CREATE_CUSTOMER: {
      const { toggle } = action.payload;
      return {
        ...state,
        createCustomerToggle: toggle ? !state.createCustomerToggle : false,
      };
    }
    case customer.TOGGLE_CREATE_CUSTOMER_COMMENT: {
      const { toggle } = action.payload;
      return {
        ...state,
        createCustomerCommentToggle: toggle
          ? !state.createCustomerCommentToggle
          : false,
      };
    }
    case customer.TOGGLE_CREATE_CUSTOMER_ACTIVITY: {
      const { toggle } = action.payload;
      return {
        ...state,
        createCustomerActivityToggle: toggle
          ? !state.createCustomerActivityToggle
          : false,
      };
    }
    case customer.TOGGLE_CREATE_CUSTOMER_TASK: {
      const { toggle } = action.payload;
      return {
        ...state,
        createCustomerTaskToggle: toggle
          ? !state.createCustomerTaskToggle
          : false,
      };
    }
    case customer.TOGGLE_UPDATE_CUSTOMER: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateCustomerToggle: toggle ? !state.updateCustomerToggle : false,
      };
    }
    case customer.TOGGLE_UPDATE_CUSTOMER_COMMENT: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateCustomerCommentToggle: toggle
          ? !state.updateCustomerCommentToggle
          : false,
      };
    }
    case customer.TOGGLE_UPDATE_CUSTOMER_ACTIVITY: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateCustomerActivityToggle: toggle
          ? !state.updateCustomerActivityToggle
          : false,
      };
    }
    case customer.TOGGLE_UPDATE_CUSTOMER_TASK: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateCustomerTaskToggle: toggle
          ? !state.updateCustomerTaskToggle
          : false,
      };
    }
    case customer.TOGGLE_DELETE_CUSTOMER: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteCustomerToggle: toggle ? !state.deleteCustomerToggle : false,
      };
    }
    case customer.TOGGLE_DELETE_CUSTOMER_COMMENT: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteCustomerCommentToggle: toggle
          ? !state.deleteCustomerCommentToggle
          : false,
      };
    }
    case customer.TOGGLE_DELETE_CUSTOMER_ACTIVITY: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteCustomerActivityToggle: toggle
          ? !state.deleteCustomerActivityToggle
          : false,
      };
    }
    case customer.TOGGLE_DELETE_CUSTOMER_TASK: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteCustomerTaskToggle: toggle
          ? !state.deleteCustomerTaskToggle
          : false,
      };
    }
    case customer.TOGGLE_DETAIL_CUSTOMER: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailCustomerToggle: toggle ? !state.detailCustomerToggle : false,
      };
    }
    case customer.TOGGLE_DETAIL_CUSTOMER_COMMENT: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailCustomerCommentToggle: toggle
          ? !state.detailCustomerCommentToggle
          : false,
      };
    }
    case customer.TOGGLE_DETAIL_CUSTOMER_ACTIVITY: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailCustomerActivityToggle: toggle
          ? !state.detailCustomerActivityToggle
          : false,
      };
    }
    case customer.TOGGLE_DETAIL_CUSTOMER_TASK: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailCustomerTaskToggle: toggle
          ? !state.detailCustomerTaskToggle
          : false,
      };
    }
    case customer.TOGGLE_CHAT_CUSTOMER: {
      const { toggle } = action.payload;
      return {
        ...state,
        chatCustomerToggle: toggle ? !state.chatCustomerToggle : false,
      };
    }
    case customer.LOAD_CHAT_HISTORY: {
      const { messageList, startChatSnapshot, stopPagination } = action.payload;
      let list = [...messageList, ...state.messageList];
      let messageDict = {};
      list.forEach((message, index) => {
        message.arrayIndex = index;
        messageDict[message.chatID] = message;
      });
      return {
        ...state,
        startChatSnapshot,
        stopPagination,
        messageDict,
        messageList: [...messageList, ...state.messageList],
      };
    }
    case customer.WRITE_CUSTOMER_CHAT: {
      const { newMessage } = action.payload;
      let dict = JSON.parse(JSON.stringify(state.messageDict));
      newMessage.arrayIndex = state.messageList.length;
      dict[newMessage.chatID] = newMessage;
      return {
        ...state,
        messageDict: dict,
        messageList: [...state.messageList, newMessage],
      };
    }
    case customer.RECEIVE_NEW_CHAT: {
      const { receivedChat } = action.payload;
      let dict = JSON.parse(JSON.stringify(state.messageDict));
      receivedChat.arayIndex = state.messageList.length;
      dict[receivedChat.chatID] = receivedChat;
      return {
        ...state,
        messageDict: dict,
        messageList: [...state.messageList, receivedChat],
      };
    }
    case customer.UPDATE_CHAT_STATUS: {
      const { updatedChat } = action.payload;
      let prevMessage = state.messageDict[updatedChat.chatID];
      let dict = JSON.parse(JSON.stringify(state.messageDict));
      let list = JSON.parse(JSON.stringify(state.messageList));
      dict[updatedChat.chatID].status = updatedChat.status;
      list[prevMessage.arrayIndex].status = updatedChat.status;
      return {
        ...state,
        updatedChat,
        messageDict: dict,
        messageList: list,
      };
    }
    case customer.RESET_CUSTOMER_PARAMETERES: {
      let prefill = action.payload.prefill ?? {};
      return {
        ...state,
        customerID: undefined,
        name: undefined,
        status: undefined,
        remark: undefined,
        address: undefined,
        source: undefined,
        numbers: undefined,
        emails: undefined,
        tag: undefined,
        ...prefill,
      };
    }
    case customer.RESET_CUSTOMER_COMMENT_PARAMETERES: {
      return {
        ...state,
        commentID: undefined,
        content: undefined,
      };
    }
    case customer.RESET_CUSTOMER_ACTIVITY_PARAMETERES: {
      let today = moment().startOf("day").toDate();
      return {
        ...state,
        activityID: undefined,
        remark: undefined,
        type: "COMMENT",
        date: timeToUnixIfAvailable(today),
      };
    }
    case customer.RESET_CUSTOMER_TASK_PARAMETERES: {
      let prefill = action.payload.prefill ?? {};
      let today = moment().startOf("day").toDate();
      return {
        ...state,
        taskID: undefined,
        assigneeName: undefined,
        assigneeID: undefined,
        status: undefined,
        remark: undefined,
        date: timeToUnixIfAvailable(today),
        ...prefill,
      };
    }
    case customer.RESET_CUSTOMER_SUB_TABLE: {
      return {
        ...state,
        tableItemsComment: [],
        tableItemsActivity: [],
        tableItemsTask: [],
      };
    }
    case customer.UPDATE_CUSTOMER_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case customer.HANDLE_CHANGE_CUSTOMER: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
