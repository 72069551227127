import { stock } from "../actionTypes";
import brandOptions from "../../helper/carbrand";
import modelOptions from "../../helper/carmodel";
import brandModelOptions from "../../helper/carmodel-new-processed";
import { arrayAmountLast } from "../actions/helper";
import {
  extractLatestStockStatus,
  isNewCarIn,
  unixToTimeIfAvailable,
} from "../../helper";

var yearMakeOptions = [];
var d = new Date();
var n = d.getFullYear();
for (var x = n; x > 1960; x--) {
  yearMakeOptions.push({ label: x.toString(), value: x });
}

const initialState = {
  tableItems: [],
  query: undefined,
  start: undefined,
  startSnapshot: undefined,
  searching: false,
  retailPrice: [],
  latestRetailPrice: undefined,
  inActive: false,
  stockDataID: undefined,
  status: undefined,
  statusDisplay: undefined,
  carInDate: undefined,
  brand: undefined,
  wholesaleRetail: undefined,
  publishStatus: "UNPUBLISHED",
  branch: undefined,
  stockBranch: undefined,
  locatedAt: undefined,
  model: undefined,
  specs: undefined,
  numberPlate: undefined,
  interchange: undefined,
  numberPlateArr: undefined,
  yearMake: undefined,
  photo: undefined,

  brandOptions,
  modelOptions,
  brandModelOptions,
  yearMakeOptions,

  createStockToggle: false,
  updateStockToggle: false,
  deleteStockToggle: false,
  detailStockToggle: false,
  errorList: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case stock.LIST_STOCK: {
      const {
        tableItems,
        start,
        startSnapshot,
        query,
        searching,
      } = action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case stock.CREATE_STOCK: {
      const { result } = action.payload;
      result.stockData = result;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case stock.UPDATE_STOCK: {
      const { result } = action.payload;
      result.inActive = state.inActive;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      result.inActive = newTableItems[index].inActive;
      result.assignedTo = newTableItems[index].assignedTo;
      result.wholesaleRetail = newTableItems[index].wholesaleRetail;
      newTableItems[index] = result;
      return { ...state, tableItems: newTableItems };
    }
    case stock.UPDATE_ACTIVE: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };

      return { ...state, tableItems: newTableItems };
    }
    case stock.DELETE_STOCK: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems.splice(index, 1);
      return { ...state, tableItems: newTableItems };
    }
    case stock.TOGGLE_CREATE_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        createStockToggle: toggle ? !state.createStockToggle : false,
      };
    }
    case stock.TOGGLE_UPDATE_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateStockToggle: toggle ? !state.updateStockToggle : false,
      };
    }
    case stock.TOGGLE_DELETE_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteStockToggle: toggle ? !state.deleteStockToggle : false,
      };
    }
    case stock.TOGGLE_DETAIL_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailStockToggle: toggle ? !state.detailStockToggle : false,
      };
    }
    case stock.RESET_STOCK_PARAMETERES: {
      const prefill = action.payload.prefill ?? {};
      return {
        ...state,
        stockDataID: undefined,
        status: undefined,
        carInDate: undefined,
        inspectionDate: undefined,
        brand: undefined,
        wholesaleRetail: undefined,
        branch: undefined,
        stockBranch: undefined,
        locatedAt: undefined,
        inActive: false,
        model: undefined,
        specs: undefined,
        numberPlate: undefined,
        interchange: undefined,
        numberPlateArr: undefined,
        yearMake: undefined,
        photo: undefined,
        errorList: {},
        publishStatus: "UNPUBLISHED",
        retailPrice: [],
        latestRetailPrice: undefined,
        retailTakenPrice: undefined,
        owner: undefined,
        takeBy: undefined,
        carBodyColor: undefined,
        fromDuplicate: false,
        ...prefill,
      };
    }
    case stock.UPDATE_STOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case stock.DELETE_STOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case stock.DETAIL_STOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case stock.HANDLE_CHANGE_STOCK: {
      const { key, value } = action.payload;
      switch (key) {
        case "retailPrice":
          const latestRetailPrice = arrayAmountLast(
            value,
            "price",
            "creationDate"
          );
          return { ...state, [key]: value, latestRetailPrice };
        case "status":
          const { latestStatus, latestStatusDate } = extractLatestStockStatus(
            value
          );
          if (latestStatus == "INSPECTED") {
            return { ...state, [key]: value, inspectionDate: latestStatusDate };
          }
          let newCarIn = isNewCarIn(value);
          if (newCarIn) {
            let carInMonth = unixToTimeIfAvailable(newCarIn.date, "MM-YYYY");
            return {
              ...state,
              [key]: value,
              carInDate: newCarIn.date,
              carInMonth,
            };
          } else {
            return {
              ...state,
              [key]: value,
              carInDate: "",
              carInMonth: "",
            };
          }
        default:
          return { ...state, [key]: value };
      }
    }
    case stock.SHOW_EMPTY_FIELD_STOCK: {
      const errorList = { ...action.payload };
      return { ...state, errorList };
    }
    default:
      return state;
  }
};
