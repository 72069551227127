export const LIST_PURCHASER = "LIST_PURCHASER";
export const GET_PURCHASER = "GET_PURCHASER";
export const CREATE_PURCHASER = "CREATE_PURCHASER";
export const UPDATE_PURCHASER = "UPDATE_PURCHASER";
export const UPDATE_ADMIN_CHECK = "UPDATE_ADMIN_CHECK";
export const UPDATE_SQL_CHECK = "UPDATE_SQL_CHECK";
export const UPDATE_SALES_INVOICE_CHECK = "UPDATE_SALES_INVOICE_CHECK";
export const UPDATE_RECEIVE_KEY_CHECK = "UPDATE_RECEIVE_KEY_CHECK";
export const UPDATE_RECEIVE_KEY_RECORDS = "UPDATE_RECEIVE_KEY_RECORDS";
export const UPDATE_ORI_GERAN_RECORDS = "UPDATE_ORI_GERAN_RECORDS";
export const UPDATE_EAUTO_RECORDS = "UPDATE_EAUTO_RECORDS";
export const UPDATE_DISBURSEMENT_RECORDS = "UPDATE_DISBURSEMENT_RECORDS";
export const DELETE_PURCHASER = "DELETE_PURCHASER";
export const UPDATE_ACTIVE_PURCHASER = "UPDATE_ACTIVE_PURCHASER";
export const UPDATE_DISBURSED = "UPDATE_DISBURSED";

export const TOGGLE_CREATE_PURCHASER = "TOGGLE_CREATE_PURCHASER";
export const TOGGLE_UPDATE_PURCHASER = "TOGGLE_UPDATE_PURCHASER";
export const TOGGLE_DELETE_PURCHASER = "TOGGLE_DELETE_PURCHASER";
export const TOGGLE_DETAIL_PURCHASER = "TOGGLE_DETAIL_PURCHASER";

export const RESET_PURCHASER_PARAMETERES = "RESET_PURCHASER_PARAMETERES";
export const UPDATE_PURCHASER_PARAMETERS = "UPDATE_PURCHASER_PARAMETERS";
export const DELETE_PURCHASER_PARAMETERS = "DELETE_PURCHASER_PARAMETERS";
export const DETAIL_PURCHASER_PARAMETERS = "DETAIL_PURCHASER_PARAMETERS";

export const HANDLE_CHANGE_PURCHASER = "HANDLE_CHANGE_PURCHASER";

export const SHOW_EMPTY_FIELD_PURCHASER = "SHOW_EMPTY_FIELD_PURCHASER";
