import { saleOutstanding } from "../actionTypes"
import { extractStockData } from "../../firebase/helper"

var purchaserOptions = [
  { label: "BOBBY", value: "BOBBY" },
  { label: "NICHOLAS", value: "NICHOLAS" },
  { label: "WILLIAM", value: "WILLIAM" },
  { label: "SING YOONG", value: "SING YOONG" }
]

var remarkOptions = [
  { label: "GAIN BANK INTEREST", value: "GAIN BANK INTEREST" },
  { label: "LOST BANK INTEREST", value: "LOST BANK INTEREST" }
]

var remarkInfoOptions = [
  { label: "DONE", value: "DONE" }
]

const initialState = {
  tableItems: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,

  stockDataID: undefined,
  id: undefined,
  status: undefined,
  statusDisplay: undefined,
  carInDate: undefined,
  brand: undefined,
  model: undefined,
  specs: undefined,
  numberPlate: undefined,
  interchange: undefined,
  yearMake: undefined,
  photo: undefined,
  date: undefined,
  carPrice: undefined,
  broker: undefined,
  purchaser: undefined,
  brokerFee: undefined,
  brokerName: undefined,
  brokerPaidDate: undefined,
  payment1: undefined,
  payment1Date: undefined,
  payment2: undefined,
  payment2Date: undefined,
  payment3: undefined,
  payment3Date: undefined,
  payment4: undefined,
  payment4Date: undefined,
  payment5: undefined,
  payment5Date: undefined,
  payment6: undefined,
  payment6Date: undefined,
  payment7: undefined,
  payment7Date: undefined,
  totalOutstanding: undefined,
  remark: undefined,
  remarkInfo: undefined,

  purchaserOptions,
  remarkOptions,
  remarkInfoOptions,

  createSaleOutstandingToggle: false,
  updateSaleOutstandingToggle: false,
  deleteSaleOutstandingToggle: false,
  detailSaleOutstandingToggle: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case saleOutstanding.LIST_SALEOUTSTANDING: {
      const { tableItems, start, startSnapshot, query, searching } = action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return { ...state, tableItems: newTableItems, startSnapshot, query, searching };
    }
    case saleOutstanding.CREATE_SALEOUTSTANDING: {
      const { result } = action.payload;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case saleOutstanding.UPDATE_SALEOUTSTANDING: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = result
      return { ...state, tableItems: newTableItems };
    }
    case saleOutstanding.DELETE_SALEOUTSTANDING: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.stockDataID == result.stockDataID;
      });
      const { stockDataID, status, statusDisplay, carInDate, brand, model, specs, numberPlate, interchange, yearMake, photo } = state.tableItems[index]
      newTableItems[index] = { stockDataID, status, statusDisplay, carInDate, brand, model, specs, numberPlate, interchange, yearMake, photo };
      return { ...state, tableItems: newTableItems };
    }
    case saleOutstanding.TOGGLE_CREATE_SALEOUTSTANDING: {
      const { toggle } = action.payload;
      return {
        ...state,
        createSaleOutstandingToggle: toggle ? !state.createSaleOutstandingToggle : false
      };
    }
    case saleOutstanding.TOGGLE_UPDATE_SALEOUTSTANDING: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateSaleOutstandingToggle: toggle ? !state.updateSaleOutstandingToggle : false
      };
    }
    case saleOutstanding.TOGGLE_DELETE_SALEOUTSTANDING: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteSaleOutstandingToggle: toggle ? !state.deleteSaleOutstandingToggle : false
      };
    }
    case saleOutstanding.TOGGLE_DETAIL_SALEOUTSTANDING: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailSaleOutstandingToggle: toggle ? !state.detailSaleOutstandingToggle : false
      };
    }
    case saleOutstanding.RESET_SALEOUTSTANDING_PARAMETERES: {
      return {
        ...state,
        stockDataID: undefined,
        id: undefined,
        status: undefined,
        carInDate: undefined,
        brand: undefined,
        model: undefined,
        specs: undefined,
        numberPlate: undefined,
        interchange: undefined,
        yearMake: undefined,
        photo: undefined,
        date: undefined,
        carPrice: undefined,
        broker: undefined,
        purchaser: undefined,
        brokerFee: undefined,
        brokerName: undefined,
        brokerPaidDate: undefined,
        payment1: undefined,
        payment1Date: undefined,
        payment2: undefined,
        payment2Date: undefined,
        payment3: undefined,
        payment3Date: undefined,
        payment4: undefined,
        payment4Date: undefined,
        payment5: undefined,
        payment5Date: undefined,
        payment6: undefined,
        payment6Date: undefined,
        payment7: undefined,
        payment7Date: undefined,
        totalOutstanding: undefined,
        remark: undefined,
        remarkInfo: undefined
      };
    }
    case saleOutstanding.UPDATE_SALEOUTSTANDING_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case saleOutstanding.DELETE_SALEOUTSTANDING_PARAMETERS: {
      return { ...state, ...action.payload }
    }
    case saleOutstanding.DETAIL_SALEOUTSTANDING_PARAMETERS: {
      return { ...state, ...action.payload }
    }
    case saleOutstanding.HANDLE_CHANGE_SALEOUTSTANDING: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
}