import React, { Component } from "react";
import { Progress } from "reactstrap";

class FileOperationPorgComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curr: 0,
      value: 0,
    };
  }
  render() {
    const { total } = this.props;
    const { curr, value } = this.state;
    return (
      <div>
        {`process ${curr}/${total} photos`}
        <div>
          <Progress animated value={value} />
        </div>
      </div>
    );
  }
}

export default FileOperationPorgComponent;
