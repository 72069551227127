export const LIST_STOCK = "LIST_STOCK";
export const CREATE_STOCK = "CREATE_STOCK";
export const UPDATE_STOCK = "UPDATE_STOCK";
export const DELETE_STOCK = "DELETE_STOCK";
export const UPDATE_ACTIVE = "UPDATE_ACTIVE";

export const TOGGLE_CREATE_STOCK = "TOGGLE_CREATE_STOCK";
export const TOGGLE_UPDATE_STOCK = "TOGGLE_UPDATE_STOCK";
export const TOGGLE_DELETE_STOCK = "TOGGLE_DELETE_STOCK";
export const TOGGLE_DETAIL_STOCK = "TOGGLE_DETAIL_STOCK";

export const RESET_STOCK_PARAMETERES = "RESET_STOCK_PARAMETERES";
export const UPDATE_STOCK_PARAMETERS = "UPDATE_STOCK_PARAMETERS";
export const DELETE_STOCK_PARAMETERS = "DELETE_STOCK_PARAMETERS";
export const DETAIL_STOCK_PARAMETERS = "DETAIL_STOCK_PARAMETERS";

export const HANDLE_CHANGE_STOCK = "HANDLE_CHANGE_STOCK";

export const SHOW_EMPTY_FIELD_STOCK = "SHOW_EMPTY_FIELD_STOCK";
