import { paymentStatus } from "../actionTypes";
import { extractStockData } from "../../firebase/helper";

const initialState = {
  tableItems: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,

  createPaymentStatusToggle: false,
  updatePaymentStatusToggle: false,
  deletePaymentStatusToggle: false,
  detailPaymentStatusToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case paymentStatus.LIST_PAYMENT_STATUS: {
      const {
        tableItems,
        start,
        startSnapshot,
        query,
        searching,
      } = action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case paymentStatus.GET_PAYMENT_STATUS: {
      const { variables } = action.payload;
      return { ...state, variables };
    }
    case paymentStatus.CREATE_PAYMENT_STATUS: {
      const { result } = action.payload;
      const { stockData } = extractStockData(result, "paymentStatus");
      result.stockData = stockData;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case paymentStatus.UPDATE_PAYMENT_STATUS: {
      const { result } = action.payload;
      result.stockData = state.stockData;
      result.inActive = state.stockData.inActive;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = result;
      return { ...state, tableItems: newTableItems };
    }
    case paymentStatus.DELETE_PAYMENT_STATUS: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      const {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      } = state.tableItems[index];
      newTableItems[index] = {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      };
      return { ...state, tableItems: newTableItems };
    }
    case paymentStatus.TOGGLE_CREATE_PAYMENT_STATUS: {
      const { toggle } = action.payload;
      return {
        ...state,
        createPaymentStatusToggle: toggle
          ? !state.createPaymentStatusToggle
          : false,
      };
    }
    case paymentStatus.TOGGLE_UPDATE_PAYMENT_STATUS: {
      const { toggle } = action.payload;
      return {
        ...state,
        updatePaymentStatusToggle: toggle
          ? !state.updatePaymentStatusToggle
          : false,
      };
    }
    case paymentStatus.TOGGLE_DELETE_PAYMENT_STATUS: {
      const { toggle } = action.payload;
      return {
        ...state,
        deletePaymentStatusToggle: toggle
          ? !state.deletePaymentStatusToggle
          : false,
      };
    }
    case paymentStatus.TOGGLE_DETAIL_PAYMENT_STATUS: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailPaymentStatusToggle: toggle
          ? !state.detailPaymentStatusToggle
          : false,
      };
    }
    case paymentStatus.RESET_PAYMENT_STATUS_PARAMETERES: {
      return {
        ...state,
        stockDataID: undefined,
        id: undefined,
        status: undefined,
        carInDate: undefined,
        brand: undefined,
        model: undefined,
        specs: undefined,
        numberPlate: undefined,
        interchange: undefined,
        yearMake: undefined,
        photo: undefined,
        paymentStatus: undefined,
        purchaseCarPayment: undefined,
        repairOtherPayment: undefined,
        salePayment: undefined,
        floorStockPayment: undefined,
        purchaseCarPaymentTotal: undefined,
        repairOtherPaymentTotal: undefined,
        salePaymentTotal: undefined,
        floorStockPaymentTotal: undefined,
        remark: undefined,
      };
    }
    case paymentStatus.UPDATE_ACTIVE_PAYMENT_STATUS: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];

      var index = newTableItems.findIndex((element) => {
        return element.stockDocID == result.stockDocID;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };

      return { ...state, tableItems: newTableItems };
    }
    case paymentStatus.UPDATE_PAYMENT_STATUS_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case paymentStatus.DELETE_PAYMENT_STATUS_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case paymentStatus.DETAIL_PAYMENT_STATUS_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case paymentStatus.HANDLE_CHANGE_PAYMENT_STATUS: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
