import { analytics } from "../actionTypes";

const initialState = {
  totalStock: 0,
  stockCounter: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case analytics.GET_STOCK_COUNTER: {
      const {
        totalStock,
        stockCounter,
        statusCounter,
        statusSecCounter,
        takeByCounter,
      } = action.payload;
      return {
        ...state,
        totalStock,
        stockCounter,
        statusCounter,
        statusSecCounter,
        takeByCounter,
      };
    }
    case analytics.GET_LINE_CHART_DATA: {
      const { lineChartData } = action.payload;
      return {
        ...state,
        lineChartData,
      };
    }
    default: {
      return state;
    }
  }
};
