import { carRepair } from "../actionTypes";
import { resetStockData } from "../actions/helper";
import { extractStockData } from "../../firebase/helper";
import {
  extractLatestStockStatus,
  isNewCarIn,
  unixToTimeIfAvailable,
} from "../../helper";
import { arrayAmountTotal, roundDecimal } from "../actions/helper";
const initialState = {
  tableItems: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,

  createCarRepairToggle: false,
  updateCarRepairToggle: false,
  deleteCarRepairToggle: false,
  detailCarRepairToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case carRepair.LIST_CAR_REPAIR: {
      const {
        tableItems,
        start,
        startSnapshot,
        query,
        searching,
      } = action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case carRepair.GET_CAR_REPAIR: {
      const { variables } = action.payload;
      return { ...state, variables };
    }
    case carRepair.CREATE_CAR_REPAIR: {
      const { result } = action.payload;
      const { stockData } = extractStockData(result, "carRepair");
      result.stockData = stockData;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case carRepair.UPDATE_CAR_REPAIR: {
      const { result } = action.payload;
      result.stockData = state.stockData;
      result.conditionReport = state.stockData.conditionReport;
      result.assignedTo = state.stockData.assignedTo;
      result.inActive = state.stockData.inActive;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = result;
      return { ...state, tableItems: newTableItems };
    }
    case carRepair.DELETE_CAR_REPAIR: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      const {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      } = state.tableItems[index];
      newTableItems[index] = {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      };
      return { ...state, tableItems: newTableItems };
    }
    case carRepair.TOGGLE_CREATE_CAR_REPAIR: {
      const { toggle } = action.payload;
      return {
        ...state,
        createCarRepairToggle: toggle ? !state.createCarRepairToggle : false,
      };
    }
    case carRepair.TOGGLE_UPDATE_CAR_REPAIR: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateCarRepairToggle: toggle ? !state.updateCarRepairToggle : false,
      };
    }
    case carRepair.TOGGLE_DELETE_CAR_REPAIR: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteCarRepairToggle: toggle ? !state.deleteCarRepairToggle : false,
      };
    }
    case carRepair.TOGGLE_DETAIL_CAR_REPAIR: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailCarRepairToggle: toggle ? !state.detailCarRepairToggle : false,
      };
    }
    case carRepair.RESET_CAR_REPAIR_PARAMETERES: {
      return {
        ...state,
        ...resetStockData(),
        id: undefined,
        estimateRepairCost: undefined,
        approval: undefined,
        repairStatus: undefined,
        repairInfo: undefined,
        estimatedRepairInfo: undefined,
        repairInfoTotal: undefined,
        needRepair: undefined,
        paint: undefined,
        accident: undefined,
        mileages: undefined,
        engine: undefined,
        gearbox: undefined,
        fullService: undefined,
        warranty: undefined,
        windscreen: undefined,
        totalCost: undefined,
      };
    }
    case carRepair.UPDATE_CAR_REPAIR_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case carRepair.UPDATE_ACTIVE_CAR_REPAIR: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];

      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };

      return { ...state, tableItems: newTableItems };
    }
    case carRepair.DELETE_CAR_REPAIR_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case carRepair.DETAIL_CAR_REPAIR_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case carRepair.HANDLE_CHANGE_CAR_REPAIR: {
      const { key, value } = action.payload;
      switch (key) {
        case "status":
          const { latestStatus, latestStatusDate } = extractLatestStockStatus(
            value
          );
          if (latestStatus == "INSPECTED") {
            return { ...state, [key]: value, inspectionDate: latestStatusDate };
          }
          let newCarIn = isNewCarIn(value);
          if (newCarIn) {
            let carInMonth = unixToTimeIfAvailable(newCarIn.date, "MM-YYYY");
            return {
              ...state,
              [key]: value,
              carInDate: newCarIn.date,
              carInMonth,
            };
          } else {
            return {
              ...state,
              [key]: value,
              carInDate: "",
              carInMonth: "",
            };
          }
        case "repairInfo":
          return {
            ...state,
            [key]: value,
            repairInfoTotal: roundDecimal(arrayAmountTotal(value)),
          };
        default:
          return { ...state, [key]: value };
      }
    }
    case carRepair.SHOW_EMPTY_FIELD_CAR_REPAIR: {
      const errorList = { ...action.payload };
      return { ...state, errorList };
    }
    default:
      return state;
  }
};
