import { bankFloorStock } from "../actionTypes";
import { extractStockData } from "../../firebase/helper";
import { getCountdownDate } from "../../helper";

const initialState = {
  tableItems: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,
  puspakomDate: undefined,

  createBankFloorStockToggle: false,
  updateBankFloorStockToggle: false,
  deleteBankFloorStockToggle: false,
  detailBankFloorStockToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case bankFloorStock.LIST_BANK_FLOOR_STOCK: {
      const {
        tableItems,
        start,
        startSnapshot,
        query,
        searching,
      } = action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case bankFloorStock.GET_BANK_FLOOR_STOCK: {
      const { variables } = action.payload;
      return { ...state, variables };
    }
    case bankFloorStock.CREATE_BANK_FLOOR_STOCK: {
      const { result } = action.payload;
      const { stockData } = extractStockData(result, "bankFloorStock");
      result.stockData = stockData;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case bankFloorStock.UPDATE_BANK_FLOOR_STOCK: {
      const { result } = action.payload;
      result.stockData = state.stockData;
      result.assignedTo = state.stockData.assignedTo;
      result.inActive = state.stockData.inActive;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = result;
      return { ...state, tableItems: newTableItems };
    }
    case bankFloorStock.DELETE_BANK_FLOOR_STOCK: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      const {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      } = state.tableItems[index];
      newTableItems[index] = {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      };
      return { ...state, tableItems: newTableItems };
    }
    case bankFloorStock.TOGGLE_CREATE_BANK_FLOOR_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        createBankFloorStockToggle: toggle
          ? !state.createBankFloorStockToggle
          : false,
      };
    }
    case bankFloorStock.TOGGLE_UPDATE_BANK_FLOOR_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateBankFloorStockToggle: toggle
          ? !state.updateBankFloorStockToggle
          : false,
      };
    }
    case bankFloorStock.TOGGLE_DELETE_BANK_FLOOR_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteBankFloorStockToggle: toggle
          ? !state.deleteBankFloorStockToggle
          : false,
      };
    }
    case bankFloorStock.TOGGLE_DETAIL_BANK_FLOOR_STOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailBankFloorStockToggle: toggle
          ? !state.detailBankFloorStockToggle
          : false,
      };
    }
    case bankFloorStock.RESET_BANK_FLOOR_STOCK_PARAMETERES: {
      return {
        ...state,
        stockDataID: undefined,
        id: undefined,
        status: undefined,
        carInDate: undefined,
        brand: undefined,
        model: undefined,
        specs: undefined,
        numberPlate: undefined,
        interchange: undefined,
        yearMake: undefined,
        photo: undefined,
        cwaCost: undefined,
        bankValue: undefined,
        floorStock: undefined,
        disbursementDate: undefined,
        puspakomDate: undefined,
        solarNumber: undefined,
        dueDate: undefined,
        daysUntilDueDate: undefined,
        floorStockStatus: undefined,
        interest: undefined,
        balanceCollect: undefined,
        remark: undefined,
        originalCarCard: undefined,
        eAuto: undefined,
        b5b7Report: undefined,
        documents: undefined,
        jpjSearch: undefined,
        docToCIMB: undefined,
        settlement: undefined,
        settlementTotal: undefined,
      };
    }
    case bankFloorStock.UPDATE_BANK_FLOOR_STOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case bankFloorStock.UPDATE_ACTIVE_BANK_FLOOR_STOCK: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];

      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };

      return { ...state, tableItems: newTableItems };
    }
    case bankFloorStock.DELETE_BANK_FLOOR_STOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case bankFloorStock.DETAIL_BANK_FLOOR_STOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case bankFloorStock.HANDLE_CHANGE_BANK_FLOOR_STOCK: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
