import { biddingCar, purchaser } from "../actionTypes";
import { extractStockData } from "../../firebase/helper";
import { arrayAmountLast, extractCost } from "../actions/helper";
import {
  extractLatestStockStatus,
  isNewCarIn,
  unixToTimeIfAvailable,
} from "../../helper";

const initialState = {
  tableItems: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,
  document: undefined,
  ucd: undefined,
  oriRC: undefined,
  disbursed: undefined,
  disbursedDate: undefined,
  dateSold: undefined,
  documentUCD: undefined,
  pnl: undefined,

  createBiddingCarToggle: false,
  updateBiddingCarToggle: false,
  deleteBiddingCarToggle: false,
  detailBiddingCarToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case biddingCar.LIST_BIDDING_CAR: {
      const { tableItems, start, startSnapshot, query, searching } =
        action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      for (let i = 0; i < newTableItems.length; ++i) {
        newTableItems[i]["documentUCD"] =
          newTableItems[i]["document"] != undefined &&
          newTableItems[i]["document"] != "" &&
          newTableItems[i]["ucd"] != undefined &&
          newTableItems[i]["ucd"] != "" &&
          !isNaN(newTableItems[i]["ucd"]) &&
          newTableItems[i]["oriRC"] != undefined &&
          newTableItems[i]["oriRC"] != ""
            ? "AVAILABLE"
            : "";
      }

      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case biddingCar.GET_BIDDING_CAR: {
      const { variables } = action.payload;
      return { ...state, variables };
    }
    case biddingCar.CREATE_BIDDING_CAR: {
      const { result } = action.payload;
      const { stockData } = extractStockData(result, "biddingCar");
      result.stockData = stockData;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case biddingCar.UPDATE_BIDDING_CAR: {
      const { result } = action.payload;
      const { totalCost } = extractCost(
        {
          owner: result.owner,
          broker: result.broker,
          otherCost: result.otherCost,
          managerCom: state.stockData.managerCom,
        },
        true
      );

      result.totalCost = totalCost;
      result.stockData = state.stockData;
      result.assignedTo = state.stockData.assignedTo;
      result.takeBy = state.stockData.takeBy;
      result.settlementDetails = state.stockData.settlementDetails;
      result.conditionReport = state.stockData.conditionReport;
      result.totalApprovedPayment = state.stockData.totalApprovedPayment;
      result.pnl =
        result.latestBidding &&
        result.latestBidding > 0 &&
        result.status.some((obj) => obj["status"].includes("SOLD TO"))
          ? parseInt(result.latestBidding) - totalCost
          : null;
      result.documentUCD =
        result.document != undefined &&
        result.document != "" &&
        result.ucd != undefined &&
        result.ucd != "" &&
        result.oriRC != undefined &&
        result.oriRC != ""
          ? "AVAILABLE"
          : "";
      result.inActive = state.stockData.inActive;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = result;
      return { ...state, tableItems: newTableItems };
    }
    case purchaser.UPDATE_RECEIVE_KEY_RECORDS:
    case purchaser.UPDATE_ORI_GERAN_RECORDS:
    case purchaser.UPDATE_EAUTO_RECORDS:
    case purchaser.UPDATE_DISBURSEMENT_RECORDS:
    case biddingCar.UPDATE_DISBURSED: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };
      return { ...state, tableItems: newTableItems };
    }
    case biddingCar.DELETE_BIDDING_CAR: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      const {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      } = state.tableItems[index];
      newTableItems[index] = {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      };
      return { ...state, tableItems: newTableItems };
    }
    case biddingCar.TOGGLE_CREATE_BIDDING_CAR: {
      const { toggle } = action.payload;
      return {
        ...state,
        createBiddingCarToggle: toggle ? !state.createBiddingCarToggle : false,
      };
    }
    case biddingCar.TOGGLE_UPDATE_BIDDING_CAR: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateBiddingCarToggle: toggle ? !state.updateBiddingCarToggle : false,
      };
    }
    case biddingCar.TOGGLE_DELETE_BIDDING_CAR: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteBiddingCarToggle: toggle ? !state.deleteBiddingCarToggle : false,
      };
    }
    case biddingCar.TOGGLE_DETAIL_BIDDING_CAR: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailBiddingCarToggle: toggle ? !state.detailBiddingCarToggle : false,
      };
    }
    case biddingCar.RESET_BIDDING_CAR_PARAMETERES: {
      return {
        ...state,
        stockDataID: undefined,
        id: undefined,
        status: undefined,
        carInDate: undefined,
        brand: undefined,
        model: undefined,
        specs: undefined,
        numberPlate: undefined,
        interchange: undefined,
        yearMake: undefined,
        photo: undefined,
        owner: undefined,
        broker: undefined,
        otherCost: undefined,
        total: undefined,
        totalCost: undefined,
        companyCost: undefined,
        myTukarFee: undefined,
        remark: undefined,
        repairList: undefined,
        latestBidding: undefined,
        biddingStatus: undefined,
        carSurrender: undefined,
        carCard: undefined,
        invoice: undefined,
        interstate: undefined,
        disbursementDate: undefined,
        otherRemark: undefined,
        settlementBank: undefined,
        biddingResults: undefined,
        errorList: {},
        minimumTargetPrice: 0,
        retailTakenPrice: 0,
        totalApprovedPayment: undefined,
        document: undefined,
        ucd: undefined,
        oriRC: undefined,
        disbursed: undefined,
        disbursedDate: undefined,
        dateSold: undefined,
        documentUCD: undefined,
        pnl: undefined,
      };
    }
    case biddingCar.UPDATE_BIDDING_CAR_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case biddingCar.UPDATE_ACTIVE_BIDDING_CAR: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];

      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };

      return { ...state, tableItems: newTableItems };
    }
    case biddingCar.DELETE_BIDDING_CAR_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case biddingCar.DETAIL_BIDDING_CAR_PARAMETERS: {
      // console.log(action.payload);
      // const { latestBidding, totalCost } = action.payload;
      // const pnl = parseInt(latestBidding) - totalCost;
      return { ...state, ...action.payload };
    }
    case biddingCar.HANDLE_CHANGE_BIDDING_CAR: {
      const { key, value } = action.payload;
      switch (key) {
        case "biddingResults":
          const latestBidding = arrayAmountLast(value);
          return { ...state, [key]: value, latestBidding };
        case "status":
          const { latestStatus, latestStatusDate } =
            extractLatestStockStatus(value);
          if (latestStatus == "SOLD TO") {
            return {
              ...state,
              [key]: value,
              dateSold: latestStatusDate,
            };
          }
          let newCarIn = isNewCarIn(value);
          if (newCarIn) {
            let carInMonth = unixToTimeIfAvailable(newCarIn.date, "MM-YYYY");
            return {
              ...state,
              [key]: value,
              carInDate: newCarIn.date,
              carInMonth,
            };
          } else {
            return {
              ...state,
              [key]: value,
              carInDate: "",
              carInMonth: "",
            };
          }
        default:
          return { ...state, [key]: value };
      }
    }
    case biddingCar.SHOW_EMPTY_FIELD_BIDDING_CAR: {
      const errorList = { ...action.payload };
      return { ...state, errorList };
    }
    default:
      return state;
  }
};
