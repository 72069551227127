const stockStatusFilter = {
  biddingCar: {
    WHOLESALE: ["WCI - BID", "BIDDING", "RETAILS - BIDDING"],
    ALL: ["WCI - BID", "BIDDING", "RETAILS - BIDDING"],
    RETAIL: ["WCI - BID", "BIDDING", "RETAILS - BIDDING"],
  },
  purchaser: {
    WHOLESALE: ["WCI - BID", "BIDDING", "SOLD", "SOLD TO"],
    RETAIL: ["RCI", "AVAILABLE", "RETAILS - BIDDING", "SOLD", "SOLD TO"],
    ALL: ["WCI - BID", "BIDDING", "RCI", "AVAILABLE", "RETAILS - BIDDING", "SOLD", "SOLD TO"],
  },
  saleStock: {
    WHOLESALE: ["RCI", "AVAILABLE"],
    RETAIL: ["RCI", "AVAILABLE"],
    ALL: ["RCI", "AVAILABLE"],
  },
  salesPerson: {
    WHOLESALE: ["BOOKING" ,"PENDING LOAN", "DELIVERY","CHANGE CAR" ,"CANCEL BOOKING" , "LOAN REJECT"],
    RETAIL: ["BOOKING" ,"PENDING LOAN", "DELIVERY","CHANGE CAR" ,"CANCEL BOOKING" , "LOAN REJECT"],
    ALL: ["BOOKING" ,"PENDING LOAN", "DELIVERY","CHANGE CAR" ,"CANCEL BOOKING" , "LOAN REJECT"],
  }

  
};

export default stockStatusFilter;
