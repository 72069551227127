import { firebaseDB, db, callableFunctions } from "./index";
import firebase from "firebase/app";
import {
  generateRandomID,
  initializeResponse,
  getFormattedResults,
} from "../helper";
import {
  getDocumentMetadata,
  commitBatch,
  removeUndefined,
  preprocessAssignedStockInput,
  manageStockAssigned,
  preprocessAssignedCustomerInput,
  manageCustomerAssigned,
  preprocessAssignedSaleStockInput,
  manageSaleStockAssigned,
} from "./helper";
const HttpStatus = require("../helper/http-status-codes");
const manageUserMethod = {
  create: "create",
  update: "update",
  delete: "delete",
};

const counterConfig = ["department", "branch"];

function updateCounter(t, path, previous, current, fieldName) {
  let shouldRender = false;
  if (current) {
    let incrementField = `${fieldName}.${current}`;
    if (previous == undefined) {
      t.update(path, { [incrementField]: increment(1) });
      shouldRender = true;
    } else if (current != previous) {
      let decrementField = `${fieldName}.${previous}`;
      t.update(path, {
        [incrementField]: increment(1),
        [decrementField]: increment(-1),
      });
      shouldRender = true;
    }
  } else if (previous) {
    let decrementField = `${fieldName}.${previous}`;
    t.update(path, { [decrementField]: increment(-1) });
    shouldRender = true;
  }
  return shouldRender;
}

function increment(step) {
  return firebase.firestore.FieldValue.increment(step);
}

async function updateDocument(userData, id, action) {
  let response = initializeResponse;
  let renderCounter = false;
  await db
    .runTransaction(async (transaction) => {
      const counterRef = firebaseDB.analyticsCollection().doc("userOverview");
      const userDocRef = firebaseDB.userCollection().doc(id);
      let current = {};
      let prev = {};
      current.department = userData.department;
      current.branch = userData.branch;
      if (action != "create") {
        let userDoc = await transaction.get(userDocRef);
        prev.department = userDoc.data().department;
        prev.branch = userDoc.data().branch;
      }
      switch (action) {
        case "create":
          const documentMetadata = getDocumentMetadata(1);
          transaction.set(userDocRef, { ...userData, ...documentMetadata });
          transaction.update(counterRef, { totalUser: increment(1) });
          renderCounter = true;
          break;
        case "update":
          const documentMetadataUpdate = getDocumentMetadata(0);
          transaction.update(userDocRef, {
            ...userData,
            ...documentMetadataUpdate,
          });
          break;
        case "delete":
          transaction.update(counterRef, { totalUser: increment(-1) });
          transaction.delete(userDocRef);
          renderCounter = true;
          break;
        default:
          break;
      }

      counterConfig.forEach((field) => {
        let shouldRender = updateCounter(
          transaction,
          counterRef,
          prev[field],
          current[field],
          field
        );
        renderCounter = renderCounter | shouldRender;
      });
    })
    .then((results) => {
      if (response.statusCode == HttpStatus.OK) {
        response = getFormattedResults("success", "Successfully Processed");
        response.id = id;
      }
    })
    .catch((err) => {
      console.log(err);
      response = getFormattedResults("error", "Error processing");
    });
  response.renderCounter = renderCounter;
  return response;
}

const user = {
  createUser: async function (data) {
    let batch = db.batch();

    let id = generateRandomID();
    const { newData, newAssigned, delAssigned } =
      preprocessAssignedStockInput(data);
    const documentMetadata = getDocumentMetadata(1);
    let userData = removeUndefined(newData);

    let authRes = await callableFunctions.manageUser({
      method: manageUserMethod.create,
      id,
      ...userData,
    });
    if (authRes.data.statusCode !== HttpStatus.OK) {
      return authRes.data;
    }

    let response = await updateDocument(userData, id, "create");
    if (response.statusCode == 200 && (newAssigned || delAssigned)) {
      await manageStockAssigned(id, newAssigned, delAssigned);
    }
    return response;
  },
  updateUser: async function (data) {
    let batch = db.batch();
    let { id } = data;
    const {
      newData: newDataStock,
      newAssigned,
      delAssigned,
    } = preprocessAssignedStockInput(data);
    const {
      newData: newDataCustomer,
      newAssigned: newAssignedCustomer,
      delAssigned: delAssignedCustomer,
    } = preprocessAssignedCustomerInput(newDataStock);
    const {
      newData,
      newAssigned: newAssignedSaleStock,
      delAssigned: delAssignedSaleStock,
    } = preprocessAssignedSaleStockInput(newDataCustomer);
    const documentMetadataUpdate = getDocumentMetadata(0);
    let userData = removeUndefined(newData);

    let authRes = await callableFunctions.manageUser({
      method: manageUserMethod.update,
      ...userData,
    });
    if (authRes.data.statusCode !== HttpStatus.OK) {
      return authRes.data;
    }

    let response = await updateDocument(userData, id, "update");
    if (response.statusCode == 200 && (newAssigned || delAssigned)) {
      await manageStockAssigned(id, newAssigned, delAssigned);
    }
    if (
      response.statusCode == 200 &&
      (newAssignedCustomer || delAssignedCustomer)
    ) {
      await manageCustomerAssigned(
        id,
        newAssignedCustomer,
        delAssignedCustomer
      );
    }
    if (
      response.statusCode == 200 &&
      (newAssignedSaleStock || delAssignedSaleStock)
    ) {
      await manageSaleStockAssigned(
        id,
        newAssignedSaleStock,
        delAssignedSaleStock
      );
    }
    return response;
  },
  deleteUser: async function (data) {
    let batch = db.batch();

    const { id } = data;

    await firebaseDB
      .userCollection()
      .doc(id)
      .get()
      .then(async (doc) => {
        let data = doc.data();
        if (data.assignedStock) {
          let delAssigned = data.assignedStock.map((item) => item.id);
          try {
            await manageStockAssigned(id, [], delAssigned);
          } catch (error) {
            // ignore if error
            console.log(error);
          }
        }
      });
    let response = await updateDocument(data, id, "delete");
    await callableFunctions.manageUser({
      method: manageUserMethod.delete,
      id,
    });
    //todo remove element in stock collection assigned array

    return response;
  },
};

export default user;
