import React from "react";
import ReactDOM from "react-dom";
import { firebase, firebaseDB, firebasePath, storageRef, auth } from "./index";
import {
  generateRandomID,
  removeSpacesAndChar,
  unixToTimeIfAvailable,
} from "../helper";
import {
  initializeResponse,
  getFormattedResults,
  asyncForEach,
  generatePromises,
  loadingToastConf,
} from "../helper";
import cogoToast from "cogo-toast";
import documentPath from "../documentPath.json";
import globalVariables from "../globalVariables.json";
import FileOperationPorgComponent from "../views/CommonComponent/FileOperationPorgComponent";
import imageCompression from "browser-image-compression";
import chunk from "chunk";
import {isMobile} from 'react-device-detect';


const HttpStatus = require("../helper/http-status-codes");
const imgCompressOpts = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1400,
  useWebWorker: true,
  onProgress: () => null,
};

function extractStockData(data, docName, noStockRaw) {
  var {
    status,
    carInDate,
    carInMonth,
    brand,
    branch,
    stockBranch,
    wholesaleRetail,
    locatedAt,
    latestLocatedAt,
    specs,
    specsLower,
    model,
    modelLower,
    numberPlate,
    numberPlateLower,
    numberPlateNumber,
    numberPlateAppend,
    numberPlateArr,
    numberPlateRaw,
    interchange,
    yearMake,
    photo,
    latestStatus,
    latestStatusSec,
    latestStatusDate,
    publishStatus,
    inActive,
    ...additionalData
  } = data;
  var stockData = {};

  if (!noStockRaw) {
    stockData = {
      status: status ? status : [],
      carInDate,
      carInMonth,
      brand,
      wholesaleRetail,
      branch,
      stockBranch,
      locatedAt: locatedAt ? locatedAt : [],
      latestLocatedAt,
      model,
      specs,
      specsLower,
      modelLower,
      numberPlate,
      publishStatus,
      numberPlateLower,
      numberPlateNumber,
      numberPlateAppend,
      numberPlateRaw,
      numberPlateArr,
      interchange,
      latestStatus,
      latestStatusSec,
      latestStatusDate,
      yearMake,
      photo: photo ? photo : [],
      inActive,
    };
  }

  const globalVariablesArray = globalVariables[docName]
    ? globalVariables[docName].globalVariables
    : [];

  const allowedStockVar = globalVariables[docName]
    ? globalVariables[docName].allowedStockVar
    : [];

  globalVariablesArray.forEach((variable) => {
    stockData[variable] = additionalData[variable];
    delete additionalData[variable];
  });

  allowedStockVar.forEach((variable) => {
    stockData[variable] = data[variable];
  });

  var stockData = removeUndefined(stockData);
  var additionalData = removeUndefined(additionalData);

  return { stockData, additionalData };
}

function getStockCheckQuery(stockData) {
  return firebaseDB
    .stockCollection()
    .where("numberPlateLower", "==", stockData.numberPlateLower);
}

function getDocumentMetadata(create) {
  var returnObject = {
    lastModifiedBy: auth.currentUser.displayName,
    updateAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  if (create) {
    returnObject["createdAt"] = firebase.firestore.FieldValue.serverTimestamp();
    returnObject["createdBy"] = auth.currentUser.displayName;
  }
  return returnObject;
}

async function commitBatch(batch, stockDataID, id, customerID) {
  var response = initializeResponse;

  await batch
    .commit()
    .then((results) => {
      if (response.statusCode == HttpStatus.OK) {
        response = getFormattedResults("success", "Successfully Processed");
        response.stockDataID = stockDataID;
        response.id = id;
        response.customerID = customerID;
      }
    })
    .catch((err) => {
      response = getFormattedResults("error", "Error processing");
    });

  return response;
}

function preprocessStockPhotoInput(data, photos, functionState, idState) {
  var newData = { ...data };
  if (functionState == "update") {
    delete newData.stockDataID;
    if (idState == "withID") {
      delete newData.id;
    }
  }

  var createPhoto = [];
  var deletePhoto = [];
  photos.forEach((photo) => {
    if (data[photo]) {
      var newPhoto = [];
      var photoToBeRemoved = [];
      data[photo].deletePhoto.forEach((e) => {
        photoToBeRemoved.push(e.id);
      });

      if (data[photo].displayPhoto) {
        data[photo].displayPhoto.forEach((e) => {
          if (e.file != null) {
            newPhoto.push({
              id: e.id,
              type: e.type || e.name.split(".").pop(),
              name: e.file.name,
            });
          } else if (!photoToBeRemoved.includes(e.id)) {
            newPhoto.push({
              id: e.id,
              type: e.type || e.name.split(".").pop(),
              name: e.name,
            });
          }
        });
      } else {
        data[photo].photo.forEach((e) => {
          if (!photoToBeRemoved.includes(e.id)) {
            newPhoto.push({
              id: e.id,
              name: e.name,
              type: e.type || e.name.split(".").pop(),
            });
          }
        });
        data[photo].createPhoto.forEach((e) => {
          newPhoto.push({
            id: e.id,
            name: e.file.name,
            type: e.type || e.file.name.split(".").pop(),
          });
        });
      }
      newData[photo] = newPhoto;
      createPhoto.push(...data[photo].createPhoto);
      deletePhoto.push(...data[photo].deletePhoto);
    } else {
      delete newData[photo];
    }
  });

  return {
    newData,
    createPhoto,
    deletePhoto,
  };
}
function preprocessAssignedCustomerInput(data) {
  var newData = JSON.parse(JSON.stringify(data));

  var newAssigned = [];
  var delAssigned = [];
  if (data.assignedCustomer && !Array.isArray(data.assignedCustomer)) {
    newData.assignedCustomer = data.assignedCustomer.assignedCustomer.map(
      (customer) => ({
        customerID: customer.customerID,
        customerName: customer.customerName,
      })
    );
    newAssigned.push(...data.assignedCustomer.newAssigned);
    delAssigned.push(...data.assignedCustomer.delAssigned);
  } else {
    delete newData.assignedCustomer;
  }
  return { newData, newAssigned, delAssigned };
}
function preprocessAssignedSaleStockInput(data) {
  var newData = JSON.parse(JSON.stringify(data));

  var newAssigned = [];
  var delAssigned = [];
  if (data.assignedSaleStock && !Array.isArray(data.assignedSaleStock)) {
    newData.assignedSaleStock = data.assignedSaleStock.assignedSaleStock.map(
      (saleStock) => ({
        saleStockID: saleStock.saleStockID,
        numberPlate: saleStock.numberPlate,
      })
    );
    newAssigned.push(...data.assignedSaleStock.newAssigned);
    delAssigned.push(...data.assignedSaleStock.delAssigned);
  } else {
    delete newData.assignedSaleStock;
  }
  return { newData, newAssigned, delAssigned };
}
function preprocessAssignedStockInput(data) {
  var newData = JSON.parse(JSON.stringify(data));

  var newAssigned = [];
  var delAssigned = [];
  if (data.assignedStock && !Array.isArray(data.assignedStock)) {
    newData.assignedStock = data.assignedStock.assignedStock.map((stock) => ({
      id: stock.id,
      numberPlate: stock.numberPlate,
    }));
    newAssigned.push(...data.assignedStock.newAssigned);
    delAssigned.push(...data.assignedStock.delAssigned);
  } else {
    delete newData.assignedStock;
  }
  return { newData, newAssigned, delAssigned };
}
function removeUndefined(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] == undefined) {
      delete data[key];
    }
  });
  return data;
}
async function waitHalfSec() {
  await new Promise((resolve, reject) => {
    setTimeout(() => resolve("done!"), 500);
  });
}

async function manageImageStorage(createPhoto, deletePhoto) {
  let total = createPhoto.length + deletePhoto.length;
  let curr = 0;
  let id = "toast-prog-container";
  let toast = cogoToast.loading(<div id={id} />, loadingToastConf);
  await waitHalfSec();
  let component = ReactDOM.render(
    <FileOperationPorgComponent total={total} />,
    document.getElementById(id)
  );
  let createPhotoChunk = chunk(createPhoto, isMobile ? 2 : 5);
  await asyncForEach(createPhotoChunk, async (photos) => {
    await generatePromises(photos, async (e) => {
      // await asyncForEach(createPhoto, async (e) => {
      var path = `stockImages/${e.id}`;
      const metadata = { contentType: e.file.type };
      curr += 1;
      component.setState({ curr });
      const compressedFile =
        e.type === "pdf"
          ? e.file
          : await imageCompression(e.file, imgCompressOpts);
      let uploadTask = storageRef.child(path).put(compressedFile, metadata);
      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const value =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            component.setState({ value });
          },
          (error) => {
            reject(error);
            alert(error);
          },
          () => {
            resolve();
          }
        );
      });
    });
  });

  component.setState({ value: 0 });
  await generatePromises(deletePhoto, async (e) => {
    var path = `stockImages/${e.id}`;
    curr += 1;
    component.setState({ curr });
    try {
      await storageRef.child(path).delete();
    } catch (error) {
      return Promise.resolve();
    }
    return Promise.resolve();
  });
  toast.hide();
  return;
}
async function manageCustomerAssigned(userID, newAssigned, delAssigned) {
  await asyncForEach(newAssigned, async (e) => {
    await firebaseDB
      .customerCollection()
      .doc(e)
      .update({
        assignedTo: firebase.firestore.FieldValue.arrayUnion(userID),
      });
  });
  await asyncForEach(delAssigned, async (e) => {
    await firebaseDB
      .customerCollection()
      .doc(e)
      .update({
        assignedTo: firebase.firestore.FieldValue.arrayRemove(userID),
      });
  });
  return;
}
async function manageSaleStockAssigned(userID, newAssigned, delAssigned) {
  await asyncForEach(newAssigned, async (e) => {
    await firebaseDB
      .saleStockCollection()
      .doc(e)
      .update({
        assignedTo: firebase.firestore.FieldValue.arrayUnion(userID),
      });
  });
  await asyncForEach(delAssigned, async (e) => {
    await firebaseDB
      .saleStockCollection()
      .doc(e)
      .update({
        assignedTo: firebase.firestore.FieldValue.arrayRemove(userID),
      });
  });
  return;
}
async function manageStockAssigned(userID, newAssigned, delAssigned) {
  await asyncForEach(newAssigned, async (e) => {
    await firebaseDB
      .stockCollection()
      .doc(e)
      .update({
        assignedTo: firebase.firestore.FieldValue.arrayUnion(userID),
      });
    await firebaseDB
      .stockCollection()
      .doc(e)
      .get()
      .then(async (doc) => {
        const salesPersonID = doc.data().salesPersonID;
        const paymentRequestID = doc.data().paymentRequestID;
        if (salesPersonID && salesPersonID.length != 0) {
          await asyncForEach(salesPersonID, async (id) => {
            await firebaseDB
              .salesPersonCollection()
              .doc(id)
              .update({
                assignedTo: firebase.firestore.FieldValue.arrayUnion(userID),
              });
          });
        }
        if (paymentRequestID && paymentRequestID.length != 0) {
          await asyncForEach(paymentRequestID, async (id) => {
            await firebaseDB
              .paymentRequestCollection()
              .doc(id)
              .update({
                assignedTo: firebase.firestore.FieldValue.arrayUnion(userID),
              });
          });
        }
      });
  });
  await asyncForEach(delAssigned, async (e) => {
    await firebaseDB
      .stockCollection()
      .doc(e)
      .update({
        assignedTo: firebase.firestore.FieldValue.arrayRemove(userID),
      });
    await firebaseDB
      .stockCollection()
      .doc(e)
      .get()
      .then(async (doc) => {
        const salesPersonID = doc.data().salesPersonID;
        const paymentRequestID = doc.data().paymentRequestID;
        if (salesPersonID && salesPersonID.length != 0) {
          await asyncForEach(salesPersonID, async (id) => {
            await firebaseDB
              .salesPersonCollection()
              .doc(id)
              .update({
                assignedTo: firebase.firestore.FieldValue.arrayRemove(userID),
              });
          });
        }
        if (paymentRequestID && paymentRequestID.length != 0) {
          await asyncForEach(paymentRequestID, async (id) => {
            await firebaseDB
              .paymentRequestCollection()
              .doc(id)
              .update({
                assignedTo: firebase.firestore.FieldValue.arrayRemove(userID),
              });
          });
        }
      });
  });
  return;
}

async function manageMultiDocUpdate(
  batch,
  stockDataID,
  additionalData,
  docName
) {
  if (documentPath[docName]) {
    const documentMetadataCreate = getDocumentMetadata(1);

    await firebaseDB
      .stockCollection()
      .doc(stockDataID)
      .get()
      .then((doc) => {
        var data = doc.data();
        Object.keys(documentPath[docName]).forEach((field) => {
          var value = additionalData[field];
          var documentArray = documentPath[docName][field];
          if (value && documentArray) {
            documentArray.forEach((e) => {
              var docID = data[`${e}ID`];
              if (docID) {
                batch.update(firebasePath[e].doc(docID), {
                  [field]: value,
                });
              } else {
                var docID = generateRandomID();
                batch.update(firebaseDB.stockCollection().doc(stockDataID), {
                  [`${e}ID`]: docID,
                });
                batch.set(firebasePath[e].doc(docID), {
                  [field]: value,
                  stockDataID,
                  ...documentMetadataCreate,
                });
              }
            });
          }
        });
      });
  }

  return batch;
}

async function formatBookingStatus(stockDataID, id, bookingStatus, state) {
  var bookingStatusObject = null;
  var oldStockBooking = null;
  await firebaseDB
    .stockCollection()
    .doc(stockDataID)
    .get()
    .then((doc) => {
      bookingStatusObject = doc.data().bookingStatus;
      if (bookingStatusObject) {
        bookingStatusObject[id] = bookingStatus;
      } else {
        bookingStatusObject = { [id]: bookingStatus };
      }
    });
  if (state == "update") {
    await firebaseDB
      .salesPersonCollection()
      .doc(id)
      .get()
      .then(async (doc) => {
        if (doc.data().stockDataID != stockDataID) {
          await firebaseDB
            .stockCollection()
            .doc(doc.data().stockDataID)
            .get()
            .then((doc) => {
              var oldBookingStatus = doc.data().bookingStatus;
              delete oldBookingStatus[id];
              oldStockBooking = {
                stockDataID: doc.id,
                bookingStatus: oldBookingStatus,
              };
            });
        }
      });
  }
  return { bookingStatus: bookingStatusObject, oldStockBooking };
}

async function addSalesPersonAndPaymentBatch({
  batch,
  stockDataID,
  stockData,
}) {
  var {
    numberPlateNumber,
    numberPlateLower,
    modelLower,
    brand,
    wholesaleRetail,
    specsLower,
    carInDate,
    latestStatus,
    latestStatusSec,
    yearMake,
    branch,
    latestLocatedAt,
  } = stockData;
  await firebaseDB
    .stockCollection()
    .doc(stockDataID)
    .get()
    .then((doc) => {
      const salesPersonID = doc.data().salesPersonID;
      const paymentRequestID = doc.data().paymentRequestID;
      var details = {
        numberPlateNumber,
        numberPlateLower,
        modelLower,
        brand,
        wholesaleRetail,
        specsLower,
        carInDate,
        latestStatus,
        latestStatusSec,
        yearMake,
        branch,
        latestLocatedAt,
      };
      var details = removeUndefined(details);
      if (salesPersonID && salesPersonID.length != 0) {
        salesPersonID.forEach((id) => {
          batch.update(firebaseDB.salesPersonCollection().doc(id), details);
        });
      }
      if (paymentRequestID && paymentRequestID.length != 0) {
        paymentRequestID.forEach((id) => {
          batch.update(firebaseDB.paymentRequestCollection().doc(id), details);
        });
      }
    });

  return batch;
}

async function addLinkedModulesTransaction({ transaction, stockDataID, data }) {
  var {
    numberPlateNumber,
    numberPlateLower,
    numberPlateArr,
    modelLower,
    brand,
    wholesaleRetail,
    specsLower,
    carInDate,
    carInMonth,
    latestStatus,
    latestStatusSec,
    latestStatusDate,
    yearMake,
    branch,
    latestLocatedAt,
    takeBy,
  } = data;
  const stockDocRef = firebaseDB.stockCollection().doc(stockDataID);
  let doc = await transaction.get(stockDocRef);

  const salesPersonID = doc.data().salesPersonID;
  const paymentRequestID = doc.data().paymentRequestID;
  var details = {
    numberPlateNumber,
    numberPlateLower,
    numberPlateArr,
    modelLower,
    brand,
    wholesaleRetail,
    specsLower,
    carInDate,
    carInMonth,
    latestStatus,
    latestStatusSec,
    latestStatusDate,
    yearMake,
    branch,
    latestLocatedAt,
    takeBy,
  };
  var details = removeUndefined(details);
  if (salesPersonID && salesPersonID.length != 0) {
    salesPersonID.forEach((id) => {
      transaction.update(firebaseDB.salesPersonCollection().doc(id), details);
    });
  }
  if (paymentRequestID && paymentRequestID.length != 0) {
    paymentRequestID.forEach((id) => {
      transaction.update(
        firebaseDB.paymentRequestCollection().doc(id),
        details
      );
    });
  }
  return transaction;
}

async function addUserBatch({ batch, userID, stockDataID, numberPlate }) {
  await firebaseDB
    .userCollection()
    .doc(userID)
    .get()
    .then((doc) => {
      var newAssignedStock = doc.data().assignedStock;
      if (!newAssignedStock) {
        newAssignedStock = [];
      }
      newAssignedStock.push({
        id: stockDataID,
        numberPlate,
      });
      batch.update(firebaseDB.userCollection().doc(userID), {
        assignedStock: newAssignedStock,
      });
    });
  return batch;
}

async function addUserTransaction({
  transaction,
  userID,
  stockDataID,
  numberPlate,
}) {
  const userDocRef = firebaseDB.userCollection().doc(userID);
  let doc = await transaction.get(userDocRef);
  let newAssignedStock = doc.data().assignedStock;
  if (!newAssignedStock) {
    newAssignedStock = [];
  }
  newAssignedStock.push({
    id: stockDataID,
    numberPlate,
  });
  transaction.update(userDocRef, {
    assignedStock: newAssignedStock,
  });
  return transaction;
}

async function getAlternateNumPlate(data) {
  let altNumPlate = "";
  await firebaseDB
    .stockCollection()
    .where("numberPlateRaw", "==", data.numberPlateRaw)
    .where("wholesaleRetail", "==", data.wholesaleRetail)
    .orderBy("numberPlate", "desc")
    .limit(1)
    .get()
    .then(async (snapshot) => {
      if (!snapshot.empty) {
        snapshot.forEach((doc) => {
          const numberPlateAppend = doc.data().numberPlateAppend;
          let letter = numberPlateAppend[0];
          let count = removeSpacesAndChar(numberPlateAppend);
          if (count == "") {
            count = 1;
          } else {
            count = parseInt(count) + 1;
          }
          altNumPlate = `${data.numberPlateRaw}-${letter}${count}`;
        });
      } else {
        altNumPlate = `${data.numberPlateRaw}-R`;
      }
    });
  return altNumPlate;
}

function increment(step) {
  return firebase.firestore.FieldValue.increment(step);
}

function rmvStatusTnsac(
  transaction,
  counterRef,
  prevMap,
  currMap,
  counterName
) {
  //check for removed status
  Object.entries(prevMap).forEach(([key, value], index) => {
    if (!currMap[key]) {
      const date = value;
      const status = key;
      let dailyID = unixToTimeIfAvailable(date, "YYYY-MM-DD");
      let monthlyID = unixToTimeIfAvailable(date, "YYYY-MM");
      transaction.set(
        counterRef.collection("daily").doc(dailyID),
        { [counterName]: { [status]: increment(-1) } },
        { merge: true }
      );
      transaction.set(
        counterRef.collection("monthly").doc(monthlyID),
        { [counterName]: { [status]: increment(-1) } },
        { merge: true }
      );
    }
  });
}

function newStatusTnsac(
  transaction,
  counterRef,
  prevMap,
  currMap,
  counterName
) {
  // check for newly added status or status with changed date
  Object.entries(currMap).forEach(([key, value], index) => {
    let date = value;
    let status = key;
    let day = unixToTimeIfAvailable(date, "DD");
    let month = unixToTimeIfAvailable(date, "MM");
    let year = unixToTimeIfAvailable(date, "YYYY");
    let dailyID = unixToTimeIfAvailable(date, "YYYY-MM-DD");
    let monthlyID = unixToTimeIfAvailable(date, "YYYY-MM");
    if (prevMap[key] && value != prevMap[key]) {
      let prevDate = prevMap[key];
      let prevDailyID = unixToTimeIfAvailable(prevDate, "YYYY-MM-DD");
      let prevMonthlyID = unixToTimeIfAvailable(prevDate, "YYYY-MM");
      transaction.set(
        counterRef.collection("daily").doc(prevDailyID),
        { [counterName]: { [status]: increment(-1) } },
        { merge: true }
      );
      transaction.set(
        counterRef.collection("monthly").doc(prevMonthlyID),
        { [counterName]: { [status]: increment(-1) } },
        { merge: true }
      );
      transaction.set(
        counterRef.collection("daily").doc(dailyID),
        {
          [counterName]: { [status]: increment(1) },
          day,
          month,
          year,
        },
        { merge: true }
      );
      transaction.set(
        counterRef.collection("monthly").doc(monthlyID),
        { [counterName]: { [status]: increment(1) }, month, year },
        { merge: true }
      );
    } else if (!prevMap[key]) {
      transaction.set(
        counterRef.collection("daily").doc(dailyID),
        {
          [counterName]: { [status]: increment(1) },
          day,
          month,
          year,
        },
        { merge: true }
      );
      transaction.set(
        counterRef.collection("monthly").doc(monthlyID),
        { [counterName]: { [status]: increment(1) }, month, year },
        { merge: true }
      );
    }
  });
}

export {
  extractStockData,
  getStockCheckQuery,
  getDocumentMetadata,
  commitBatch,
  preprocessStockPhotoInput,
  preprocessAssignedCustomerInput,
  preprocessAssignedSaleStockInput,
  preprocessAssignedStockInput,
  removeUndefined,
  manageImageStorage,
  manageStockAssigned,
  manageSaleStockAssigned,
  manageCustomerAssigned,
  manageMultiDocUpdate,
  formatBookingStatus,
  addSalesPersonAndPaymentBatch,
  addUserBatch,
  addUserTransaction,
  addLinkedModulesTransaction,
  getAlternateNumPlate,
  rmvStatusTnsac,
  newStatusTnsac,
};
