export const LIST_SALESTOCK = "LIST_SALESTOCK";
export const GET_SALESTOCK = "GET_SALESTOCK";
export const CREATE_SALESTOCK = "CREATE_SALESTOCK";
export const UPDATE_SALESTOCK = "UPDATE_SALESTOCK";
export const PUBLISH_SALESTOCK = "PUBLISH_SALESTOCK";
export const UNPUBLISH_SALESTOCK = "UNPUBLISH_SALESTOCK";
export const DELETE_SALESTOCK = "DELETE_SALESTOCK";
export const UPDATE_ACTIVE_SALESTOCK = "UPDATE_ACTIVE_SALESTOCK";
export const UPDATE_FLOOR_STOCK_RECORDS = "UPDATE_FLOOR_STOCK_RECORDS";
export const UPDATE_PUSPAKOM_RECORDS = "UPDATE_PUSPAKOM_RECORDS";
export const UPDATE_ORI_GERAN_RECORDS = "UPDATE_ORI_GERAN_RECORDS";
export const UPDATE_RECEIVE_KEY_CHECK = "UPDATE_RECEIVE_KEY_CHECK";
export const UPDATE_RECEIVE_KEY_RECORDS = "UPDATE_RECEIVE_KEY_RECORDS";
export const UPDATE_EAUTO_RECORDS = "UPDATE_EAUTO_RECORDS";

export const TOGGLE_CREATE_SALESTOCK = "TOGGLE_CREATE_SALESTOCK";
export const TOGGLE_UPDATE_SALESTOCK = "TOGGLE_UPDATE_SALESTOCK";
export const TOGGLE_DELETE_SALESTOCK = "TOGGLE_DELETE_SALESTOCK";
export const TOGGLE_DETAIL_SALESTOCK = "TOGGLE_DETAIL_SALESTOCK";

export const RESET_SALESTOCK_PARAMETERES = "RESET_SALESTOCK_PARAMETERES";
export const UPDATE_SALESTOCK_PARAMETERS = "UPDATE_SALESTOCK_PARAMETERS";
export const DELETE_SALESTOCK_PARAMETERS = "DELETE_SALESTOCK_PARAMETERS";
export const DETAIL_SALESTOCK_PARAMETERS = "DETAIL_SALESTOCK_PARAMETERS";

export const HANDLE_CHANGE_SALESTOCK = "HANDLE_CHANGE_SALESTOCK";

export const SHOW_EMPTY_FIELD_SALESTOCK = "SHOW_EMPTY_FIELD_SALESTOCK";
