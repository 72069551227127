import { preprocessStockPhotoInput } from "../../firebase/helper";
import { firebaseDB } from "../../firebase";
import {
  unixToTimeIfAvailable,
  dateTimeAPFormat,
  dateAPFormat,
  extractLatestStatus,
  removeSpacesAndLowerCase,
  removeSpacesAndChar,
} from "../../helper";
import stockStatusFilter from "../../helper/stockStatusFilter";
import {
  stockStatusConf,
  wholesaleRetailStatus,
} from "../../helper/stockStatus";

const moment = require("moment");

function filterByStatus(currentFilter, query) {
  const queryFieldStatus = "latestStatus";
  query = query.where(queryFieldStatus, "in", currentFilter);
  return query;
}
// function filterByCustomerStatus(currentFilter, query) {
//   const queryFieldStatus = "latestStatus";
//   query = query.where(queryFieldStatus, "in", currentFilter);
//   return query;
// }
function firebaseQuerySelector(specialQuery) {
  if (specialQuery) {
    switch (specialQuery) {
      case "salesPerson":
        return firebaseDB.salesPersonCollection();
      case "paymentRequest":
        return firebaseDB.paymentRequestCollection();
      default:
        break;
    }
  } else {
    return firebaseDB.stockCollection();
  }
}
function initializeQuery({
  size,
  start,
  prevQuery,
  specialQuery,
  prevSearching,
  attachModule,
  search,
  searchModel,
  searchSpecs,
  searchBrand,
  searchBranch,
  searchLocatedAt,
  searchStatus,
  searchStartStatusDate,
  searchEndStatusDate,
  searchStartCustomerStatusDate,
  searchEndCustomerStatusDate,
  searchCreationDate,
  searchCarInDate,
  searchBookingDate,
  searchCustomerStatus,
  searchYearMake,
  searchRepairStatus,
  searchBiddingStatus,
  searchFloorStockStatus,
  searchMyTukarFundStatus,
  searchBookingStatus,
  searchSalesPersonName,
  searchUserBranch,
  searchTakeBy,
  searchAssignedTo,
  searchDeactivate,
  searchDeadline,
  searchPublishStatus,
  searchCheckStatus,
  searchApproveStatus,
  searchSellingPriceApproveStatus,
  searchPayment,
  searchWholesaleRetail,
  searchStockBranch,
  searchLatestStatusSec,
  module,
  purchaserWR,
  isFirstLoad,
  isToggleButton,
}) {
  var tableItems = [];
  var searching = (prevSearching && start) || false;
  var startSnapshot = undefined;
  if (specialQuery) {
    switch (specialQuery) {
      case "salesPerson":
        var query = firebaseDB.salesPersonCollection();
        break;
      case "paymentRequest":
        var query = firebaseDB.paymentRequestCollection();
        break;
      default:
        break;
    }
  } else {
    var query =
      prevSearching && start ? prevQuery : firebaseDB.stockCollection();
  }

  var newSize = size ? size : 20;
  var queryFieldCreatedAt = "createdAt";
  var queryFieldPlate = "numberPlateLower";
  var queryFieldPlateNumber = "numberPlateNumber";
  var queryFieldModel = "modelLower";
  var queryFieldSpecs = "specsLower";
  var queryFieldBrand = "brand";
  var queryFieldBranch = "branch";
  var queryFieldLocatedAt = "latestLocatedAt";
  var queryFieldStatus = "latestStatus";
  var queryFieldStatusDate = "latestStatusDate";
  var queryFieldCarInDate = "carInDate";
  var queryFieldCarInMonth = "carInMonth";
  var queryFieldBookingDate = "bookingDate";
  var queryFieldBookingMonth = "bookingMonth";
  var queryFieldLatestCustomerStatus = "latestCustomerStatus";
  var queryFieldLatestCustomerDate = "latestCustomerDate";
  // var queryFieldCreationDate = "createdAt";
  var queryFieldYearMake = "yearMake";
  var queryFieldRepairStatus = "repairStatus";
  var queryFieldBiddingStatus = "biddingStatus";
  var queryFieldFloorStockStatus = "latestFloorStockStatus";
  var queryFieldMyTukarFundStatus = "myTukarFundStatus";
  var queryFieldBookingStatus = "bookingStatus";
  var queryFieldSalesPersonName = "salesPersonName";
  var queryFieldTakeBy = "takeBy";
  var queryFieldAssignedTo = "assignedTo";
  var queryFieldInActive = "inActive";
  var queryFieldDeadline = "deadline";
  var queryFieldPublish = "publishStatus";
  var queryFieldCheck = "checkStatus";
  var queryFieldApprove = "approvalStatus";
  var queryFieldSellingPriceApprove = "sellingPriceApprovalStatus";
  var queryFieldPayment = "payment";
  var queryFieldStockBranch = "stockBranch";
  var queryFieldLatestStatusSec = "latestStatusSec";
  var queryFieldWholesaleRetail = "wholesaleRetail";
  var queryFieldNumberPlateArr = "numberPlateArr";
  var queryFieldUserBranch = "userBranch";

  var sortByDate = true;
  var sortKey =
    attachModule || module == "salesPerson"
      ? queryFieldCreatedAt
      : queryFieldStatusDate;
  let defaultStatusFilter = stockStatusFilter[module];
  if (
    !searchStatus &&
    !attachModule &&
    defaultStatusFilter &&
    defaultStatusFilter[purchaserWR] &&
    !searchLatestStatusSec
  ) {
    query =
      module !== "salesPerson"
        ? filterByStatus(defaultStatusFilter[purchaserWR], query)
        : query;
  } else if (
    searchStatus &&
    searchStatus.length == 0 &&
    !attachModule &&
    !searchLatestStatusSec
  ) {
    let stockStatusOptions = [];
    let defaultFilter = [];
    if (defaultStatusFilter && defaultStatusFilter[purchaserWR]) {
      defaultFilter = defaultStatusFilter[purchaserWR];
    }
    if (
      wholesaleRetailStatus[module] &&
      wholesaleRetailStatus[module][purchaserWR]
    ) {
      stockStatusOptions = wholesaleRetailStatus[module][purchaserWR]
        .concat(defaultFilter)
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
      query =
        module !== "salesPerson"
          ? filterByStatus(stockStatusOptions, query)
          : query;
    } else if (stockStatusConf[module]) {
      stockStatusOptions = stockStatusConf[module]
        .concat(defaultFilter)
        .filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
      query =
        module !== "salesPerson"
          ? filterByStatus(stockStatusOptions, query)
          : query;
    }
  } else if (searchStatus && searchStatus.length > 0) {
    query =
      module !== "salesPerson" ? filterByStatus(searchStatus, query) : query;
    searching = true;
    newSize = null;
  }

  // if (attachModule) {
  //   orderByLatestStatus = false;
  // }

  if (search !== "" && search != undefined) {
    query =
      prevSearching && start ? prevQuery : firebaseQuerySelector(specialQuery);
    // if (isNaN(search)) {
    // .where(queryFieldPlate, "<=", search.toLowerCase() + "\uf8ff")
    // .orderBy(queryFieldNumberPlateArr);
    // } else {
    // .where(queryFieldPlateNumber, "<=", search + "\uf8ff")
    // .orderBy(queryFieldPlateNumber);
    // }
    query = query.where(
      `${queryFieldNumberPlateArr}.${search.toLowerCase()}`,
      "==",
      true
    );
    sortByDate = false;
    searching = true;
    newSize = null;
  } else if (
    searchModel ||
    searchBrand ||
    searchSpecs ||
    searchBranch ||
    searchLocatedAt ||
    searchCarInDate ||
    searchBookingDate ||
    searchCustomerStatus ||
    searchYearMake ||
    searchRepairStatus ||
    searchBiddingStatus ||
    searchFloorStockStatus ||
    searchMyTukarFundStatus ||
    searchBookingStatus ||
    searchSalesPersonName ||
    searchUserBranch ||
    searchTakeBy ||
    searchDeadline ||
    searchPublishStatus ||
    searchCheckStatus ||
    searchApproveStatus ||
    searchSellingPriceApproveStatus ||
    searchStartStatusDate ||
    searchCreationDate ||
    searchPayment ||
    searchEndStatusDate ||
    searchStartCustomerStatusDate ||
    searchEndCustomerStatusDate ||
    searchWholesaleRetail ||
    searchStockBranch ||
    searchLatestStatusSec
  ) {
    if (!isFirstLoad) {
      newSize = null;
      searching = true;
    }
    if (searchSpecs != "" && searchSpecs != undefined) {
      query = query
        .where(queryFieldSpecs, ">=", searchSpecs.toLowerCase())
        .where(queryFieldSpecs, "<=", searchSpecs.toLowerCase() + "\uf8ff")
        .orderBy(queryFieldSpecs);
    }
    if (searchBrand != "" && searchBrand != undefined) {
      query = query.where(queryFieldBrand, "==", searchBrand);
    }
    if (searchModel != "" && searchModel != undefined) {
      query = query.where(
        queryFieldModel,
        "==",
        removeSpacesAndLowerCase(searchModel)
      );
    }
    if (searchBranch != "" && searchBranch != undefined) {
      query = query.where(queryFieldBranch, "==", searchBranch);
    }
    if (searchWholesaleRetail != "" && searchWholesaleRetail != undefined) {
      query = query.where(
        queryFieldWholesaleRetail,
        "==",
        searchWholesaleRetail
      );
    }
    if (searchStockBranch != "" && searchStockBranch != undefined) {
      query = query.where(queryFieldStockBranch, "==", searchStockBranch);
    }
    if (searchLatestStatusSec != "" && searchLatestStatusSec != undefined) {
      query = query.where(
        queryFieldLatestStatusSec,
        "==",
        searchLatestStatusSec
      );
    }

    if (searchLocatedAt != "" && searchLocatedAt != undefined) {
      query = query.where(queryFieldLocatedAt, "==", searchLocatedAt);
    }
    if (searchCarInDate) {
      query = query.where(queryFieldCarInMonth, "==", searchCarInDate);
      sortKey = queryFieldCarInDate;
    }
    if (searchBookingDate) {
      query = query.where(queryFieldBookingMonth, "==", searchBookingDate);
      sortKey = queryFieldBookingDate;
    }

    if (module == "paymentRequest") {
      if (searchStartStatusDate && !searchEndStatusDate) {
        let startDate = new Date(moment.unix(searchStartStatusDate));
        let endDate = new Date(moment.unix(searchStartStatusDate).endOf("day"));
        query = query
          .where(queryFieldCreatedAt, ">=", startDate)
          .where(queryFieldCreatedAt, "<=", endDate);
        sortKey = queryFieldCreatedAt;
      } else if (searchStartStatusDate && searchEndStatusDate) {
        query = query
          .where(
            queryFieldCreatedAt,
            ">=",
            new Date(moment.unix(searchStartStatusDate))
          )
          .where(
            queryFieldCreatedAt,
            "<=",
            new Date(moment.unix(searchEndStatusDate))
          );
        sortKey = queryFieldCreatedAt;
      }
    } else {
      if (searchStartStatusDate && !searchEndStatusDate) {
        query = query.where(queryFieldStatusDate, "==", searchStartStatusDate);
      } else if (searchStartStatusDate && searchEndStatusDate) {
        query = query
          .where(queryFieldStatusDate, ">=", searchStartStatusDate)
          .where(queryFieldStatusDate, "<=", searchEndStatusDate);
        sortKey = queryFieldStatusDate;
      }
    }
    if (searchStartCustomerStatusDate && !searchEndCustomerStatusDate) {
      query = query.where(
        queryFieldLatestCustomerDate,
        "==",
        searchStartCustomerStatusDate
      );
    } else if (searchStartCustomerStatusDate && searchEndCustomerStatusDate) {
      query = query
        .where(
          queryFieldLatestCustomerDate,
          ">=",
          searchStartCustomerStatusDate
        )
        .where(queryFieldLatestCustomerDate, "<=", searchEndCustomerStatusDate);
      sortKey = queryFieldLatestCustomerDate;
    }
    if (searchYearMake != "" && searchYearMake != undefined) {
      query = query.where(queryFieldYearMake, "==", searchYearMake);
    }
    if (searchRepairStatus != "" && searchRepairStatus != undefined) {
      query = query.where(queryFieldRepairStatus, "==", searchRepairStatus);
    }
    if (searchBiddingStatus != "" && searchBiddingStatus != undefined) {
      query = query.where(queryFieldBiddingStatus, "==", searchBiddingStatus);
    }
    if (searchFloorStockStatus != "" && searchFloorStockStatus != undefined) {
      query = query.where(
        queryFieldFloorStockStatus,
        "==",
        searchFloorStockStatus
      );
    }
    if (searchMyTukarFundStatus != "" && searchMyTukarFundStatus != undefined) {
      query = query.where(
        queryFieldMyTukarFundStatus,
        "==",
        searchMyTukarFundStatus
      );
    }
    if (searchBookingStatus != "" && searchBookingStatus != undefined) {
      query = query.where(queryFieldBookingStatus, "==", searchBookingStatus);
    }
    if (searchSalesPersonName != "" && searchSalesPersonName != undefined) {
      query = query.where(
        queryFieldSalesPersonName,
        "==",
        searchSalesPersonName
      );
      sortKey = queryFieldLatestCustomerDate;
    }
    if (searchUserBranch != "" && searchUserBranch != undefined) {
      query = query.where(queryFieldUserBranch, "==", searchUserBranch);
    }
    if (searchDeadline != "" && searchDeadline != undefined) {
      query = query.where(queryFieldDeadline, "==", searchDeadline);
    }
    if (searchPublishStatus != "" && searchPublishStatus != undefined) {
      query = query.where(queryFieldPublish, "==", searchPublishStatus);
    }
    if (searchCheckStatus != "" && searchCheckStatus != undefined) {
      query = query.where(queryFieldCheck, "==", searchCheckStatus);
    }
    if (searchApproveStatus != "" && searchApproveStatus != undefined) {
      query = query.where(queryFieldApprove, "==", searchApproveStatus);
    }
    if (
      searchSellingPriceApproveStatus != "" &&
      searchSellingPriceApproveStatus != undefined
    ) {
      query = query.where(
        queryFieldSellingPriceApprove,
        "==",
        searchSellingPriceApproveStatus
      );
    }
    if (searchTakeBy != "" && searchTakeBy != undefined) {
      query = query.where(queryFieldTakeBy, "==", searchTakeBy);
    }
    if (searchPayment != "" && searchPayment != undefined) {
      query = query.where(queryFieldPayment, "==", searchPayment);
    }
    if (searchCustomerStatus != "" && searchCustomerStatus != undefined) {
      query = query.where(
        queryFieldLatestCustomerStatus,
        "in",
        searchCustomerStatus
      );
      sortKey = queryFieldLatestCustomerDate;
    }
  }
  if (searchAssignedTo != "" && searchAssignedTo != undefined) {
    query = query.where(
      queryFieldAssignedTo,
      "array-contains",
      searchAssignedTo
    );
  }
  if (searchDeactivate != "" && searchDeactivate != undefined) {
    query = query.where(queryFieldInActive, "==", searchDeactivate);
  }
  if (sortByDate) {
    query = query.orderBy(sortKey, "desc");
  }
  //query.orderBy has to be called before query.startAfter
  if (start != undefined) {
    query = query.startAfter(start);
  }
  return {
    tableItems,
    searching,
    startSnapshot,
    query,
    size: newSize,
    start,
    index: 0,
  };
}

function extractCost(stockDoc, type) {
  if (!type) {
    var owner = stockDoc.data().owner ? stockDoc.data().owner : "-";
    var broker = stockDoc.data().broker ? stockDoc.data().broker : "-";
    var managerCom = stockDoc.data().managerCom
      ? stockDoc.data().managerCom
      : "-";
    var otherCost = stockDoc.data().otherCost ? stockDoc.data().otherCost : "-";
    var repairInfoTotal = stockDoc.data().repairInfoTotal
      ? stockDoc.data().repairInfoTotal
      : "-";
  } else {
    var owner = stockDoc.owner ? stockDoc.owner : "-";
    var broker = stockDoc.broker ? stockDoc.broker : "-";
    var managerCom = stockDoc.managerCom ? stockDoc.managerCom : "-";
    var otherCost = stockDoc.otherCost ? stockDoc.otherCost : "-";
    var repairInfoTotal = stockDoc.repairInfoTotal
      ? stockDoc.repairInfoTotal
      : "-";
  }

  if (owner == "-" && broker == "-") {
    var total = "-";
  } else {
    var total =
      (owner != "-" ? parseFloat(owner, 10) : 0) +
      (broker != "-" ? parseFloat(broker, 10) : 0);
  }
  if (
    total == "-" &&
    otherCost == "-" &&
    managerCom == "-" &&
    repairInfoTotal == "-"
  ) {
    var totalCost = "-";
  } else {
    var totalCost =
      (total != "-" ? parseFloat(total, 10) : 0) +
      (otherCost != "-" ? parseFloat(otherCost, 10) : 0) +
      (managerCom != "-" ? parseFloat(managerCom, 10) : 0) +
      (repairInfoTotal != "-" ? parseFloat(repairInfoTotal, 10) : 0);
  }
  return {
    owner,
    broker,
    managerCom,
    otherCost,
    total: roundDecimal(total),
    totalCost: roundDecimal(totalCost),
  };
}

function extractSalesPersonAmount(stockDoc, type) {
  if (!type) {
    var finalSellingPrice = stockDoc.data().finalSellingPrice
      ? stockDoc.data().finalSellingPrice
      : "-";
    var insurancePrice = stockDoc.data().insurancePrice
      ? stockDoc.data().insurancePrice
      : "-";
    var roadTaxPrice = stockDoc.data().roadTaxPrice
      ? stockDoc.data().roadTaxPrice
      : "-";
    var loanAmount = stockDoc.data().loanAmount
      ? stockDoc.data().loanAmount
      : "-";
    var bookDownPayment = Array.isArray(stockDoc.data().bookDownPayment)
      ? stockDoc.data().bookDownPayment
      : [];
    var refundAmount = Array.isArray(stockDoc.data().refundAmount)
      ? stockDoc.data().refundAmount
      : [];
    var otherDeduction = Array.isArray(stockDoc.data().otherDeduction)
      ? stockDoc.data().otherDeduction
      : [];
  } else {
    var finalSellingPrice = stockDoc.finalSellingPrice
      ? stockDoc.finalSellingPrice
      : "-";
    var insurancePrice = stockDoc.insurancePrice
      ? stockDoc.insurancePrice
      : "-";
    var roadTaxPrice = stockDoc.roadTaxPrice ? stockDoc.roadTaxPrice : "-";
    var loanAmount = stockDoc.loanAmount ? stockDoc.loanAmount : "-";
    var bookDownPayment = Array.isArray(stockDoc.bookDownPayment)
      ? stockDoc.bookDownPayment
      : [];
    var refundAmount = Array.isArray(stockDoc.refundAmount)
      ? stockDoc.refundAmount
      : [];

    var otherDeduction = Array.isArray(stockDoc.otherDeduction)
      ? stockDoc.otherDeduction
      : [];
  }

  if (
    finalSellingPrice == "-" &&
    insurancePrice == "-" &&
    roadTaxPrice == "-" &&
    otherDeduction == []
  ) {
    var carBodyPrice = "-";
  } else {
    var carBodyPrice =
      (finalSellingPrice != "-" ? parseFloat(finalSellingPrice, 10) : 0) -
      (insurancePrice != "-" ? parseFloat(insurancePrice, 10) : 0) -
      (roadTaxPrice != "-" ? parseFloat(roadTaxPrice, 10) : 0) -
      arrayAmountTotal(otherDeduction);
  }
  if (
    finalSellingPrice == "-" &&
    loanAmount == "-" &&
    bookDownPayment == [] &&
    refundAmount == []
  ) {
    var salesTotal = "-";
  } else {
    var salesTotal =
      (finalSellingPrice != "-" ? parseFloat(finalSellingPrice, 10) : 0) -
      (loanAmount != "-" ? parseFloat(loanAmount, 10) : 0) -
      arrayAmountTotal(bookDownPayment) -
      arrayAmountTotal(refundAmount);
  }

  return {
    finalSellingPrice,
    insurancePrice,
    roadTaxPrice,
    loanAmount,
    bookDownPayment,
    refundAmount,
    otherDeduction,
    carBodyPrice: roundDecimal(carBodyPrice),
    salesTotal: roundDecimal(salesTotal),
  };
}

function extractFloorStockAmount(stockDoc, type) {
  if (!type) {
    var balanceCollect = stockDoc.data().balanceCollect
      ? stockDoc.data().balanceCollect
      : "-";
    var floorStock = stockDoc.data().floorStock
      ? stockDoc.data().floorStock
      : "-";
    var settlement = Array.isArray(stockDoc.data().settlement)
      ? stockDoc.data().settlement
      : [];
  } else {
    var balanceCollect = stockDoc.balanceCollect
      ? stockDoc.balanceCollect
      : "-";
    var floorStock = stockDoc.floorStock ? stockDoc.floorStock : "-";
    var settlement = Array.isArray(stockDoc.settlement)
      ? stockDoc.settlement
      : [];
  }

  var settlementTotal = arrayAmountTotal(settlement);

  if (balanceCollect == "-" && floorStock == "-" && settlementTotal == 0) {
    var interest = "-";
  } else {
    var interest =
      settlementTotal -
      (balanceCollect != "-" ? parseFloat(balanceCollect, 10) : 0) -
      (floorStock != "-" ? parseFloat(floorStock, 10) : 0);
  }

  return {
    balanceCollect,
    floorStock,
    settlement,
    settlementTotal: roundDecimal(settlementTotal),
    interest: roundDecimal(interest),
  };
}

function extractMytukarAmount(stockDoc, type) {
  if (!type) {
    var myTukarFund = stockDoc.data().myTukarFund
      ? stockDoc.data().myTukarFund
      : "-";
    var biddingResults = Array.isArray(stockDoc.data().biddingResults)
      ? stockDoc.data().biddingResults
      : [];
    var mytukarCharges = Array.isArray(stockDoc.data().mytukarCharges)
      ? stockDoc.data().mytukarCharges
      : [];
  } else {
    var myTukarFund = stockDoc.myTukarFund ? stockDoc.myTukarFund : "-";
    var biddingResults = Array.isArray(stockDoc.biddingResults)
      ? stockDoc.biddingResults
      : [];
    var mytukarCharges = Array.isArray(stockDoc.mytukarCharges)
      ? stockDoc.mytukarCharges
      : [];
  }

  var totalMyTukarCharge = arrayAmountTotal(mytukarCharges);

  if (myTukarFund == "-" && biddingResults == [] && mytukarCharges == []) {
    var PL = "-";
  } else {
    var PL =
      arrayAmountLast(biddingResults) -
      (myTukarFund != "-" ? parseFloat(myTukarFund, 10) : 0) -
      totalMyTukarCharge;
  }
  return {
    myTukarFund,
    biddingResults,
    mytukarCharges,
    totalMyTukarCharge: roundDecimal(totalMyTukarCharge),
    PL: roundDecimal(PL),
  };
}

function extractPaymentStatusAmount(stockDoc, type) {
  if (!type) {
    var purchaseCarPayment = Array.isArray(stockDoc.data().purchaseCarPayment)
      ? stockDoc.data().purchaseCarPayment
      : [];
    var repairOtherPayment = Array.isArray(stockDoc.data().repairOtherPayment)
      ? stockDoc.data().repairOtherPayment
      : [];
    var salePayment = Array.isArray(stockDoc.data().salePayment)
      ? stockDoc.data().salePayment
      : [];
    var floorStockPayment = Array.isArray(stockDoc.data().floorStockPayment)
      ? stockDoc.data().floorStockPayment
      : [];
  } else {
    var purchaseCarPayment = Array.isArray(stockDoc.purchaseCarPayment)
      ? stockDoc.purchaseCarPayment
      : [];
    var repairOtherPayment = Array.isArray(stockDoc.repairOtherPayment)
      ? stockDoc.repairOtherPayment
      : [];
    var salePayment = Array.isArray(stockDoc.salePayment)
      ? stockDoc.salePayment
      : [];
    var floorStockPayment = Array.isArray(stockDoc.floorStockPayment)
      ? stockDoc.floorStockPayment
      : [];
  }

  var purchaseCarPaymentTotal = arrayAmountTotal(purchaseCarPayment);
  var repairOtherPaymentTotal = arrayAmountTotal(repairOtherPayment);
  var salePaymentTotal = arrayAmountTotal(salePayment);
  var floorStockPaymentTotal = arrayAmountTotal(floorStockPayment);

  return {
    purchaseCarPayment,
    repairOtherPayment,
    salePayment,
    floorStockPayment,
    purchaseCarPaymentTotal: roundDecimal(purchaseCarPaymentTotal),
    repairOtherPaymentTotal: roundDecimal(repairOtherPaymentTotal),
    salePaymentTotal: roundDecimal(salePaymentTotal),
    floorStockPaymentTotal: roundDecimal(floorStockPaymentTotal),
  };
}

//array must have amount field
function arrayAmountTotal(array, key = "amount") {
  if (Array.isArray(array)) {
    return array.reduce((total, item) => {
      return total + parseFloat(item[key] ? item[key] : 0);
    }, 0);
  } else {
    return 0;
  }
}

//array must have date and amount field
function arrayAmountLast(array, key = "amount", sortKey = "date") {
  if (Array.isArray(array) && array.length !== 0) {
    array.sort((a, b) => {
      return b[sortKey] - a[sortKey];
    });
    return array[0][key];
  } else {
    return 0;
  }
}

function roundDecimal(decimal) {
  return Math.round(decimal * 100) / 100;
}

function extractStatusDisplay(latestStatus, latestStatusSec, latestStatusDate) {
  return latestStatus
    ? `${moment.unix(latestStatusDate).format(dateAPFormat)}\n${latestStatus}${
        latestStatus == latestStatusSec ? "" : ` - ${latestStatusSec}`
      }`
    : null;
}
function extractLatestStatusDisplay(latestStatus, latestDate) {
  return latestStatus
    ? `${moment.unix(latestDate).format(dateAPFormat)}\n${latestStatus}`
    : null;
}

function extractLatestBankStatusDisplay(loanStatusList) {
  let flags = [],
    latestLoanStatusListObj = [];
  if (loanStatusList) {
    let latestLoanStatusListObjTemp = loanStatusList.sort((a, b) => {
      return b.creationDate - a.creationDate;
    });
    latestLoanStatusListObjTemp.map((e) => {
      if (!flags[e.bank]) {
        flags[e.bank] = true;
        latestLoanStatusListObj.push({ bank: e.bank, status: e.status });
      }
      return e;
    });
  }
  let res = "";
  latestLoanStatusListObj.map((e) => {
    res += e.bank + ": " + e.status + "\n";
  });
  return res;
}
function extractPhoto(data, photos) {
  const { newData } = preprocessStockPhotoInput(data, photos);
  return {
    photo: newData[photos[0]] ? newData[photos[0]] : [],
    createPhoto: [],
    deletePhoto: [],
  };
}

function extractStockDoc(stockDoc, convertTime) {
  return {
    stockDataID: stockDoc.id,
    assignedTo: stockDoc.data().assignedTo,
    status: stockDoc.data().status,
    latestStatus: stockDoc.data().latestStatus,
    latestStatusSec: stockDoc.data().latestStatusSec,
    latestStatusDate: stockDoc.data().latestStatusDate,
    statusDisplay: extractStatusDisplay(
      stockDoc.data().latestStatus,
      stockDoc.data().latestStatusSec,
      stockDoc.data().latestStatusDate
    ),
    carInDate: convertTime
      ? unixToTimeIfAvailable(stockDoc.data().carInDate, "DD/MM/YYYY")
      : stockDoc.data().carInDate,
    brand: stockDoc.data().brand,
    wholesaleRetail: stockDoc.data().wholesaleRetail,
    branch: stockDoc.data().branch,
    stockBranch: stockDoc.data().stockBranch,
    locatedAt: stockDoc.data().locatedAt,
    latestLocatedAt: stockDoc.data().latestLocatedAt,
    model: stockDoc.data().model,
    specs: stockDoc.data().specs,
    numberPlate: stockDoc.data().numberPlate,
    numberPlateLower: stockDoc.data().numberPlateLower,
    numberPlateNumber: stockDoc.data().numberPlateNumber,
    numberPlateArr: stockDoc.data().numberPlateArr,
    interchange: stockDoc.data().interchange,
    yearMake: stockDoc.data().yearMake,
    photo: {
      photo: stockDoc.data().photo ? stockDoc.data().photo : [],
      createPhoto: [],
      deletePhoto: [],
    },
    stockData: stockDoc.data(),
    retailPrice: stockDoc.data().retailPrice,
    latestRetailPrice: stockDoc.data().latestRetailPrice,
  };
}

function extractStockDocAdditional(stockDoc) {
  return {
    additionalDetailsID: {
      bankFloorStockID: {
        name: "Bank Floor Stock",
        id: stockDoc.data().bankFloorStockID,
      },
      biddingCarID: {
        name: "Bidding Car",
        id: stockDoc.data().biddingCarID,
      },
      carRepairID: {
        name: "Car Repair",
        id: stockDoc.data().carRepairID,
      },
      costStockID: {
        name: "Cost Stock",
        id: stockDoc.data().costStockID,
      },
      myTukarFloorStockID: {
        name: "MyTukar Floor Stock",
        id: stockDoc.data().myTukarFloorStockID,
      },
      paymentRequestID: {
        name: "Payment Request",
        id: stockDoc.data().paymentRequestID,
      },
      paymentStatusID: {
        name: "Payment Status",
        id: stockDoc.data().paymentStatusID,
      },
      purchaserID: {
        name: "Stock In",
        id: stockDoc.data().purchaserID,
      },
      saleStockID: {
        name: "Sale Stock",
        id: stockDoc.data().saleStockID,
      },
      salesPersonID: {
        name: "Sales Person",
        id: stockDoc.data().salesPersonID,
      },
    },
    inActive: stockDoc.data().inActive,
  };
}

function extractMetadata(doc) {
  return {
    lastModifiedBy: doc.data().lastModifiedBy,
    createdBy: doc.data().createdBy,
    createdAt: doc.data().createdAt
      ? moment(doc.data().createdAt?.toDate()).format(dateTimeAPFormat)
      : null,
    updateAt: doc.data().updateAt
      ? moment(doc.data().updateAt?.toDate()).format(dateTimeAPFormat)
      : null,
  };
}

function resetStockData() {
  return {
    stockDataID: undefined,
    status: undefined,
    locatedAt: undefined,
    branch: undefined,
    stockBranch: undefined,
    carInDate: undefined,
    carInMonth: undefined,
    brand: undefined,
    model: undefined,
    specs: undefined,
    numberPlate: undefined,
    interchange: undefined,
    yearMake: undefined,
    photo: undefined,
    stockData: undefined,
    latestRetailPrice: undefined,
    inActive: false,
    publishStatus: "UNPUBLISHED",
  };
}

function capitalizeAll(data) {
  var capitalizedData = { ...data };
  const exclusion = [
    "id",
    "stockDataID",
    "userID",
    "customerID",
    "commentID",
    "activityID",
    "taskID",
    // "attachID",
    // "attachModule"
  ];
  Object.keys(data).forEach((key) => {
    if (
      !exclusion.includes(key) &&
      typeof data[key] == "string" &&
      !key.endsWith("Lower")
    ) {
      capitalizedData[key] = data[key].toUpperCase();
    }
  });
  return capitalizedData;
}

function appendNumPlate(numberPlate, wholesaleRetail) {
  let numPlate = numberPlate.split("-")[0];
  let append = numberPlate.split("-")[1];
  if (wholesaleRetail == "WHOLESALE") {
    numPlate = numPlate + "-W";
  } else {
    numPlate = numPlate + "-R";
  }
  if (append) {
    let index = append.substring(1);
    return `${numPlate}${index}`;
  } else return numPlate;
}

export {
  initializeQuery,
  extractCost,
  extractSalesPersonAmount,
  extractMytukarAmount,
  extractFloorStockAmount,
  extractPaymentStatusAmount,
  extractStatusDisplay,
  extractLatestStatusDisplay,
  extractLatestBankStatusDisplay,
  extractPhoto,
  extractStockDoc,
  extractStockDocAdditional,
  extractMetadata,
  resetStockData,
  capitalizeAll,
  arrayAmountLast,
  arrayAmountTotal,
  roundDecimal,
  appendNumPlate,
};
