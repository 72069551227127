import { combineReducers } from "redux";
import helper from "./helper";
import user from "./user"
import analytics from "./analytics"
import stock from "./stock"
import purchaser from "./purchaser"
import costStock from "./costStock"
import saleStock from "./saleStock"
import carRepair from "./carRepair"
import salesPerson from "./salesPerson"
import biddingCar from "./biddingCar"
import paymentRequest from "./paymentRequest"
import paymentStatus from "./paymentStatus"
import saleOutstanding from "./saleOutstanding"
import floorStock from "./floorStock"
import bankFloorStock from "./bankFloorStock";
import myTukarFloorStock from "./myTukarFloorStock"
import customer from "./customer"
import setting from "./setting"
import global from "./global"

export default combineReducers({
  helper,
  user,
  analytics,
  stock,
  purchaser,
  costStock,
  saleStock,
  carRepair,
  salesPerson,
  biddingCar,
  paymentRequest,
  paymentStatus,
  saleOutstanding,
  floorStock,
  bankFloorStock,
  myTukarFloorStock,
  customer,
  setting,
  global
});
