import { firebaseDB, firebase, db } from "./index";
import {
  generateRandomID,
  getFormattedResults
} from "../helper";
import {
  extractStockData,
  getStockCheckQuery,
  getDocumentMetadata,
  commitBatch,
  preprocessStockPhotoInput,
  manageImageStorage
} from "./helper";

const saleOutstanding = {
  createSaleOutstanding: async function(data) {
    var response = null
    var batch = db.batch()

    var stockDataID = generateRandomID()
    var id = generateRandomID();
    const query = getStockCheckQuery(data);
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(data, ["photo"], "create");
    const { stockData, additionalData } = extractStockData(newData, "saleOutstanding", true);
    const documentMetadata = getDocumentMetadata(1)

    await query.get()
      .then(async snapshot => {
        if (snapshot.empty) {
          batch.set(firebaseDB.stockCollection().doc(stockDataID), {
            ...stockData,
            saleOutstandingID: id,
            ...documentMetadata
          });
          batch.set(firebaseDB.saleOutstandingCollection().doc(id), {
            ...additionalData,
            stockDataID,
            ...documentMetadata
          })
          response = await commitBatch(batch, stockDataID, id)
          if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
            await manageImageStorage(createPhoto, deletePhoto);
          }
        } else {
          response = getFormattedResults("error", "Stock already exists");
        }
      })
      
    return response;
  },
  updateSaleOutstanding: async function(data) {
    var response = null;
    var batch = db.batch()

    var { stockDataID, id } = data
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(data, ["photo"], "update", "withID");
    const { stockData, additionalData } = extractStockData(newData, "saleOutstanding", true);
    const documentMetadataUpdate = getDocumentMetadata(0)
    const documentMetadataCreate = getDocumentMetadata(1)

    if (id) {
      batch.update(firebaseDB.stockCollection().doc(stockDataID), {
        ...stockData,
        saleOutstandingID: id,
        ...documentMetadataUpdate
      });
      batch.update(firebaseDB.saleOutstandingCollection().doc(id), {
        ...additionalData,
        stockDataID,
        ...documentMetadataUpdate
      })
    } else {
      var id = generateRandomID()
      batch.update(firebaseDB.stockCollection().doc(stockDataID), {
        ...stockData,
        saleOutstandingID: id,
        ...documentMetadataUpdate
      });
      batch.set(firebaseDB.saleOutstandingCollection().doc(id), {
        ...additionalData,
        stockDataID,
        ...documentMetadataCreate
      })
    }
    response = await commitBatch(batch, stockDataID, id);
    if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
      await manageImageStorage(createPhoto, deletePhoto);
    }

    return response;
  },
  deleteSaleOutstanding: async function(data) {
    var batch = db.batch()

    const { stockDataID, id } = data

    batch.update(firebaseDB.stockCollection().doc(stockDataID), {
      saleOutstandingID: firebase.firestore.FieldValue.delete()
    })
    batch.delete(firebaseDB.saleOutstandingCollection().doc(id))

    return await commitBatch(batch, stockDataID, id)
  }
}

export default saleOutstanding