import { firebaseDB, firebase, db } from "./index";
import {
  generateRandomID,
  getFormattedResults,
  initializeResponse,
  unixToTimeIfAvailable,
} from "../helper";
import {
  extractStockData,
  getStockCheckQuery,
  getDocumentMetadata,
  commitBatch,
  preprocessStockPhotoInput,
  manageImageStorage,
  removeUndefined,
  addLinkedModulesTransaction,
  rmvStatusTnsac,
  newStatusTnsac,
} from "./helper";
import { roundDecimal } from "../redux/actions/helper";

const HttpStatus = require("../helper/http-status-codes");

const counterConfig = ["latestStatus", "latestStatusSec"];

function updateCounter(t, path, previous, current, fieldName) {
  let shouldRender = false;
  if (current) {
    let incrementField = `${fieldName}.${current}`;
    if (previous == undefined) {
      t.update(path, { [incrementField]: increment(1) });
      shouldRender = true;
    } else if (current != previous) {
      let decrementField = `${fieldName}.${previous}`;
      t.update(path, {
        [incrementField]: increment(1),
        [decrementField]: increment(-1),
      });
      shouldRender = true;
    }
  } else if (previous) {
    let decrementField = `${fieldName}.${previous}`;
    t.update(path, { [decrementField]: increment(-1) });
    shouldRender = true;
  }
  return shouldRender;
}

function increment(step) {
  return firebase.firestore.FieldValue.increment(step);
}

async function updateDocument({
  data,
  paymentRequestData,
  stockDataID,
  id,
  action,
  createPaymentRequest,
}) {
  let response = initializeResponse;
  let renderCounter = false;
  let { attachID, attachModule, amount, approvalStatus } = paymentRequestData;
  attachID = attachID.toLowerCase();
  await db
    .runTransaction(async (transaction) => {
      const counterRef = firebaseDB.analyticsOverviewDoc();
      const stockDocRef = firebaseDB.stockCollection().doc(stockDataID);
      const currentDocRef = firebaseDB.paymentRequestCollection().doc(id);
      let docRef = firebaseDB.stockCollection().doc(attachID);
      let current = {};
      let prev = {};
      let prevAmount = 0;
      let prevApproval = "FALSE";
      current.carInDate = data.carInDate;
      current.branch = data.branch;
      current.status = data.status;
      current.latestStatus = data ? data.latestStatus : undefined;
      current.latestStatusSec = data ? data.latestStatusSec : undefined;
      let stockDoc = await transaction.get(stockDocRef);
      let paymentRequestDoc = await transaction.get(currentDocRef);
      prev.carInDate = stockDoc.data().carInDate;
      prev.branch = stockDoc.data().branch;
      prev.takeBy = stockDoc.data().takeBy;
      prev.status = stockDoc.data().status;
      prev.latestStatus = stockDoc.data().latestStatus;
      prev.latestStatusSec = stockDoc.data().latestStatusSec;
      if (attachModule == "SALESPERSON") {
        docRef = firebaseDB.salesPersonCollection().doc(attachID);
      }
      switch (action) {
        case "create":
          transaction = await addPaymentRequestTransaction({
            transaction,
            stockDataID,
            id,
            data,
          });
          transaction = await approveAmountTransaction({
            transaction,
            docRef,
            amount,
            prevAmount,
            approvalStatus,
            prevApproval,
          });
          transaction.update(stockDocRef, data);
          transaction.set(currentDocRef, paymentRequestData);
          break;
        case "update":
          prevAmount = paymentRequestDoc.data().amount;
          prevApproval = paymentRequestDoc.data().approvalStatus;
          transaction = await addLinkedModulesTransaction({
            transaction,
            stockDataID,
            data,
          });
          transaction = await approveAmountTransaction({
            transaction,
            docRef,
            amount,
            prevAmount,
            approvalStatus,
            prevApproval,
          });
          transaction.update(stockDocRef, data);
          if (createPaymentRequest) {
            transaction.set(currentDocRef, paymentRequestData);
          } else {
            transaction.update(currentDocRef, paymentRequestData);
          }
          break;
        case "delete":
          prevAmount = paymentRequestDoc.data().amount;
          prevApproval = paymentRequestDoc.data().approvalStatus;
          transaction = await removePaymentRequestTransaction({
            transaction,
            stockDataID,
            id,
          });
          transaction = await approveAmountTransaction({
            transaction,
            docRef,
            amount,
            prevAmount,
            approvalStatus: "FALSE",
            prevApproval,
          });
          transaction.delete(currentDocRef);
          break;
        default:
          break;
      }
      if (response.statusCode == HttpStatus.OK) {
        switch (action) {
          case "update":
            let prevStatusDateMap = {};
            let currentStatusDateMap = {};
            let prevStatusSecDateMap = {};
            let currentStatusSecDateMap = {};
            prev.status.forEach((s) => {
              prevStatusDateMap[s.status] = s.date;
              prevStatusSecDateMap[s.statusSec] = s.date;
            });
            current.status.forEach((s) => {
              currentStatusDateMap[s.status] = s.date;
              currentStatusSecDateMap[s.statusSec] = s.date;
            });
            //check for removed status
            rmvStatusTnsac(
              transaction,
              counterRef,
              prevStatusDateMap,
              currentStatusDateMap,
              "statusCounter"
            );
            rmvStatusTnsac(
              transaction,
              counterRef,
              prevStatusSecDateMap,
              currentStatusSecDateMap,
              "statusSecCounter"
            );
            // check for newly added status or status with changed date
            newStatusTnsac(
              transaction,
              counterRef,
              prevStatusDateMap,
              currentStatusDateMap,
              "statusCounter"
            );
            newStatusTnsac(
              transaction,
              counterRef,
              prevStatusSecDateMap,
              currentStatusSecDateMap,
              "statusSecCounter"
            );

            // check changes in car in date
            if (current.carInDate) {
              let carInDate = current.carInDate;
              let carInDay = unixToTimeIfAvailable(carInDate, "DD");
              let carInMonth = unixToTimeIfAvailable(carInDate, "MM");
              let carInYear = unixToTimeIfAvailable(carInDate, "YYYY");
              let carInDailyID = unixToTimeIfAvailable(carInDate, "YYYY-MM-DD");
              let carInMonthlyID = unixToTimeIfAvailable(carInDate, "YYYY-MM");

              // if changes in car in date or branch/takeBy / or previous car in date undefined
              // increment counter
              if (
                current.carInDate != prev.carInDate ||
                current.branch != prev.branch ||
                prev.carInDate == undefined
              ) {
                if (current.branch) {
                  transaction.set(
                    counterRef.collection("daily").doc(carInDailyID),
                    {
                      branchCounter: { [current.branch]: increment(1) },
                      day: carInDay,
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(carInMonthlyID),
                    {
                      branchCounter: { [current.branch]: increment(1) },
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                }
              }
              // for module that cant change takeBy value, we look at previous takeBy
              if (
                current.carInDate != prev.carInDate ||
                prev.carInDate == undefined
              ) {
                if (prev.takeBy) {
                  transaction.set(
                    counterRef.collection("daily").doc(carInDailyID),
                    {
                      takeByCounter: { [prev.takeBy]: increment(1) },
                      day: carInDay,
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(carInMonthlyID),
                    {
                      takeByCounter: { [prev.takeBy]: increment(1) },
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                }
              }
              // get previousID, same as current ID if no changes to car in date,
              let prevCarInDailyID = carInDailyID;
              let prevCarInMonthlyID = carInMonthlyID;
              // adjust previous ID if previous differ from current car in date
              if (prev.carInDate && current.carInDate != prev.carInDate) {
                let prevCarInDate = prev.carInDate;
                prevCarInDailyID = unixToTimeIfAvailable(
                  prevCarInDate,
                  "YYYY-MM-DD"
                );
                prevCarInMonthlyID = unixToTimeIfAvailable(
                  prevCarInDate,
                  "YYYY-MM"
                );
              }
              // if previous car in date exist , we have to decrease the previous counter if there is changes
              if (prev.carInDate) {
                if (
                  prev.branch &&
                  (prev.carInDate != current.carInDate ||
                    prev.branch != current.branch)
                ) {
                  // decrease prev branch based on prev car in date,
                  transaction.set(
                    counterRef.collection("daily").doc(prevCarInDailyID),
                    {
                      branchCounter: { [prev.branch]: increment(-1) },
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(prevCarInMonthlyID),
                    {
                      branchCounter: { [prev.branch]: increment(-1) },
                    },
                    { merge: true }
                  );
                }
                if (prev.carInDate != current.carInDate && prev.takeBy) {
                  transaction.set(
                    counterRef.collection("daily").doc(prevCarInDailyID),
                    {
                      takeBy: { [prev.takeBy]: increment(-1) },
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(prevCarInMonthlyID),
                    {
                      takeBy: { [prev.takeBy]: increment(-1) },
                    },
                    { merge: true }
                  );
                }
              }
            }
            break;
          default:
            break;
        }
        if (action != "delete") {
          counterConfig.forEach((field) => {
            let shouldRender = updateCounter(
              transaction,
              counterRef,
              prev[field],
              current[field],
              field
            );
            renderCounter = renderCounter | shouldRender;
          });
        }
      }
    })
    .then((results) => {
      if (response.statusCode == HttpStatus.OK) {
        response = getFormattedResults("success", "Successfully Processed");
        response.stockDataID = stockDataID;
        response.id = id;
      }
    })
    .catch((err) => {
      console.log(err);
      response = getFormattedResults("error", "Error processing");
    });
  response.renderCounter = renderCounter;
  return response;
}

const paymentRequest = {
  createPaymentRequest: async function (data) {
    var response = null;
    // var batch = db.batch();
    var stockDataID = data.stockDataID;
    var id = generateRandomID();
    const query = getStockCheckQuery(data);
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(
      data,
      ["supportingDocs"],
      "create"
    );
    var { stockData, additionalData } = extractStockData(
      newData,
      "paymentRequest",
      true
    );
    additionalData.latestStatus = stockData.latestStatus;
    additionalData.latestStatusSec = stockData.latestStatusSec;
    additionalData.latestStatusDate = stockData.latestStatusDate;
    await firebaseDB
      .stockCollection()
      .doc(stockDataID)
      .get()
      .then((doc) => {
        const {
          numberPlateNumber,
          numberPlateLower,
          numberPlateArr,
          modelLower,
          brand,
          wholesaleRetail,
          specsLower,
          branch,
          latestLocatedAt,
          carInDate,
          latestStatus,
          yearMake,
          assignedTo,
        } = doc.data();
        additionalData.numberPlateLower = numberPlateLower;
        additionalData.numberPlateNumber = numberPlateNumber;
        additionalData.numberPlateArr = numberPlateArr;
        additionalData.modelLower = modelLower;
        additionalData.specsLower = specsLower;
        additionalData.brand = brand;
        additionalData.wholesaleRetail = wholesaleRetail;
        additionalData.branch = branch;
        additionalData.latestLocatedAt = latestLocatedAt;
        additionalData.carInDate = carInDate;
        additionalData.yearMake = yearMake;
        additionalData.assignedTo = assignedTo;
        additionalData = removeUndefined(additionalData);
      });
    const documentMetadata = getDocumentMetadata(1);
    const documentMetadataUpdate = getDocumentMetadata(0);

    var setDetails = {
      status: stockData.status,
      latestStatus: stockData.latestStatus,
      latestStatusSec: stockData.latestStatusSec,
      latestStatusDate: stockData.latestStatusDate,
      ...documentMetadataUpdate,
    };
    let paymentRequestData = {
      ...additionalData,
      stockDataID,
      ...documentMetadata,
    };
    await query.get().then(async (snapshot) => {
      if (!snapshot.empty) {
        // transaction here
        response = await updateDocument({
          data: setDetails,
          paymentRequestData,
          stockDataID,
          id,
          action: "create",
        });
        if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
          await manageImageStorage(createPhoto, deletePhoto);
        }
      } else {
        response = getFormattedResults("error", "Stock does not exists");
      }
    });

    return response;
  },
  updatePaymentRequest: async function (data) {
    var response = null;
    // var batch = db.batch();

    var { stockDataID, id } = data;
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(
      data,
      ["supportingDocs"],
      "update",
      "withID"
    );
    const { stockData, additionalData } = extractStockData(
      newData,
      "paymentRequest",
      true
    );
    const documentMetadataUpdate = getDocumentMetadata(0);
    let updateDetails = {};
    let paymentRequestData = {};
    if (id) {
      updateDetails = {
        status: stockData.status,
        latestStatus: stockData.latestStatus,
        latestStatusSec: stockData.latestStatusSec,
        latestStatusDate: stockData.latestStatusDate,
        ...documentMetadataUpdate,
      };
      paymentRequestData = {
        ...additionalData,
        stockDataID,
        ...documentMetadataUpdate,
      };
      response = await updateDocument({
        data: updateDetails,
        paymentRequestData,
        stockDataID,
        id,
        action: "update",
      });
    }
    if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
      await manageImageStorage(createPhoto, deletePhoto);
    }

    return response;
  },
  updateApproval: async function (data) {
    let response = initializeResponse;
    let batch = db.batch();
    let { stockDataID, id, ...approvalData } = data;
    let { attachID, attachModule, amount, approvalStatus } = approvalData;
    const documentMetadataUpdate = getDocumentMetadata(0);
    let paymentRequestData = {
      ...approvalData,
      ...documentMetadataUpdate,
    };
    paymentRequestData = removeUndefined(paymentRequestData);
    const paymentRequestRef = firebaseDB.paymentRequestCollection().doc(id);

    if (id) {
      if (approvalStatus) {
        attachID = attachID.toLowerCase();
        await db
          .runTransaction(async (transaction) => {
            let docRef = firebaseDB.stockCollection().doc(attachID);
            if (attachModule == "SALESPERSON") {
              docRef = firebaseDB.salesPersonCollection().doc(attachID);
            }
            let doc = await transaction.get(paymentRequestRef);
            const prevAmount = doc.data().amount;
            const prevApproval = doc.data().approvalStatus;
            transaction = await approveAmountTransaction({
              transaction,
              docRef,
              amount,
              prevAmount,
              approvalStatus,
              prevApproval,
            });
            transaction.update(paymentRequestRef, paymentRequestData);
          })
          .then(() => {
            if (response.statusCode == HttpStatus.OK) {
              response = getFormattedResults(
                "success",
                "Successfully Processed"
              );
              response.stockDataID = stockDataID;
              response.id = id;
            }
          })
          .catch((err) => {
            console.log(err);
            response = getFormattedResults("error", "Error processing");
          });
      } else {
        batch.update(paymentRequestRef, paymentRequestData);
        response = await commitBatch(batch, stockDataID, id);
      }
    }
    return response;
  },
  updateActivePaymentRequest: async function (data) {
    let response = null;
    let batch = db.batch();

    let { stockDataID, id, ...prInActive } = data;
    const documentMetadataUpdate = getDocumentMetadata(0);
    if (id) {
      batch.update(firebaseDB.paymentRequestCollection().doc(id), {
        ...prInActive,
        ...documentMetadataUpdate,
      });
    }
    response = await commitBatch(batch, stockDataID, id);
    return response;
  },
  deletePaymentRequest: async function (data) {
    var batch = db.batch();

    const { stockDataID, id, ...paymentRequestData } = data;

    await firebaseDB
      .paymentRequestCollection()
      .doc(id)
      .get()
      .then(async (doc) => {
        await manageImageStorage([], doc.data().supportingDocs || []);
      });
    let response = await updateDocument({
      data,
      stockDataID,
      paymentRequestData,
      id,
      action: "delete",
    });
    return response;
  },
};

async function addPaymentRequestTransaction({
  transaction,
  stockDataID,
  id,
  data,
}) {
  const stockRef = firebaseDB.stockCollection().doc(stockDataID);
  let doc = await transaction.get(stockRef);
  var newPaymentRequestID = doc.data().paymentRequestID;
  if (newPaymentRequestID) {
    newPaymentRequestID.push(id);
  } else {
    newPaymentRequestID = [id];
  }
  transaction = await addLinkedModulesTransaction({
    transaction,
    stockDataID,
    data,
  });
  transaction.update(stockRef, {
    paymentRequestID: newPaymentRequestID,
  });
  return transaction;
}

async function approveAmountTransaction({
  transaction,
  docRef,
  amount,
  prevAmount,
  approvalStatus,
  prevApproval,
}) {
  let value = null;
  prevAmount = prevAmount ?? 0;
  if (approvalStatus == "TRUE" && prevApproval == "FALSE") {
    value = roundDecimal(parseFloat(amount));
  }
  //payment was unapproved
  else if (approvalStatus == "FALSE" && prevApproval == "TRUE") {
    value = -roundDecimal(parseFloat(prevAmount));
  }
  //payment remains approved, increment by change in amount
  else if (approvalStatus == "TRUE" && prevApproval == "TRUE") {
    value = roundDecimal(parseFloat(amount) - parseFloat(prevAmount));
  }
  if (value) {
    transaction.update(docRef, { totalApprovedPayment: increment(value) });
  }
  return transaction;
}

async function removePaymentRequestTransaction({
  transaction,
  stockDataID,
  id,
}) {
  const stockRef = firebaseDB.stockCollection().doc(stockDataID);
  let stockDoc = await transaction.get(stockRef);
  let newPaymentRequestID = stockDoc.data().paymentRequestID;
  if (newPaymentRequestID) {
    const index = newPaymentRequestID.indexOf(id);
    if (index > -1) {
      newPaymentRequestID.splice(index, 1);
      transaction.update(stockRef, {
        paymentRequestID: newPaymentRequestID,
      });
    }
  }
  return transaction;
}

// async function removePaymentRequestStock({ batch, stockDataID, id }) {
//   await firebaseDB
//     .stockCollection()
//     .doc(stockDataID)
//     .get()
//     .then((doc) => {
//       var newPaymentRequestID = doc.data().paymentRequestID;
//       if (newPaymentRequestID) {
//         const index = newPaymentRequestID.indexOf(id);
//         if (index > -1) {
//           newPaymentRequestID.splice(index, 1);
//           batch.update(firebaseDB.stockCollection().doc(stockDataID), {
//             paymentRequestID: newPaymentRequestID,
//           });
//         }
//       }
//     });
//   return batch;
// }

export default paymentRequest;
