import { firebaseDB, firebase, db } from "./index";
import {
  generateRandomID,
  getFormattedResults,
  initializeResponse,
  asyncForEach,
  unixToTimeIfAvailable,
} from "../helper";
import {
  extractStockData,
  getStockCheckQuery,
  getDocumentMetadata,
  commitBatch,
  preprocessStockPhotoInput,
  manageImageStorage,
  addSalesPersonAndPaymentBatch,
  addUserBatch,
  addUserTransaction,
  addLinkedModulesTransaction,
  getAlternateNumPlate,
  rmvStatusTnsac,
  newStatusTnsac,
} from "./helper";
const HttpStatus = require("../helper/http-status-codes");

const counterConfig = [
  "latestStatus",
  "latestStatusSec",
  "branch",
  "stockBranch",
  "wholesaleRetail",
];

function updateCounter(t, path, previous, current, fieldName) {
  let shouldRender = false;
  if (current) {
    let incrementField = `${fieldName}.${current}`;
    if (previous == undefined) {
      t.update(path, { [incrementField]: increment(1) });
      shouldRender = true;
    } else if (current != previous) {
      let decrementField = `${fieldName}.${previous}`;
      t.update(path, {
        [incrementField]: increment(1),
        [decrementField]: increment(-1),
      });
      shouldRender = true;
    }
  } else if (previous) {
    let decrementField = `${fieldName}.${previous}`;
    t.update(path, { [decrementField]: increment(-1) });
    shouldRender = true;
  }
  return shouldRender;
}

function increment(step) {
  return firebase.firestore.FieldValue.increment(step);
}

async function updateDocument({
  data,
  stockDataID,
  userID,
  action,
  stockDetailsArray,
}) {
  let response = initializeResponse;
  let renderCounter = false;
  await db
    .runTransaction(async (transaction) => {
      const counterRef = firebaseDB.analyticsOverviewDoc();
      const stockDocRef = firebaseDB.stockCollection().doc(stockDataID);
      let current = {};
      let prev = {};
      // for line chart daily/monthhly counter
      current.status = data.status;
      current.carInDate = data.carInDate;
      current.branch = data.branch;
      // pie chart counter
      counterConfig.forEach((field) => {
        current[field] = data[field];
      });

      if (action != "create") {
        let stockDoc = await transaction.get(stockDocRef);
        prev.branch = stockDoc.data().branch;
        prev.status = stockDoc.data().status;
        prev.carInDate = stockDoc.data().carInDate;
        counterConfig.forEach((field) => {
          prev[field] = stockDoc.data()[field];
        });
        prev.takeBy = stockDoc.data().takeBy;
      }
      switch (action) {
        case "create":
          if (userID) {
            data.assignedTo = firebase.firestore.FieldValue.arrayUnion(userID);
            transaction = await addUserTransaction({
              transaction,
              userID,
              stockDataID,
              numberPlate: data.numberPlate,
            });
          }
          const query = getStockCheckQuery(data);
          await query.get().then(async (snapshot) => {
            if (snapshot.empty) {
              transaction.set(stockDocRef, data);
              transaction.update(counterRef, { totalStock: increment(1) });
              renderCounter = true;
            } else {
              let altNumPlate = await getAlternateNumPlate(data);
              response = getFormattedResults("error", "Stock already exists");
              response.altNumPlate = altNumPlate;
            }
          });
          break;
        case "update":
          transaction = await addLinkedModulesTransaction({
            transaction,
            stockDataID,
            data,
          });
          transaction.update(stockDocRef, data);
          break;
        case "delete":
          transaction.update(counterRef, { totalStock: increment(-1) });
          if (prev.takeBy) {
            let decrementField = `takeBy.${prev.takeBy}`;
            transaction.update(counterRef, { [decrementField]: increment(-1) });
          }
          transaction.delete(stockDocRef);

          await firebaseDB
            .stockCollection()
            .doc(stockDataID)
            .get()
            .then(async (doc) => {
              await asyncForEach(stockDetailsArray, async (details) => {
                const detailsID = doc.data()[details.key];
                if (detailsID) {
                  if (details.key == "salesPersonID") {
                    await asyncForEach(detailsID, async (id) => {
                      transaction.delete(details.collection.doc(id));
                      await details.collection
                        .doc(id)
                        .get()
                        .then(async (doc) => {
                          await manageImageStorage(
                            [],
                            doc.data().deliveryPhoto || []
                          );
                          await manageImageStorage(
                            [],
                            doc.data().uploanPhoto || []
                          );
                          await manageImageStorage(
                            [],
                            doc.data().louPhoto || []
                          );
                        });
                    });
                  } else if (details.key == "paymentRequestID") {
                    await asyncForEach(detailsID, async (id) => {
                      transaction.delete(details.collection.doc(id));
                      await details.collection
                        .doc(id)
                        .get()
                        .then(async (doc) => {
                          await manageImageStorage(
                            [],
                            doc.data().supportingDocs || []
                          );
                        });
                    });
                  } else if (details.key == "saleStockID") {
                    transaction.delete(details.collection.doc(detailsID));
                    await details.collection
                      .doc(detailsID)
                      .get()
                      .then(async (doc) => {
                        await manageImageStorage(
                          [],
                          doc.data().displayPhoto || []
                        );
                      });
                  } else if (details.key == "assignedTo") {
                    await asyncForEach(detailsID, async (id) => {
                      await details.collection
                        .doc(id)
                        .get()
                        .then((doc) => {
                          if (!doc.exists) {
                            //ignore if the assigned stock doesnt exist
                            return;
                          }
                          var assignedStock = doc.data().assignedStock;
                          var assignedStock = assignedStock.filter((obj) => {
                            return obj.id != stockDataID;
                          });
                          transaction.update(details.collection.doc(id), {
                            assignedStock,
                          });
                        });
                    });
                  } else {
                    transaction.delete(details.collection.doc(detailsID));
                  }
                }
              });
            });
          renderCounter = true;
          break;
        default:
          break;
      }
      if (response.statusCode == HttpStatus.OK) {
        switch (action) {
          case "create":
            current.status.forEach((s) => {
              let date = s.date;
              let status = s.status;
              let statusSec = s.statusSec;
              let day = unixToTimeIfAvailable(date, "DD");
              let month = unixToTimeIfAvailable(date, "MM");
              let year = unixToTimeIfAvailable(date, "YYYY");
              let dailyID = unixToTimeIfAvailable(date, "YYYY-MM-DD");
              let monthlyID = unixToTimeIfAvailable(date, "YYYY-MM");
              transaction.set(
                counterRef.collection("daily").doc(dailyID),
                {
                  statusCounter: { [status]: increment(1) },
                  statusSecCounter: { [statusSec]: increment(1) },
                  day,
                  month,
                  year,
                },
                { merge: true }
              );
              transaction.set(
                counterRef.collection("monthly").doc(monthlyID),
                {
                  statusCounter: { [status]: increment(1) },
                  statusSecCounter: { [statusSec]: increment(1) },
                  month,
                  year,
                },
                { merge: true }
              );
            });
            if (current.carInDate) {
              let carInDate = current.carInDate;
              let carInDay = unixToTimeIfAvailable(carInDate, "DD");
              let carInMonth = unixToTimeIfAvailable(carInDate, "MM");
              let carInYear = unixToTimeIfAvailable(carInDate, "YYYY");
              let carInDailyID = unixToTimeIfAvailable(carInDate, "YYYY-MM-DD");
              let carInMonthlyID = unixToTimeIfAvailable(carInDate, "YYYY-MM");
              if (current.takeBy) {
                transaction.set(
                  counterRef.collection("daily").doc(carInDailyID),
                  {
                    takeByCounter: { [current.takeBy]: increment(1) },
                    day: carInDay,
                    month: carInMonth,
                    year: carInYear,
                  },
                  { merge: true }
                );
                transaction.set(
                  counterRef.collection("monthly").doc(carInMonthlyID),
                  {
                    takeByCounter: { [current.takeBy]: increment(1) },
                    month: carInMonth,
                    year: carInYear,
                  },
                  { merge: true }
                );
              }
              if (current.branch) {
                transaction.set(
                  counterRef.collection("daily").doc(carInDailyID),
                  {
                    branchCounter: { [current.branch]: increment(1) },
                    day: carInDay,
                    month: carInMonth,
                    year: carInYear,
                  },
                  { merge: true }
                );
                transaction.set(
                  counterRef.collection("monthly").doc(carInMonthlyID),
                  {
                    branchCounter: { [current.branch]: increment(1) },
                    month: carInMonth,
                    year: carInYear,
                  },
                  { merge: true }
                );
              }
            }
            break;
          case "update":
            let prevStatusDateMap = {};
            let currentStatusDateMap = {};
            let prevStatusSecDateMap = {};
            let currentStatusSecDateMap = {};
            prev.status.forEach((s) => {
              prevStatusDateMap[s.status] = s.date;
              prevStatusSecDateMap[s.statusSec] = s.date;
            });
            current.status.forEach((s) => {
              currentStatusDateMap[s.status] = s.date;
              currentStatusSecDateMap[s.statusSec] = s.date;
            });
            //check for removed status
            rmvStatusTnsac(
              transaction,
              counterRef,
              prevStatusDateMap,
              currentStatusDateMap,
              "statusCounter"
            );
            rmvStatusTnsac(
              transaction,
              counterRef,
              prevStatusSecDateMap,
              currentStatusSecDateMap,
              "statusSecCounter"
            );
            // check for newly added status or status with changed date
            newStatusTnsac(
              transaction,
              counterRef,
              prevStatusDateMap,
              currentStatusDateMap,
              "statusCounter"
            );
            newStatusTnsac(
              transaction,
              counterRef,
              prevStatusSecDateMap,
              currentStatusSecDateMap,
              "statusSecCounter"
            );

            // check changes in car in date
            if (current.carInDate) {
              let carInDate = current.carInDate;
              let carInDay = unixToTimeIfAvailable(carInDate, "DD");
              let carInMonth = unixToTimeIfAvailable(carInDate, "MM");
              let carInYear = unixToTimeIfAvailable(carInDate, "YYYY");
              let carInDailyID = unixToTimeIfAvailable(carInDate, "YYYY-MM-DD");
              let carInMonthlyID = unixToTimeIfAvailable(carInDate, "YYYY-MM");

              // if changes in car in date or branch/takeBy / or previous car in date undefined
              // increment counter
              if (
                current.carInDate != prev.carInDate ||
                current.branch != prev.branch ||
                prev.carInDate == undefined
              ) {
                if (current.branch) {
                  transaction.set(
                    counterRef.collection("daily").doc(carInDailyID),
                    {
                      branchCounter: { [current.branch]: increment(1) },
                      day: carInDay,
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(carInMonthlyID),
                    {
                      branchCounter: { [current.branch]: increment(1) },
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                }
              }
              // for module that cant change takeBy value, we look at previous takeBy
              if (
                current.carInDate != prev.carInDate ||
                prev.carInDate == undefined
              ) {
                if (prev.takeBy) {
                  transaction.set(
                    counterRef.collection("daily").doc(carInDailyID),
                    {
                      takeByCounter: { [prev.takeBy]: increment(1) },
                      day: carInDay,
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(carInMonthlyID),
                    {
                      takeByCounter: { [prev.takeBy]: increment(1) },
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                }
              }
              // get previousID, same as current ID if no changes to car in date,
              let prevCarInDailyID = carInDailyID;
              let prevCarInMonthlyID = carInMonthlyID;
              // adjust previous ID if previous differ from current car in date
              if (prev.carInDate && current.carInDate != prev.carInDate) {
                let prevCarInDate = prev.carInDate;
                prevCarInDailyID = unixToTimeIfAvailable(
                  prevCarInDate,
                  "YYYY-MM-DD"
                );
                prevCarInMonthlyID = unixToTimeIfAvailable(
                  prevCarInDate,
                  "YYYY-MM"
                );
              }
              // if previous car in date exist , we have to decrease the previous counter if there is changes
              if (prev.carInDate) {
                if (
                  prev.branch &&
                  (prev.carInDate != current.carInDate ||
                    prev.branch != current.branch)
                ) {
                  // decrease prev branch based on prev car in date,
                  transaction.set(
                    counterRef.collection("daily").doc(prevCarInDailyID),
                    {
                      branchCounter: { [prev.branch]: increment(-1) },
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(prevCarInMonthlyID),
                    {
                      branchCounter: { [prev.branch]: increment(-1) },
                    },
                    { merge: true }
                  );
                }
                if (prev.carInDate != current.carInDate && prev.takeBy) {
                  transaction.set(
                    counterRef.collection("daily").doc(prevCarInDailyID),
                    {
                      takeBy: { [prev.takeBy]: increment(-1) },
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(prevCarInMonthlyID),
                    {
                      takeBy: { [prev.takeBy]: increment(-1) },
                    },
                    { merge: true }
                  );
                }
              }
            }
            break;
          case "delete":
            if (prev.status) {
              prev.status.forEach((s) => {
                let date = s.date;
                let status = s.status;
                let statusSec = s.statusSec;
                let dailyID = unixToTimeIfAvailable(date, "YYYY-MM-DD");
                let monthlyID = unixToTimeIfAvailable(date, "YYYY-MM");
                transaction.set(
                  counterRef.collection("daily").doc(dailyID),
                  {
                    statusCounter: { [status]: increment(-1) },
                    statusSecCounter: { [statusSec]: increment(-1) },
                  },
                  { merge: true }
                );
                transaction.set(
                  counterRef.collection("monthly").doc(monthlyID),
                  {
                    statusCounter: { [status]: increment(-1) },
                    statusSecCounter: { [statusSec]: increment(-1) },
                  },
                  { merge: true }
                );
              });
            }
            if (prev.carInDate) {
              let prevCarInDate = prev.carInDate;
              let prevCarInDailyID = unixToTimeIfAvailable(
                prevCarInDate,
                "YYYY-MM-DD"
              );
              let prevCarInMonthlyID = unixToTimeIfAvailable(
                prevCarInDate,
                "YYYY-MM"
              );
              if (prev.takeBy) {
                transaction.set(
                  counterRef.collection("daily").doc(prevCarInDailyID),
                  {
                    takeByCounter: { [prev.takeBy]: increment(-1) },
                  },
                  { merge: true }
                );
                transaction.set(
                  counterRef.collection("monthly").doc(prevCarInMonthlyID),
                  {
                    takeByCounter: { [prev.takeBy]: increment(-1) },
                  },
                  { merge: true }
                );
              }
              if (prev.branch) {
                transaction.set(
                  counterRef.collection("daily").doc(prevCarInDailyID),
                  {
                    branchCounter: { [prev.branch]: increment(-1) },
                  },
                  { merge: true }
                );
                transaction.set(
                  counterRef.collection("monthly").doc(prevCarInMonthlyID),
                  {
                    branchCounter: { [prev.branch]: increment(-1) },
                  },
                  { merge: true }
                );
              }
            }
            break;
          default:
            break;
        }
        //update pie chart counter
        counterConfig.forEach((field) => {
          let shouldRender = updateCounter(
            transaction,
            counterRef,
            prev[field],
            current[field],
            field
          );
          renderCounter = renderCounter | shouldRender;
        });
      }
    })
    .then((results) => {
      if (response.statusCode == HttpStatus.OK) {
        response = getFormattedResults("success", "Successfully Processed");
        response.stockDataID = stockDataID;
      }
    })
    .catch((err) => {
      console.log(err);
      response = getFormattedResults("error", "Error processing");
    });
  response.renderCounter = renderCounter;
  return response;
}

const stock = {
  createStock: async function (data) {
    const userID = data.userID;
    if (data.userID) {
      delete data.userID;
    }

    var stockDataID = generateRandomID();
    const query = getStockCheckQuery(data);
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(
      data,
      ["photo"],
      "create"
    );
    const { stockData, additionalData } = extractStockData(newData, "stock");
    const documentMetadata = getDocumentMetadata(1);
    var setDetails = {
      ...stockData,
      ...additionalData,
      ...documentMetadata,
    };
    let response = await updateDocument({
      data: setDetails,
      stockDataID,
      userID,
      action: "create",
    });
    if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
      await manageImageStorage(createPhoto, deletePhoto);
    }
    response.assignedTo = userID ? [userID] : [];
    return response;
  },
  updateStock: async function (data) {
    var { stockDataID } = data;
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(
      data,
      ["photo"],
      "update"
    );
    const { stockData, additionalData } = extractStockData(newData, "stock");
    const documentMetadataUpdate = getDocumentMetadata(0);
    let updateDetails = {
      ...stockData,
      ...additionalData,
      ...documentMetadataUpdate,
    };
    let response = await updateDocument({
      data: updateDetails,
      stockDataID,
      action: "update",
    });
    if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
      await manageImageStorage(createPhoto, deletePhoto);
    }
    return response;
  },
  updateActive: async function (data) {
    let response = null;
    let batch = db.batch();

    let { stockDataID, id, ...inActive } = data;
    const documentMetadataUpdate = getDocumentMetadata(0);
    if (stockDataID) {
      batch.update(firebaseDB.stockCollection().doc(stockDataID), {
        ...inActive,
        ...documentMetadataUpdate,
      });
    }
    response = await commitBatch(batch, stockDataID, id);
    return response;
  },
  deleteStock: async function (data) {
    const { stockDataID } = data;
    const stockDetailsArray = [
      {
        key: "bankFloorStockID",
        collection: firebaseDB.bankFloorStockCollection(),
      },
      {
        key: "biddingCarID",
        collection: firebaseDB.biddingCarCollection(),
      },
      {
        key: "carRepairID",
        collection: firebaseDB.carRepairCollection(),
      },
      {
        key: "costStockID",
        collection: firebaseDB.costStockCollection(),
      },
      {
        key: "myTukarFloorStockID",
        collection: firebaseDB.myTukarFloorStockCollection(),
      },
      {
        key: "paymentStatusID",
        collection: firebaseDB.paymentStatusCollection(),
      },
      {
        key: "purchaserID",
        collection: firebaseDB.purchaserCollection(),
      },
      {
        key: "saleOutstandingID",
        collection: firebaseDB.saleOutstandingCollection(),
      },
      {
        key: "salesPersonID",
        collection: firebaseDB.salesPersonCollection(),
      },
      {
        key: "paymentRequestID",
        collection: firebaseDB.paymentRequestCollection(),
      },
      {
        key: "saleStockID",
        collection: firebaseDB.saleStockCollection(),
      },
      {
        key: "assignedTo",
        collection: firebaseDB.userCollection(),
      },
    ];

    await firebaseDB
      .stockCollection()
      .doc(stockDataID)
      .get()
      .then(async (doc) => {
        await manageImageStorage([], doc.data().photo || []);
      });
    let response = await updateDocument({
      data,
      stockDataID,
      action: "delete",
      stockDetailsArray,
    });
    return response;
  },
};

export default stock;
