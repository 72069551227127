let brandList = [
  { label: "TOYOTA", value: "TOYOTA" },
  { label: "MERCEDES-BENZ", value: "MERCEDES-BENZ" },
  { label: "HONDA", value: "HONDA" },
  { label: "PERODUA", value: "PERODUA" },
  { label: "PROTON", value: "PROTON" },
  { label: "NISSAN", value: "NISSAN" },
  { label: "BMW", value: "BMW" },
  { label: "PORSCHE", value: "PORSCHE" },
  { label: "VOLKSWAGEN", value: "VOLKSWAGEN" },
  { label: "MAZDA", value: "MAZDA" },
  { label: "ALFA ROMEO", value: "ALFA ROMEO" },
  { label: "ASTON MARTIN", value: "ASTON MARTIN" },
  { label: "AUDI", value: "AUDI" },
  { label: "AUSTIN", value: "AUSTIN" },
  { label: "BENTLEY", value: "BENTLEY" },
  { label: "BUFORI", value: "BUFORI" },
  { label: "CAM", value: "CAM" },
  { label: "CADILLAC", value: "CADILLAC" },
  { label: "CHANA", value: "CHANA" },
  { label: "CHANGAN", value: "CHANGAN" },
  { label: "CHERY", value: "CHERY" },
  { label: "CHEVROLET", value: "CHEVROLET" },
  { label: "CHRYSLER", value: "CHRYSLER" },
  { label: "CITROEN", value: "CITROEN" },
  { label: "DAIHATSU", value: "DAIHATSU" },
  { label: "DODGE", value: "DODGE" },
  { label: "FERRARI", value: "FERRARI" },
  { label: "FIAT", value: "FIAT" },
  { label: "FODAY", value: "FODAY" },
  { label: "FORD", value: "FORD" },
  { label: "FOTON", value: "FOTON" },
  { label: "GOLDEN DRAGON", value: "GOLDEN DRAGON" },
  { label: "GREAT WALL", value: "GREAT WALL" },
  { label: "HAVAL", value: "HAVAL" },
  { label: "HUMMER", value: "HUMMER" },
  { label: "HYUNDAI", value: "HYUNDAI" },
  { label: "INFINITI", value: "INFINITI" },
  { label: "INOKOM", value: "INOKOM" },
  { label: "ISUZU", value: "ISUZU" },
  { label: "JAGUAR", value: "JAGUAR" },
  { label: "JEEP", value: "JEEP" },
  { label: "JOYLONG", value: "JOYLONG" },
  { label: "KIA", value: "KIA" },
  { label: "LMG", value: "LMG" },
  { label: "LAMBORGHINI", value: "LAMBORGHINI" },
  { label: "LAND ROVER", value: "LAND ROVER" },
  { label: "LEXUS", value: "LEXUS" },
  { label: "LOTUS", value: "LOTUS" },
  { label: "MG", value: "MG" },
  { label: "MINI", value: "MINI" },
  { label: "MAHINDRA", value: "MAHINDRA" },
  { label: "MASERATI", value: "MASERATI" },
  { label: "MAXUS", value: "MAXUS" },
  { label: "MCLAREN", value: "MCLAREN" },
  { label: "MITSUBISHI", value: "MITSUBISHI" },
  { label: "MITSUOKA", value: "MITSUOKA" },
  { label: "MORRIS", value: "MORRIS" },
  { label: "NAZA", value: "NAZA" },
  { label: "PEUGEOT", value: "PEUGEOT" },
  { label: "RENAULT", value: "RENAULT" },
  { label: "ROLLS-ROYCE", value: "ROLLS-ROYCE" },
  { label: "ROVER", value: "ROVER" },
  { label: "SAAB", value: "SAAB" },
  { label: "SHENYANG BRILLIANCE", value: "SHENYANG BRILLIANCE" },
  { label: "SKODA", value: "SKODA" },
  { label: "SMART", value: "SMART" },
  { label: "SSANGYONG", value: "SSANGYONG" },
  { label: "SUBARU", value: "SUBARU" },
  { label: "SUZUKI", value: "SUZUKI" },
  { label: "TD2000", value: "TD2000" },
  { label: "TESLA", value: "TESLA" },
  { label: "VOLVO", value: "VOLVO" },
  { label: "YANGTSE", value: "YANGTSE" },
  { label: "AC", value: "AC" },
  { label: "ARIEL", value: "ARIEL" },
  { label: "ASIA MOTORS", value: "ASIA MOTORS" },
  { label: "BAW", value: "BAW" },
  { label: "BISON", value: "BISON" },
  { label: "BOON KOON", value: "BOON KOON" },
  { label: "BORGWARD", value: "BORGWARD" },
  { label: "BUGATTI", value: "BUGATTI" },
  { label: "BUICK", value: "BUICK" },
  { label: "DAEWOO", value: "DAEWOO" },
  { label: "DAIMLER", value: "DAIMLER" },
  { label: "DATSUN", value: "DATSUN" },
  { label: "DS AUTOMOBILES", value: "DS AUTOMOBILES" },
  { label: "DURANT", value: "DURANT" },
  { label: "FACTORY FIVE", value: "FACTORY FIVE" },
  { label: "GMC", value: "GMC" },
  { label: "HOLDEN", value: "HOLDEN" },
  { label: "HUANGHAI", value: "HUANGHAI" },
  { label: "KING LONG", value: "KING LONG" },
  { label: "LANCIA", value: "LANCIA" },
  { label: "MORGAN", value: "MORGAN" },
  { label: "OLDSMOBILE", value: "OLDSMOBILE" },
  { label: "OPEL", value: "OPEL" },
  { label: "OTHERS", value: "OTHERS" },
  { label: "PONTIAC", value: "PONTIAC" },
  { label: "SHELBY", value: "SHELBY" },
  { label: "SUNBEAM", value: "SUNBEAM" },
  { label: "SUTTON", value: "SUTTON" },
  { label: "TATA", value: "TATA" },
  { label: "TRIUMPH", value: "TRIUMPH" },
  { label: "TVR", value: "TVR" },
  { label: "WOLSELEY", value: "WOLSELEY" },
  { label: "ZENVO", value: "ZENVO" },
  { label: "ZNA", value: "ZNA" },
  { label: "ZXAUTO", value: "ZXAUTO" },
];
export default brandList;
