import { saleStock } from "../actionTypes";
import { resetStockData } from "../actions/helper";
import {
  extractLatestStockStatus,
  unixToTimeIfAvailable,
  isNewCarIn,
  timeToUnix,
} from "../../helper";
import { arrayAmountLast } from "../actions/helper";
import { extractStockData } from "../../firebase/helper";
const moment = require("moment");

const initialState = {
  tableItems: [],
  imperfections: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,
  sellingPriceList: undefined,

  imperfectionUpdateToggle: false,
  createSaleStockToggle: false,
  updateSaleStockToggle: false,
  deleteSaleStockToggle: false,
  detailSaleStockToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case saleStock.LIST_SALESTOCK: {
      const { tableItems, start, startSnapshot, query, searching } =
        action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case saleStock.GET_SALESTOCK: {
      const { variables } = action.payload;
      return { ...state, variables };
    }
    case saleStock.CREATE_SALESTOCK: {
      const { result } = action.payload;
      const { stockData } = extractStockData(result, "saleStock");
      result.stockData = stockData;
      result.sellingPriceList = state.sellingPriceList;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case saleStock.UPDATE_SALESTOCK: {
      const { result } = action.payload;
      result.stockData = state.stockData;
      result.biddingStatus = state.stockData.biddingStatus;
      result.floorStockStatus = state.stockData.floorStockStatus;
      result.repairStatus = state.stockData.repairStatus;
      result.estimateRepairCost = state.stockData.estimateRepairCost;
      result.assignedTo = state.stockData.assignedTo;
      result.inActive = state.stockData.inActive;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = result;
      return { ...state, tableItems: newTableItems };
    }
    case saleStock.UPDATE_RECEIVE_KEY_RECORDS:
    case saleStock.UPDATE_ORI_GERAN_RECORDS:
    case saleStock.UPDATE_FLOOR_STOCK_RECORDS:
    case saleStock.UPDATE_EAUTO_RECORDS:
    case saleStock.UPDATE_PUSPAKOM_RECORDS:
    case saleStock.UPDATE_ACTIVE_SALESTOCK: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };
      return { ...state, tableItems: newTableItems };
    }
    case saleStock.PUBLISH_SALESTOCK: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };
      return { ...state, tableItems: newTableItems };
    }
    case saleStock.UNPUBLISH_SALESTOCK: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };
      return { ...state, tableItems: newTableItems };
    }
    case saleStock.DELETE_SALESTOCK: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      const {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      } = state.tableItems[index];
      newTableItems[index] = {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      };
      return { ...state, tableItems: newTableItems };
    }
    case saleStock.TOGGLE_CREATE_SALESTOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        createSaleStockToggle: toggle ? !state.createSaleStockToggle : false,
      };
    }
    case saleStock.TOGGLE_UPDATE_SALESTOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateSaleStockToggle: toggle ? !state.updateSaleStockToggle : false,
      };
    }
    case saleStock.TOGGLE_DELETE_SALESTOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteSaleStockToggle: toggle ? !state.deleteSaleStockToggle : false,
      };
    }
    case saleStock.TOGGLE_DETAIL_SALESTOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailSaleStockToggle: toggle ? !state.detailSaleStockToggle : false,
      };
    }
    case saleStock.RESET_SALESTOCK_PARAMETERES: {
      return {
        ...state,
        ...resetStockData(),
        id: undefined,
        sellingPrice: undefined,
        sellingPriceDate: undefined,
        sellingPriceList: undefined,
        websiteUpload: undefined,
        sellingPriceApprovalStatus: undefined,
        sellingPriceApprovalDate: undefined,
        advPrice: undefined,
        dueDate: undefined,
        daysUntilDueDate: undefined,
        bookingStatus: undefined,
        deliveryPhoto: undefined,
        floorStockRecords: undefined,
        puspakomRecords: undefined,
        eAutoRecords: undefined,
        publishStatus: "UNPUBLISHED",
      };
    }
    case saleStock.UPDATE_SALESTOCK_PARAMETERS: {
      let imperfections = action.payload.imperfections
        ? JSON.parse(JSON.stringify(action.payload.imperfections))
        : action.payload.imperfections;
      action.payload.imperfections = imperfections;
      return { ...state, ...action.payload };
    }
    case saleStock.DELETE_SALESTOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case saleStock.DETAIL_SALESTOCK_PARAMETERS: {
      let imperfections = action.payload.imperfections
        ? JSON.parse(JSON.stringify(action.payload.imperfections))
        : action.payload.imperfections;
      action.payload.imperfections = imperfections;
      return { ...state, ...action.payload };
    }
    case saleStock.HANDLE_CHANGE_SALESTOCK: {
      const { catIndex, inspIndex, key, value, isInspection } = action.payload;
      if (isInspection) {
        let newImperfections = state.imperfections;
        let prevValue =
          newImperfections[inspIndex].category[catIndex].fields[key];
        if (value === "PASSED") {
          delete newImperfections[inspIndex].category[catIndex].fields[key];
        } else {
          newImperfections[inspIndex].category[catIndex].fields[key] = value;
        }
        if (
          value !== "FIXED" &&
          value !== "PASSED" &&
          (!prevValue || prevValue === "PASSED" || prevValue === "FIXED")
        ) {
          newImperfections[inspIndex].issue++;
          newImperfections[inspIndex].category[catIndex].issue++;
        } else if (
          (value === "PASSED" || value === "FIXED") &&
          prevValue &&
          prevValue !== "PASSED" &&
          prevValue !== "FIXED"
        ) {
          newImperfections[inspIndex].issue--;
          newImperfections[inspIndex].category[catIndex].issue--;
        }
        return {
          ...state,
          imperfections: newImperfections,
          imperfectionUpdateToggle: !state.imperfectionUpdateToggle,
        };
      } else {
        switch (key) {
          case "sellingPriceList":
            const sellingPrice = arrayAmountLast(
              value,
              "sellingPrice",
              "creationDate"
            );
            const sellingPriceDate = arrayAmountLast(
              value,
              "creationDate",
              "creationDate"
            );
            return {
              ...state,
              [key]: value,
              sellingPrice,
              sellingPriceDate,
              sellingPriceApprovalDate: "",
              sellingPriceApprovalStatus: "",
            };
          case "sellingPriceApprovalStatus":
            if (value === "TRUE") {
              const sellingPriceApprovalDate = timeToUnix(
                moment().startOf("day")
              );
              return { ...state, [key]: value, sellingPriceApprovalDate };
            } else {
              return { ...state, [key]: value, sellingPriceApprovalDate: "" };
            }
          case "status":
            const { latestStatus, latestStatusDate } =
              extractLatestStockStatus(value);
            let newCarIn = isNewCarIn(value);
            if (newCarIn) {
              let carInMonth = unixToTimeIfAvailable(newCarIn.date, "MM-YYYY");
              return {
                ...state,
                [key]: value,
                carInDate: newCarIn.date,
                carInMonth,
              };
            } else {
              return {
                ...state,
                [key]: value,
                carInDate: "",
                carInMonth: "",
              };
            }
          default:
            return { ...state, [key]: value };
        }
      }
    }
    case saleStock.SHOW_EMPTY_FIELD_SALESTOCK: {
      const errorList = { ...action.payload };
      return { ...state, errorList };
    }
    default:
      return state;
  }
};
