import { firebaseDB, firebase, db } from "./index";
import {
  generateRandomID,
  getFormattedResults,
  initializeResponse,
  unixToTimeIfAvailable,
} from "../helper";
import {
  extractStockData,
  getStockCheckQuery,
  getDocumentMetadata,
  commitBatch,
  preprocessStockPhotoInput,
  manageImageStorage,
  formatBookingStatus,
  removeUndefined,
  addLinkedModulesTransaction,
  rmvStatusTnsac,
  newStatusTnsac,
} from "./helper";

const HttpStatus = require("../helper/http-status-codes");

const counterConfig = ["latestStatus", "latestStatusSec"];

function updateCounter(t, path, previous, current, fieldName) {
  let shouldRender = false;
  if (current) {
    let incrementField = `${fieldName}.${current}`;
    if (previous == undefined) {
      t.update(path, { [incrementField]: increment(1) });
      shouldRender = true;
    } else if (current != previous) {
      let decrementField = `${fieldName}.${previous}`;
      t.update(path, {
        [incrementField]: increment(1),
        [decrementField]: increment(-1),
      });
      shouldRender = true;
    }
  } else if (previous) {
    let decrementField = `${fieldName}.${previous}`;
    t.update(path, { [decrementField]: increment(-1) });
    shouldRender = true;
  }
  return shouldRender;
}

function increment(step) {
  return firebase.firestore.FieldValue.increment(step);
}

async function updateDocument({
  data,
  salesPersonData,
  stockDataID,
  id,
  action,
  createSalesPerson,
}) {
  let response = initializeResponse;
  let renderCounter = false;
  await db
    .runTransaction(async (transaction) => {
      const counterRef = firebaseDB.analyticsOverviewDoc();
      const stockDocRef = firebaseDB.stockCollection().doc(stockDataID);
      const currentDocRef = firebaseDB.salesPersonCollection().doc(id);
      let current = {};
      let prev = {};
      current.carInDate = data.carInDate;
      current.branch = data.branch;
      current.status = data.status;
      current.latestStatus = data.latestStatus;
      current.latestStatusSec = data.latestStatusSec;
      let stockDoc = await transaction.get(stockDocRef);
      prev.carInDate = stockDoc.data().carInDate;
      prev.branch = stockDoc.data().branch;
      prev.takeBy = stockDoc.data().takeBy;
      prev.status = stockDoc.data().status;
      prev.latestStatus = stockDoc.data().latestStatus;
      prev.latestStatusSec = stockDoc.data().latestStatusSec;
      switch (action) {
        case "create":
          transaction = await addSalesPersonTransaction({
            transaction,
            stockDataID,
            id,
            data,
          });
          transaction.update(stockDocRef, data);
          transaction.set(currentDocRef, salesPersonData);
          break;
        case "update":
          transaction = await addLinkedModulesTransaction({
            transaction,
            stockDataID,
            data,
          });
          transaction.update(stockDocRef, data);
          if (createSalesPerson) {
            transaction.set(currentDocRef, salesPersonData);
          } else {
            transaction.update(currentDocRef, salesPersonData);
          }
          break;
        default:
          break;
      }
      if (response.statusCode == HttpStatus.OK) {
        switch (action) {
          case "update":
            let prevStatusDateMap = {};
            let currentStatusDateMap = {};
            let prevStatusSecDateMap = {};
            let currentStatusSecDateMap = {};
            prev.status.forEach((s) => {
              prevStatusDateMap[s.status] = s.date;
              prevStatusSecDateMap[s.statusSec] = s.date;
            });
            current.status.forEach((s) => {
              currentStatusDateMap[s.status] = s.date;
              currentStatusSecDateMap[s.statusSec] = s.date;
            });
            //check for removed status
            rmvStatusTnsac(
              transaction,
              counterRef,
              prevStatusDateMap,
              currentStatusDateMap,
              "statusCounter"
            );
            rmvStatusTnsac(
              transaction,
              counterRef,
              prevStatusSecDateMap,
              currentStatusSecDateMap,
              "statusSecCounter"
            );
            // check for newly added status or status with changed date
            newStatusTnsac(
              transaction,
              counterRef,
              prevStatusDateMap,
              currentStatusDateMap,
              "statusCounter"
            );
            newStatusTnsac(
              transaction,
              counterRef,
              prevStatusSecDateMap,
              currentStatusSecDateMap,
              "statusSecCounter"
            );

            // check changes in car in date
            if (current.carInDate) {
              let carInDate = current.carInDate;
              let carInDay = unixToTimeIfAvailable(carInDate, "DD");
              let carInMonth = unixToTimeIfAvailable(carInDate, "MM");
              let carInYear = unixToTimeIfAvailable(carInDate, "YYYY");
              let carInDailyID = unixToTimeIfAvailable(carInDate, "YYYY-MM-DD");
              let carInMonthlyID = unixToTimeIfAvailable(carInDate, "YYYY-MM");

              // if changes in car in date or branch/takeBy / or previous car in date undefined
              // increment counter
              if (
                current.carInDate != prev.carInDate ||
                current.branch != prev.branch ||
                prev.carInDate == undefined
              ) {
                if (current.branch) {
                  transaction.set(
                    counterRef.collection("daily").doc(carInDailyID),
                    {
                      branchCounter: { [current.branch]: increment(1) },
                      day: carInDay,
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(carInMonthlyID),
                    {
                      branchCounter: { [current.branch]: increment(1) },
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                }
              }
              // for module that cant change takeBy value, we look at previous takeBy
              if (
                current.carInDate != prev.carInDate ||
                prev.carInDate == undefined
              ) {
                if (prev.takeBy) {
                  transaction.set(
                    counterRef.collection("daily").doc(carInDailyID),
                    {
                      takeByCounter: { [prev.takeBy]: increment(1) },
                      day: carInDay,
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(carInMonthlyID),
                    {
                      takeByCounter: { [prev.takeBy]: increment(1) },
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                }
              }
              // get previousID, same as current ID if no changes to car in date,
              let prevCarInDailyID = carInDailyID;
              let prevCarInMonthlyID = carInMonthlyID;
              // adjust previous ID if previous differ from current car in date
              if (prev.carInDate && current.carInDate != prev.carInDate) {
                let prevCarInDate = prev.carInDate;
                prevCarInDailyID = unixToTimeIfAvailable(
                  prevCarInDate,
                  "YYYY-MM-DD"
                );
                prevCarInMonthlyID = unixToTimeIfAvailable(
                  prevCarInDate,
                  "YYYY-MM"
                );
              }
              // if previous car in date exist , we have to decrease the previous counter if there is changes
              if (prev.carInDate) {
                if (
                  prev.branch &&
                  (prev.carInDate != current.carInDate ||
                    prev.branch != current.branch)
                ) {
                  // decrease prev branch based on prev car in date,
                  transaction.set(
                    counterRef.collection("daily").doc(prevCarInDailyID),
                    {
                      branchCounter: { [prev.branch]: increment(-1) },
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(prevCarInMonthlyID),
                    {
                      branchCounter: { [prev.branch]: increment(-1) },
                    },
                    { merge: true }
                  );
                }
                if (prev.carInDate != current.carInDate && prev.takeBy) {
                  transaction.set(
                    counterRef.collection("daily").doc(prevCarInDailyID),
                    {
                      takeBy: { [prev.takeBy]: increment(-1) },
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(prevCarInMonthlyID),
                    {
                      takeBy: { [prev.takeBy]: increment(-1) },
                    },
                    { merge: true }
                  );
                }
              }
            }
            break;
          default:
            break;
        }
        counterConfig.forEach((field) => {
          let shouldRender = updateCounter(
            transaction,
            counterRef,
            prev[field],
            current[field],
            field
          );
          renderCounter = renderCounter | shouldRender;
        });
      }
    })
    .then((results) => {
      if (response.statusCode == HttpStatus.OK) {
        response = getFormattedResults("success", "Successfully Processed");
        response.stockDataID = stockDataID;
        response.id = id;
      }
    })
    .catch((err) => {
      console.log(err);
      response = getFormattedResults("error", "Error processing");
    });
  response.renderCounter = renderCounter;
  return response;
}

const salesPerson = {
  createSalesPerson: async function (data) {
    var response = null;
    // var batch = db.batch();
    var stockDataID = data.stockDataID;
    var id = generateRandomID();
    const query = getStockCheckQuery(data);
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(
      data,
      ["photo", "deliveryPhoto", "uploanPhoto", "louPhoto"],
      "create"
    );
    var { stockData, additionalData } = extractStockData(
      newData,
      "salesPerson",
      true
    );
    additionalData.latestStatus = stockData.latestStatus;
    additionalData.latestStatusSec = stockData.latestStatusSec;
    additionalData.latestStatusDate = stockData.latestStatusDate;
    if (stockData.bookingStatus) {
      var bookingStatus = await formatBookingStatus(
        stockDataID,
        id,
        stockData.bookingStatus,
        "create"
      );
    }
    await firebaseDB
      .stockCollection()
      .doc(stockDataID)
      .get()
      .then((doc) => {
        const {
          numberPlateNumber,
          numberPlateLower,
          numberPlateArr,
          modelLower,
          specsLower,
          brand,
          wholesaleRetail,
          branch,
          latestLocatedAt,
          carInDate,
          latestStatus,
          yearMake,
          assignedTo,
        } = doc.data();
        additionalData.numberPlateLower = numberPlateLower;
        additionalData.numberPlateNumber = numberPlateNumber;
        additionalData.numberPlateArr = numberPlateArr;
        additionalData.modelLower = modelLower;
        additionalData.specsLower = specsLower;
        additionalData.brand = brand;
        additionalData.wholesaleRetail = wholesaleRetail;
        additionalData.branch = branch;
        additionalData.latestLocatedAt = latestLocatedAt;
        additionalData.carInDate = carInDate;
        additionalData.yearMake = yearMake;
        additionalData.assignedTo = assignedTo;
        additionalData = removeUndefined(additionalData);
      });

    const documentMetadata = getDocumentMetadata(1);
    const documentMetadataUpdate = getDocumentMetadata(0);
    var setDetails = {
      status: stockData.status,
      latestStatus: stockData.latestStatus,
      latestStatusSec: stockData.latestStatusSec,
      latestStatusDate: stockData.latestStatusDate,
      ...documentMetadataUpdate,
    };
    let salesPersonData = {
      ...additionalData,
      stockDataID,
      ...documentMetadata,
    };
    await query.get().then(async (snapshot) => {
      if (!snapshot.empty) {
        // if (bookingStatus) {
        //   batch.update(firebaseDB.stockCollection().doc(stockDataID), {
        //     bookingStatus,
        //   });
        // }
        response = await updateDocument({
          data: setDetails,
          salesPersonData,
          stockDataID,
          id,
          action: "create",
        });
        if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
          await manageImageStorage(createPhoto, deletePhoto);
        }
      } else {
        response = getFormattedResults("error", "Stock does not exists");
      }
    });

    return response;
  },
  updateSalesPerson: async function (data) {
    var response = null;
    // var batch = db.batch();

    var { stockDataID, id } = data;
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(
      data,
      ["photo", "deliveryPhoto", "uploanPhoto", "louPhoto"],
      "update",
      "withID"
    );
    console.log({ newData, createPhoto, deletePhoto });
    let { stockData, additionalData } = extractStockData(
      newData,
      "salesPerson",
      true
    );
    if (stockData.bookingStatus) {
      var { bookingStatus, oldStockBooking } = await formatBookingStatus(
        stockDataID,
        id,
        stockData.bookingStatus,
        "update"
      );
    }
    additionalData.numberPlateLower = stockData.numberPlateLower;
    additionalData.numberPlateArr = stockData.numberPlateArr;
    additionalData.numberPlateNumber = stockData.numberPlateNumber;
    additionalData.modelLower = stockData.modelLower;
    additionalData.specsLower = stockData.specsLower;
    additionalData.brand = stockData.brand;
    additionalData.wholesaleRetail = stockData.wholesaleRetail;
    additionalData.carInDate = stockData.carInDate;
    additionalData.status = stockData.status;
    additionalData.latestStatus = stockData.latestStatus;
    additionalData.latestStatusSec = stockData.latestStatusSec;
    additionalData.latestStatusDate = stockData.latestStatusDate;
    additionalData.yearMake = stockData.yearMake;
    additionalData.assignedTo = stockData.assignedTo;
    additionalData = removeUndefined(additionalData);

    const documentMetadataUpdate = getDocumentMetadata(0);
    let updateDetails = {};
    let salesPersonData = {};
    if (id) {
      // if (bookingStatus) {
      //   batch.update(firebaseDB.stockCollection().doc(stockDataID), {
      //     bookingStatus,
      //     ...documentMetadataUpdate,
      //   });
      // }
      // if (oldStockBooking) {
      //   batch.update(
      //     firebaseDB.stockCollection().doc(oldStockBooking.stockDataID),
      //     {
      //       bookingStatus: oldStockBooking.bookingStatus,
      //     }
      //   );
      // }
      updateDetails = {
        status: stockData.status,
        latestStatus: stockData.latestStatus,
        latestStatusSec: stockData.latestStatus,
        latestStatusDate: stockData.latestStatusDate,
        ...documentMetadataUpdate,
      };
      salesPersonData = {
        ...additionalData,
        stockDataID,
        ...documentMetadataUpdate,
      };
      response = await updateDocument({
        data: updateDetails,
        salesPersonData,
        stockDataID,
        id,
        action: "update",
      });
    }
    // else {
    //   var id = generateRandomID()
    //   // batch.update(firebaseDB.stockCollection().doc(stockDataID), {
    //   //   ...stockData,
    //   //   ...documentMetadataUpdate
    //   // });
    //   batch.set(firebaseDB.salesPersonCollection().doc(id), {
    //     ...additionalData,
    //     stockDataID,
    //     ...documentMetadataCreate
    //   })
    // }
    // response = await commitBatch(batch, stockDataID, id);
    if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
      await manageImageStorage(createPhoto, deletePhoto);
    }

    return response;
  },
  updateActiveSalesPerson: async function (data) {
    let response = null;
    let batch = db.batch();

    let { stockDataID, id, ...inActive } = data;
    const documentMetadataUpdate = getDocumentMetadata(0);
    if (id) {
      batch.update(firebaseDB.stockCollection().doc(stockDataID), {
        ...inActive,
        ...documentMetadataUpdate,
      });
    }
    response = await commitBatch(batch, stockDataID, id);
    return response;
  },
  deleteSalesPerson: async function (data) {
    var batch = db.batch();

    const { stockDataID, id } = data;

    await firebaseDB
      .salesPersonCollection()
      .doc(id)
      .get()
      .then(async (doc) => {
        await manageImageStorage([], doc.data().deliveryPhoto || []);
        await manageImageStorage([], doc.data().uploanPhoto || []);
        await manageImageStorage([], doc.data().louPhoto || []);
      });
    if (stockDataID != undefined && stockDataID !== "undefined") {
      batch = await removeSalesPersonStock({ batch, stockDataID, id });
    }
    batch.delete(firebaseDB.salesPersonCollection().doc(id));

    return await commitBatch(batch, stockDataID, id);
  },
};

async function addSalesPersonStock({ batch, stockDataID, id }) {
  await firebaseDB
    .stockCollection()
    .doc(stockDataID)
    .get()
    .then((doc) => {
      var newSalesPersonID = doc.data().salesPersonID;
      if (newSalesPersonID) {
        newSalesPersonID.push(id);
      } else {
        newSalesPersonID = [id];
      }
      batch.update(firebaseDB.stockCollection().doc(stockDataID), {
        salesPersonID: newSalesPersonID,
      });
    });
  return batch;
}

async function addSalesPersonTransaction({
  transaction,
  stockDataID,
  id,
  data,
}) {
  const stockRef = firebaseDB.stockCollection().doc(stockDataID);
  let doc = await transaction.get(stockRef);
  var newSalesPersonID = doc.data().salesPersonID;
  if (newSalesPersonID) {
    newSalesPersonID.push(id);
  } else {
    newSalesPersonID = [id];
  }
  transaction = await addLinkedModulesTransaction({
    transaction,
    stockDataID,
    data,
  });
  transaction.update(stockRef, {
    salesPersonID: newSalesPersonID,
  });
  return transaction;
}

async function removeSalesPersonStock({ batch, stockDataID, id }) {
  await firebaseDB
    .stockCollection()
    .doc(stockDataID)
    .get()
    .then((doc) => {
      var newSalesPersonID = doc.data().salesPersonID;
      if (newSalesPersonID) {
        const index = newSalesPersonID.indexOf(id);
        if (index > -1) {
          newSalesPersonID.splice(index, 1);
          batch.update(firebaseDB.stockCollection().doc(stockDataID), {
            salesPersonID: newSalesPersonID,
          });
        }
      }
    });
  return batch;
}

export default salesPerson;
