const modules = [
  { label: "User", module: "user" },
  { label: "Analytics", module: "analytics" },
  { label: "Stock", module: "stock" },
  { label: "Cost Stock", module: "costStock" },
  { label: "Sale Stock", module: "saleStock" },
  { label: "Car Repair", module: "carRepair" },
  { label: "Stock In", module: "purchaser" },
  { label: "Sales Person", module: "salesPerson" },
  { label: "Sales Person Approver", module: "salesPersonApprover" },
  { label: "Bidding Car", module: "biddingCar" },
  { label: "Payment Request", module: "paymentRequest" },
  { label: "Payment Status", module: "paymentStatus" },
  { label: "Floor Stock", module: "floorStock" },
  { label: "Customer", module: "customer" },
  { label: "Setting", module: "setting" },
];

export default modules;
