export const LIST_MYTUKAR_FLOOR_STOCK = "LIST_MYTUKAR_FLOOR_STOCK";
export const GET_MYTUKAR_FLOOR_STOCK = "GET_MYTUKAR_FLOOR_STOCK";
export const CREATE_MYTUKAR_FLOOR_STOCK = "CREATE_MYTUKAR_FLOOR_STOCK";
export const UPDATE_MYTUKAR_FLOOR_STOCK = "UPDATE_MYTUKAR_FLOOR_STOCK";
export const DELETE_MYTUKAR_FLOOR_STOCK = "DELETE_MYTUKAR_FLOOR_STOCK";
export const UPDATE_ACTIVE_MYTUKAR_FLOOR_STOCK =
  "UPDATE_ACTIVE_MYTUKAR_FLOOR_STOCK";

export const TOGGLE_CREATE_MYTUKAR_FLOOR_STOCK =
  "TOGGLE_CREATE_MYTUKAR_FLOOR_STOCK";
export const TOGGLE_UPDATE_MYTUKAR_FLOOR_STOCK =
  "TOGGLE_UPDATE_MYTUKAR_FLOOR_STOCK";
export const TOGGLE_DELETE_MYTUKAR_FLOOR_STOCK =
  "TOGGLE_DELETE_MYTUKAR_FLOOR_STOCK";
export const TOGGLE_DETAIL_MYTUKAR_FLOOR_STOCK =
  "TOGGLE_DETAIL_MYTUKAR_FLOOR_STOCK";

export const RESET_MYTUKAR_FLOOR_STOCK_PARAMETERES =
  "RESET_MYTUKAR_FLOOR_STOCK_PARAMETERES";
export const UPDATE_MYTUKAR_FLOOR_STOCK_PARAMETERS =
  "UPDATE_MYTUKAR_FLOOR_STOCK_PARAMETERS";
export const DELETE_MYTUKAR_FLOOR_STOCK_PARAMETERS =
  "DELETE_MYTUKAR_FLOOR_STOCK_PARAMETERS";
export const DETAIL_MYTUKAR_FLOOR_STOCK_PARAMETERS =
  "DETAIL_MYTUKAR_FLOOR_STOCK_PARAMETERS";

export const HANDLE_CHANGE_MYTUKAR_FLOOR_STOCK =
  "HANDLE_CHANGE_MYTUKAR_FLOOR_STOCK";
