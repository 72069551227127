import { salesPerson } from "../actionTypes";
import { resetStockData } from "../actions/helper";
import { extractStockData } from "../../firebase/helper";
import globalVariables from "../../globalVariables.json";

const initialState = {
  tableItems: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,
  stockExistance: {},
  createSalesPersonToggle: false,
  updateSalesPersonToggle: false,
  deleteSalesPersonToggle: false,
  detailSalesPersonToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case salesPerson.LIST_SALES_PERSON: {
      const {
        tableItems,
        start,
        startSnapshot,
        query,
        searching,
        stockExistance,
      } = action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
        stockExistance,
      };
    }
    case salesPerson.CREATE_SALES_PERSON: {
      const { result, stockDocData } = action.payload;
      const { stockData } = extractStockData(result, "salesPerson");
      result.stockData = stockData;
      var newTableItems = [result, ...state.tableItems];
      let stockExistanceUpdate = state.stockExistance;
      if (!stockExistanceUpdate[result.stockDataID]) {
        stockExistanceUpdate[result.stockDataID] = stockDocData;
      }

      newTableItems.forEach((element, i) => {
        if (element.stockDataID == result.stockDataID) {
          newTableItems[i] = {
            ...newTableItems[i],
            status: result.status,
            statusDisplay: result.statusDisplay,
          };
        }
      });
      return {
        ...state,
        tableItems: newTableItems,
        stockExistance: stockExistanceUpdate,
      };
    }
    case salesPerson.UPDATE_SALES_PERSON: {
      const { result } = action.payload;
      result.stockData = state.stockData;
      result.companySellingPrice = state.stockData.companySellingPrice;
      result.sellingPrice = state.stockData.sellingPrice;
      result.sellingPriceDate = state.stockData.sellingPriceDate;
      result.disbursementDate = state.stockData.disbursementDate;
      result.totalApprovedPayment = state.totalApprovedPayment;
      result.inActive = state.stockData.inActive;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = result;
      let stockExistanceUpdate = state.stockExistance;
      let keys = globalVariables["salesPerson"].allowedStockVar;
      keys.forEach(function (key) {
        if (
          stockExistanceUpdate[newTableItems[index].stockDataID][key] !=
          result[key]
        ) {
          stockExistanceUpdate[newTableItems[index].stockDataID][key] =
            result[key];
        }
      });
      newTableItems.forEach((element, i) => {
        if (element.stockDataID == result.stockDataID) {
          newTableItems[i] = {
            ...newTableItems[i],
            status: result.status,
            statusDisplay: result.statusDisplay,
          };
        }
      });
      return {
        ...state,
        tableItems: newTableItems,
        stockExistance: stockExistanceUpdate,
      };
    }
    case salesPerson.UPDATE_ACTIVE_SALES_PERSON: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];

      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };

      return { ...state, tableItems: newTableItems };
    }
    case salesPerson.DELETE_SALES_PERSON: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems.splice(index, 1);
      return { ...state, tableItems: newTableItems };
    }
    case salesPerson.EMPTY_STOCKEXISTANCE_SALES_PERSON: {
      return {
        ...state,
        stockExistance: {},
      };
    }
    case salesPerson.TOGGLE_CREATE_SALES_PERSON: {
      const { toggle } = action.payload;
      return {
        ...state,
        createSalesPersonToggle: toggle
          ? !state.createSalesPersonToggle
          : false,
      };
    }
    case salesPerson.TOGGLE_UPDATE_SALES_PERSON: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateSalesPersonToggle: toggle
          ? !state.updateSalesPersonToggle
          : false,
      };
    }
    case salesPerson.TOGGLE_DELETE_SALES_PERSON: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteSalesPersonToggle: toggle
          ? !state.deleteSalesPersonToggle
          : false,
      };
    }
    case salesPerson.TOGGLE_DETAIL_SALES_PERSON: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailSalesPersonToggle: toggle
          ? !state.detailSalesPersonToggle
          : false,
      };
    }
    case salesPerson.RESET_SALES_PERSON_PARAMETERES: {
      let prefill = action.payload.prefill ?? {};
      return {
        ...state,
        ...resetStockData(),
        id: undefined,
        attachID: undefined,
        approveByManager: undefined,
        customer: undefined,
        customerIcAndLicense: undefined,
        customerFrom: undefined,
        salesPersonName: undefined,
        sellingPrice: undefined,
        finalSellingPrice: undefined,
        contactNumber: undefined,
        customerEmail: undefined,
        salesRemark: undefined,
        bookingStatus: undefined,
        loanStatus: undefined,
        loanStatusList: undefined,
        salesCheckList: undefined,
        agreementEHAK: undefined,
        insurance: undefined,
        roadTax: undefined,
        carBodyPrice: undefined,
        insurancePrice: undefined,
        roadTaxPrice: undefined,
        loanAmount: undefined,
        cashLoanPeriod: undefined,
        customerStatus: undefined,
        customerStatusDisplay: undefined,
        bookDownPayment: undefined,
        refundAmount: undefined,
        tradeInCar: undefined,
        bookingDate: undefined,
        bookingMonth: undefined,
        latestCustomerDate: undefined,
        latestCustomerStatus: undefined,
        latestLoanStatusList: undefined,
        latestLoanStatusListDate: undefined,
        latestLoanStatusListDisplay: undefined,
        latestBankStatusListDisplay: undefined,
        brokerFee: undefined,
        salesTotal: undefined,
        otherDeduction: undefined,
        sumInsured: undefined,
        sumInsuredBank: undefined,
        windscreenCover: undefined,
        oldCarPlate: undefined,
        nameDriver: undefined,
        uploanPhoto: undefined,
        louPhoto: undefined,
        deliveryPhoto: undefined,
        paymentType: undefined,
        paymentRequest: undefined,
        totalApprovedPayment: undefined,
        ...prefill,
      };
    }
    case salesPerson.UPDATE_SALES_PERSON_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case salesPerson.DELETE_SALES_PERSON_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case salesPerson.DETAIL_SALES_PERSON_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case salesPerson.HANDLE_CHANGE_SALES_PERSON: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
