export const LIST_BIDDING_CAR = "LIST_BIDDING_CAR";
export const GET_BIDDING_CAR = "GET_BIDDING_CAR";
export const CREATE_BIDDING_CAR = "CREATE_BIDDING_CAR";
export const UPDATE_BIDDING_CAR = "UPDATE_BIDDING_CAR";
export const UPDATE_DISBURSED = "UPDATE_DISBURSED";
export const DELETE_BIDDING_CAR = "DELETE_BIDDING_CAR";
export const UPDATE_ACTIVE_BIDDING_CAR = "UPDATE_ACTIVE_BIDDING_CAR";

export const TOGGLE_CREATE_BIDDING_CAR = "TOGGLE_CREATE_BIDDING_CAR";
export const TOGGLE_UPDATE_BIDDING_CAR = "TOGGLE_UPDATE_BIDDING_CAR";
export const TOGGLE_DELETE_BIDDING_CAR = "TOGGLE_DELETE_BIDDING_CAR";
export const TOGGLE_DETAIL_BIDDING_CAR = "TOGGLE_DETAIL_BIDDING_CAR";

export const RESET_BIDDING_CAR_PARAMETERES = "RESET_BIDDING_CAR_PARAMETERES";
export const UPDATE_BIDDING_CAR_PARAMETERS = "UPDATE_BIDDING_CAR_PARAMETERS";
export const DELETE_BIDDING_CAR_PARAMETERS = "DELETE_BIDDING_CAR_PARAMETERS";
export const DETAIL_BIDDING_CAR_PARAMETERS = "DETAIL_BIDDING_CAR_PARAMETERS";

export const SHOW_EMPTY_FIELD_BIDDING_CAR = "SHOW_EMPTY_FIELD_BIDDING_CAR";

export const HANDLE_CHANGE_BIDDING_CAR = "HANDLE_CHANGE_BIDDING_CAR";
