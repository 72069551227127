import { firebaseDB, db } from "./index";
import { getDocumentMetadata, commitBatch, removeUndefined } from "./helper";
const setting = {
  updateSetting: async function(data) {
    let batch = db.batch();

    const documentMetadataUpdate = getDocumentMetadata(0);
    let settingData = removeUndefined(data);

    batch.update(firebaseDB.globalCollection().doc('optSetting'), {
      ...settingData,
      ...documentMetadataUpdate
    });

    return await commitBatch(batch);
  },
};

export default setting;
