export const LIST_PAYMENT_STATUS = "LIST_PAYMENT_STATUS";
export const GET_PAYMENT_STATUS = "GET_PAYMENT_STATUS";
export const CREATE_PAYMENT_STATUS = "CREATE_PAYMENT_STATUS";
export const UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS";
export const DELETE_PAYMENT_STATUS = "DELETE_PAYMENT_STATUS";
export const UPDATE_ACTIVE_PAYMENT_STATUS = "UPDATE_ACTIVE_PAYMENT_STATUS";

export const TOGGLE_CREATE_PAYMENT_STATUS = "TOGGLE_CREATE_PAYMENT_STATUS";
export const TOGGLE_UPDATE_PAYMENT_STATUS = "TOGGLE_UPDATE_PAYMENT_STATUS";
export const TOGGLE_DELETE_PAYMENT_STATUS = "TOGGLE_DELETE_PAYMENT_STATUS";
export const TOGGLE_DETAIL_PAYMENT_STATUS = "TOGGLE_DETAIL_PAYMENT_STATUS";

export const RESET_PAYMENT_STATUS_PARAMETERES =
  "RESET_PAYMENT_STATUS_PARAMETERES";
export const UPDATE_PAYMENT_STATUS_PARAMETERS =
  "UPDATE_PAYMENT_STATUS_PARAMETERS";
export const DELETE_PAYMENT_STATUS_PARAMETERS =
  "DELETE_PAYMENT_STATUS_PARAMETERS";
export const DETAIL_PAYMENT_STATUS_PARAMETERS =
  "DETAIL_PAYMENT_STATUS_PARAMETERS";

export const HANDLE_CHANGE_PAYMENT_STATUS = "HANDLE_CHANGE_PAYMENT_STATUS";
