import React from "react";
import * as HttpStatus from "http-status-codes";
import cogoToast from "cogo-toast";
import CustomPhoneLib from "libphonenumber-js/custom";
import PhoneMeta from "libphonenumber-js/metadata.min.json";
import { removeUndefined } from "../firebase/helper";

const moment = require("moment");
const currencyFormatter = new Intl.NumberFormat("en-MY", {
  style: "currency",
  currency: "MYR",
});

var uniqid = require("uniqid");

const tableInitConfig = {
  loading: true,
  allowNextPage: false,
  page: 0,
  totalPages: 1,
  pageSize: 20,
  pageSizeOptions: [20, 100, 200],
  noDataText: "No Data",
  showPagination: false,
};
const selectDDConfig = {
  maxItem: 10,
};

const showAmountConfig = {
  paymentRequest: {
    amount: { title: "Approved Payment", flag: "approvalStatus" },
  },
  purchaser: {
    totalCost: { title: "Total Cost", accessPerm: [2, 6]  },
    totalApprovedPayment: { title: "Approved Payment", accessPerm: [2, 6]  },
    latestBidding: { title: "Latest Bidding", accessPerm: [2, 6] },
    pnl: { title: "PNL", accessPerm: [2, 6] },
  },
  biddingCar: {
    totalCost: { title: "Total Cost" },
    totalApprovedPayment: { title: "Approved Payment" },
    pnl: { title: "PNL" },
    latestBidding: { title: "Latest Bidding" },
  },
  saleStock: { sellingPrice: { title: "Selling Price" } },
};

const dateTimeAPFormat = "D/MM/YYYY h:mmA";
const dateAPFormat = "D/MM/YYYY";
const timeFormat = "HH:mm";
const timeFormatAWS = "HH:mmZ";
const dateFormat = "YYYY-MM-DD";
const timeDate = "1970-01-01T";
const timeDateEnd = ":00.000Z";
const timeFull = "T00:00:00.00Z";

const dateTimeFormat = (dateTimeStr, format) => {
  return moment(dateTimeStr).format(format);
};

const array2String = (array, element, key = "name") => {
  var string = "";
  array.map((e, index) => {
    if (element == null) {
      string += index == 0 ? `${e}` : `, ${e}`;
    } else {
      string += index == 0 ? `${e[element][key]}` : `, ${e[element][key]}`;
    }
  });
  return string;
};

const array2StringExt = (array, element, innerArray, innerArrayElement) => {
  var string = "";
  array.map((e, index) => {
    string += index == 0 ? `${e[element]}` : `, ${e[element]}`;
    e[innerArray].map((e2, index) => {
      string +=
        index == 0
          ? ` (${e2[innerArrayElement]}`
          : `, ${e2[innerArrayElement]}`;
      if (e[innerArray].length == index + 1) {
        string += ")";
      }
    });
  });
  return string;
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

function generatePromises(array, callback) {
  let promises = array.map((value, index) => {
    return callback(value, index, array);
  });
  return Promise.all(promises);
}

const getRoute = (routes, pageName) => {
  return routes.default.filter((item) => {
    return item.name === pageName;
  })[0];
};

const UserType = {
  POS: "POS",
  ADMIN: "ADMIN",
  SYSADMIN: "SYSADMIN",
};

const cnvModuleKeyType = {
  moduleID: "moduleID",
  moduleType: "moduleType",
  moduleYear: "moduleYear",
};

const cnvModuleKey = (moduleKey, type) => {
  let keys = moduleKey.split("_");
  let output = "";
  switch (type) {
    case cnvModuleKeyType.moduleID:
      output = keys[0];
      break;
    case cnvModuleKeyType.moduleType:
      output = keys[1];
      break;
    case cnvModuleKeyType.moduleYear:
      output = keys[2];
      break;
    default:
      output = "";
      break;
  }
  return output;
};

const loadingToastConf = {
  hideAfter: 0,
};

const toastConf = {};

const dateTimePickerConf = {
  showTimeSelect: true,
  showMonthDropdown: true,
  placeholderText: "Select Date Time",
  isClearable: true,
  timeFormat: "HH:mm",
  timeIntervals: 15,
  dateFormat: "d/MM/yyyy h:mm aa",
  timeCaption: "Time",
  todayButton: "Today",
};
const datePickerConf = {
  showMonthDropdown: true,
  placeholderText: "Select Date",
  dateFormat: "dd-MM-yyyy",
  todayButton: "Today",
};
const timePickerConf = {
  showTimeSelect: true,
  showTimeSelectOnly: true,
  placeholderText: "Select Time",
  timeIntervals: 15,
  dateFormat: "HH:mm",
  timeFormat: "HH:mm",
  timeCaption: "Time",
};
const reactTableConf = {
  className: "-striped -highlight",
  minRows: 12,
  resizable: false,
  defaultFilterMethod: (filter, row) =>
    String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase()),
};

const reactTableNumSort = (a, b) => {
  if (!isNaN(parseFloat(a)) && !isNaN(parseFloat(b))) {
    return parseFloat(a) - parseFloat(b);
  } else {
    return a > b;
  }
};

const selectFilterConf = {
  selectFilterMethod: (filter, row) => {
    if (filter.value === "all") {
      return true;
    } else {
      if (typeof row[filter.id] == "string") {
        return row[filter.id].toLowerCase() == filter.value.toLowerCase();
      } else {
        return row[filter.id] == filter.value;
      }
    }
  },

  genSelectWithOptions: (filter, onChange, options) => {
    let optionItems = options.map((item) => {
      return (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      );
    });
    return (
      <select
        onChange={(event) => onChange(event.target.value)}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">All</option>
        {optionItems}
      </select>
    );
  },
};

function calculateStdTotal(totalConducted, constant, totalVoid, totalVoidP) {
  let voidValue = totalVoid || 0;
  let voidPValue = totalVoidP || 0;
  let modConst = constant || 0;
  if (modConst < totalConducted && modConst != 0) {
    return constant;
  } else {
    return totalConducted - voidValue + voidPValue;
  }
}

const attCalculation = {
  calculateAtt: (att, totalConducted, constant, totalVoid, totalVoidP) => {
    let finalAtt = 0;
    finalAtt =
      (att * 100) /
      calculateStdTotal(totalConducted, constant, totalVoid, totalVoidP);
    return finalAtt > 100 ? 100 : isNaN(finalAtt) ? 0 : finalAtt;
  },

  calculateStdTotal: (totalConducted, constant, totalVoid, totalVoidP) => {
    return calculateStdTotal(totalConducted, constant, totalVoid, totalVoidP);
  },

  calculateModTotal: (totalConducted, constant) => {
    let modConst = constant || 0;
    return modConst < totalConducted && modConst > 0
      ? modConst
      : totalConducted;
  },

  calculateClassAtt: (studentsAttended, totalStudent) => {
    let finalAtt = 0;
    if (studentsAttended != null) {
      finalAtt = (studentsAttended * 100) / totalStudent;
    } else {
      finalAtt = 0;
    }
    return finalAtt > 100 ? 100 : isNaN(finalAtt) ? 0 : finalAtt;
  },

  calculateModAtt: (attList, classLGroup) => {
    let noLinkSum,
      avg = 0;
    let classLinkAtt = {};
    if (attList.length) {
      let nolinkClass = attList.filter(function (classes) {
        return classes.classLinkID == null;
      });
      noLinkSum = attList.reduce(function (a, b) {
        if (b.classLinkID != null) {
          if (classLinkAtt[b.classLinkID] != null) {
            if (classLinkAtt[b.classLinkID] > 100) {
              classLinkAtt[b.classLinkID] = 100;
            } else {
              classLinkAtt[b.classLinkID] += b.calAttRate;
            }
          } else {
            classLinkAtt[b.classLinkID] = b.calAttRate;
          }
          return a;
        } else {
          return a + b.calAttRate;
        }
      }, 0);
      let linkSum = Object.keys(classLinkAtt).reduce(function (a, b) {
        return a + classLinkAtt[b];
      }, 0);
      let linkClassCount = Object.keys(classLGroup).length || 0;
      avg = (noLinkSum + linkSum) / (linkClassCount + nolinkClass.length);
    }
    return avg > 100 ? 100 : isNaN(avg) ? 0 : avg;
  },
};

function modGroupToStr(moduleGroup) {
  return String.fromCharCode(moduleGroup + 65);
}

function classGroupToStr(classGroup) {
  return `Grp ${classGroup + 1}`;
}

const groupLabeling = {
  classModGroupToStr: (moduleGroup, classGroup) => {
    return `Set ${modGroupToStr(moduleGroup)} ${classGroupToStr(classGroup)}`;
  },
  modGroupToStr: modGroupToStr,
  classGroupToStr: classGroupToStr,
};

// function processResult(res, showSuccess) {
//   if (res != undefined) {
//     const resData = {
//       body: res.data.body,
//       statusCode: res.data.statusCode,
//       message: res.data.message
//     };
//     console.log(resData);
//     switch (resData.statusCode) {
//       case HttpStatus.OK:
//         if (showSuccess) {
//           cogoToast.success("Successfully processed", toastConf);
//         }
//         return true;
//       default:
//         cogoToast.error(JSON.stringify(resData.body), toastConf);
//         return false;
//     }
//   } else {
//     return false;
//   }
// }

function processResult(response, showSuccess = true) {
  if (response.statusCode == HttpStatus.OK) {
    var res = {
      body: "Success",
      statusCode: 200,
      message: HttpStatus.getStatusText(200),
    };
    var status = 1;
  } else {
    var res = {
      body: response ? response.body : "Error, please try again",
      statusCode: 420,
      message: HttpStatus.getStatusText(420),
    };
    var status = 1;
  }

  if (res != undefined && status == 1) {
    const resData = {
      body: res.body,
      statusCode: res.statusCode,
      message: res.message,
    };
    switch (resData.statusCode) {
      case HttpStatus.OK:
        if (showSuccess) {
          cogoToast.success(resData.body, toastConf);
        }
        return true;
      default:
        cogoToast.error(JSON.stringify(resData.body), toastConf);
        return false;
    }
  } else if (res != undefined && status == 0) {
    const resData = {
      body: "Unable to connect to server. It appears you are currently offline",
      statusCode: HttpStatus.METHOD_FAILURE,
      message: "Method Failure",
    };
    cogoToast.error(JSON.stringify(resData.body), toastConf);
    return false;
  } else {
    return false;
  }
}

const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

function getMinutes(startDateTime, endDateTime) {
  var dateArray = [];
  var startDateTime = moment(startDateTime).subtract(30, "minutes");
  var endDateTime = moment(endDateTime).add(30, "minutes");
  while (startDateTime <= endDateTime) {
    dateArray.push(moment(startDateTime).toISOString());
    startDateTime = moment(startDateTime).add(1, "minutes");
  }
  return dateArray;
}

function getMinutesSides(datetime) {
  Object.keys(datetime).forEach((key) => {
    var prevDatetime = moment(key).subtract(1, "minutes").toISOString();
    var nextDatetime = moment(key).add(1, "minutes").toISOString();

    if (!datetime.includes(prevDatetime)) {
      datetime[prevDatetime] = 0;
    }
    if (!datetime.includes(nextDatetime)) {
      datetime[nextDatetime] = 0;
    }
  });
  var dateSortedArray = Object.keys(datetime)
    .sort()
    .map(function (key) {
      return key;
    });
  return { datetime, dateSortedArray };
}

function removeSeconds(datetime) {
  return moment(datetime).seconds(0).milliseconds(0).toISOString();
}

function formatPhoneNumber(phoneNumber) {
  return CustomPhoneLib.format(phoneNumber, "MY", "E.164", PhoneMeta);
}
function isValidPhoneNumber(...args) {
  return CustomPhoneLib.isValidNumber(...args, PhoneMeta);
}

function formatCurrency(currency) {
  return isNaN(currency)
    ? "-"
    : currencyFormatter.format(currency).replace(/RM+\s/, "");
}

function generateRandomID() {
  return uniqid() + moment().unix();
}

function generatePublicID() {
  return uniqid();
}

function timeToUnix(time) {
  if (typeof time == "object") {
    return moment(time).unix();
  } else {
    return moment(time, "DD/MM/YYYY").unix();
  }
}

function timeToUnixIfAvailable(time) {
  return time ? timeToUnix(time) : null;
}

function unixToTime(unix, format) {
  return moment.unix(unix).format(format);
}

function unixToTimeIfAvailable(time, format) {
  return time ? unixToTime(time, format) : null;
}

function addDays(date, numberOfDays) {
  return moment.unix(date).add(numberOfDays, "d").unix();
}

function sortDateString(a, b) {
  let aDate = timeToUnix(a) || 0;
  let bDate = timeToUnix(b) || 0;
  console.log(aDate, bDate);
  return aDate - bDate;
}

function getCountdownDate(dueDate) {
  dueDate = moment.unix(dueDate);
  let today = moment(Math.round(new Date().getTime()));
  let days = dueDate.diff(today, "days");
  let hours = dueDate.diff(today, "hours");

  if (dueDate - today < 0) {
    return "Past Due Date";
  } else if (days > 0) {
    hours = Math.floor(hours % 24);
    return `${days}D ` + (hours > 0 ? `${hours}Hrs` : "");
  }
  return `${hours}Hrs `;
}

function removeSpacesAndLowerCase(str) {
  return str.replace(/\s+/g, "").toLowerCase();
}

function removeSpacesAndChar(str) {
  return str.replace(/^\D+/g, "");
}
function generateNumberPlateArr(str1, str2) {
  console.log("generateNum");
  let num = /[0-9]/g;
  let nonAlphaNum = /[^a-zA-Z\d-]/gi;
  let numPlate1 = str1 ? str1.toLowerCase().replace(nonAlphaNum, "") : null;
  let numPlate2 = str2 ? str2.toLowerCase().replace(nonAlphaNum, "") : null;
  let numPlateMatch1 = numPlate1 ? numPlate1.match(num) : null;
  let numPlateMatch2 = numPlate2 ? numPlate2.match(num) : null;
  let num1 = numPlateMatch1 ? numPlateMatch1.join("") : null;
  let num2 = numPlateMatch2 ? numPlateMatch2.join("") : null;
  let obj = {};
  if (numPlate1 != null) {
    for (let i = 0; i < numPlate1.length; ++i) {
      obj[numPlate1.substring(0, i + 1)] = true;
    }
  }
  if (numPlate2 != null) {
    for (let i = 0; i < numPlate2.length && numPlate2 != null; ++i) {
      obj[numPlate2.substring(0, i + 1)] = true;
    }
  }
  if (num1 != null) {
    for (let i = 0; i < num1.length; ++i) {
      obj[num1.substring(0, i + 1)] = true;
    }
  }
  if (num2 != null) {
    for (let i = 0; i < num2.length; ++i) {
      obj[num2.substring(0, i + 1)] = true;
    }
  }
  console.log(obj);
  return obj;
}
function extractLatestStatus(status, key = "date") {
  if (status && status.length != 0) {
    status.sort((a, b) => {
      return b[key] - a[key];
    });
    return status[0];
  } else {
    return {
      status: null,
      date: null,
      username: null,
      creationDate: null,
    };
  }
}
function extractLatestSelectedStatus(status, key = "date", selectedStatus) {
  if (status && status.length != 0) {
    status.sort((a, b) => {
      return b[key] - a[key];
    });
    return (
      status.find((e) => e.status === selectedStatus) || {
        status: null,
        date: null,
        username: null,
        creationDate: null,
      }
    ); // retrieve latest status for selected status
  } else {
    return {
      status: null,
      date: null,
      username: null,
      creationDate: null,
    };
  }
}

function extractLatestStockStatus(status) {
  const latestStatusObj = extractLatestStatus(status, "creationDate");
  const latestStatus = latestStatusObj.status;
  const latestStatusDate = latestStatusObj.date;
  const latestStatusSec = latestStatusObj.statusSec;
  const latestStatusUserName = latestStatusObj.username;
  return {
    latestStatus,
    latestStatusDate,
    latestStatusSec,
    latestStatusUserName,
  };
}
function extractLatestCustomerStatus(customerStatus) {
  const latestCustomerStatusObj = extractLatestStatus(
    customerStatus,
    "creationDate"
  );
  const latestCustomerStatus = latestCustomerStatusObj.status || "";
  const latestCustomerStatusDate = latestCustomerStatusObj.date || "";
  const latestCustomerStatusUserName = latestCustomerStatusObj.username || "";
  return {
    latestCustomerStatus,
    latestCustomerStatusDate,
    latestCustomerStatusUserName
  };
}
function extractLatestLoanStatus(loanStatusList) {
  const latestLoanStatusListObj = extractLatestStatus(
    loanStatusList,
    "loanStatusList"
  );
  const latestLoanStatusList = latestLoanStatusListObj.status || "";
  const latestLoanStatusListDate = latestLoanStatusListObj.date || "";
  return {
    latestLoanStatusList,
    latestLoanStatusListDate,
  };
}

function extractLatestCustomerStatusBooking(customerStatus) {
  const latestCustomerStatusBookingObj = extractLatestSelectedStatus(
    customerStatus,
    "creationDate",
    "BOOKING "
  );
  const latestCustomerStatusBookingDate =
    latestCustomerStatusBookingObj.date || "";
  const latestCustomerStatusBookingMonth =
    unixToTimeIfAvailable(latestCustomerStatusBookingObj.date, "MM-YYYY") || "";
  return {
    latestCustomerStatusBookingDate,
    latestCustomerStatusBookingMonth,
  };
}
function extractLatestLocation(locatedAt) {
  if (locatedAt && locatedAt.length != 0) {
    locatedAt.sort((a, b) => {
      return b.creationDate - a.creationDate;
    });
    return locatedAt[0];
  } else {
    return {
      locatedAt: null,
      date: null,
      username: null,
      remark: null,
      creationDate: null,
    };
  }
}
function extractLatestReceiveKey(receiveKey) {
  if (receiveKey && receiveKey.length != 0) {
    receiveKey.sort((a, b) => {
      return b.creationDate - a.creationDate;
    });
    return receiveKey[0];
  } else {
    return {
      date: null,
      location: null,
      username: null,
      creationDate: null,
    };
  }
}

function extractLatestOriGeran(oriGeran) {
  if (oriGeran && oriGeran.length != 0) {
    oriGeran.sort((a, b) => {
      return b.creationDate - a.creationDate;
    });
    return oriGeran[0];
  } else {
    return {
      date: null,
      geran: null,
      username: null,
      creationDate: null,
    };
  }
}

function extractLatestEAuto(eAuto) {
  if (eAuto && eAuto.length != 0) {
    eAuto.sort((a, b) => {
      return b.creationDate - a.creationDate;
    });
    return eAuto[0];
  } else {
    return {
      date: null,
      eAuto: null,
      username: null,
      creationDate: null,
    };
  }
}

function extractLatestPuspakom(eAuto) {
  if (eAuto && eAuto.length != 0) {
    eAuto.sort((a, b) => {
      return b.creationDate - a.creationDate;
    });
    return eAuto[0];
  } else {
    return {
      date: null,
      eAuto: null,
      username: null,
      creationDate: null,
    };
  }
}

function extractLatestDisbursement(eAuto) {
  if (eAuto && eAuto.length != 0) {
    eAuto.sort((a, b) => {
      return b.creationDate - a.creationDate;
    });
    return eAuto[0];
  } else {
    return {
      date: null,
      disbursement: null,
      username: null,
      creationDate: null,
    };
  }
}

const initializeResponse = {
  body: null,
  statusCode: HttpStatus.OK,
  message: null,
};

function getFormattedResults(status, message) {
  if (status == "success") {
    return {
      body: message,
      statusCode: HttpStatus.OK,
      message: HttpStatus.getStatusText(HttpStatus.OK),
    };
  } else if (status == "error") {
    return {
      body: message,
      statusCode: HttpStatus.METHOD_FAILURE,
      message: HttpStatus.getStatusText(HttpStatus.METHOD_FAILURE),
    };
  }
}

function checkForEmptyFields(requiredFields) {
  const errorList = { hasError: false };
  let errorKeys = [];
  for (let key in requiredFields) {
    let fieldValue = requiredFields[key];
    errorList[key] = false;
    if (key == "displayPhoto") {
      if (
        fieldValue.createPhoto.length == 0 &&
        fieldValue.photo.length == fieldValue.deletePhoto.length
      ) {
        errorList[key] = errorList.hasError = true;
        errorKeys.push(key);
      }
    } else if (Array.isArray(fieldValue) && fieldValue.length == 0) {
      errorList[key] = errorList.hasError = true;
      errorKeys.push(key);
    } else if (!fieldValue) {
      errorList[key] = errorList.hasError = true;
      errorKeys.push(key);
    }
  }
  if (errorList.hasError) {
    cogoToast.error(`Please fill in all the required fields: ${errorKeys}`);
  }
  return errorList;
}

function showErrorIcon(showError) {
  return showError ? (
    <i className="fa fa-exclamation-circle text-danger"></i>
  ) : null;
}

function checkAssignFilter(module, perm) {
  let key = `${module}-filterAssigned`;
  if (perm == 2 || perm == 3 || perm == 4) {
    return localStorage[key] == "true";
  } else {
    return false;
  }
}
function checkFilterDeactiveate(module) {
  let key = `${module}-filterDeactivate`;
  return localStorage[key] == "true";
}

function isNewCarIn(status) {
  let sortedStatus = status.sort((a, b) => {
    return b.date - a.date;
  });
  return sortedStatus.find(
    (obj) =>
      obj["status"].includes("WCI - BID") || obj["status"].includes("RCI")
  );
}

function generateLineChartLabel(view, time) {
  let labels = [];
  const year = moment(time).format("YYYY");
  const totalYrsInYearly = 5;
  const totalYrsInQuarterly = 2;
  switch (view) {
    case "daily":
      let totalDays = moment(
        moment(time).format("YYYY-MM"),
        "YYYY-MM"
      ).daysInMonth();
      let i = 1;
      while (i <= totalDays) {
        labels.push(i);
        i++;
      }
      break;
    case "monthly":
      labels = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      break;
    case "quarterly":
      for (let i = 0; i < totalYrsInQuarterly; i++) {
        let includedYear = parseInt(year) + i;
        for (let j = 1; j <= 4; j++) {
          let quarter = includedYear.toString() + " Q" + j;
          labels.push(quarter);
        }
      }
      break;
    case "yearly":
      for (let i = 0; i < totalYrsInYearly; i++) {
        let includedYear = parseInt(year) + i;
        labels.push(includedYear.toString());
      }
    default:
      break;
  }
  return labels;
}

function generateDataset(data, labels, view, startDate, chartContent) {
  let yearTable = {};
  let monthTable = {};
  let dataset = {};
  let counter = "statusCounter";
  const totalYrsInYearly = 5;
  const totalYrsInQuarterly = 2;
  if (chartContent == "Purchaser") {
    counter = "takeByCounter";
  } else if (chartContent == "Company") {
    counter = "branchCounter";
  }
  // initial;
  data.forEach((d, index) => {
    if (d[counter]) {
      Object.entries(d[counter]).forEach(([key, value]) => {
        let yearArray = [];
        let monthArray = [];
        let counterArray = [];
        // get previous array value if exist
        if (dataset[key]) {
          counterArray = dataset[key];
        } else {
          counterArray = new Array(labels.length).fill(0);
        }
        if (yearTable[key]) {
          yearArray = yearTable[key];
        }
        if (monthTable[key]) {
          monthArray = monthTable[key];
        }
        // populate array index with current value
        let counterIndex = index;
        if (view == "daily") {
          counterIndex = parseInt(d.day) - 1;
        } else if (view == "monthly") {
          counterIndex = parseInt(d.month) - 1;
        }
        counterArray[counterIndex] = value;
        yearArray[index] = d.year;
        monthArray[index] = d.month;
        dataset[key] = counterArray;
        yearTable[key] = yearArray;
        monthTable[key] = monthArray;
      });
    }
  });
  if (view == "quarterly") {
    const year = moment(startDate).format("YYYY");
    let quarterMap = {};
    let quarterlyDataset = {};
    for (let i = 0; i < totalYrsInQuarterly; i++) {
      let includedYear = parseInt(year) + i;
      for (let j = 1; j <= 4; j++) {
        let quarter = includedYear.toString() + " Q" + j;
        quarterMap[quarter] = i * totalYrsInQuarterly + j - 1;
      }
    }
    Object.entries(dataset).forEach(([key, countArray]) => {
      let newDataset = new Array(totalYrsInQuarterly * 4).fill(0);
      if (quarterlyDataset[key]) {
        newDataset = quarterlyDataset[key];
      } else {
        quarterlyDataset[key] = newDataset;
      }
      countArray.forEach((count, i) => {
        if (count) {
          let statusMonth = parseInt(monthTable[key][i]);
          let statusYear = yearTable[key][i];
          let quarter = "Q4";
          if (statusMonth <= 3) {
            quarter = "Q1";
          } else if (statusMonth <= 6) {
            quarter = "Q2";
          } else if (statusMonth <= 9) {
            quarter = "Q3";
          }
          let yearQuarter = statusYear + " " + quarter;
          let index = quarterMap[yearQuarter];
          newDataset[index] += count;
        }
      });
      quarterlyDataset[key] = newDataset;
    });
    dataset = quarterlyDataset;
  } else if (view == "yearly") {
    const year = moment(startDate).format("YYYY");
    let yearMap = {};
    let yearlyDataset = {};
    for (let i = 0; i < totalYrsInYearly; i++) {
      let includedYear = parseInt(year) + i;
      yearMap[includedYear.toString()] = i;
    }
    Object.entries(dataset).forEach(([key, countArray]) => {
      let newDataset = [0, 0, 0, 0, 0];
      if (yearlyDataset[key]) {
        newDataset = yearlyDataset[key];
      } else {
        yearlyDataset[key] = newDataset;
      }
      countArray.forEach((count, i) => {
        if (count) {
          let index = yearMap[yearTable[key][i]];
          newDataset[index] += count;
        }
      });
      yearlyDataset[key] = newDataset;
    });
    dataset = yearlyDataset;
  }
  return dataset;
}

export {
  getRoute,
  UserType,
  tableInitConfig,
  cnvModuleKeyType,
  cnvModuleKey,
  selectDDConfig,
  dateTimeAPFormat,
  dateAPFormat,
  timeFormat,
  timeFormatAWS,
  dateFormat,
  timeDate,
  timeDateEnd,
  dateTimeFormat,
  array2String,
  array2StringExt,
  asyncForEach,
  generatePromises,
  HttpStatus,
  toastConf,
  loadingToastConf,
  dateTimePickerConf,
  datePickerConf,
  timePickerConf,
  reactTableConf,
  reactTableNumSort,
  selectFilterConf,
  attCalculation,
  processResult,
  groupLabeling,
  datesAreOnSameDay,
  getMinutes,
  getMinutesSides,
  removeSeconds,
  formatPhoneNumber,
  isValidPhoneNumber,
  formatCurrency,
  generateRandomID,
  generatePublicID,
  timeToUnix,
  timeToUnixIfAvailable,
  sortDateString,
  unixToTime,
  unixToTimeIfAvailable,
  addDays,
  getCountdownDate,
  removeSpacesAndLowerCase,
  removeSpacesAndChar,
  extractLatestStatus,
  extractLatestStockStatus,
  extractLatestCustomerStatus,
  extractLatestCustomerStatusBooking,
  extractLatestLoanStatus,
  extractLatestLocation,
  extractLatestReceiveKey,
  extractLatestOriGeran,
  extractLatestEAuto,
  extractLatestDisbursement,
  extractLatestPuspakom,
  initializeResponse,
  getFormattedResults,
  showErrorIcon,
  checkForEmptyFields,
  checkAssignFilter,
  checkFilterDeactiveate,
  isNewCarIn,
  showAmountConfig,
  generateLineChartLabel,
  generateDataset,
  generateNumberPlateArr,
};
