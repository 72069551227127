import { firebaseDB, firebase, db } from "./index";
import {
  generateRandomID,
  getFormattedResults,
  initializeResponse,
  unixToTimeIfAvailable,
} from "../helper";
import {
  extractStockData,
  getStockCheckQuery,
  getDocumentMetadata,
  commitBatch,
  preprocessStockPhotoInput,
  manageImageStorage,
  addLinkedModulesTransaction,
  rmvStatusTnsac,
  newStatusTnsac,
} from "./helper";

const HttpStatus = require("../helper/http-status-codes");

const counterConfig = ["latestStatus", "latestStatusSec"];

function updateCounter(t, path, previous, current, fieldName) {
  let shouldRender = false;
  if (current) {
    let incrementField = `${fieldName}.${current}`;
    if (previous == undefined) {
      t.update(path, { [incrementField]: increment(1) });
      shouldRender = true;
    } else if (current != previous) {
      let decrementField = `${fieldName}.${previous}`;
      t.update(path, {
        [incrementField]: increment(1),
        [decrementField]: increment(-1),
      });
      shouldRender = true;
    }
  } else if (previous) {
    let decrementField = `${fieldName}.${previous}`;
    t.update(path, { [decrementField]: increment(-1) });
    shouldRender = true;
  }
  return shouldRender;
}

function increment(step) {
  return firebase.firestore.FieldValue.increment(step);
}

async function updateDocument({
  data,
  carRepairData,
  stockDataID,
  userID,
  id,
  action,
  createCarRepair,
}) {
  let response = initializeResponse;
  let renderCounter = false;
  await db
    .runTransaction(async (transaction) => {
      const counterRef = firebaseDB.analyticsOverviewDoc();
      const stockDocRef = firebaseDB.stockCollection().doc(stockDataID);
      const currentDocRef = firebaseDB.carRepairCollection().doc(id);
      let current = {};
      let prev = {};
      current.carInDate = data.carInDate;
      current.branch = data.branch;
      current.status = data.status;
      current.latestStatus = data.latestStatus;
      current.latestStatusSec = data.latestStatusSec;
      if (action != "create") {
        let stockDoc = await transaction.get(stockDocRef);
        prev.carInDate = stockDoc.data().carInDate;
        prev.branch = stockDoc.data().branch;
        prev.takeBy = stockDoc.data().takeBy;
        prev.status = stockDoc.data().status;
        prev.latestStatus = stockDoc.data().latestStatus;
        prev.latestStatusSec = stockDoc.data().latestStatusSec;
      }
      switch (action) {
        case "update":
          transaction = await addLinkedModulesTransaction({
            transaction,
            stockDataID,
            data,
          });
          transaction.update(stockDocRef, data);
          if (createCarRepair) {
            transaction.set(currentDocRef, carRepairData);
          } else {
            transaction.update(currentDocRef, carRepairData);
          }
          break;
        default:
          break;
      }
      if (response.statusCode == HttpStatus.OK) {
        switch (action) {
          case "update":
            let prevStatusDateMap = {};
            let currentStatusDateMap = {};
            let prevStatusSecDateMap = {};
            let currentStatusSecDateMap = {};
            prev.status.forEach((s) => {
              prevStatusDateMap[s.status] = s.date;
              prevStatusSecDateMap[s.statusSec] = s.date;
            });
            current.status.forEach((s) => {
              currentStatusDateMap[s.status] = s.date;
              currentStatusSecDateMap[s.statusSec] = s.date;
            });
            //check for removed status
            rmvStatusTnsac(
              transaction,
              counterRef,
              prevStatusDateMap,
              currentStatusDateMap,
              "statusCounter"
            );
            rmvStatusTnsac(
              transaction,
              counterRef,
              prevStatusSecDateMap,
              currentStatusSecDateMap,
              "statusSecCounter"
            );
            // check for newly added status or status with changed date
            newStatusTnsac(
              transaction,
              counterRef,
              prevStatusDateMap,
              currentStatusDateMap,
              "statusCounter"
            );
            newStatusTnsac(
              transaction,
              counterRef,
              prevStatusSecDateMap,
              currentStatusSecDateMap,
              "statusSecCounter"
            );

            // check changes in car in date
            if (current.carInDate) {
              let carInDate = current.carInDate;
              let carInDay = unixToTimeIfAvailable(carInDate, "DD");
              let carInMonth = unixToTimeIfAvailable(carInDate, "MM");
              let carInYear = unixToTimeIfAvailable(carInDate, "YYYY");
              let carInDailyID = unixToTimeIfAvailable(carInDate, "YYYY-MM-DD");
              let carInMonthlyID = unixToTimeIfAvailable(carInDate, "YYYY-MM");

              // if changes in car in date or branch/takeBy / or previous car in date undefined
              // increment counter
              if (
                current.carInDate != prev.carInDate ||
                current.branch != prev.branch ||
                prev.carInDate == undefined
              ) {
                if (current.branch) {
                  transaction.set(
                    counterRef.collection("daily").doc(carInDailyID),
                    {
                      branchCounter: { [current.branch]: increment(1) },
                      day: carInDay,
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(carInMonthlyID),
                    {
                      branchCounter: { [current.branch]: increment(1) },
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                }
              }
              // for module that cant change takeBy value, we look at previous takeBy
              if (
                current.carInDate != prev.carInDate ||
                prev.carInDate == undefined
              ) {
                if (prev.takeBy) {
                  transaction.set(
                    counterRef.collection("daily").doc(carInDailyID),
                    {
                      takeByCounter: { [prev.takeBy]: increment(1) },
                      day: carInDay,
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(carInMonthlyID),
                    {
                      takeByCounter: { [prev.takeBy]: increment(1) },
                      month: carInMonth,
                      year: carInYear,
                    },
                    { merge: true }
                  );
                }
              }
              // get previousID, same as current ID if no changes to car in date,
              let prevCarInDailyID = carInDailyID;
              let prevCarInMonthlyID = carInMonthlyID;
              // adjust previous ID if previous differ from current car in date
              if (prev.carInDate && current.carInDate != prev.carInDate) {
                let prevCarInDate = prev.carInDate;
                prevCarInDailyID = unixToTimeIfAvailable(
                  prevCarInDate,
                  "YYYY-MM-DD"
                );
                prevCarInMonthlyID = unixToTimeIfAvailable(
                  prevCarInDate,
                  "YYYY-MM"
                );
              }
              // if previous car in date exist , we have to decrease the previous counter if there is changes
              if (prev.carInDate) {
                if (
                  prev.branch &&
                  (prev.carInDate != current.carInDate ||
                    prev.branch != current.branch)
                ) {
                  // decrease prev branch based on prev car in date,
                  transaction.set(
                    counterRef.collection("daily").doc(prevCarInDailyID),
                    {
                      branchCounter: { [prev.branch]: increment(-1) },
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(prevCarInMonthlyID),
                    {
                      branchCounter: { [prev.branch]: increment(-1) },
                    },
                    { merge: true }
                  );
                }
                if (prev.carInDate != current.carInDate && prev.takeBy) {
                  transaction.set(
                    counterRef.collection("daily").doc(prevCarInDailyID),
                    {
                      takeBy: { [prev.takeBy]: increment(-1) },
                    },
                    { merge: true }
                  );
                  transaction.set(
                    counterRef.collection("monthly").doc(prevCarInMonthlyID),
                    {
                      takeBy: { [prev.takeBy]: increment(-1) },
                    },
                    { merge: true }
                  );
                }
              }
            }
            break;
          default:
            break;
        }
        counterConfig.forEach((field) => {
          let shouldRender = updateCounter(
            transaction,
            counterRef,
            prev[field],
            current[field],
            field
          );
          renderCounter = renderCounter | shouldRender;
        });
      }
    })
    .then((results) => {
      if (response.statusCode == HttpStatus.OK) {
        response = getFormattedResults("success", "Successfully Processed");
        response.stockDataID = stockDataID;
      }
    })
    .catch((err) => {
      console.log(err);
      response = getFormattedResults("error", "Error processing");
    });
  response.renderCounter = renderCounter;
  return response;
}

const carRepair = {
  createCarRepair: async function (data) {
    var response = null;
    var batch = db.batch();

    var stockDataID = generateRandomID();
    var id = generateRandomID();
    const query = getStockCheckQuery(data);
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(
      data,
      ["photo"],
      "create"
    );
    const { stockData, additionalData } = extractStockData(
      newData,
      "carRepair",
      true
    );
    const documentMetadata = getDocumentMetadata(1);

    await query.get().then(async (snapshot) => {
      if (snapshot.empty) {
        batch.set(firebaseDB.stockCollection().doc(stockDataID), {
          ...stockData,
          carRepairID: id,
          ...documentMetadata,
        });
        batch.set(firebaseDB.carRepairCollection().doc(id), {
          ...additionalData,
          stockDataID,
          ...documentMetadata,
        });
        response = await commitBatch(batch, stockDataID, id);
        if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
          await manageImageStorage(createPhoto, deletePhoto);
        }
      } else {
        response = getFormattedResults("error", "Stock already exists");
      }
    });

    return response;
  },
  updateCarRepair: async function (data) {
    var response = null;
    // var batch = db.batch();
    var { stockDataID, id } = data;
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(
      data,
      ["photo"],
      "update",
      "withID"
    );
    const { stockData, additionalData } = extractStockData(
      newData,
      "carRepair",
      true
    );
    const documentMetadataUpdate = getDocumentMetadata(0);
    const documentMetadataCreate = getDocumentMetadata(1);
    let updateDetails = {};
    let carRepairData = {};
    if (id) {
      updateDetails = {
        ...stockData,
        carRepairID: id,
        ...documentMetadataUpdate,
      };
      carRepairData = {
        ...additionalData,
        stockDataID,
        ...documentMetadataUpdate,
      };
      response = await updateDocument({
        data: updateDetails,
        carRepairData,
        stockDataID,
        id,
        action: "update",
      });
    } else {
      let id = generateRandomID();
      updateDetails = {
        ...stockData,
        carRepairID: id,
        ...documentMetadataUpdate,
      };
      carRepairData = {
        ...additionalData,
        stockDataID,
        ...documentMetadataCreate,
      };
      response = await updateDocument({
        data: updateDetails,
        carRepairData,
        stockDataID,
        id,
        action: "update",
        createCarRepair: true,
      });
    }
    if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
      await manageImageStorage(createPhoto, deletePhoto);
    }

    return response;
  },
  updateActiveCarRepair: async function (data) {
    let response = null;
    let batch = db.batch();

    let { stockDataID, id, ...inActive } = data;
    const documentMetadataUpdate = getDocumentMetadata(0);
    if (id) {
      batch.update(firebaseDB.stockCollection().doc(stockDataID), {
        ...inActive,
        ...documentMetadataUpdate,
      });
    }
    response = await commitBatch(batch, stockDataID, id);
    return response;
  },
  deleteCarRepair: async function (data) {
    var batch = db.batch();

    const { stockDataID, id } = data;

    batch.update(firebaseDB.stockCollection().doc(stockDataID), {
      carRepairID: firebase.firestore.FieldValue.delete(),
    });
    batch.delete(firebaseDB.carRepairCollection().doc(id));

    return await commitBatch(batch, stockDataID, id);
  },
};

export default carRepair;
