module.exports = {
  helper: require("./helper"),
  user: require("./user"),
  analytics: require("./analytics"),
  stock: require("./stock"),
  purchaser: require("./purchaser"),
  costStock: require("./costStock"),
  saleStock: require("./saleStock"),
  carRepair: require("./carRepair"),
  salesPerson: require("./salesPerson"),
  biddingCar: require("./biddingCar"),
  paymentRequest: require("./paymentRequest"),
  paymentStatus: require("./paymentStatus"),
  saleOutstanding: require("./saleOutstanding"),
  floorStock: require("./floorStock"),
  bankFloorStock: require("./bankFloorStock"),
  myTukarFloorStock: require("./myTukarFloorStock"),
  customer: require("./customer"),
  setting: require("./setting"),
  global: require("./global")
};
