import { purchaser, saleStock } from "../actionTypes";
import { resetStockData } from "../actions/helper";
import { extractStockData } from "../../firebase/helper";
import {
  isNewCarIn,
  timeToUnix,
  extractLatestStockStatus,
  unixToTimeIfAvailable,
} from "../../helper";
const moment = require("moment");

const initialState = {
  tableItems: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,
  pendingDoc: undefined,
  latestBidding: undefined,
  adjustMileage: undefined,
  disbursed: undefined,
  disbursedDate: undefined,
  puspakomDate: undefined,
  dateSold: undefined,

  createPurchaserToggle: false,
  updatePurchaserToggle: false,
  deletePurchaserToggle: false,
  detailPurchaserToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case purchaser.LIST_PURCHASER: {
      const { tableItems, start, startSnapshot, query, searching } =
        action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case purchaser.GET_PURCHASER: {
      const { variables } = action.payload;
      return { ...state, variables };
    }
    case purchaser.CREATE_PURCHASER: {
      const { result } = action.payload;
      const { stockData } = extractStockData(result, "purchaser");
      result.stockData = stockData;
      result.dateSold = state.dateSold;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case purchaser.UPDATE_SQL_CHECK:
    case purchaser.UPDATE_SALES_INVOICE_CHECK:
    case purchaser.UPDATE_RECEIVE_KEY_CHECK:
    case purchaser.UPDATE_ADMIN_CHECK:
    case purchaser.UPDATE_RECEIVE_KEY_RECORDS:
    case purchaser.UPDATE_ORI_GERAN_RECORDS:
    case purchaser.UPDATE_DISBURSEMENT_RECORDS:
    case purchaser.UPDATE_EAUTO_RECORDS: 
    case purchaser.UPDATE_FLOOR_STOCK_RECORDS: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };
      return { ...state, tableItems: newTableItems };
    }
    case purchaser.UPDATE_PURCHASER: {
      const { result } = action.payload;
      result.stockData = state.stockData;
      result.assignedTo = state.stockData.assignedTo;
      result.latestBidding = state.stockData.latestBidding;
      result.totalApprovedPayment = state.stockData.totalApprovedPayment;
      result.dateSold = state.stockData.dateSold;
      result.pnl =
        result.latestBidding &&
        result.latestBidding > 0 &&
        result.status.some((obj) => obj["status"].includes("SOLD TO"))
          ? parseInt(result.latestBidding) - result.totalCost
          : null;
      result.inActive = state.stockData.inActive;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = result;
      return { ...state, tableItems: newTableItems };
    }
    case purchaser.DELETE_PURCHASER: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      const {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      } = state.tableItems[index];
      newTableItems[index] = {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      };
      return { ...state, tableItems: newTableItems };
    }
    case purchaser.TOGGLE_CREATE_PURCHASER: {
      const { toggle } = action.payload;
      return {
        ...state,
        createPurchaserToggle: toggle ? !state.createPurchaserToggle : false,
      };
    }
    case purchaser.TOGGLE_UPDATE_PURCHASER: {
      const { toggle } = action.payload;
      return {
        ...state,
        updatePurchaserToggle: toggle ? !state.updatePurchaserToggle : false,
      };
    }
    case purchaser.TOGGLE_DELETE_PURCHASER: {
      const { toggle } = action.payload;
      return {
        ...state,
        deletePurchaserToggle: toggle ? !state.deletePurchaserToggle : false,
      };
    }
    case purchaser.TOGGLE_DETAIL_PURCHASER: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailPurchaserToggle: toggle ? !state.detailPurchaserToggle : false,
      };
    }
    case purchaser.RESET_PURCHASER_PARAMETERES: {
      const prefill = action.payload.prefill ?? {};
      return {
        ...state,
        ...resetStockData(),
        inspectionDate: undefined,
        brokerBranch: undefined,
        brokerPhone: undefined,
        sellerEmail: undefined,
        sellerContact: undefined,
        carBodyColor: undefined,
        publishStatus: "UNPUBLISHED",
        purchaserCheckStatus: undefined,
        purchaserCheckDate: undefined,
        purSQLCheckStatus: undefined,
        purSQLCheckDate: undefined,
        salesInvoiceStatus: undefined,
        salesInvoiceCheckDate: undefined,
        adminUpdater: undefined,
        sqlUpdater: undefined,
        salesInvoiceUpdater: undefined,
        receiveKeyStatus: undefined,
        receiveKeyCheckDate: undefined,
        receiveKeyUpdater: undefined,
        receiveKeyRecords: undefined,
        floorStockRecords:undefined,
        puspakomRecords:undefined,
        latestFloorStock:undefined,
        latestPuspakom:undefined,
        oriGeranRecords: undefined,
        eAutoRecords: undefined,
        disbursementRecords: undefined,
        viewCar: undefined,
        id: undefined,
        owner: undefined,
        broker: undefined,
        otherCost: undefined,
        managerCom: undefined,
        takeBy: undefined,
        fromSalesPerson: undefined,
        authoriseLetter: undefined,
        remark: undefined,
        qcNote: undefined,
        conditionReport: undefined,
        originalCarCard: undefined,
        carGrade: undefined,
        spareKey: undefined,
        ownerDoc: undefined,
        eAuto: undefined,
        settlementDetails: undefined,
        paint: undefined,
        accident: undefined,
        mileages: undefined,
        adjustMileage: undefined,
        disbursed: undefined,
        disbursedDate: undefined,
        puspakomDate: undefined,
        engine: undefined,
        gearbox: undefined,
        fullService: undefined,
        warranty: undefined,
        windscreen: undefined,
        tyretool: undefined,
        needRepair: undefined,
        errorList: {},
        pendingDoc: undefined,
        latestBidding: undefined,
        dateSold: undefined,
        pnl: undefined,
        totalApprovedPayment: undefined,
        wholesaleRetail: undefined,
        carRegistrationCardPhoto: undefined,
        carQCPhotoPhoto: undefined,
        carQCDamagePhoto: undefined,
        ...prefill,
      };
    }
    case purchaser.UPDATE_PURCHASER_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case purchaser.UPDATE_ACTIVE_PURCHASER: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];

      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };

      return { ...state, tableItems: newTableItems };
    }
    case purchaser.UPDATE_DISBURSED: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };
      return { ...state, tableItems: newTableItems };
    }
    case purchaser.DELETE_PURCHASER_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case purchaser.DETAIL_PURCHASER_PARAMETERS: {
      const a = action.payload;
      return { ...state, ...action.payload };
    }
    case purchaser.HANDLE_CHANGE_PURCHASER: {
      const { key, value } = action.payload;
      switch (key) {
        case "purchaserCheckStatus":
          if (value === "TRUE") {
            const purchaserCheckDate = timeToUnix(moment().startOf("day"));
            return { ...state, [key]: value, purchaserCheckDate };
          } else {
            return { ...state, [key]: value, purchaserCheckDate: "" };
          }
        case "purSQLCheckStatus":
          if (value === "TRUE") {
            const purSQLCheckDate = timeToUnix(moment().startOf("day"));
            return { ...state, [key]: value, purSQLCheckDate };
          } else {
            return { ...state, [key]: value, purSQLCheckDate: "" };
          }
        case "salesInvoiceStatus":
          if (value === "TRUE") {
            const salesInvoiceCheckDate = timeToUnix(moment().startOf("day"));
            return { ...state, [key]: value, salesInvoiceCheckDate };
          } else {
            return { ...state, [key]: value, salesInvoiceCheckDate: "" };
          }
        case "receiveKeyStatus":
          if (value === "TRUE") {
            const receiveKeyCheckDate = timeToUnix(moment().startOf("day"));
            return { ...state, [key]: value, receiveKeyCheckDate };
          } else {
            return { ...state, [key]: value, receiveKeyCheckDate: "" };
          }

        case "status":
          const { latestStatus, latestStatusDate } =
            extractLatestStockStatus(value);

          if (latestStatus == "INSPECTED") {
            return { ...state, [key]: value, inspectionDate: latestStatusDate };
          } else if (latestStatus == "SOLD TO") {
            return { ...state, [key]: value, dateSold: latestStatusDate };
          }

          let newCarIn = isNewCarIn(value);
          if (newCarIn) {
            let carInMonth = unixToTimeIfAvailable(newCarIn.date, "MM-YYYY");
            return {
              ...state,
              [key]: value,
              carInDate: newCarIn.date,
              carInMonth,
            };
          } else {
            return {
              ...state,
              [key]: value,
              carInDate: "",
              carInMonth: "",
            };
          }
        default:
          return { ...state, [key]: value };
      }
    }
    case purchaser.SHOW_EMPTY_FIELD_PURCHASER: {
      const errorList = { ...action.payload };
      return { ...state, errorList };
    }
    default:
      return state;
  }
};
