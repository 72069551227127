export const LIST_PAYMENT_REQUEST = "LIST_PAYMENT_REQUEST";
export const CREATE_PAYMENT_REQUEST = "CREATE_PAYMENT_REQUEST";
export const UPDATE_PAYMENT_REQUEST = "UPDATE_PAYMENT_REQUEST";
export const DELETE_PAYMENT_REQUEST = "DELETE_PAYMENT_REQUEST";

export const UPDATE_APPROVAL = "UPDATE_APPROVAL";
export const UPDATE_CHECK = "UPDATE_CHECK";
export const UPDATE_ACTIVE_PAYMENT_REQUEST = "UPDATE_ACTIVE_PAYMENT_REQUEST";

export const EMPTY_STOCKEXISTANCE_PAYMENT_REQUEST =
  "EMPTY_STOCKEXISTANCE_PAYMENT_REQUEST";

export const TOGGLE_CREATE_PAYMENT_REQUEST = "TOGGLE_CREATE_PAYMENT_REQUEST";
export const TOGGLE_UPDATE_PAYMENT_REQUEST = "TOGGLE_UPDATE_PAYMENT_REQUEST";
export const TOGGLE_DELETE_PAYMENT_REQUEST = "TOGGLE_DELETE_PAYMENT_REQUEST";
export const TOGGLE_DETAIL_PAYMENT_REQUEST = "TOGGLE_DETAIL_PAYMENT_REQUEST";

export const RESET_PAYMENT_REQUEST_PARAMETERES =
  "RESET_PAYMENT_REQUEST_PARAMETERES";
export const UPDATE_PAYMENT_REQUEST_PARAMETERS =
  "UPDATE_PAYMENT_REQUEST_PARAMETERS";
export const DELETE_PAYMENT_REQUEST_PARAMETERS =
  "DELETE_PAYMENT_REQUEST_PARAMETERS";
export const DETAIL_PAYMENT_REQUEST_PARAMETERS =
  "DETAIL_PAYMENT_REQUEST_PARAMETERS";

export const HANDLE_CHANGE_PAYMENT_REQUEST = "HANDLE_CHANGE_PAYMENT_REQUEST";
export const SHOW_EMPTY_PAYMENT_REQUEST = "SHOW_EMPTY_PAYMENT_REQUEST";
