import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/database";

import user from "./user";
import stock from "./stock";
import purchaser from "./purchaser";
import costStock from "./costStock";
import saleStock from "./saleStock";
import carRepair from "./carRepair";
import salesPerson from "./salesPerson";
import biddingCar from "./biddingCar";
import paymentRequest from "./paymentRequest";
import paymentStatus from "./paymentStatus";
import saleOutstanding from "./saleOutstanding";
import bankFloorStock from "./bankFloorStock";
import myTukarFloorStock from "./myTukarFloorStock";
import customer from "./customer";
import setting from "./setting";

const firebaseConfig = {
  apiKey: "AIzaSyB3J6W3IUVXR0Y_1GhbVHIpkpG9a-vxqdk",
  authDomain: "carwife-dms-27687.firebaseapp.com",
  projectId: "carwife-dms-27687",
  storageBucket: "carwife-dms-27687.appspot.com",
  messagingSenderId: "794684444007",
  appId: "1:794684444007:web:4bd6adfa1e5f62a983b3c2",
};

firebase.initializeApp(firebaseConfig);

//auth setup
const auth = firebase.auth();
const database = firebase.database();
const db = firebase.firestore();
db.settings({
  timestampsInSnapshots: true,
});

const firebaseString = {
  ANALYTICS_COLLECTION: "analytics",
  USER_COLLECTION: "users",
  STOCK_COLLECTION: "stock",
  BIDDING_DATA: "biddingData",
  PURCHASER_COLLECTION: "purchaser",
  COST_STOCK_COLLECTION: "costStock",
  SALE_STOCK_COLLECTION: "saleStock",
  CAR_REPAIR_COLLECTION: "carRepair",
  SALES_PERSON_COLLECTION: "salesPerson",
  BIDDING_CAR_COLLECTION: "biddingCar",
  PAYMENT_REQUEST_COLLECTION: "paymentRequest",
  PAYMENT_STATUS_COLLECTION: "paymentStatus",
  SALE_OUTSTANDING_COLLECTION: "saleOutstanding",
  GLOBAL_COLLECTION: "global",
  BANK_FLOOR_STOCK: "bankFloorStock",
  MYTUKAR_FLOOR_STOCK: "myTukarFloorStock",
  CUSTOMER_COLLECTION: "customer",
  GLOBAL: "global",
};

const firebaseDB = {
  analyticsCollection: () => {
    return db.collection(firebaseString.ANALYTICS_COLLECTION);
  },
  analyticsOverviewDoc: () => {
    return db
      .collection(firebaseString.ANALYTICS_COLLECTION)
      .doc("stockOverview");
  },
  analyticsOvrDailyDoc: () => {
    return db
      .collection(firebaseString.ANALYTICS_COLLECTION)
      .doc("stockOverview")
      .collection("daily");
  },
  analyticsOvrMonthlyDoc: () => {
    return db
      .collection(firebaseString.ANALYTICS_COLLECTION)
      .doc("stockOverview")
      .collection("monthly");
  },
  userCollection: () => {
    return db.collection(firebaseString.USER_COLLECTION);
  },
  stockCollection: () => {
    return db.collection(firebaseString.STOCK_COLLECTION);
  },
  biddingDataCollection: () => {
    return db.collection(firebaseString.BIDDING_DATA);
  },
  purchaserCollection: () => {
    return db.collection(firebaseString.PURCHASER_COLLECTION);
  },
  costStockCollection: () => {
    return db.collection(firebaseString.COST_STOCK_COLLECTION);
  },
  saleStockCollection: () => {
    return db.collection(firebaseString.SALE_STOCK_COLLECTION);
  },
  carRepairCollection: () => {
    return db.collection(firebaseString.CAR_REPAIR_COLLECTION);
  },
  salesPersonCollection: () => {
    return db.collection(firebaseString.SALES_PERSON_COLLECTION);
  },
  biddingCarCollection: () => {
    return db.collection(firebaseString.BIDDING_CAR_COLLECTION);
  },
  paymentRequestCollection: () => {
    return db.collection(firebaseString.PAYMENT_REQUEST_COLLECTION);
  },
  paymentStatusCollection: () => {
    return db.collection(firebaseString.PAYMENT_STATUS_COLLECTION);
  },
  saleOutstandingCollection: () => {
    return db.collection(firebaseString.SALE_OUTSTANDING_COLLECTION);
  },
  bankFloorStockCollection: () => {
    return db.collection(firebaseString.BANK_FLOOR_STOCK);
  },
  myTukarFloorStockCollection: () => {
    return db.collection(firebaseString.MYTUKAR_FLOOR_STOCK);
  },
  customerCollection: () => {
    return db.collection(firebaseString.CUSTOMER_COLLECTION);
  },
  globalCollection: () => {
    return db.collection(firebaseString.GLOBAL_COLLECTION);
  },
};

const firebasePath = {
  stock: firebaseDB.stockCollection(),
  biddingData: firebaseDB.biddingDataCollection(),
  purchaser: firebaseDB.purchaserCollection(),
  costStock: firebaseDB.costStockCollection(),
  saleStock: firebaseDB.saleStockCollection(),
  carRepair: firebaseDB.carRepairCollection(),
  salesPerson: firebaseDB.salesPersonCollection(),
  biddingCar: firebaseDB.biddingCarCollection(),
  paymentRequest: firebaseDB.paymentRequestCollection(),
  paymentStatus: firebaseDB.paymentStatusCollection(),
  saleOutstanding: firebaseDB.saleOutstandingCollection(),
  bankFloorStock: firebaseDB.bankFloorStockCollection(),
  myTukarFloorStock: firebaseDB.myTukarFloorStockCollection(),
  customer: firebaseDB.customerCollection(),
};

const functions = firebase.functions();
const callableFunctions = {
  getUser: functions.httpsCallable("getUser"),
  manageUser: functions.httpsCallable("manageUser"),
  createUser: functions.httpsCallable("createUser"),
  chat: functions.httpsCallable("chat"),
};

const localFunctions = {
  createUser: user.createUser,
  updateUser: user.updateUser,
  deleteUser: user.deleteUser,
  createStock: stock.createStock,
  updateStock: stock.updateStock,
  deleteStock: stock.deleteStock,
  updateActive: stock.updateActive,
  createPurchaser: purchaser.createPurchaser,
  updatePurchaser: purchaser.updatePurchaser,
  updateAdminCheck: purchaser.updateAdminCheck,
  updateReceiveKeyRecords: purchaser.updateReceiveKeyRecords,
  updateOriGeranRecords: purchaser.updateOriGeranRecords,
  updateEAutoRecords: purchaser.updateEAutoRecords,
  updateDisbursementRecords: purchaser.updateDisbursementRecords,
  updateSQLCheck: purchaser.updateSQLCheck,
  updateSalesInvoiceCheck: purchaser.updateSalesInvoiceCheck,
  updateReceiveKeyCheck: purchaser.updateReceiveKeyCheck,
  deletePurchaser: purchaser.deletePurchaser,
  updateActivePurchaser: purchaser.updateActivePurchaser,
  createCostStock: costStock.createCostStock,
  updateCostStock: costStock.updateCostStock,
  deleteCostStock: costStock.deleteCostStock,
  updateActiveCostStock: costStock.updateActiveCostStock,
  createSaleStock: saleStock.createSaleStock,
  updateSaleStock: saleStock.updateSaleStock,
  deleteSaleStock: saleStock.deleteSaleStock,
  updateActiveSaleStock: saleStock.updateActiveSaleStock,
  updateFloorStockRecords: saleStock.updateFloorStockRecords,
  updatePuspakomRecords: saleStock.updatePuspakomRecords,
  createCarRepair: carRepair.createCarRepair,
  updateCarRepair: carRepair.updateCarRepair,
  deleteCarRepair: carRepair.deleteCarRepair,
  updateActiveCarRepair: carRepair.updateActiveCarRepair,
  createSalesPerson: salesPerson.createSalesPerson,
  updateSalesPerson: salesPerson.updateSalesPerson,
  deleteSalesPerson: salesPerson.deleteSalesPerson,
  updateActiveSalesPerson: salesPerson.updateActiveSalesPerson,
  createBiddingCar: biddingCar.createBiddingCar,
  updateBiddingCar: biddingCar.updateBiddingCar,
  updateDisbursed: biddingCar.updateDisbursed,
  deleteBiddingCar: biddingCar.deleteBiddingCar,
  updateActiveBiddingCar: biddingCar.updateActiveBiddingCar,
  createPaymentRequest: paymentRequest.createPaymentRequest,
  updatePaymentRequest: paymentRequest.updatePaymentRequest,
  updateApproval: paymentRequest.updateApproval,
  updateActivePaymentRequest: paymentRequest.updateActivePaymentRequest,
  deletePaymentRequest: paymentRequest.deletePaymentRequest,
  createPaymentStatus: paymentStatus.createPaymentStatus,
  updatePaymentStatus: paymentStatus.updatePaymentStatus,
  deletePaymentStatus: paymentStatus.deletePaymentStatus,
  updateActivePaymentStatus: paymentStatus.updateActivePaymentStatus,
  createSaleOutstanding: saleOutstanding.createSaleOutstanding,
  updateSaleOutstanding: saleOutstanding.updateSaleOutstanding,
  deleteSaleOutstanding: saleOutstanding.deleteSaleOutstanding,
  createBankFloorStock: bankFloorStock.createBankFloorStock,
  updateBankFloorStock: bankFloorStock.updateBankFloorStock,
  deleteBankFloorStock: bankFloorStock.deleteBankFloorStock,
  updateActiveBankFloorStock: bankFloorStock.updateActiveBankFloorStock,
  createMyTukarFloorStock: myTukarFloorStock.createMyTukarFloorStock,
  updateMyTukarFloorStock: myTukarFloorStock.updateMyTukarFloorStock,
  deleteMyTukarFloorStock: myTukarFloorStock.deleteMyTukarFloorStock,
  updateActiveMyTukarFloorStock:
    myTukarFloorStock.updateActiveMyTukarFloorStock,
  createCustomer: customer.createCustomer,
  updateCustomer: customer.updateCustomer,
  deleteCustomer: customer.deleteCustomer,
  createCustomerComment: customer.createCustomerComment,
  updateCustomerComment: customer.updateCustomerComment,
  deleteCustomerComment: customer.deleteCustomerComment,
  createCustomerActivity: customer.createCustomerActivity,
  updateCustomerActivity: customer.updateCustomerActivity,
  deleteCustomerActivity: customer.deleteCustomerActivity,
  createCustomerTask: customer.createCustomerTask,
  updateCustomerTask: customer.updateCustomerTask,
  deleteCustomerTask: customer.deleteCustomerTask,
  writeCustomerChat: customer.writeCustomerChat,
  updateSetting: setting.updateSetting,
};

const storageRef = firebase.storage().ref();

export {
  auth,
  database,
  firebase,
  firebaseString,
  firebaseDB,
  firebasePath,
  db,
  callableFunctions,
  localFunctions,
  storageRef,
};
