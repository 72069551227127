import { paymentRequest } from "../actionTypes";
import { resetStockData } from "../actions/helper";
import { extractStockData } from "../../firebase/helper";
import { timeToUnix, unixToTimeIfAvailable } from "../../helper";
import globalVariables from "../../globalVariables.json";

const moment = require("moment");

const initialState = {
  tableItems: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,
  email1: undefined,
  email2: undefined,
  latestBidding: undefined,
  pendingDoc: undefined,
  stockExistance: {},
  createPaymentRequestToggle: false,
  updatePaymentRequestToggle: false,
  deletePaymentRequestToggle: false,
  detailPaymentRequestToggle: false,
  errorList: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case paymentRequest.LIST_PAYMENT_REQUEST: {
      const {
        tableItems,
        start,
        startSnapshot,
        query,
        searching,
        showAmount,
        attachID,
        attachModule,
        hideStockStatus,
        disableCheck,
      } = action.payload;
      var newTableItems = tableItems;

      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
        showAmount,
        attachID,
        attachModule,
        hideStockStatus,
        disableCheck,
      };
    }
    case paymentRequest.CREATE_PAYMENT_REQUEST: {
      const { result, stockDocData } = action.payload;
      const { stockData } = extractStockData(result, "paymentRequest");
      result.stockData = stockData;
      result.latestBidding = state.latestBidding;
      result.pendingDoc = state.pendingDoc;
      result.totalRequiredPayment = state.totalRequiredPayment;
      var newTableItems = [result, ...state.tableItems];
      let stockExistanceUpdate = state.stockExistance;
      if (!stockExistanceUpdate[result.stockDataID]) {
        stockExistanceUpdate[result.stockDataID] = stockDocData;
      }

      newTableItems.forEach((element, i) => {
        if (element.stockDataID == result.stockDataID) {
          newTableItems[i] = {
            ...newTableItems[i],
            status: result.status,
            statusDisplay: result.statusDisplay,
          };
        }
      });
      return {
        ...state,
        tableItems: newTableItems,
        stockExistance: stockExistanceUpdate,
      };
    }
    case paymentRequest.UPDATE_APPROVAL: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };
      return { ...state, tableItems: newTableItems };
    }
    case paymentRequest.UPDATE_PAYMENT_REQUEST: {
      const { result } = action.payload;
      result.stockData = state.stockData;
      result.companySellingPrice = state.stockData.companySellingPrice;
      result.sellingPrice = state.stockData.sellingPrice;
      result.latestBidding = state.stockData.latestBidding;
      result.inActive = state.stockData.inActive;
      result.pendingDoc = state.stockData.pendingDoc;
      result.totalRequiredPayment = state.totalRequiredPayment;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = result;
      let stockExistanceUpdate = state.stockExistance;
      let keys = globalVariables["paymentRequest"].allowedStockVar;
      keys.forEach(function (key) {
        if (
          stockExistanceUpdate[newTableItems[index].stockDataID][key] !=
          result[key]
        ) {
          stockExistanceUpdate[newTableItems[index].stockDataID][key] =
            result[key];
        }
      });
      newTableItems.forEach((element, i) => {
        if (element.stockDataID == result.stockDataID) {
          newTableItems[i] = {
            ...newTableItems[i],
            status: result.status,
            statusDisplay: result.statusDisplay,
          };
        }
      });
      return {
        ...state,
        tableItems: newTableItems,
        stockExistance: stockExistanceUpdate,
      };
    }
    case paymentRequest.UPDATE_ACTIVE_PAYMENT_REQUEST: {
      const { result } = action.payload;
      // result.inActive = !state.stockData.inActive;
      var newTableItems = [...state.tableItems];
      // console.log(newTableItems[0]);

      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };

      return { ...state, tableItems: newTableItems };
    }
    case paymentRequest.UPDATE_APPROVAL: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };
      return { ...state, tableItems: newTableItems };
    }
    case paymentRequest.DELETE_PAYMENT_REQUEST: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems.splice(index, 1);
      return { ...state, tableItems: newTableItems };
    }
    case paymentRequest.EMPTY_STOCKEXISTANCE_PAYMENT_REQUEST: {
      return {
        ...state,
        stockExistance: {},
      };
    }
    case paymentRequest.TOGGLE_CREATE_PAYMENT_REQUEST: {
      const { toggle } = action.payload;
      return {
        ...state,
        createPaymentRequestToggle: toggle
          ? !state.createPaymentRequestToggle
          : false,
      };
    }
    case paymentRequest.TOGGLE_UPDATE_PAYMENT_REQUEST: {
      const { toggle } = action.payload;
      return {
        ...state,
        updatePaymentRequestToggle: toggle
          ? !state.updatePaymentRequestToggle
          : false,
      };
    }
    case paymentRequest.TOGGLE_DELETE_PAYMENT_REQUEST: {
      const { toggle } = action.payload;
      return {
        ...state,
        deletePaymentRequestToggle: toggle
          ? !state.deletePaymentRequestToggle
          : false,
      };
    }
    case paymentRequest.TOGGLE_DETAIL_PAYMENT_REQUEST: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailPaymentRequestToggle: toggle
          ? !state.detailPaymentRequestToggle
          : false,
      };
    }
    case paymentRequest.RESET_PAYMENT_REQUEST_PARAMETERES: {
      let prefill = action.payload.prefill ?? {};
      return {
        ...state,
        ...resetStockData(),
        customer: undefined,
        payment: undefined,
        amount: undefined,
        bank: undefined,
        bankAccNum: undefined,
        deadline: undefined,
        purchaserCheckStatus: undefined,
        purchaserCheckDate: undefined,
        purSQLCheckStatus: undefined,
        purSQLCheckDate: undefined,
        approvalStatus: "false",
        approvalDate: undefined,
        checkStatus: "false",
        checkDate: undefined,
        salesRemark: undefined,
        supportingDocs: undefined,
        paymentRequest: undefined,
        paymentType: "PAID",
        id: undefined,
        isCreate: true,
        email1: undefined,
        email2: undefined,
        latestBidding: undefined,
        pendingDoc: undefined,
        totalRequiredPayment: undefined,
        newAccount: undefined,
        accountReceive: undefined,
        carRegistrationCardPhoto: undefined,
        errorList: {},
        ...prefill,
      };
    }
    case paymentRequest.UPDATE_PAYMENT_REQUEST_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case paymentRequest.DELETE_PAYMENT_REQUEST_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case paymentRequest.DETAIL_PAYMENT_REQUEST_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case paymentRequest.HANDLE_CHANGE_PAYMENT_REQUEST: {
      const { key, value } = action.payload;
      switch (key) {
        case "approvalStatus":
          if (value === "TRUE") {
            const approvalDate = timeToUnix(moment().startOf("day"));
            return { ...state, [key]: value, approvalDate };
          } else {
            return { ...state, [key]: value, approvalDate: "" };
          }
        case "checkStatus":
          if (value === "TRUE") {
            const checkDate = timeToUnix(moment().startOf("day"));
            return { ...state, [key]: value, checkDate };
          } else {
            return { ...state, [key]: value, checkDate: "" };
          }
        default:
          return { ...state, [key]: value };
      }
    }
    case paymentRequest.SHOW_EMPTY_PAYMENT_REQUEST: {
      const errorList = { ...action.payload };
      return { ...state, errorList };
    }
    default:
      return state;
  }
};
