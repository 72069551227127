export const LIST_SALEOUTSTANDING = "LIST_SALEOUTSTANDING"
export const CREATE_SALEOUTSTANDING = "CREATE_SALEOUTSTANDING";
export const UPDATE_SALEOUTSTANDING = "UPDATE_SALEOUTSTANDING"
export const DELETE_SALEOUTSTANDING = "DELETE_SALEOUTSTANDING"

export const TOGGLE_CREATE_SALEOUTSTANDING = "TOGGLE_CREATE_SALEOUTSTANDING"
export const TOGGLE_UPDATE_SALEOUTSTANDING = "TOGGLE_UPDATE_SALEOUTSTANDING";
export const TOGGLE_DELETE_SALEOUTSTANDING = "TOGGLE_DELETE_SALEOUTSTANDING";
export const TOGGLE_DETAIL_SALEOUTSTANDING = "TOGGLE_DETAIL_SALEOUTSTANDING";

export const RESET_SALEOUTSTANDING_PARAMETERES = "RESET_SALEOUTSTANDING_PARAMETERES";
export const UPDATE_SALEOUTSTANDING_PARAMETERS = "UPDATE_SALEOUTSTANDING_PARAMETERS";
export const DELETE_SALEOUTSTANDING_PARAMETERS = "DELETE_SALEOUTSTANDING_PARAMETERS"
export const DETAIL_SALEOUTSTANDING_PARAMETERS = "DETAIL_SALEOUTSTANDING_PARAMETERS"

export const HANDLE_CHANGE_SALEOUTSTANDING = "HANDLE_CHANGE_SALEOUTSTANDING";