export const LIST_COSTSTOCK = "LIST_COSTSTOCK";
export const GET_COSTSTOCK = "GET_COSTSTOCK";
export const CREATE_COSTSTOCK = "CREATE_COSTSTOCK";
export const UPDATE_COSTSTOCK = "UPDATE_COSTSTOCK";
export const DELETE_COSTSTOCK = "DELETE_COSTSTOCK";
export const UPDATE_ACTIVE_COSTSTOCK = "UPDATE_ACTIVE_COSTSTOCK";

export const TOGGLE_CREATE_COSTSTOCK = "TOGGLE_CREATE_COSTSTOCK";
export const TOGGLE_UPDATE_COSTSTOCK = "TOGGLE_UPDATE_COSTSTOCK";
export const TOGGLE_DELETE_COSTSTOCK = "TOGGLE_DELETE_COSTSTOCK";
export const TOGGLE_DETAIL_COSTSTOCK = "TOGGLE_DETAIL_COSTSTOCK";

export const RESET_COSTSTOCK_PARAMETERES = "RESET_COSTSTOCK_PARAMETERES";
export const UPDATE_COSTSTOCK_PARAMETERS = "UPDATE_COSTSTOCK_PARAMETERS";
export const DELETE_COSTSTOCK_PARAMETERS = "DELETE_COSTSTOCK_PARAMETERS";
export const DETAIL_COSTSTOCK_PARAMETERS = "DETAIL_COSTSTOCK_PARAMETERS";

export const HANDLE_CHANGE_COSTSTOCK = "HANDLE_CHANGE_COSTSTOCK";
