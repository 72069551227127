const modelMap = {
  "TOYOTA": [
      {
          "label": "AVANZA",
          "value": "avanza"
      },
      {
          "label": "VIOS",
          "value": "vios"
      },
      {
          "label": "RUSH",
          "value": "rush"
      },
      {
          "label": "YARIS",
          "value": "yaris"
      },
      {
          "label": "INNOVA",
          "value": "innova"
      },
      {
          "label": "ALTIS",
          "value": "altis"
      },
      {
          "label": "CAMRY",
          "value": "camry"
      },
      {
          "label": "WISH",
          "value": "wish"
      },
      {
          "label": "HILUX",
          "value": "hilux"
      },
      {
          "label": "FORTUNER",
          "value": "fortuner"
      },
      {
          "label": "CORONA",
          "value": "corona"
      },
      {
          "label": "COROLLA",
          "value": "corolla"
      },
      {
          "label": "PRIUS",
          "value": "prius"
      },
      {
          "label": "ALPHARD",
          "value": "alphard"
      },
      {
          "label": "ALTEZZA",
          "value": "altezza"
      },
      {
          "label": "CELICA",
          "value": "celica"
      },
      {
          "label": "CROWN",
          "value": "crown"
      },
      {
          "label": "ESTIMA",
          "value": "estima"
      },
      {
          "label": "HARRIER",
          "value": "harrier"
      },
      {
          "label": "LITEACE",
          "value": "liteace"
      },
      {
          "label": "MARK II",
          "value": "markii"
      },
      {
          "label": "MARK V",
          "value": "markv"
      },
      {
          "label": "PREMIO",
          "value": "premio"
      },
      {
          "label": "RAV 4",
          "value": "rav4"
      },
      {
          "label": "UNSER",
          "value": "unser"
      },
      {
          "label": "DX",
          "value": "dx"
      },
      {
          "label": "KE70",
          "value": "ke70"
      },
      {
          "label": "CALDINA",
          "value": "caldina"
      },
      {
          "label": "KE25",
          "value": "ke25"
      },
      {
          "label": "LC II",
          "value": "lcii"
      },
      {
          "label": "MARK X",
          "value": "markx"
      },
      {
          "label": "SPRINTER",
          "value": "sprinter"
      },
      {
          "label": "CYGNUS",
          "value": "cygnus"
      },
      {
          "label": "IPSUM",
          "value": "ipsum"
      },
      {
          "label": "LE",
          "value": "le"
      },
      {
          "label": "PRADO",
          "value": "prado"
      },
      {
          "label": "MRS",
          "value": "mrs"
      },
      {
          "label": "MR2",
          "value": "mr2"
      },
      {
          "label": "SPACIO",
          "value": "spacio"
      },
      {
          "label": "VELLFIRE",
          "value": "vellfire"
      },
      {
          "label": "86",
          "value": "86"
      },
      {
          "label": "AURIS",
          "value": "auris"
      },
      {
          "label": "CARINA",
          "value": "carina"
      },
      {
          "label": "FJ CRUISER",
          "value": "fjcruiser"
      },
      {
          "label": "ISIS",
          "value": "isis"
      },
      {
          "label": "IST",
          "value": "ist"
      },
      {
          "label": "LAND CRUISER",
          "value": "landcruiser"
      },
      {
          "label": "PRIUS C",
          "value": "priusc"
      },
      {
          "label": "AE86",
          "value": "ae86"
      },
      {
          "label": "2000GT",
          "value": "2000gt"
      },
      {
          "label": "ALLEX",
          "value": "allex"
      },
      {
          "label": "ALLION",
          "value": "allion"
      },
      {
          "label": "ARISTO",
          "value": "aristo"
      },
      {
          "label": "AVALON",
          "value": "avalon"
      },
      {
          "label": "AVENSIS",
          "value": "avensis"
      },
      {
          "label": "BB",
          "value": "bb"
      },
      {
          "label": "BELTA",
          "value": "belta"
      },
      {
          "label": "BLADE",
          "value": "blade"
      },
      {
          "label": "BLIZZARD",
          "value": "blizzard"
      },
      {
          "label": "BREVIS",
          "value": "brevis"
      },
      {
          "label": "BU",
          "value": "bu"
      },
      {
          "label": "CAMI",
          "value": "cami"
      },
      {
          "label": "CAVALIER",
          "value": "cavalier"
      },
      {
          "label": "CELSIOR",
          "value": "celsior"
      },
      {
          "label": "CENTURY",
          "value": "century"
      },
      {
          "label": "CHASER",
          "value": "chaser"
      },
      {
          "label": "COASTER",
          "value": "coaster"
      },
      {
          "label": "COMFORT",
          "value": "comfort"
      },
      {
          "label": "CORSA",
          "value": "corsa"
      },
      {
          "label": "CRESTA",
          "value": "cresta"
      },
      {
          "label": "CURREN",
          "value": "curren"
      },
      {
          "label": "DUET",
          "value": "duet"
      },
      {
          "label": "FT-86",
          "value": "ft-86"
      },
      {
          "label": "GAIA",
          "value": "gaia"
      },
      {
          "label": "GRANVIA",
          "value": "granvia"
      },
      {
          "label": "IQ",
          "value": "iq"
      },
      {
          "label": "KLUGER",
          "value": "kluger"
      },
      {
          "label": "NADIA",
          "value": "nadia"
      },
      {
          "label": "NOAH",
          "value": "noah"
      },
      {
          "label": "OPA",
          "value": "opa"
      },
      {
          "label": "ORIGIN",
          "value": "origin"
      },
      {
          "label": "PASSO",
          "value": "passo"
      },
      {
          "label": "PIXIS",
          "value": "pixis"
      },
      {
          "label": "PLATZ",
          "value": "platz"
      },
      {
          "label": "PORTE",
          "value": "porte"
      },
      {
          "label": "PROGRES",
          "value": "progres"
      },
      {
          "label": "PRONARD",
          "value": "pronard"
      },
      {
          "label": "RACTIS",
          "value": "ractis"
      },
      {
          "label": "RAUM",
          "value": "raum"
      },
      {
          "label": "SAI",
          "value": "sai"
      },
      {
          "label": "SCEPTER",
          "value": "scepter"
      },
      {
          "label": "SEG",
          "value": "seg"
      },
      {
          "label": "SERA",
          "value": "sera"
      },
      {
          "label": "SIENTA",
          "value": "sienta"
      },
      {
          "label": "SOARER",
          "value": "soarer"
      },
      {
          "label": "SPARKY",
          "value": "sparky"
      },
      {
          "label": "SPORT 800",
          "value": "sport800"
      },
      {
          "label": "STARLET",
          "value": "starlet"
      },
      {
          "label": "STOUT",
          "value": "stout"
      },
      {
          "label": "SUPRA",
          "value": "supra"
      },
      {
          "label": "TERCEL",
          "value": "tercel"
      },
      {
          "label": "VEROSSA",
          "value": "verossa"
      },
      {
          "label": "VISTA",
          "value": "vista"
      },
      {
          "label": "VITZ",
          "value": "vitz"
      },
      {
          "label": "VOLTZ",
          "value": "voltz"
      },
      {
          "label": "WILL",
          "value": "will"
      },
      {
          "label": "WINDOM",
          "value": "windom"
      },
      {
          "label": "HIACE",
          "value": "hiace"
      },
      {
          "label": "VANGUARD",
          "value": "vanguard"
      },
      {
          "label": "VOXY",
          "value": "voxy"
      },
      {
          "label": "AXIO",
          "value": "axio"
      },
      {
          "label": "KE20",
          "value": "ke20"
      },
      {
          "label": "C-HR",
          "value": "c-hr"
      },
      {
          "label": "PREVIA",
          "value": "previa"
      },
      {
          "label": "CRESSIDA",
          "value": "cressida"
      },
      {
          "label": "MEGA CRUISER",
          "value": "megacruiser"
      },
      {
          "label": "GR SUPRA",
          "value": "grsupra"
      },
      {
          "label": "ESQUIRE",
          "value": "esquire"
      },
      {
          "label": "CYNOS",
          "value": "cynos"
      },
      {
          "label": "TANK",
          "value": "tank"
      }
  ],
  "MERCEDES-BENZ": [
      {
          "label": "A170",
          "value": "a170"
      },
      {
          "label": "B170",
          "value": "b170"
      },
      {
          "label": "B200",
          "value": "b200"
      },
      {
          "label": "C180",
          "value": "c180"
      },
      {
          "label": "C200",
          "value": "c200"
      },
      {
          "label": "C230",
          "value": "c230"
      },
      {
          "label": "CLK200",
          "value": "clk200"
      },
      {
          "label": "SLK200",
          "value": "slk200"
      },
      {
          "label": "SLK280",
          "value": "slk280"
      },
      {
          "label": "SL350",
          "value": "sl350"
      },
      {
          "label": "CLS350",
          "value": "cls350"
      },
      {
          "label": "CLS55",
          "value": "cls55"
      },
      {
          "label": "E200",
          "value": "e200"
      },
      {
          "label": "E260",
          "value": "e260"
      },
      {
          "label": "E230",
          "value": "e230"
      },
      {
          "label": "E280",
          "value": "e280"
      },
      {
          "label": "S300L",
          "value": "s300l"
      },
      {
          "label": "S350L",
          "value": "s350l"
      },
      {
          "label": "S500L",
          "value": "s500l"
      },
      {
          "label": "ML350",
          "value": "ml350"
      },
      {
          "label": "R280L",
          "value": "r280l"
      },
      {
          "label": "R350L",
          "value": "r350l"
      },
      {
          "label": "C240",
          "value": "c240"
      },
      {
          "label": "E240",
          "value": "e240"
      },
      {
          "label": "W115",
          "value": "w115"
      },
      {
          "label": "W126",
          "value": "w126"
      },
      {
          "label": "190E",
          "value": "190e"
      },
      {
          "label": "300SE",
          "value": "300se"
      },
      {
          "label": "CL-CLASS",
          "value": "cl-class"
      },
      {
          "label": "CLC-CLASS",
          "value": "clc-class"
      },
      {
          "label": "G-CLASS",
          "value": "g-class"
      },
      {
          "label": "GL-CLASS",
          "value": "gl-class"
      },
      {
          "label": "GLK-CLASS",
          "value": "glk-class"
      },
      {
          "label": "SEL",
          "value": "sel"
      },
      {
          "label": "SLS AMG",
          "value": "slsamg"
      },
      {
          "label": "300CE",
          "value": "300ce"
      },
      {
          "label": "280SE",
          "value": "280se"
      },
      {
          "label": "A160",
          "value": "a160"
      },
      {
          "label": "B160",
          "value": "b160"
      },
      {
          "label": "C220",
          "value": "c220"
      },
      {
          "label": "C250",
          "value": "c250"
      },
      {
          "label": "C280",
          "value": "c280"
      },
      {
          "label": "C300",
          "value": "c300"
      },
      {
          "label": "C320",
          "value": "c320"
      },
      {
          "label": "CL 63",
          "value": "cl63"
      },
      {
          "label": "CL 65",
          "value": "cl65"
      },
      {
          "label": "CL203",
          "value": "cl203"
      },
      {
          "label": "CL500",
          "value": "cl500"
      },
      {
          "label": "CL550",
          "value": "cl550"
      },
      {
          "label": "CL600",
          "value": "cl600"
      },
      {
          "label": "CLC160",
          "value": "clc160"
      },
      {
          "label": "CLC180",
          "value": "clc180"
      },
      {
          "label": "CLC200",
          "value": "clc200"
      },
      {
          "label": "CLC220",
          "value": "clc220"
      },
      {
          "label": "CLK230",
          "value": "clk230"
      },
      {
          "label": "CLK240",
          "value": "clk240"
      },
      {
          "label": "CLK280",
          "value": "clk280"
      },
      {
          "label": "CLK320",
          "value": "clk320"
      },
      {
          "label": "CLK250",
          "value": "clk250"
      },
      {
          "label": "CLK430",
          "value": "clk430"
      },
      {
          "label": "CLK500",
          "value": "clk500"
      },
      {
          "label": "CLS500",
          "value": "cls500"
      },
      {
          "label": "E250",
          "value": "e250"
      },
      {
          "label": "G350",
          "value": "g350"
      },
      {
          "label": "G55",
          "value": "g55"
      },
      {
          "label": "GL320",
          "value": "gl320"
      },
      {
          "label": "GL420",
          "value": "gl420"
      },
      {
          "label": "GL500",
          "value": "gl500"
      },
      {
          "label": "ML-CLASS",
          "value": "ml-class"
      },
      {
          "label": "ML280",
          "value": "ml280"
      },
      {
          "label": "ML320",
          "value": "ml320"
      },
      {
          "label": "ML55",
          "value": "ml55"
      },
      {
          "label": "S-CLASS",
          "value": "s-class"
      },
      {
          "label": "S280",
          "value": "s280"
      },
      {
          "label": "S320L",
          "value": "s320l"
      },
      {
          "label": "SL280",
          "value": "sl280"
      },
      {
          "label": "SL500",
          "value": "sl500"
      },
      {
          "label": "SL55",
          "value": "sl55"
      },
      {
          "label": "SL63",
          "value": "sl63"
      },
      {
          "label": "SLR MCLAREN",
          "value": "slrmclaren"
      },
      {
          "label": "SLS 63",
          "value": "sls63"
      },
      {
          "label": "TRANSPORTER",
          "value": "transporter"
      },
      {
          "label": "URBAN MINIMOG",
          "value": "urbanminimog"
      },
      {
          "label": "VANOE",
          "value": "vanoe"
      },
      {
          "label": "V-CLASS",
          "value": "v-class"
      },
      {
          "label": "VIANO",
          "value": "viano"
      },
      {
          "label": "CLK55",
          "value": "clk55"
      },
      {
          "label": "A45",
          "value": "a45"
      },
      {
          "label": "A200",
          "value": "a200"
      },
      {
          "label": "A250",
          "value": "a250"
      },
      {
          "label": "GLA45",
          "value": "gla45"
      },
      {
          "label": "GLA200",
          "value": "gla200"
      },
      {
          "label": "GLA250",
          "value": "gla250"
      },
      {
          "label": "E300",
          "value": "e300"
      },
      {
          "label": "CLA200",
          "value": "cla200"
      },
      {
          "label": "CLA250",
          "value": "cla250"
      },
      {
          "label": "CLA45",
          "value": "cla45"
      },
      {
          "label": "CLS63",
          "value": "cls63"
      },
      {
          "label": "C43",
          "value": "c43"
      },
      {
          "label": "C36",
          "value": "c36"
      },
      {
          "label": "E55",
          "value": "e55"
      },
      {
          "label": "E63",
          "value": "e63"
      },
      {
          "label": "300SL",
          "value": "300sl"
      },
      {
          "label": "B180",
          "value": "b180"
      },
      {
          "label": "E400",
          "value": "e400"
      },
      {
          "label": "VITO",
          "value": "vito"
      },
      {
          "label": "G280",
          "value": "g280"
      },
      {
          "label": "230CE",
          "value": "230ce"
      },
      {
          "label": "R350",
          "value": "r350"
      },
      {
          "label": "380 SLC",
          "value": "380slc"
      },
      {
          "label": "E350",
          "value": "e350"
      },
      {
          "label": "230E",
          "value": "230e"
      },
      {
          "label": "280TE",
          "value": "280te"
      },
      {
          "label": "S400L HYBRID",
          "value": "s400lhybrid"
      },
      {
          "label": "C63",
          "value": "c63"
      },
      {
          "label": "S63",
          "value": "s63"
      },
      {
          "label": "AMG GT",
          "value": "amggt"
      },
      {
          "label": "AMG GT S",
          "value": "amggts"
      },
      {
          "label": "MB100",
          "value": "mb100"
      },
      {
          "label": "S320",
          "value": "s320"
      },
      {
          "label": "E270",
          "value": "e270"
      },
      {
          "label": "A180",
          "value": "a180"
      },
      {
          "label": "E220",
          "value": "e220"
      },
      {
          "label": "SLK230",
          "value": "slk230"
      },
      {
          "label": "SLK250",
          "value": "slk250"
      },
      {
          "label": "C270",
          "value": "c270"
      },
      {
          "label": "E320",
          "value": "e320"
      },
      {
          "label": "200D",
          "value": "200d"
      },
      {
          "label": "GLC250",
          "value": "glc250"
      },
      {
          "label": "GLE250D",
          "value": "gle250d"
      },
      {
          "label": "GLE400",
          "value": "gle400"
      },
      {
          "label": "GLE400 COUPE",
          "value": "gle400coupe"
      },
      {
          "label": "GLE450 COUPE",
          "value": "gle450coupe"
      },
      {
          "label": "260E",
          "value": "260e"
      },
      {
          "label": "CLA220",
          "value": "cla220"
      },
      {
          "label": "MB140D",
          "value": "mb140d"
      },
      {
          "label": "280SL",
          "value": "280sl"
      },
      {
          "label": "C350E",
          "value": "c350e"
      },
      {
          "label": "A190",
          "value": "a190"
      },
      {
          "label": "B SPORTS TOURER",
          "value": "bsportstourer"
      },
      {
          "label": "CLK270",
          "value": "clk270"
      },
      {
          "label": "CLS400",
          "value": "cls400"
      },
      {
          "label": "SLC 200",
          "value": "slc200"
      },
      {
          "label": "GLC200",
          "value": "glc200"
      },
      {
          "label": "CLA180",
          "value": "cla180"
      },
      {
          "label": "E350E AMG",
          "value": "e350eamg"
      },
      {
          "label": "E350E EXCLUSIVE",
          "value": "e350eexclusive"
      },
      {
          "label": "C200 AMG",
          "value": "c200amg"
      },
      {
          "label": "S400 HYBRID",
          "value": "s400hybrid"
      },
      {
          "label": "CLS220",
          "value": "cls220"
      },
      {
          "label": "E250 SE",
          "value": "e250se"
      },
      {
          "label": "SLK350",
          "value": "slk350"
      },
      {
          "label": "E250 CGI",
          "value": "e250cgi"
      },
      {
          "label": "GLC43",
          "value": "glc43"
      },
      {
          "label": "GLC43 COUPE",
          "value": "glc43coupe"
      },
      {
          "label": "200E",
          "value": "200e"
      },
      {
          "label": "230TE",
          "value": "230te"
      },
      {
          "label": "GLE43",
          "value": "gle43"
      },
      {
          "label": "280SLC",
          "value": "280slc"
      },
      {
          "label": "300E",
          "value": "300e"
      },
      {
          "label": "GLS350",
          "value": "gls350"
      },
      {
          "label": "E43",
          "value": "e43"
      },
      {
          "label": "GLA180",
          "value": "gla180"
      },
      {
          "label": "S600",
          "value": "s600"
      },
      {
          "label": "200",
          "value": "200"
      },
      {
          "label": "S450L",
          "value": "s450l"
      },
      {
          "label": "SL400 AMG",
          "value": "sl400amg"
      },
      {
          "label": "GLE63 S AMG",
          "value": "gle63samg"
      },
      {
          "label": "420SL",
          "value": "420sl"
      },
      {
          "label": "SPRINTER",
          "value": "sprinter"
      },
      {
          "label": "X250D",
          "value": "x250d"
      },
      {
          "label": "R500L",
          "value": "r500l"
      },
      {
          "label": "E500",
          "value": "e500"
      },
      {
          "label": "300TE",
          "value": "300te"
      },
      {
          "label": "500 SEC",
          "value": "500sec"
      },
      {
          "label": "350 SLC",
          "value": "350slc"
      },
      {
          "label": "240D",
          "value": "240d"
      },
      {
          "label": "S280V",
          "value": "s280v"
      },
      {
          "label": "230",
          "value": "230"
      },
      {
          "label": "250S",
          "value": "250s"
      },
      {
          "label": "SLC 43",
          "value": "slc43"
      },
      {
          "label": "A150",
          "value": "a150"
      },
      {
          "label": "CLS53",
          "value": "cls53"
      },
      {
          "label": "A35",
          "value": "a35"
      },
      {
          "label": "CLS450",
          "value": "cls450"
      },
      {
          "label": "E53",
          "value": "e53"
      },
      {
          "label": "GLC300",
          "value": "glc300"
      },
      {
          "label": "GLC63",
          "value": "glc63"
      },
      {
          "label": "S560",
          "value": "s560"
      },
      {
          "label": "S650",
          "value": "s650"
      },
      {
          "label": "SLC300",
          "value": "slc300"
      },
      {
          "label": "200TE",
          "value": "200te"
      },
      {
          "label": "SL320",
          "value": "sl320"
      },
      {
          "label": "320CE",
          "value": "320ce"
      },
      {
          "label": "300GE",
          "value": "300ge"
      },
      {
          "label": "GLE350D",
          "value": "gle350d"
      }
  ],
  "HONDA": [
      {
          "label": "CITY",
          "value": "city"
      },
      {
          "label": "JAZZ",
          "value": "jazz"
      },
      {
          "label": "CIVIC",
          "value": "civic"
      },
      {
          "label": "ACCORD",
          "value": "accord"
      },
      {
          "label": "STREAM",
          "value": "stream"
      },
      {
          "label": "ODYSSEY",
          "value": "odyssey"
      },
      {
          "label": "CR-V",
          "value": "cr-v"
      },
      {
          "label": "PRELUDE",
          "value": "prelude"
      },
      {
          "label": "INSPIRE",
          "value": "inspire"
      },
      {
          "label": "INTEGRA",
          "value": "integra"
      },
      {
          "label": "LEGEND",
          "value": "legend"
      },
      {
          "label": "NSX",
          "value": "nsx"
      },
      {
          "label": "HYBRID",
          "value": "hybrid"
      },
      {
          "label": "INSIGHT",
          "value": "insight"
      },
      {
          "label": "CR-Z",
          "value": "cr-z"
      },
      {
          "label": "CIVIC TYPE R",
          "value": "civictyper"
      },
      {
          "label": "CROSSROAD",
          "value": "crossroad"
      },
      {
          "label": "EDIX",
          "value": "edix"
      },
      {
          "label": "FREED",
          "value": "freed"
      },
      {
          "label": "JAZZ HYBRID",
          "value": "jazzhybrid"
      },
      {
          "label": "MDX",
          "value": "mdx"
      },
      {
          "label": "S2000",
          "value": "s2000"
      },
      {
          "label": "VIGOR",
          "value": "vigor"
      },
      {
          "label": "1300",
          "value": "1300"
      },
      {
          "label": "145",
          "value": "145"
      },
      {
          "label": "ACTY TRUCK",
          "value": "actytruck"
      },
      {
          "label": "ACTY VAN",
          "value": "actyvan"
      },
      {
          "label": "AIRWAVE",
          "value": "airwave"
      },
      {
          "label": "ASCOT",
          "value": "ascot"
      },
      {
          "label": "AVANCIER",
          "value": "avancier"
      },
      {
          "label": "BALLADE",
          "value": "ballade"
      },
      {
          "label": "BEAT",
          "value": "beat"
      },
      {
          "label": "CAPA",
          "value": "capa"
      },
      {
          "label": "CONCERTO",
          "value": "concerto"
      },
      {
          "label": "CR-X",
          "value": "cr-x"
      },
      {
          "label": "DOMANI",
          "value": "domani"
      },
      {
          "label": "ELEMENT",
          "value": "element"
      },
      {
          "label": "ELYSION",
          "value": "elysion"
      },
      {
          "label": "HORIZON",
          "value": "horizon"
      },
      {
          "label": "HR-V",
          "value": "hr-v"
      },
      {
          "label": "LAGREAT",
          "value": "lagreat"
      },
      {
          "label": "LIFE",
          "value": "life"
      },
      {
          "label": "LOGO",
          "value": "logo"
      },
      {
          "label": "MOBILIO",
          "value": "mobilio"
      },
      {
          "label": "N360",
          "value": "n360"
      },
      {
          "label": "ORTHIA",
          "value": "orthia"
      },
      {
          "label": "PERTNER",
          "value": "pertner"
      },
      {
          "label": "QUINT",
          "value": "quint"
      },
      {
          "label": "RAFAGA",
          "value": "rafaga"
      },
      {
          "label": "S500",
          "value": "s500"
      },
      {
          "label": "S600",
          "value": "s600"
      },
      {
          "label": "S800",
          "value": "s800"
      },
      {
          "label": "SABER",
          "value": "saber"
      },
      {
          "label": "S-MX",
          "value": "s-mx"
      },
      {
          "label": "STEPWAGON",
          "value": "stepwagon"
      },
      {
          "label": "STREET",
          "value": "street"
      },
      {
          "label": "TODAY",
          "value": "today"
      },
      {
          "label": "TORNEO",
          "value": "torneo"
      },
      {
          "label": "VANOS",
          "value": "vanos"
      },
      {
          "label": "Z",
          "value": "z"
      },
      {
          "label": "ZEST",
          "value": "zest"
      },
      {
          "label": "INTEGRA TYPE R",
          "value": "integratyper"
      },
      {
          "label": "FIT",
          "value": "fit"
      },
      {
          "label": "S660",
          "value": "s660"
      },
      {
          "label": "BR-V",
          "value": "br-v"
      },
      {
          "label": "JADE",
          "value": "jade"
      },
      {
          "label": "N BOX",
          "value": "nbox"
      }
  ],
  "PERODUA": [
      {
          "label": "KANCIL",
          "value": "kancil"
      },
      {
          "label": "KEMBARA",
          "value": "kembara"
      },
      {
          "label": "KENARI",
          "value": "kenari"
      },
      {
          "label": "KELISA",
          "value": "kelisa"
      },
      {
          "label": "MYVI",
          "value": "myvi"
      },
      {
          "label": "VIVA",
          "value": "viva"
      },
      {
          "label": "NAUTICA",
          "value": "nautica"
      },
      {
          "label": "ALZA",
          "value": "alza"
      },
      {
          "label": "AXIA",
          "value": "axia"
      },
      {
          "label": "RUSA",
          "value": "rusa"
      },
      {
          "label": "BEZZA",
          "value": "bezza"
      },
      {
          "label": "ARUZ",
          "value": "aruz"
      },
      {
        "label": "ATIVA",
        "value": "ativa"
    }
  ],
  "PROTON": [
      {
          "label": "SAGA",
          "value": "saga"
      },
      {
          "label": "ISWARA",
          "value": "iswara"
      },
      {
          "label": "WIRA",
          "value": "wira"
      },
      {
          "label": "WAJA",
          "value": "waja"
      },
      {
          "label": "SATRIA",
          "value": "satria"
      },
      {
          "label": "SAVVY",
          "value": "savvy"
      },
      {
          "label": "PERDANA",
          "value": "perdana"
      },
      {
          "label": "GEN2",
          "value": "gen2"
      },
      {
          "label": "PERSONA",
          "value": "persona"
      },
      {
          "label": "EXORA",
          "value": "exora"
      },
      {
          "label": "PUTRA",
          "value": "putra"
      },
      {
          "label": "IRIZ",
          "value": "iriz"
      },
      {
          "label": "SATRIA NEO",
          "value": "satrianeo"
      },
      {
          "label": "SATRIA GTI",
          "value": "satriagti"
      },
      {
          "label": "INSPIRA",
          "value": "inspira"
      },
      {
          "label": "TIARA",
          "value": "tiara"
      },
      {
          "label": "ARENA",
          "value": "arena"
      },
      {
          "label": "PREVE",
          "value": "preve"
      },
      {
          "label": "JUARA",
          "value": "juara"
      },
      {
          "label": "SUPRIMA S",
          "value": "suprimas"
      },
      {
          "label": "ERTIGA",
          "value": "ertiga"
      },
      {
          "label": "X70",
          "value": "x70"
      },
      {
          "label": "X50",
          "value": "x50"
      }
  ],
  "NISSAN": [
      {
          "label": "SENTRA",
          "value": "sentra"
      },
      {
          "label": "LATIO",
          "value": "latio"
      },
      {
          "label": "IMPUL",
          "value": "impul"
      },
      {
          "label": "SYLPHY",
          "value": "sylphy"
      },
      {
          "label": "SERENA",
          "value": "serena"
      },
      {
          "label": "FRONTIER",
          "value": "frontier"
      },
      {
          "label": "NAVARA",
          "value": "navara"
      },
      {
          "label": "X-TRAIL",
          "value": "x-trail"
      },
      {
          "label": "SUNNY",
          "value": "sunny"
      },
      {
          "label": "ALTIMA",
          "value": "altima"
      },
      {
          "label": "BLUEBIRD",
          "value": "bluebird"
      },
      {
          "label": "CEFIRO",
          "value": "cefiro"
      },
      {
          "label": "CEDRIC",
          "value": "cedric"
      },
      {
          "label": "MURANO",
          "value": "murano"
      },
      {
          "label": "TERRANO",
          "value": "terrano"
      },
      {
          "label": "SKYLINE",
          "value": "skyline"
      },
      {
          "label": "MICRA",
          "value": "micra"
      },
      {
          "label": "SILVIA",
          "value": "silvia"
      },
      {
          "label": "MARCH",
          "value": "march"
      },
      {
          "label": "AD RESORT",
          "value": "adresort"
      },
      {
          "label": "GRAND LIVINA",
          "value": "grandlivina"
      },
      {
          "label": "PATROL",
          "value": "patrol"
      },
      {
          "label": "TEANA",
          "value": "teana"
      },
      {
          "label": "SAFARI",
          "value": "safari"
      },
      {
          "label": "X-GEAR",
          "value": "x-gear"
      },
      {
          "label": "350Z",
          "value": "350z"
      },
      {
          "label": "370Z",
          "value": "370z"
      },
      {
          "label": "ELGRAND",
          "value": "elgrand"
      },
      {
          "label": "FAIRLADY Z",
          "value": "fairladyz"
      },
      {
          "label": "GTR",
          "value": "gtr"
      },
      {
          "label": "JUKE",
          "value": "juke"
      },
      {
          "label": "PRESAGE",
          "value": "presage"
      },
      {
          "label": "180SX",
          "value": "180sx"
      },
      {
          "label": "280ZX",
          "value": "280zx"
      },
      {
          "label": "ALMERA",
          "value": "almera"
      },
      {
          "label": "ATLAS",
          "value": "atlas"
      },
      {
          "label": "AUSTER",
          "value": "auster"
      },
      {
          "label": "AVENIR",
          "value": "avenir"
      },
      {
          "label": "BASSARA",
          "value": "bassara"
      },
      {
          "label": "BE-1",
          "value": "be-1"
      },
      {
          "label": "CHERRY",
          "value": "cherry"
      },
      {
          "label": "CIMA",
          "value": "cima"
      },
      {
          "label": "CONDOR",
          "value": "condor"
      },
      {
          "label": "CREW",
          "value": "crew"
      },
      {
          "label": "EXA",
          "value": "exa"
      },
      {
          "label": "EXPERT",
          "value": "expert"
      },
      {
          "label": "FIRAGO",
          "value": "firago"
      },
      {
          "label": "FUGA",
          "value": "fuga"
      },
      {
          "label": "GAZELLA",
          "value": "gazella"
      },
      {
          "label": "GLORIA",
          "value": "gloria"
      },
      {
          "label": "GT",
          "value": "gt"
      },
      {
          "label": "GTT",
          "value": "gtt"
      },
      {
          "label": "INFINITI",
          "value": "infiniti"
      },
      {
          "label": "KIX",
          "value": "kix"
      },
      {
          "label": "LAFESTA",
          "value": "lafesta"
      },
      {
          "label": "LANGLEY",
          "value": "langley"
      },
      {
          "label": "LAUREL",
          "value": "laurel"
      },
      {
          "label": "LEAF",
          "value": "leaf"
      },
      {
          "label": "LEOPARD",
          "value": "leopard"
      },
      {
          "label": "LIBERTY",
          "value": "liberty"
      },
      {
          "label": "LUCINO",
          "value": "lucino"
      },
      {
          "label": "MAXIMA",
          "value": "maxima"
      },
      {
          "label": "MISTRAL",
          "value": "mistral"
      },
      {
          "label": "MOCO",
          "value": "moco"
      },
      {
          "label": "NOTE",
          "value": "note"
      },
      {
          "label": "OTTI",
          "value": "otti"
      },
      {
          "label": "PAO",
          "value": "pao"
      },
      {
          "label": "PINO",
          "value": "pino"
      },
      {
          "label": "PRAIRIE JOY",
          "value": "prairiejoy"
      },
      {
          "label": "PRESEA",
          "value": "presea"
      },
      {
          "label": "PRESIDENT",
          "value": "president"
      },
      {
          "label": "PRIMERA",
          "value": "primera"
      },
      {
          "label": "PULSAR",
          "value": "pulsar"
      },
      {
          "label": "QUEST",
          "value": "quest"
      },
      {
          "label": "RASHEEN",
          "value": "rasheen"
      },
      {
          "label": "RNESSA",
          "value": "rnessa"
      },
      {
          "label": "ROOX",
          "value": "roox"
      },
      {
          "label": "STAGEA",
          "value": "stagea"
      },
      {
          "label": "STANZA",
          "value": "stanza"
      },
      {
          "label": "TILDA",
          "value": "tilda"
      },
      {
          "label": "TINO",
          "value": "tino"
      },
      {
          "label": "VIOLET",
          "value": "violet"
      },
      {
          "label": "VW SANTANA",
          "value": "vwsantana"
      },
      {
          "label": "WINGROAD",
          "value": "wingroad"
      },
      {
          "label": "VANETTE",
          "value": "vanette"
      },
      {
          "label": "URVAN",
          "value": "urvan"
      },
      {
          "label": "NV200",
          "value": "nv200"
      },
      {
          "label": "DUALIS",
          "value": "dualis"
      },
      {
          "label": "CUBE",
          "value": "cube"
      },
      {
          "label": "130Y",
          "value": "130y"
      },
      {
          "label": "ELGRAND 2.5 HIGHWAY STAR",
          "value": "elgrand2.5highwaystar"
      },
      {
          "label": "ELGRAND 2.5 RIDER",
          "value": "elgrand2.5rider"
      },
      {
          "label": "LIBERTY S",
          "value": "libertys"
      },
      {
          "label": "FAIRLADY 280Z",
          "value": "fairlady280z"
      },
      {
          "label": "720",
          "value": "720"
      }
  ],
  "BMW": [
      {
          "label": "116I",
          "value": "116i"
      },
      {
          "label": "118I",
          "value": "118i"
      },
      {
          "label": "120I",
          "value": "120i"
      },
      {
          "label": "125I",
          "value": "125i"
      },
      {
          "label": "135I",
          "value": "135i"
      },
      {
          "label": "320I",
          "value": "320i"
      },
      {
          "label": "323I",
          "value": "323i"
      },
      {
          "label": "325I",
          "value": "325i"
      },
      {
          "label": "335I",
          "value": "335i"
      },
      {
          "label": "M3",
          "value": "m3"
      },
      {
          "label": "523I",
          "value": "523i"
      },
      {
          "label": "525I",
          "value": "525i"
      },
      {
          "label": "530I",
          "value": "530i"
      },
      {
          "label": "540I",
          "value": "540i"
      },
      {
          "label": "M5",
          "value": "m5"
      },
      {
          "label": "630I",
          "value": "630i"
      },
      {
          "label": "M6",
          "value": "m6"
      },
      {
          "label": "X3",
          "value": "x3"
      },
      {
          "label": "X5",
          "value": "x5"
      },
      {
          "label": "X6",
          "value": "x6"
      },
      {
          "label": "Z4",
          "value": "z4"
      },
      {
          "label": "728I",
          "value": "728i"
      },
      {
          "label": "730I",
          "value": "730i"
      },
      {
          "label": "735I",
          "value": "735i"
      },
      {
          "label": "740LI",
          "value": "740li"
      },
      {
          "label": "750LI",
          "value": "750li"
      },
      {
          "label": "E21",
          "value": "e21"
      },
      {
          "label": "E30",
          "value": "e30"
      },
      {
          "label": "E36",
          "value": "e36"
      },
      {
          "label": "E46",
          "value": "e46"
      },
      {
          "label": "E90",
          "value": "e90"
      },
      {
          "label": "E12",
          "value": "e12"
      },
      {
          "label": "E28",
          "value": "e28"
      },
      {
          "label": "E34",
          "value": "e34"
      },
      {
          "label": "E39",
          "value": "e39"
      },
      {
          "label": "E60",
          "value": "e60"
      },
      {
          "label": "E23",
          "value": "e23"
      },
      {
          "label": "E32",
          "value": "e32"
      },
      {
          "label": "E38",
          "value": "e38"
      },
      {
          "label": "E65",
          "value": "e65"
      },
      {
          "label": "E66",
          "value": "e66"
      },
      {
          "label": "E67",
          "value": "e67"
      },
      {
          "label": "520I",
          "value": "520i"
      },
      {
          "label": "528I",
          "value": "528i"
      },
      {
          "label": "535I",
          "value": "535i"
      },
      {
          "label": "535 GT",
          "value": "535gt"
      },
      {
          "label": "320D",
          "value": "320d"
      },
      {
          "label": "328I",
          "value": "328i"
      },
      {
          "label": "520D",
          "value": "520d"
      },
      {
          "label": "640I",
          "value": "640i"
      },
      {
          "label": "650I",
          "value": "650i"
      },
      {
          "label": "730LI",
          "value": "730li"
      },
      {
          "label": "730LD",
          "value": "730ld"
      },
      {
          "label": "X1",
          "value": "x1"
      },
      {
          "label": "123D",
          "value": "123d"
      },
      {
          "label": "128I",
          "value": "128i"
      },
      {
          "label": "130I",
          "value": "130i"
      },
      {
          "label": "645CI",
          "value": "645ci"
      },
      {
          "label": "740I",
          "value": "740i"
      },
      {
          "label": "738I",
          "value": "738i"
      },
      {
          "label": "750I",
          "value": "750i"
      },
      {
          "label": "760I",
          "value": "760i"
      },
      {
          "label": "760LI",
          "value": "760li"
      },
      {
          "label": "E81",
          "value": "e81"
      },
      {
          "label": "E93",
          "value": "e93"
      },
      {
          "label": "M4",
          "value": "m4"
      },
      {
          "label": "M-SPORT",
          "value": "m-sport"
      },
      {
          "label": "Z1",
          "value": "z1"
      },
      {
          "label": "Z3",
          "value": "z3"
      },
      {
          "label": "Z8",
          "value": "z8"
      },
      {
          "label": "1 M",
          "value": "1m"
      },
      {
          "label": "318I",
          "value": "318i"
      },
      {
          "label": "318CI",
          "value": "318ci"
      },
      {
          "label": "320CI",
          "value": "320ci"
      },
      {
          "label": "325CI",
          "value": "325ci"
      },
      {
          "label": "330CI",
          "value": "330ci"
      },
      {
          "label": "X6 M",
          "value": "x6m"
      },
      {
          "label": "Z4 M",
          "value": "z4m"
      },
      {
          "label": "Z4 COUPE",
          "value": "z4coupe"
      },
      {
          "label": "428I GRAN COUPE",
          "value": "428igrancoupe"
      },
      {
          "label": "316I",
          "value": "316i"
      },
      {
          "label": "335CI",
          "value": "335ci"
      },
      {
          "label": "640I GRAN COUPE",
          "value": "640igrancoupe"
      },
      {
          "label": "M6 GRAN COUPE",
          "value": "m6grancoupe"
      },
      {
          "label": "420I",
          "value": "420i"
      },
      {
          "label": "428I",
          "value": "428i"
      },
      {
          "label": "435I",
          "value": "435i"
      },
      {
          "label": "320D GT",
          "value": "320dgt"
      },
      {
          "label": "328I GT",
          "value": "328igt"
      },
      {
          "label": "220I",
          "value": "220i"
      },
      {
          "label": "218I ACTIVE TOURER",
          "value": "218iactivetourer"
      },
      {
          "label": "220I GRAN TOURER",
          "value": "220igrantourer"
      },
      {
          "label": "235I",
          "value": "235i"
      },
      {
          "label": "I8",
          "value": "i8"
      },
      {
          "label": "840CI",
          "value": "840ci"
      },
      {
          "label": "850I",
          "value": "850i"
      },
      {
          "label": "850CI",
          "value": "850ci"
      },
      {
          "label": "850CSI",
          "value": "850csi"
      },
      {
          "label": "X4",
          "value": "x4"
      },
      {
          "label": "I3",
          "value": "i3"
      },
      {
          "label": "530D",
          "value": "530d"
      },
      {
          "label": "340I",
          "value": "340i"
      },
      {
          "label": "2002",
          "value": "2002"
      },
      {
          "label": "M2",
          "value": "m2"
      },
      {
          "label": "330E",
          "value": "330e"
      },
      {
          "label": "328CI",
          "value": "328ci"
      },
      {
          "label": "ACTIVEHYBRID 3",
          "value": "activehybrid3"
      },
      {
          "label": "430I",
          "value": "430i"
      },
      {
          "label": "545I",
          "value": "545i"
      },
      {
          "label": "ACTIVEHYBRID 5",
          "value": "activehybrid5"
      },
      {
          "label": "630CI",
          "value": "630ci"
      },
      {
          "label": "640CI",
          "value": "640ci"
      },
      {
          "label": "650CI",
          "value": "650ci"
      },
      {
          "label": "740LE",
          "value": "740le"
      },
      {
          "label": "745LI",
          "value": "745li"
      },
      {
          "label": "ACTIVEHYBRID 7 L",
          "value": "activehybrid7l"
      },
      {
          "label": "323CI",
          "value": "323ci"
      },
      {
          "label": "525D",
          "value": "525d"
      },
      {
          "label": "330I",
          "value": "330i"
      },
      {
          "label": "530E",
          "value": "530e"
      },
      {
          "label": "635CSI",
          "value": "635csi"
      },
      {
          "label": "628CSI",
          "value": "628csi"
      },
      {
          "label": "X2",
          "value": "x2"
      },
      {
          "label": "735IL",
          "value": "735il"
      },
      {
          "label": "X7",
          "value": "x7"
      }
  ],
  "PORSCHE": [
      {
          "label": "BOXSTER",
          "value": "boxster"
      },
      {
          "label": "CAYMAN",
          "value": "cayman"
      },
      {
          "label": "911 CARRERA",
          "value": "911carrera"
      },
      {
          "label": "911 TARGA",
          "value": "911targa"
      },
      {
          "label": "911 GT2",
          "value": "911gt2"
      },
      {
          "label": "911 TURBO",
          "value": "911turbo"
      },
      {
          "label": "CAYENNE",
          "value": "cayenne"
      },
      {
          "label": "PANAMERA",
          "value": "panamera"
      },
      {
          "label": "356",
          "value": "356"
      },
      {
          "label": "550",
          "value": "550"
      },
      {
          "label": "914",
          "value": "914"
      },
      {
          "label": "924",
          "value": "924"
      },
      {
          "label": "928",
          "value": "928"
      },
      {
          "label": "944",
          "value": "944"
      },
      {
          "label": "959",
          "value": "959"
      },
      {
          "label": "968",
          "value": "968"
      },
      {
          "label": "991",
          "value": "991"
      },
      {
          "label": "997",
          "value": "997"
      },
      {
          "label": "C2S",
          "value": "c2s"
      },
      {
          "label": "C4S",
          "value": "c4s"
      },
      {
          "label": "CARERRA",
          "value": "carerra"
      },
      {
          "label": "911 GT3",
          "value": "911gt3"
      },
      {
          "label": "911 CARRERA S",
          "value": "911carreras"
      },
      {
          "label": "911 CARRERA 4S",
          "value": "911carrera4s"
      },
      {
          "label": "911 GTS",
          "value": "911gts"
      },
      {
          "label": "911 GT3 RS",
          "value": "911gt3rs"
      },
      {
          "label": "MACAN",
          "value": "macan"
      },
      {
          "label": "MACAN S",
          "value": "macans"
      },
      {
          "label": "MACAN TURBO",
          "value": "macanturbo"
      },
      {
          "label": "CAYENNE DIESEL",
          "value": "cayennediesel"
      },
      {
          "label": "CAYENNE TURBO",
          "value": "cayenneturbo"
      },
      {
          "label": "CAYENNE S",
          "value": "cayennes"
      },
      {
          "label": "CAYENNE TURBO S",
          "value": "cayenneturbos"
      },
      {
          "label": "CAYENNE GTS",
          "value": "cayennegts"
      },
      {
          "label": "BOXSTER S",
          "value": "boxsters"
      },
      {
          "label": "BOXSTER GTS",
          "value": "boxstergts"
      },
      {
          "label": "CAYMAN S",
          "value": "caymans"
      },
      {
          "label": "CAYMAN GTS",
          "value": "caymangts"
      },
      {
          "label": "CAYMAN GT4",
          "value": "caymangt4"
      },
      {
          "label": "911S",
          "value": "911s"
      },
      {
          "label": "MACAN GTS",
          "value": "macangts"
      }
  ],
  "VOLKSWAGEN": [
      {
          "label": "POLO",
          "value": "polo"
      },
      {
          "label": "CROSSPOLO",
          "value": "crosspolo"
      },
      {
          "label": "BEETLE",
          "value": "beetle"
      },
      {
          "label": "GOLF",
          "value": "golf"
      },
      {
          "label": "JETTA",
          "value": "jetta"
      },
      {
          "label": "PASSAT",
          "value": "passat"
      },
      {
          "label": "EOS",
          "value": "eos"
      },
      {
          "label": "TIGUAN",
          "value": "tiguan"
      },
      {
          "label": "TOUAREG",
          "value": "touareg"
      },
      {
          "label": "SCIROCCO",
          "value": "scirocco"
      },
      {
          "label": "CC",
          "value": "cc"
      },
      {
          "label": "CROSS TOURAN",
          "value": "crosstouran"
      },
      {
          "label": "GOLF GTI",
          "value": "golfgti"
      },
      {
          "label": "GOLF R",
          "value": "golfr"
      },
      {
          "label": "PASSAT CC",
          "value": "passatcc"
      },
      {
          "label": "POLO GTI",
          "value": "pologti"
      },
      {
          "label": "POLO SEDAN",
          "value": "polosedan"
      },
      {
          "label": "SCIROCCO R",
          "value": "sciroccor"
      },
      {
          "label": "SHARAN",
          "value": "sharan"
      },
      {
          "label": "BORA",
          "value": "bora"
      },
      {
          "label": "CARAVEL",
          "value": "caravel"
      },
      {
          "label": "CORRADO",
          "value": "corrado"
      },
      {
          "label": "CROSS GOLF",
          "value": "crossgolf"
      },
      {
          "label": "KARMANN GHIA",
          "value": "karmannghia"
      },
      {
          "label": "LUPO",
          "value": "lupo"
      },
      {
          "label": "SANTANA",
          "value": "santana"
      },
      {
          "label": "TRANSPORTER",
          "value": "transporter"
      },
      {
          "label": "TYPE",
          "value": "type"
      },
      {
          "label": "VANAGON",
          "value": "vanagon"
      },
      {
          "label": "VENTO",
          "value": "vento"
      },
      {
          "label": "TOURAN",
          "value": "touran"
      },
      {
          "label": "KOMBI",
          "value": "kombi"
      },
      {
          "label": "UP",
          "value": "up"
      },
      {
          "label": "ARTEON",
          "value": "arteon"
      },
      {
          "label": "PHAETON",
          "value": "phaeton"
      },
      {
          "label": "WALD",
          "value": "wald"
      }
  ],
  "MAZDA": [
      {
          "label": "BT-50",
          "value": "bt-50"
      },
      {
        "label": "B2500",
        "value": "b2500"
    },
      {
          "label": "FIGHTER",
          "value": "fighter"
      },
      {
          "label": "MAZDA3",
          "value": "mazda3"
      },
      {
          "label": "MAZDA2",
          "value": "mazda2"
      },
      {
          "label": "MAZDA6",
          "value": "mazda6"
      },
      {
          "label": "RX-8",
          "value": "rx-8"
      },
      {
          "label": "MX-5",
          "value": "mx-5"
      },
      {
          "label": "CX-9",
          "value": "cx-9"
      },
      {
          "label": "323",
          "value": "323"
      },
      {
          "label": "FAMILIA",
          "value": "familia"
      },
      {
          "label": "626",
          "value": "626"
      },
      {
          "label": "929",
          "value": "929"
      },
      {
          "label": "RX-7",
          "value": "rx-7"
      },
      {
          "label": "PREMACY",
          "value": "premacy"
      },
      {
          "label": "CAPELLA",
          "value": "capella"
      },
      {
          "label": "626 CRONOS",
          "value": "626cronos"
      },
      {
          "label": "JUMBO",
          "value": "jumbo"
      },
      {
          "label": "LANTIS",
          "value": "lantis"
      },
      {
          "label": "MPV",
          "value": "mpv"
      },
      {
          "label": "2",
          "value": "2"
      },
      {
          "label": "6",
          "value": "6"
      },
      {
          "label": "CX-5",
          "value": "cx-5"
      },
      {
          "label": "CX-7",
          "value": "cx-7"
      },
      {
          "label": "MAZDA3 MPS",
          "value": "mazda3mps"
      },
      {
          "label": "MAZDA5",
          "value": "mazda5"
      },
      {
          "label": "MAZDA8",
          "value": "mazda8"
      },
      {
          "label": "3",
          "value": "3"
      },
      {
          "label": "5",
          "value": "5"
      },
      {
          "label": "8",
          "value": "8"
      },
      {
          "label": "ATENZA",
          "value": "atenza"
      },
      {
          "label": "AUTOZAM",
          "value": "autozam"
      },
      {
          "label": "AUTOZAM AZ-1",
          "value": "autozamaz-1"
      },
      {
          "label": "AUTOZAM AZ-3",
          "value": "autozamaz-3"
      },
      {
          "label": "AXELA",
          "value": "axela"
      },
      {
          "label": "AZ",
          "value": "az"
      },
      {
          "label": "BIANTE",
          "value": "biante"
      },
      {
          "label": "CAROL",
          "value": "carol"
      },
      {
          "label": "CHANTEZ",
          "value": "chantez"
      },
      {
          "label": "COSMO",
          "value": "cosmo"
      },
      {
          "label": "CRONOS",
          "value": "cronos"
      },
      {
          "label": "DEMIO",
          "value": "demio"
      },
      {
          "label": "EFINI",
          "value": "efini"
      },
      {
          "label": "ETUDE",
          "value": "etude"
      },
      {
          "label": "LAPUTA",
          "value": "laputa"
      },
      {
          "label": "MILLENIA",
          "value": "millenia"
      },
      {
          "label": "MX-6",
          "value": "mx-6"
      },
      {
          "label": "PARKWAY",
          "value": "parkway"
      },
      {
          "label": "PERSONA",
          "value": "persona"
      },
      {
          "label": "PORTER",
          "value": "porter"
      },
      {
          "label": "PROCEED",
          "value": "proceed"
      },
      {
          "label": "REVUE",
          "value": "revue"
      },
      {
          "label": "ROAD PACER",
          "value": "roadpacer"
      },
      {
          "label": "ROADSTER",
          "value": "roadster"
      },
      {
          "label": "SAVANNA",
          "value": "savanna"
      },
      {
          "label": "SCRUM",
          "value": "scrum"
      },
      {
          "label": "SENTIA",
          "value": "sentia"
      },
      {
          "label": "SPEED",
          "value": "speed"
      },
      {
          "label": "SPIANO",
          "value": "spiano"
      },
      {
          "label": "TRIBUTE",
          "value": "tribute"
      },
      {
          "label": "VERISA",
          "value": "verisa"
      },
      {
          "label": "808",
          "value": "808"
      },
      {
          "label": "E2000",
          "value": "e2000"
      },
      {
          "label": "CX-3",
          "value": "cx-3"
      },
      {
          "label": "B2200",
          "value": "b2200"
      },
      {
          "label": "BONGO",
          "value": "bongo"
      },
      {
          "label": "LUCE",
          "value": "luce"
      },
      {
          "label": "CX-8",
          "value": "cx-8"
      },
      {
          "label": "CX-30",
          "value": "cx-30"
      }
  ],
  "ALFA ROMEO": [
      {
          "label": "146",
          "value": "146"
      },
      {
          "label": "147",
          "value": "147"
      },
      {
          "label": "155",
          "value": "155"
      },
      {
          "label": "156",
          "value": "156"
      },
      {
          "label": "158",
          "value": "158"
      },
      {
          "label": "159",
          "value": "159"
      },
      {
          "label": "GT BLACKLINE",
          "value": "gtblackline"
      },
      {
          "label": "BRERA",
          "value": "brera"
      },
      {
          "label": "SPIDER",
          "value": "spider"
      },
      {
          "label": "145",
          "value": "145"
      },
      {
          "label": "GT",
          "value": "gt"
      },
      {
          "label": "MITO",
          "value": "mito"
      },
      {
          "label": "33",
          "value": "33"
      },
      {
          "label": "75",
          "value": "75"
      },
      {
          "label": "164",
          "value": "164"
      },
      {
          "label": "166",
          "value": "166"
      },
      {
          "label": "8C COMPETIZION",
          "value": "8ccompetizion"
      },
      {
          "label": "8C SPIDER",
          "value": "8cspider"
      },
      {
          "label": "ALFASUD",
          "value": "alfasud"
      },
      {
          "label": "ALFETTA",
          "value": "alfetta"
      },
      {
          "label": "ALNA",
          "value": "alna"
      },
      {
          "label": "GIULIA",
          "value": "giulia"
      },
      {
          "label": "GIULIETTA",
          "value": "giulietta"
      },
      {
          "label": "GTV",
          "value": "gtv"
      },
      {
          "label": "MILANO",
          "value": "milano"
      },
      {
          "label": "ZAGATO",
          "value": "zagato"
      },
      {
          "label": "4C",
          "value": "4c"
      },
      {
          "label": "4C SPIDER",
          "value": "4cspider"
      },
      {
          "label": "LANCIA",
          "value": "lancia"
      },
      {
          "label": "2000",
          "value": "2000"
      },
      {
          "label": "STELVIO",
          "value": "stelvio"
      }
  ],
  "ASTON MARTIN": [
      {
          "label": "DB9",
          "value": "db9"
      },
      {
          "label": "RAPIDE",
          "value": "rapide"
      },
      {
          "label": "DB7",
          "value": "db7"
      },
      {
          "label": "DBS",
          "value": "dbs"
      },
      {
          "label": "LAGONDA",
          "value": "lagonda"
      },
      {
          "label": "VANQUISH",
          "value": "vanquish"
      },
      {
          "label": "VANTAGE",
          "value": "vantage"
      },
      {
          "label": "VIRAGE",
          "value": "virage"
      },
      {
          "label": "DB11",
          "value": "db11"
      },
      {
          "label": "DB5",
          "value": "db5"
      },
      {
          "label": "850",
          "value": "850"
      }
  ],
  "AUDI": [
      {
          "label": "A4",
          "value": "a4"
      },
      {
          "label": "A6",
          "value": "a6"
      },
      {
          "label": "A8",
          "value": "a8"
      },
      {
          "label": "TT",
          "value": "tt"
      },
      {
          "label": "Q5",
          "value": "q5"
      },
      {
          "label": "Q7",
          "value": "q7"
      },
      {
          "label": "A1",
          "value": "a1"
      },
      {
          "label": "A3",
          "value": "a3"
      },
      {
          "label": "A5",
          "value": "a5"
      },
      {
          "label": "A7",
          "value": "a7"
      },
      {
          "label": "R8",
          "value": "r8"
      },
      {
          "label": "RS3",
          "value": "rs3"
      },
      {
          "label": "RS4",
          "value": "rs4"
      },
      {
          "label": "RS5",
          "value": "rs5"
      },
      {
          "label": "RS6",
          "value": "rs6"
      },
      {
          "label": "S4",
          "value": "s4"
      },
      {
          "label": "S5",
          "value": "s5"
      },
      {
          "label": "80",
          "value": "80"
      },
      {
          "label": "90",
          "value": "90"
      },
      {
          "label": "100",
          "value": "100"
      },
      {
          "label": "200",
          "value": "200"
      },
      {
          "label": "TTS",
          "value": "tts"
      },
      {
          "label": "TTRS",
          "value": "ttrs"
      },
      {
          "label": "A2",
          "value": "a2"
      },
      {
          "label": "Q3",
          "value": "q3"
      },
      {
          "label": "S3",
          "value": "s3"
      },
      {
          "label": "S6",
          "value": "s6"
      },
      {
          "label": "S8",
          "value": "s8"
      },
      {
          "label": "S7",
          "value": "s7"
      },
      {
          "label": "RS7",
          "value": "rs7"
      },
      {
          "label": "A8 L",
          "value": "a8l"
      },
      {
          "label": "SQ5",
          "value": "sq5"
      },
      {
          "label": "Q2",
          "value": "q2"
      },
      {
          "label": "Q8",
          "value": "q8"
      },
      {
          "label": "SQ7",
          "value": "sq7"
      }
  ],
  "AUSTIN": [
      {
          "label": "MORRIS",
          "value": "morris"
      },
      {
          "label": "1300",
          "value": "1300"
      },
      {
          "label": "A50",
          "value": "a50"
      },
      {
          "label": "MAESTRO",
          "value": "maestro"
      },
      {
          "label": "METRO",
          "value": "metro"
      },
      {
          "label": "MINI",
          "value": "mini"
      },
      {
          "label": "MONTEGO",
          "value": "montego"
      },
      {
          "label": "A35",
          "value": "a35"
      },
      {
          "label": "HEALEY SPRITE",
          "value": "healeysprite"
      }
  ],
  "BENTLEY": [
      {
          "label": "AZURE",
          "value": "azure"
      },
      {
          "label": "CONTINENTAL",
          "value": "continental"
      },
      {
          "label": "CONTINENTAL GT",
          "value": "continentalgt"
      },
      {
          "label": "CONTINENTAL GTC",
          "value": "continentalgtc"
      },
      {
          "label": "CONTINENTAL SUPERSPORTS",
          "value": "continentalsupersports"
      },
      {
          "label": "BROOKLANDS",
          "value": "brooklands"
      },
      {
          "label": "EIGHT",
          "value": "eight"
      },
      {
          "label": "FLYING SPUR",
          "value": "flyingspur"
      },
      {
          "label": "MULSANNE",
          "value": "mulsanne"
      },
      {
          "label": "TURBO",
          "value": "turbo"
      },
      {
          "label": "ARNAGE",
          "value": "arnage"
      },
      {
          "label": "CONTINENTAL GT SPEED",
          "value": "continentalgtspeed"
      },
      {
          "label": "BENTAYGA",
          "value": "bentayga"
      },
      {
          "label": "TURBO R AUTO",
          "value": "turborauto"
      }
  ],
  "BUFORI": [
      {
          "label": "LA JOYA MK",
          "value": "lajoyamk"
      },
      {
          "label": "LA JOYA",
          "value": "lajoya"
      }
  ],
  "CAM": [
      {
          "label": "PLACER-X",
          "value": "placer-x"
      }
  ],
  "CADILLAC": [
      {
          "label": "ALLANTE",
          "value": "allante"
      },
      {
          "label": "CIMARRON",
          "value": "cimarron"
      },
      {
          "label": "CONCOURS",
          "value": "concours"
      },
      {
          "label": "CTS",
          "value": "cts"
      },
      {
          "label": "DEVILLE",
          "value": "deville"
      },
      {
          "label": "DTS",
          "value": "dts"
      },
      {
          "label": "ELDORADO",
          "value": "eldorado"
      },
      {
          "label": "ESCALADE",
          "value": "escalade"
      },
      {
          "label": "FLEETWOOD",
          "value": "fleetwood"
      },
      {
          "label": "LOMOUSINE",
          "value": "lomousine"
      },
      {
          "label": "SEVILE",
          "value": "sevile"
      },
      {
          "label": "SRX",
          "value": "srx"
      },
      {
          "label": "STS",
          "value": "sts"
      },
      {
          "label": "XLR",
          "value": "xlr"
      }
  ],
  "CHANA": [
      {
          "label": "ERA CM8",
          "value": "eracm8"
      },
      {
          "label": "ERA CV6",
          "value": "eracv6"
      },
      {
          "label": "ERA KOMUTER",
          "value": "erakomuter"
      },
      {
          "label": "ERA STAR",
          "value": "erastar"
      }
  ],
  "CHANGAN": [
      {
          "label": "ERA CV6",
          "value": "eracv6"
      },
      {
          "label": "ERA CM8",
          "value": "eracm8"
      },
      {
          "label": "ALSVIN",
          "value": "alsvin"
      }
  ],
  "CHERY": [
      {
          "label": "A520",
          "value": "a520"
      },
      {
          "label": "EASTAR",
          "value": "eastar"
      },
      {
          "label": "TIGGO",
          "value": "tiggo"
      },
      {
          "label": "A160",
          "value": "a160"
      },
      {
          "label": "QQ",
          "value": "qq"
      },
      {
          "label": "MAXIME",
          "value": "maxime"
      },
      {
          "label": "TRANSCOM",
          "value": "transcom"
      },
      {
          "label": "B240",
          "value": "b240"
      }
  ],
  "CHEVROLET": [
      {
          "label": "AVEO",
          "value": "aveo"
      },
      {
          "label": "OPTRA",
          "value": "optra"
      },
      {
          "label": "CAPTIVA",
          "value": "captiva"
      },
      {
          "label": "CRUZE",
          "value": "cruze"
      },
      {
          "label": "CAMARO",
          "value": "camaro"
      },
      {
          "label": "COLORADO",
          "value": "colorado"
      },
      {
          "label": "NABIRA",
          "value": "nabira"
      },
      {
          "label": "OPTRA 5",
          "value": "optra5"
      },
      {
          "label": "ASTRO",
          "value": "astro"
      },
      {
          "label": "AVALANCE",
          "value": "avalance"
      },
      {
          "label": "BELAIR",
          "value": "belair"
      },
      {
          "label": "BERETA",
          "value": "bereta"
      },
      {
          "label": "BLAZER",
          "value": "blazer"
      },
      {
          "label": "BLAZER SILVERRADO",
          "value": "blazersilverrado"
      },
      {
          "label": "C-10",
          "value": "c-10"
      },
      {
          "label": "C-1500",
          "value": "c-1500"
      },
      {
          "label": "C-2500",
          "value": "c-2500"
      },
      {
          "label": "C-3500",
          "value": "c-3500"
      },
      {
          "label": "CAPRICE",
          "value": "caprice"
      },
      {
          "label": "CAVALIER",
          "value": "cavalier"
      },
      {
          "label": "CELEBRITY",
          "value": "celebrity"
      },
      {
          "label": "CHEVELLE",
          "value": "chevelle"
      },
      {
          "label": "CHEVY",
          "value": "chevy"
      },
      {
          "label": "CHEVYVAN",
          "value": "chevyvan"
      },
      {
          "label": "CITATION",
          "value": "citation"
      },
      {
          "label": "CIVET",
          "value": "civet"
      },
      {
          "label": "CORSIA",
          "value": "corsia"
      },
      {
          "label": "CORVETTE",
          "value": "corvette"
      },
      {
          "label": "ELCAMINO",
          "value": "elcamino"
      },
      {
          "label": "EXPRESS",
          "value": "express"
      },
      {
          "label": "HHR",
          "value": "hhr"
      },
      {
          "label": "IMPARA",
          "value": "impara"
      },
      {
          "label": "K10",
          "value": "k10"
      },
      {
          "label": "K-2500",
          "value": "k-2500"
      },
      {
          "label": "K-3500",
          "value": "k-3500"
      },
      {
          "label": "K-5",
          "value": "k-5"
      },
      {
          "label": "LUMINA",
          "value": "lumina"
      },
      {
          "label": "MALIBU",
          "value": "malibu"
      },
      {
          "label": "MONTECARLO",
          "value": "montecarlo"
      },
      {
          "label": "MONZA",
          "value": "monza"
      },
      {
          "label": "MW",
          "value": "mw"
      },
      {
          "label": "NOVA",
          "value": "nova"
      },
      {
          "label": "S-10",
          "value": "s-10"
      },
      {
          "label": "S-10 BLAZER",
          "value": "s-10blazer"
      },
      {
          "label": "SILVERADO",
          "value": "silverado"
      },
      {
          "label": "SONIC",
          "value": "sonic"
      },
      {
          "label": "SSR",
          "value": "ssr"
      },
      {
          "label": "SUBURBAN",
          "value": "suburban"
      },
      {
          "label": "TAHOE",
          "value": "tahoe"
      },
      {
          "label": "TRAILBLAZER",
          "value": "trailblazer"
      },
      {
          "label": "TRAVERSE",
          "value": "traverse"
      },
      {
          "label": "TYPHOON",
          "value": "typhoon"
      },
      {
          "label": "VEGA",
          "value": "vega"
      },
      {
          "label": "VENTURE",
          "value": "venture"
      },
      {
          "label": "ORLANDO",
          "value": "orlando"
      },
      {
          "label": "SPARK",
          "value": "spark"
      }
  ],
  "CHRYSLER": [
      {
          "label": "300",
          "value": "300"
      },
      {
          "label": "ACCLAIM",
          "value": "acclaim"
      },
      {
          "label": "ASPEN",
          "value": "aspen"
      },
      {
          "label": "CORDOBA",
          "value": "cordoba"
      },
      {
          "label": "CROSSFIRE",
          "value": "crossfire"
      },
      {
          "label": "DAYTONA",
          "value": "daytona"
      },
      {
          "label": "E CLASS",
          "value": "eclass"
      },
      {
          "label": "GRAND VOYAGER",
          "value": "grandvoyager"
      },
      {
          "label": "IMPERIAL",
          "value": "imperial"
      },
      {
          "label": "LE BARON",
          "value": "lebaron"
      },
      {
          "label": "LHS NEON",
          "value": "lhsneon"
      },
      {
          "label": "NEWPORT",
          "value": "newport"
      },
      {
          "label": "NEW YORKER",
          "value": "newyorker"
      },
      {
          "label": "PACIFICA",
          "value": "pacifica"
      },
      {
          "label": "PT CRUISER",
          "value": "ptcruiser"
      },
      {
          "label": "SIMCA",
          "value": "simca"
      },
      {
          "label": "STRATUS",
          "value": "stratus"
      },
      {
          "label": "TOWN & COUNTRY",
          "value": "towncountry"
      },
      {
          "label": "VIPER",
          "value": "viper"
      },
      {
          "label": "VISION",
          "value": "vision"
      },
      {
          "label": "VOYAGER",
          "value": "voyager"
      }
  ],
  "CITROEN": [
      {
          "label": "GRAND C4 PICASSO",
          "value": "grandc4picasso"
      },
      {
          "label": "C6",
          "value": "c6"
      },
      {
          "label": "ZX",
          "value": "zx"
      },
      {
          "label": "EVASION",
          "value": "evasion"
      },
      {
          "label": "XSARA",
          "value": "xsara"
      },
      {
          "label": "C3",
          "value": "c3"
      },
      {
          "label": "C4",
          "value": "c4"
      },
      {
          "label": "DS3",
          "value": "ds3"
      },
      {
          "label": "DS4",
          "value": "ds4"
      },
      {
          "label": "XANTIA",
          "value": "xantia"
      },
      {
          "label": "C2",
          "value": "c2"
      },
      {
          "label": "C8",
          "value": "c8"
      },
      {
          "label": "CHANSON",
          "value": "chanson"
      },
      {
          "label": "CX",
          "value": "cx"
      },
      {
          "label": "DIANE",
          "value": "diane"
      },
      {
          "label": "GS",
          "value": "gs"
      },
      {
          "label": "GSA",
          "value": "gsa"
      },
      {
          "label": "JUMPY",
          "value": "jumpy"
      },
      {
          "label": "PICASSO",
          "value": "picasso"
      },
      {
          "label": "SAXO",
          "value": "saxo"
      },
      {
          "label": "SM",
          "value": "sm"
      },
      {
          "label": "VISA",
          "value": "visa"
      },
      {
          "label": "XM",
          "value": "xm"
      },
      {
          "label": "DS5",
          "value": "ds5"
      },
      {
          "label": "BERLINGO",
          "value": "berlingo"
      },
      {
          "label": "C5",
          "value": "c5"
      },
      {
          "label": "BX",
          "value": "bx"
      },
      {
          "label": "GRAND C4 SPACETOURER",
          "value": "grandc4spacetourer"
      }
  ],
  "DAIHATSU": [
      {
          "label": "AURA",
          "value": "aura"
      },
      {
          "label": "CHARADE",
          "value": "charade"
      },
      {
          "label": "CHARMANT",
          "value": "charmant"
      },
      {
          "label": "ESPRI",
          "value": "espri"
      },
      {
          "label": "MIRA",
          "value": "mira"
      },
      {
          "label": "ROCKY",
          "value": "rocky"
      },
      {
          "label": "ASCENT",
          "value": "ascent"
      },
      {
          "label": "ALTIS",
          "value": "altis"
      },
      {
          "label": "APPLAUSE",
          "value": "applause"
      },
      {
          "label": "ATRAI",
          "value": "atrai"
      },
      {
          "label": "BEGO",
          "value": "bego"
      },
      {
          "label": "BOON",
          "value": "boon"
      },
      {
          "label": "COMPAGNO",
          "value": "compagno"
      },
      {
          "label": "CONSORTE",
          "value": "consorte"
      },
      {
          "label": "COO",
          "value": "coo"
      },
      {
          "label": "COPEN",
          "value": "copen"
      },
      {
          "label": "CUORE",
          "value": "cuore"
      },
      {
          "label": "ESSE",
          "value": "esse"
      },
      {
        "label": "GRAN MAX",
        "value": "granmax"
      },
      {
          "label": "LEEZA",
          "value": "leeza"
      },
      {
          "label": "MAX",
          "value": "max"
      },
      {
          "label": "MIDGET",
          "value": "midget"
      },
      {
          "label": "MOVE",
          "value": "move"
      },
      {
          "label": "NAKED",
          "value": "naked"
      },
      {
          "label": "OPTI",
          "value": "opti"
      },
      {
          "label": "PYZAR",
          "value": "pyzar"
      },
      {
          "label": "RUGGER",
          "value": "rugger"
      },
      {
          "label": "SONICA",
          "value": "sonica"
      },
      {
          "label": "STORIA",
          "value": "storia"
      },
      {
          "label": "TANTO",
          "value": "tanto"
      },
      {
          "label": "TERIOS",
          "value": "terios"
      },
      {
          "label": "YRV",
          "value": "yrv"
      },
      {
          "label": "FEROZA",
          "value": "feroza"
      },
      {
          "label": "HIJET",
          "value": "hijet"
      },
      {
          "label": "HIJET MAXX",
          "value": "hijetmaxx"
      },
      {
          "label": "TAFT",
          "value": "taft"
      }
  ],
  "DODGE": [
      {
          "label": "RAM",
          "value": "ram"
      },
      {
          "label": "NITRO",
          "value": "nitro"
      },
      {
          "label": "CHALLENGER",
          "value": "challenger"
      },
      {
          "label": "CHARGER",
          "value": "charger"
      },
      {
          "label": "MAGNUM",
          "value": "magnum"
      },
      {
          "label": "VIPER",
          "value": "viper"
      }
  ],
  "FERRARI": [
      {
          "label": "360",
          "value": "360"
      },
      {
          "label": "430",
          "value": "430"
      },
      {
          "label": "458",
          "value": "458"
      },
      {
          "label": "599",
          "value": "599"
      },
      {
          "label": "612",
          "value": "612"
      },
      {
          "label": "F355",
          "value": "f355"
      },
      {
          "label": "308",
          "value": "308"
      },
      {
          "label": "328",
          "value": "328"
      },
      {
          "label": "348",
          "value": "348"
      },
      {
          "label": "355",
          "value": "355"
      },
      {
          "label": "365",
          "value": "365"
      },
      {
          "label": "400",
          "value": "400"
      },
      {
          "label": "412",
          "value": "412"
      },
      {
          "label": "512",
          "value": "512"
      },
      {
          "label": "550",
          "value": "550"
      },
      {
          "label": "575",
          "value": "575"
      },
      {
          "label": "CALIFORNIA",
          "value": "california"
      },
      {
          "label": "CHALLENGE STRADALE",
          "value": "challengestradale"
      },
      {
          "label": "DINO",
          "value": "dino"
      },
      {
          "label": "ENZO",
          "value": "enzo"
      },
      {
          "label": "MONDIAL",
          "value": "mondial"
      },
      {
          "label": "SUPERAMERICA",
          "value": "superamerica"
      },
      {
          "label": "TESTAROSSA",
          "value": "testarossa"
      },
      {
          "label": "488",
          "value": "488"
      },
      {
          "label": "F12",
          "value": "f12"
      },
      {
          "label": "FF",
          "value": "ff"
      },
      {
          "label": "328 GTS",
          "value": "328gts"
      }
  ],
  "FIAT": [
      {
          "label": "124",
          "value": "124"
      },
      {
          "label": "127",
          "value": "127"
      },
      {
          "label": "128",
          "value": "128"
      },
      {
          "label": "500",
          "value": "500"
      },
      {
          "label": "UNO",
          "value": "uno"
      },
      {
          "label": "PUNTO",
          "value": "punto"
      },
      {
          "label": "BRAVA",
          "value": "brava"
      },
      {
          "label": "PANDA",
          "value": "panda"
      },
      {
          "label": "LADA NIVA",
          "value": "ladaniva"
      },
      {
          "label": "ULYSSE",
          "value": "ulysse"
      },
      {
          "label": "MAREA",
          "value": "marea"
      },
      {
          "label": "MULTIPLA",
          "value": "multipla"
      },
      {
          "label": "126",
          "value": "126"
      },
      {
          "label": "131",
          "value": "131"
      },
      {
          "label": "132",
          "value": "132"
      },
      {
          "label": "850",
          "value": "850"
      },
      {
          "label": "ARGENTA",
          "value": "argenta"
      },
      {
          "label": "BARCHETTA",
          "value": "barchetta"
      },
      {
          "label": "BRAVISSIMO",
          "value": "bravissimo"
      },
      {
          "label": "CINQUECENTO",
          "value": "cinquecento"
      },
      {
          "label": "COUPE",
          "value": "coupe"
      },
      {
          "label": "CROMA",
          "value": "croma"
      },
      {
          "label": "DINO",
          "value": "dino"
      },
      {
          "label": "GRANDE PUNTO",
          "value": "grandepunto"
      },
      {
          "label": "REGATA",
          "value": "regata"
      },
      {
          "label": "RITMO",
          "value": "ritmo"
      },
      {
          "label": "TEMPRA",
          "value": "tempra"
      },
      {
          "label": "TIPO",
          "value": "tipo"
      },
      {
          "label": "BRAVO",
          "value": "bravo"
      },
      {
          "label": "ABARTH",
          "value": "abarth"
      },
      {
          "label": "600",
          "value": "600"
      },
      {
          "label": "DOBLO",
          "value": "doblo"
      },
      {
          "label": "STILO",
          "value": "stilo"
      },
      {
          "label": "125",
          "value": "125"
      }
  ],
  "FODAY": [
      {
          "label": "NHQ1029",
          "value": "nhq1029"
      }
  ],
  "FORD": [
      {
          "label": "EVEREST",
          "value": "everest"
      },
      {
          "label": "ESCAPE",
          "value": "escape"
      },
      {
          "label": "RANGER",
          "value": "ranger"
      },
      {
          "label": "FOCUS",
          "value": "focus"
      },
      {
          "label": "LASER",
          "value": "laser"
      },
      {
          "label": "TELSTAR",
          "value": "telstar"
      },
      {
          "label": "LYNX",
          "value": "lynx"
      },
      {
          "label": "ESCORT",
          "value": "escort"
      },
      {
          "label": "MONDEO",
          "value": "mondeo"
      },
      {
          "label": "FIESTA",
          "value": "fiesta"
      },
      {
          "label": "S-MAX",
          "value": "s-max"
      },
      {
          "label": "AEROSTAR",
          "value": "aerostar"
      },
      {
          "label": "BRONCO",
          "value": "bronco"
      },
      {
          "label": "CAPRI",
          "value": "capri"
      },
      {
          "label": "CONTOUR",
          "value": "contour"
      },
      {
          "label": "CORTINA",
          "value": "cortina"
      },
      {
          "label": "CROWN VICTORIA",
          "value": "crownvictoria"
      },
      {
          "label": "ECONOLINE",
          "value": "econoline"
      },
      {
          "label": "ECONOVAN",
          "value": "econovan"
      },
      {
          "label": "EDGE",
          "value": "edge"
      },
      {
          "label": "EXCURSION",
          "value": "excursion"
      },
      {
          "label": "EXPEDITION",
          "value": "expedition"
      },
      {
          "label": "EXPLORER",
          "value": "explorer"
      },
      {
          "label": "FAIRLANE",
          "value": "fairlane"
      },
      {
          "label": "FAIRMONT",
          "value": "fairmont"
      },
      {
          "label": "FALCON",
          "value": "falcon"
      },
      {
          "label": "FLEX",
          "value": "flex"
      },
      {
          "label": "KUGA",
          "value": "kuga"
      },
      {
          "label": "MUSTANG",
          "value": "mustang"
      },
      {
          "label": "PINT",
          "value": "pint"
      },
      {
          "label": "ECOSPORT",
          "value": "ecosport"
      },
      {
          "label": "FOCUS ST",
          "value": "focusst"
      },
      {
          "label": "ANGLIA",
          "value": "anglia"
      },
      {
          "label": "TRANSIT",
          "value": "transit"
      },
      {
          "label": "SPECTRON",
          "value": "spectron"
      },
      {
          "label": "COURIER",
          "value": "courier"
      },
      {
          "label": "GALAXIE",
          "value": "galaxie"
      },
      {
          "label": "F150",
          "value": "f150"
      }
  ],
  "FOTON": [
      {
          "label": "VIEW",
          "value": "view"
      },
      {
          "label": "BISON SAVANNA",
          "value": "bisonsavanna"
      }
  ],
  "GOLDEN DRAGON": [
      {
          "label": "RK COMMUTER",
          "value": "rkcommuter"
      },
      {
          "label": "X5",
          "value": "x5"
      }
  ],
  "GREAT WALL": [
      {
          "label": "HAVAL",
          "value": "haval"
      },
      {
          "label": "WINGLE",
          "value": "wingle"
      },
      {
          "label": "M4",
          "value": "m4"
      },
      {
          "label": "C30",
          "value": "c30"
      },
      {
          "label": "H3",
          "value": "h3"
      },
      {
          "label": "H5",
          "value": "h5"
      },
      {
          "label": "H6",
          "value": "h6"
      },
      {
          "label": "C50",
          "value": "c50"
      },
      {
          "label": "C20R",
          "value": "c20r"
      }
  ],
  "HAVAL": [
      {
          "label": "H1",
          "value": "h1"
      },
      {
          "label": "H2",
          "value": "h2"
      },
      {
          "label": "H9",
          "value": "h9"
      }
  ],
  "HUMMER": [
      {
          "label": "H3",
          "value": "h3"
      },
      {
          "label": "H1",
          "value": "h1"
      },
      {
          "label": "H2",
          "value": "h2"
      }
  ],
  "HYUNDAI": [
      {
          "label": "GETZ",
          "value": "getz"
      },
      {
          "label": "KONA",
          "value": "kona"
      },
      {
          "label": "MATRIX",
          "value": "matrix"
      },
      {
          "label": "TRAJET",
          "value": "trajet"
      },
      {
          "label": "COUPE",
          "value": "coupe"
      },
      {
          "label": "TUCSON",
          "value": "tucson"
      },
      {
          "label": "XG250",
          "value": "xg250"
      },
      {
          "label": "NF",
          "value": "nf"
      },
      {
          "label": "AZERA",
          "value": "azera"
      },
      {
          "label": "ACCENT",
          "value": "accent"
      },
      {
          "label": "ELANTRA",
          "value": "elantra"
      },
      {
          "label": "SONATA",
          "value": "sonata"
      },
      {
          "label": "I10",
          "value": "i10"
      },
      {
          "label": "I20",
          "value": "i20"
      },
      {
          "label": "I30",
          "value": "i30"
      },
      {
          "label": "ATOS",
          "value": "atos"
      },
      {
          "label": "GRANDEUR XG",
          "value": "grandeurxg"
      },
      {
          "label": "TERRACAN",
          "value": "terracan"
      },
      {
          "label": "SANTA FE",
          "value": "santafe"
      },
      {
          "label": "SCOUPE",
          "value": "scoupe"
      },
      {
          "label": "AMICA",
          "value": "amica"
      },
      {
          "label": "AVANTE",
          "value": "avante"
      },
      {
          "label": "EXCEL",
          "value": "excel"
      },
      {
          "label": "GALLOPER",
          "value": "galloper"
      },
      {
          "label": "GRAND STAREX",
          "value": "grandstarex"
      },
      {
          "label": "I40",
          "value": "i40"
      },
      {
          "label": "I800",
          "value": "i800"
      },
      {
          "label": "IX20",
          "value": "ix20"
      },
      {
          "label": "IX35",
          "value": "ix35"
      },
      {
          "label": "JM",
          "value": "jm"
      },
      {
          "label": "LANTRA",
          "value": "lantra"
      },
      {
          "label": "PONY",
          "value": "pony"
      },
      {
          "label": "SCOOP",
          "value": "scoop"
      },
      {
          "label": "STAREX",
          "value": "starex"
      },
      {
          "label": "STELLAR",
          "value": "stellar"
      },
      {
          "label": "TB",
          "value": "tb"
      },
      {
          "label": "X2",
          "value": "x2"
      },
      {
          "label": "XG",
          "value": "xg"
      },
      {
          "label": "VELOSTER",
          "value": "veloster"
      },
      {
          "label": "IONIQ",
          "value": "ioniq"
      },
      {
          "label": "GENESIS",
          "value": "genesis"
      }
  ],
  "INFINITI": [
      {
          "label": "G COUPE",
          "value": "gcoupe"
      },
      {
          "label": "M",
          "value": "m"
      },
      {
          "label": "M HYBRID",
          "value": "mhybrid"
      },
      {
          "label": "EX",
          "value": "ex"
      },
      {
          "label": "FX",
          "value": "fx"
      },
      {
          "label": "Q50",
          "value": "q50"
      },
      {
          "label": "QX60",
          "value": "qx60"
      },
      {
          "label": "QX70",
          "value": "qx70"
      },
      {
          "label": "QX50",
          "value": "qx50"
      },
      {
          "label": "QX80",
          "value": "qx80"
      },
      {
          "label": "Q60",
          "value": "q60"
      },
      {
          "label": "Q70",
          "value": "q70"
      }
  ],
  "INOKOM": [
      {
          "label": "I10",
          "value": "i10"
      },
      {
          "label": "ATOS",
          "value": "atos"
      },
      {
          "label": "GETZ",
          "value": "getz"
      },
      {
        "label": "LORIMAS",
        "value": "lorimas"
      },
      {
          "label": "MATRIX",
          "value": "matrix"
      },
      {
          "label": "SANTA",
          "value": "santa"
      },
      {
          "label": "SANTA FE",
          "value": "santafe"
      },
      {
          "label": "ELANTRA",
          "value": "elantra"
      },
      {
          "label": "PERMAS",
          "value": "permas"
      }
  ],
  "ISUZU": [
      {
          "label": "D-MAX",
          "value": "dmax"
      },
      {
          "label": "TROOPER",
          "value": "trooper"
      },
      {
          "label": "INVADER",
          "value": "invader"
      },
      {
          "label": "RODEO",
          "value": "rodeo"
      },
      {
          "label": "VEHICROSS",
          "value": "vehicross"
      },
      {
          "label": "117 COUPE",
          "value": "117coupe"
      },
      {
          "label": "ASKA",
          "value": "aska"
      },
      {
          "label": "BELLET",
          "value": "bellet"
      },
      {
          "label": "BIGHORN",
          "value": "bighorn"
      },
      {
          "label": "COMO",
          "value": "como"
      },
      {
          "label": "FASTER",
          "value": "faster"
      },
      {
          "label": "FILLY",
          "value": "filly"
      },
      {
          "label": "FLORIAN",
          "value": "florian"
      },
      {
          "label": "FORWARD",
          "value": "forward"
      },
      {
          "label": "F SERIES",
          "value": "fseries"
      },
      {
          "label": "GEMINETT",
          "value": "geminett"
      },
      {
          "label": "GEMINI",
          "value": "gemini"
      },
      {
          "label": "HILLMAN MINX",
          "value": "hillmanminx"
      },
      {
          "label": "MU",
          "value": "mu"
      },
      {
          "label": "PIAZZA",
          "value": "piazza"
      },
      {
          "label": "WIZARD",
          "value": "wizard"
      },
      {
          "label": "ELF",
          "value": "elf"
      },
      {
          "label": "KBD25",
          "value": "kbd25"
      },
      {
          "label": "MU-X",
          "value": "mu-x"
      },
      {
          "label": "CITATION",
          "value": "citation"
      },
      {
          "label": "FARGO",
          "value": "fargo"
      }
  ],
  "JAGUAR": [
      {
          "label": "X-TYPE",
          "value": "x-type"
      },
      {
          "label": "XF",
          "value": "xf"
      },
      {
          "label": "XJ",
          "value": "xj"
      },
      {
          "label": "DAIMLER",
          "value": "daimler"
      },
      {
          "label": "XK",
          "value": "xk"
      },
      {
          "label": "XKR",
          "value": "xkr"
      },
      {
          "label": "XJ6",
          "value": "xj6"
      },
      {
          "label": "XJ12",
          "value": "xj12"
      },
      {
          "label": "XJ8",
          "value": "xj8"
      },
      {
          "label": "S-TYPE",
          "value": "s-type"
      },
      {
          "label": "420",
          "value": "420"
      },
      {
          "label": "240",
          "value": "240"
      },
      {
          "label": "340",
          "value": "340"
      },
      {
          "label": "XFR",
          "value": "xfr"
      },
      {
          "label": "XJR",
          "value": "xjr"
      },
      {
          "label": "XJS",
          "value": "xjs"
      },
      {
          "label": "XJ18",
          "value": "xj18"
      },
      {
          "label": "F-TYPE",
          "value": "f-type"
      },
      {
          "label": "F-TYPE S",
          "value": "f-types"
      },
      {
          "label": "XJL",
          "value": "xjl"
      },
      {
          "label": "F-PACE",
          "value": "f-pace"
      },
      {
          "label": "SOVEREIGN",
          "value": "sovereign"
      },
      {
          "label": "XE",
          "value": "xe"
      },
      {
          "label": "E-TYPE",
          "value": "e-type"
      },
      {
          "label": "MK9",
          "value": "mk9"
      }
  ],
  "JEEP": [
      {
          "label": "CHEROKEE",
          "value": "cherokee"
      },
      {
          "label": "GRAND CHEROKEE",
          "value": "grandcherokee"
      },
      {
          "label": "WRANGLER",
          "value": "wrangler"
      },
      {
          "label": "COMMANDER",
          "value": "commander"
      },
      {
          "label": "COMPASS",
          "value": "compass"
      },
      {
          "label": "LIBERTY",
          "value": "liberty"
      },
      {
          "label": "PATRIOT",
          "value": "patriot"
      },
      {
          "label": "RENEGADE",
          "value": "renegade"
      },
      {
          "label": "WILLYS",
          "value": "willys"
      }
  ],
  "JOYLONG": [
      {
          "label": "ERA KOMUTER",
          "value": "erakomuter"
      },
      {
          "label": "MINI VAN",
          "value": "minivan"
      }
  ],
  "KIA": [
      {
          "label": "PICANTO",
          "value": "picanto"
      },
      {
          "label": "RIO",
          "value": "rio"
      },
      {
          "label": "SPECTRA",
          "value": "spectra"
      },
      {
          "label": "OPTIMA",
          "value": "optima"
      },
      {
          "label": "SPORTAGE",
          "value": "sportage"
      },
      {
          "label": "RONDO",
          "value": "rondo"
      },
      {
          "label": "FORTE",
          "value": "forte"
      },
      {
          "label": "FORTE COUP",
          "value": "fortecoup"
      },
      {
          "label": "CARENS",
          "value": "carens"
      },
      {
          "label": "SORENTO",
          "value": "sorento"
      },
      {
          "label": "CARNIVAL",
          "value": "carnival"
      },
      {
          "label": "SEPHIA",
          "value": "sephia"
      },
      {
          "label": "OPIRUS",
          "value": "opirus"
      },
      {
          "label": "CEED",
          "value": "ceed"
      },
      {
          "label": "CERATO",
          "value": "cerato"
      },
      {
          "label": "CITRA",
          "value": "citra"
      },
      {
          "label": "CLARUS",
          "value": "clarus"
      },
      {
          "label": "ELAN",
          "value": "elan"
      },
      {
          "label": "FESTIVA",
          "value": "festiva"
      },
      {
          "label": "FIESTA",
          "value": "fiesta"
      },
      {
          "label": "MAGENTIS",
          "value": "magentis"
      },
      {
          "label": "MENTOR",
          "value": "mentor"
      },
      {
          "label": "PRIDE",
          "value": "pride"
      },
      {
          "label": "VIGATO",
          "value": "vigato"
      },
      {
          "label": "PREGIO",
          "value": "pregio"
      },
      {
          "label": "ROCSTA",
          "value": "rocsta"
      },
      {
          "label": "STINGER",
          "value": "stinger"
      }
  ],
  "LMG": [
      {
          "label": "LMS31SLE",
          "value": "lms31sle"
      }
  ],
  "LAMBORGHINI": [
      {
          "label": "GALLARDO",
          "value": "gallardo"
      },
      {
          "label": "MURCIELAGO",
          "value": "murcielago"
      },
      {
          "label": "AVENTADOR",
          "value": "aventador"
      },
      {
          "label": "COUNTACH",
          "value": "countach"
      },
      {
          "label": "DIABLO",
          "value": "diablo"
      },
      {
          "label": "JALPA",
          "value": "jalpa"
      },
      {
          "label": "LM002",
          "value": "lm002"
      },
      {
          "label": "SUPERLEGGERA",
          "value": "superleggera"
      },
      {
          "label": "ESPADA",
          "value": "espada"
      },
      {
          "label": "HURACAN",
          "value": "huracan"
      },
      {
          "label": "URUS",
          "value": "urus"
      }
  ],
  "LAND ROVER": [
      {
          "label": "DEFENDER",
          "value": "defender"
      },
      {
          "label": "FREELANDER",
          "value": "freelander"
      },
      {
          "label": "DISCOVERY",
          "value": "discovery"
      },
      {
          "label": "RANGE ROVER",
          "value": "rangerover"
      },
      {
          "label": "RANGE ROVER SPORT",
          "value": "rangeroversport"
      },
      {
          "label": "RANGE ROVER EVOQUE",
          "value": "rangeroverevoque"
      },
      {
          "label": "RANGE ROVER VOGUE",
          "value": "rangerovervogue"
      },
      {
          "label": "DISCOVERY SPORT",
          "value": "discoverysport"
      },
      {
          "label": "RANGE ROVER VELAR",
          "value": "rangerovervelar"
      }
  ],
  "LEXUS": [
      {
          "label": "IS 250",
          "value": "is250"
      },
      {
          "label": "RX 350",
          "value": "rx350"
      },
      {
          "label": "GS 300",
          "value": "gs300"
      },
      {
          "label": "LS 460",
          "value": "ls460"
      },
      {
          "label": "CALSIOR",
          "value": "calsior"
      },
      {
          "label": "CT HYBRID",
          "value": "cthybrid"
      },
      {
          "label": "IS F",
          "value": "isf"
      },
      {
          "label": "LS HYBRID",
          "value": "lshybrid"
      },
      {
          "label": "LX",
          "value": "lx"
      },
      {
          "label": "RX HYBRID",
          "value": "rxhybrid"
      },
      {
          "label": "CT200",
          "value": "ct200"
      },
      {
          "label": "ES300",
          "value": "es300"
      },
      {
          "label": "ES330",
          "value": "es330"
      },
      {
          "label": "GS250",
          "value": "gs250"
      },
      {
          "label": "GS300",
          "value": "gs300"
      },
      {
          "label": "GS350",
          "value": "gs350"
      },
      {
          "label": "GS400",
          "value": "gs400"
      },
      {
          "label": "GS430",
          "value": "gs430"
      },
      {
          "label": "GS450",
          "value": "gs450"
      },
      {
          "label": "GS460",
          "value": "gs460"
      },
      {
          "label": "GX470",
          "value": "gx470"
      },
      {
          "label": "IS 350",
          "value": "is350"
      },
      {
          "label": "LS 400",
          "value": "ls400"
      },
      {
          "label": "LS 430",
          "value": "ls430"
      },
      {
          "label": "LS 600",
          "value": "ls600"
      },
      {
          "label": "LX 450",
          "value": "lx450"
      },
      {
          "label": "LX 470",
          "value": "lx470"
      },
      {
          "label": "LX 570",
          "value": "lx570"
      },
      {
          "label": "RX",
          "value": "rx"
      },
      {
          "label": "RX 330",
          "value": "rx330"
      },
      {
          "label": "RX 400",
          "value": "rx400"
      },
      {
          "label": "RX 450",
          "value": "rx450"
      },
      {
          "label": "SC300",
          "value": "sc300"
      },
      {
          "label": "SC400",
          "value": "sc400"
      },
      {
          "label": "SC430",
          "value": "sc430"
      },
      {
          "label": "GS HYBRID",
          "value": "gshybrid"
      },
      {
          "label": "IS HYBRID",
          "value": "ishybrid"
      },
      {
          "label": "ES250",
          "value": "es250"
      },
      {
          "label": "NX200T",
          "value": "nx200t"
      },
      {
          "label": "NX F SPORT",
          "value": "nxfsport"
      },
      {
          "label": "NX HYBRID",
          "value": "nxhybrid"
      },
      {
          "label": "CT F SPORT",
          "value": "ctfsport"
      },
      {
          "label": "RC350",
          "value": "rc350"
      },
      {
          "label": "RC F",
          "value": "rcf"
      },
      {
          "label": "IS250 F SPORT",
          "value": "is250fsport"
      },
      {
          "label": "IS 200",
          "value": "is200"
      },
      {
          "label": "RX 350 F SPORT",
          "value": "rx350fsport"
      },
      {
          "label": "RX 200T",
          "value": "rx200t"
      },
      {
          "label": "GS F",
          "value": "gsf"
      },
      {
          "label": "GS200",
          "value": "gs200"
      },
      {
          "label": "RX270",
          "value": "rx270"
      },
      {
          "label": "RX300",
          "value": "rx300"
      },
      {
          "label": "IS 300",
          "value": "is300"
      },
      {
          "label": "RX 300",
          "value": "rx300"
      },
      {
          "label": "NX 300",
          "value": "nx300"
      },
      {
          "label": "LC 500",
          "value": "lc500"
      },
      {
          "label": "LS 500",
          "value": "ls500"
      },
      {
          "label": "UX200",
          "value": "ux200"
      }
  ],
  "LOTUS": [
      {
          "label": "ELISE",
          "value": "elise"
      },
      {
          "label": "EUROPA",
          "value": "europa"
      },
      {
          "label": "EXIGE",
          "value": "exige"
      },
      {
          "label": "340R",
          "value": "340r"
      },
      {
          "label": "ELAN",
          "value": "elan"
      },
      {
          "label": "ESPRIT",
          "value": "esprit"
      },
      {
          "label": "EVORA",
          "value": "evora"
      },
      {
          "label": "EXCEL",
          "value": "excel"
      },
      {
          "label": "SUPER SEVEN",
          "value": "superseven"
      }
  ],
  "MG": [
      {
          "label": "F",
          "value": "f"
      },
      {
          "label": "TF",
          "value": "tf"
      },
      {
          "label": "ZT",
          "value": "zt"
      },
      {
          "label": "MAESTRO",
          "value": "maestro"
      },
      {
          "label": "METRO",
          "value": "metro"
      },
      {
          "label": "MGA",
          "value": "mga"
      },
      {
          "label": "MGB",
          "value": "mgb"
      },
      {
          "label": "MGF",
          "value": "mgf"
      },
      {
          "label": "MIDGET",
          "value": "midget"
      },
      {
          "label": "MONTEGO",
          "value": "montego"
      },
      {
          "label": "RV 8",
          "value": "rv8"
      }
  ],
  "MINI": [
      {
          "label": "COOPER",
          "value": "cooper"
      },
      {
          "label": "COOPER S",
          "value": "coopers"
      },
      {
          "label": "COOPER CLUBMAN",
          "value": "cooperclubman"
      },
      {
          "label": "COOPER S CLUBMAN",
          "value": "coopersclubman"
      },
      {
          "label": "COUNTRYMAN",
          "value": "countryman"
      },
      {
          "label": "COUNTRYMAN S",
          "value": "countrymans"
      },
      {
          "label": "JOHN COOPER WORKS",
          "value": "johncooperworks"
      },
      {
          "label": "PICKUP (CLASSIC)",
          "value": "pickupclassic"
      },
      {
          "label": "ONE",
          "value": "one"
      },
      {
          "label": "COOPER CABRIO",
          "value": "coopercabrio"
      },
      {
          "label": "PACEMAN",
          "value": "paceman"
      },
      {
          "label": "CLUBMAN DLX",
          "value": "clubmandlx"
      }
  ],
  "MAHINDRA": [
      {
          "label": "SCORPIO",
          "value": "scorpio"
      },
      {
          "label": "CJ",
          "value": "cj"
      },
      {
          "label": "MARKMAN",
          "value": "markman"
      }
  ],
  "MASERATI": [
      {
          "label": "GRANTURISMO",
          "value": "granturismo"
      },
      {
          "label": "GRANCABRIO",
          "value": "grancabrio"
      },
      {
          "label": "QUATTROPORTE",
          "value": "quattroporte"
      },
      {
          "label": "222",
          "value": "222"
      },
      {
          "label": "228",
          "value": "228"
      },
      {
          "label": "3200GT",
          "value": "3200gt"
      },
      {
          "label": "430",
          "value": "430"
      },
      {
          "label": "BITURBO",
          "value": "biturbo"
      },
      {
          "label": "COUPE",
          "value": "coupe"
      },
      {
          "label": "GHIBLI",
          "value": "ghibli"
      },
      {
          "label": "GRAN SPORT",
          "value": "gransport"
      },
      {
          "label": "KARIF",
          "value": "karif"
      },
      {
          "label": "KHAMSIN",
          "value": "khamsin"
      },
      {
          "label": "KYALAMI",
          "value": "kyalami"
      },
      {
          "label": "MERAK",
          "value": "merak"
      },
      {
          "label": "ROYAL",
          "value": "royal"
      },
      {
          "label": "SHAMAL",
          "value": "shamal"
      },
      {
          "label": "SPIDER ZAGATO",
          "value": "spiderzagato"
      },
      {
          "label": "LEVANTE",
          "value": "levante"
      }
  ],
  "MAXUS": [
      {
          "label": "G10",
          "value": "g10"
      },
      {
          "label": "ISTANA",
          "value": "istana"
      },
      {
          "label": "V80",
          "value": "v80"
      },
      {
          "label": "T60",
          "value": "t60"
      }
  ],
  "MCLAREN": [
      {
          "label": "12C SPIDER",
          "value": "12cspider"
      },
      {
          "label": "650S",
          "value": "650s"
      },
      {
          "label": "MP4-12C",
          "value": "mp4-12c"
      },
      {
          "label": "540C",
          "value": "540c"
      },
      {
          "label": "570",
          "value": "570"
      },
      {
          "label": "675LT",
          "value": "675lt"
      },
      {
          "label": "720S",
          "value": "720s"
      },
      {
          "label": "F1",
          "value": "f1"
      }
  ],
  "MITSUBISHI": [
      {
          "label": "LANCER",
          "value": "lancer"
      },
      {
          "label": "GRANDIS",
          "value": "grandis"
      },
      {
          "label": "TRITON",
          "value": "triton"
      },
      {
          "label": "PAJERO",
          "value": "pajero"
      },
      {
          "label": "COLT",
          "value": "colt"
      },
      {
          "label": "GALANT",
          "value": "galant"
      },
      {
          "label": "TREDIA",
          "value": "tredia"
      },
      {
          "label": "STORM",
          "value": "storm"
      },
      {
          "label": "EVO LANCER",
          "value": "evolancer"
      },
      {
          "label": "SUPER TOURING",
          "value": "supertouring"
      },
      {
          "label": "SPACEWAGON",
          "value": "spacewagon"
      },
      {
          "label": "OUTLANDER",
          "value": "outlander"
      },
      {
          "label": "ASX",
          "value": "asx"
      },
      {
          "label": "AIRTREK",
          "value": "airtrek"
      },
      {
          "label": "CHALLENGER",
          "value": "challenger"
      },
      {
          "label": "COLT RALLIART",
          "value": "coltralliart"
      },
      {
          "label": "PAJERO SPORT",
          "value": "pajerosport"
      },
      {
          "label": "ASPIRE",
          "value": "aspire"
      },
      {
          "label": "ATTRAGE",
          "value": "attrage"
      },
      {
          "label": "BRAVO",
          "value": "bravo"
      },
      {
          "label": "CARISMA",
          "value": "carisma"
      },
      {
          "label": "CHARIOT",
          "value": "chariot"
      },
      {
          "label": "CORDIA",
          "value": "cordia"
      },
      {
          "label": "DEBONAIR",
          "value": "debonair"
      },
      {
          "label": "DIAMANTE",
          "value": "diamante"
      },
      {
          "label": "DIGNITY",
          "value": "dignity"
      },
      {
          "label": "DINGO",
          "value": "dingo"
      },
      {
          "label": "DION",
          "value": "dion"
      },
      {
          "label": "ECLIPSE",
          "value": "eclipse"
      },
      {
          "label": "EK",
          "value": "ek"
      },
      {
          "label": "EMERAUDE",
          "value": "emeraude"
      },
      {
          "label": "ETERNA",
          "value": "eterna"
      },
      {
          "label": "EURO",
          "value": "euro"
      },
      {
          "label": "EVO",
          "value": "evo"
      },
      {
          "label": "FE",
          "value": "fe"
      },
      {
          "label": "FORTE",
          "value": "forte"
      },
      {
          "label": "FTO",
          "value": "fto"
      },
      {
          "label": "GTO",
          "value": "gto"
      },
      {
          "label": "JEEP",
          "value": "jeep"
      },
      {
          "label": "LEGNUM",
          "value": "legnum"
      },
      {
          "label": "LIBERO",
          "value": "libero"
      },
      {
          "label": "MAGNA",
          "value": "magna"
      },
      {
          "label": "MINICA",
          "value": "minica"
      },
      {
          "label": "MIRAGE",
          "value": "mirage"
      },
      {
          "label": "PISTACHIO",
          "value": "pistachio"
      },
      {
          "label": "PROUDIA",
          "value": "proudia"
      },
      {
          "label": "RVR",
          "value": "rvr"
      },
      {
          "label": "SHOGUN",
          "value": "shogun"
      },
      {
          "label": "SIGMA",
          "value": "sigma"
      },
      {
          "label": "SPACE GEAR",
          "value": "spacegear"
      },
      {
          "label": "STRADA",
          "value": "strada"
      },
      {
          "label": "TOPPO",
          "value": "toppo"
      },
      {
          "label": "DELICA",
          "value": "delica"
      },
      {
          "label": "TRITON LITE",
          "value": "tritonlite"
      },
      {
          "label": "SAPPORO",
          "value": "sapporo"
      },
      {
          "label": "I-MIEV",
          "value": "imiev"
      }
  ],
  "MITSUOKA": [
      {
          "label": "GALUE",
          "value": "galue"
      },
      {
          "label": "HIMIKO",
          "value": "himiko"
      },
      {
          "label": "LE SEYDE",
          "value": "leseyde"
      },
      {
          "label": "LIKE",
          "value": "like"
      },
      {
          "label": "MC-1",
          "value": "mc-1"
      },
      {
          "label": "NOUERA",
          "value": "nouera"
      },
      {
          "label": "OROCHI",
          "value": "orochi"
      },
      {
          "label": "RAY",
          "value": "ray"
      },
      {
          "label": "RYOGA",
          "value": "ryoga"
      },
      {
          "label": "VIEWT",
          "value": "viewt"
      },
      {
          "label": "YUGA",
          "value": "yuga"
      },
      {
          "label": "ZERO1",
          "value": "zero1"
      },
      {
          "label": "CUTE",
          "value": "cute"
      },
      {
          "label": "M-BOX",
          "value": "m-box"
      },
      {
          "label": "LX460",
          "value": "lx460"
      }
  ],
  "MORRIS": [
      {
          "label": "1300",
          "value": "1300"
      },
      {
          "label": "ITAL",
          "value": "ital"
      },
      {
          "label": "MINI",
          "value": "mini"
      },
      {
          "label": "MINOR",
          "value": "minor"
      },
      {
          "label": "TEN",
          "value": "ten"
      },
      {
          "label": "OXFORD",
          "value": "oxford"
      }
  ],
  "NAZA": [
      {
          "label": "FORZA",
          "value": "forza"
      },
      {
          "label": "CITRA",
          "value": "citra"
      },
      {
          "label": "RIA",
          "value": "ria"
      },
      {
          "label": "SORENTO",
          "value": "sorento"
      },
      {
          "label": "206",
          "value": "206"
      },
      {
          "label": "SUTERA",
          "value": "sutera"
      },
      {
          "label": "FORTE",
          "value": "forte"
      },
      {
          "label": "BESTARI",
          "value": "bestari"
      },
      {
          "label": "PICANTO",
          "value": "picanto"
      },
      {
          "label": "SURIA",
          "value": "suria"
      }
  ],
  "PEUGEOT": [
      {
          "label": "206 COUPE",
          "value": "206coupe"
      },
      {
          "label": "308 VTI",
          "value": "308vti"
      },
      {
          "label": "308 TURBO PANORAMIC",
          "value": "308turbopanoramic"
      },
      {
          "label": "308 GT",
          "value": "308gt"
      },
      {
          "label": "405",
          "value": "405"
      },
      {
          "label": "406",
          "value": "406"
      },
      {
          "label": "407",
          "value": "407"
      },
      {
          "label": "207",
          "value": "207"
      },
      {
          "label": "207 SV",
          "value": "207sv"
      },
      {
          "label": "308 TURBO",
          "value": "308turbo"
      },
      {
          "label": "5008",
          "value": "5008"
      },
      {
          "label": "508",
          "value": "508"
      },
      {
          "label": "RCZ",
          "value": "rcz"
      },
      {
          "label": "408",
          "value": "408"
      },
      {
          "label": "806",
          "value": "806"
      },
      {
          "label": "807",
          "value": "807"
      },
      {
          "label": "1007",
          "value": "1007"
      },
      {
          "label": "104",
          "value": "104"
      },
      {
          "label": "106",
          "value": "106"
      },
      {
          "label": "205",
          "value": "205"
      },
      {
          "label": "3008",
          "value": "3008"
      },
      {
          "label": "304",
          "value": "304"
      },
      {
          "label": "305",
          "value": "305"
      },
      {
          "label": "306",
          "value": "306"
      },
      {
          "label": "307",
          "value": "307"
      },
      {
          "label": "309",
          "value": "309"
      },
      {
          "label": "504",
          "value": "504"
      },
      {
          "label": "505",
          "value": "505"
      },
      {
          "label": "604",
          "value": "604"
      },
      {
          "label": "605",
          "value": "605"
      },
      {
          "label": "607",
          "value": "607"
      },
      {
          "label": "208",
          "value": "208"
      },
      {
          "label": "208 GTI",
          "value": "208gti"
      },
      {
          "label": "205 GTI",
          "value": "205gti"
      },
      {
          "label": "206 GTI",
          "value": "206gti"
      },
      {
          "label": "207 GTI",
          "value": "207gti"
      },
      {
          "label": "108",
          "value": "108"
      },
      {
          "label": "2008",
          "value": "2008"
      },
      {
          "label": "TRAVELLER",
          "value": "traveller"
      },
      {
          "label": "308",
          "value": "308"
      }
  ],
  "RENAULT": [
      {
          "label": "KANGOO",
          "value": "kangoo"
      },
      {
          "label": "CLIO",
          "value": "clio"
      },
      {
          "label": "MEGANE",
          "value": "megane"
      },
      {
          "label": "GRAND ESPACE",
          "value": "grandespace"
      },
      {
          "label": "RT19",
          "value": "rt19"
      },
      {
          "label": "SCENIC",
          "value": "scenic"
      },
      {
          "label": "LAGUNA",
          "value": "laguna"
      },
      {
          "label": "KOLEOS",
          "value": "koleos"
      },
      {
          "label": "11",
          "value": "11"
      },
      {
          "label": "14",
          "value": "14"
      },
      {
          "label": "15",
          "value": "15"
      },
      {
          "label": "16",
          "value": "16"
      },
      {
          "label": "17",
          "value": "17"
      },
      {
          "label": "18",
          "value": "18"
      },
      {
          "label": "19",
          "value": "19"
      },
      {
          "label": "20",
          "value": "20"
      },
      {
          "label": "21",
          "value": "21"
      },
      {
          "label": "25",
          "value": "25"
      },
      {
          "label": "30",
          "value": "30"
      },
      {
          "label": "4",
          "value": "4"
      },
      {
          "label": "5",
          "value": "5"
      },
      {
          "label": "ALPHINE",
          "value": "alphine"
      },
      {
          "label": "AVANTIME",
          "value": "avantime"
      },
      {
          "label": "EXPRESS",
          "value": "express"
      },
      {
          "label": "FUEGO",
          "value": "fuego"
      },
      {
          "label": "GRAND SCENIC",
          "value": "grandscenic"
      },
      {
          "label": "LUTECIA",
          "value": "lutecia"
      },
      {
          "label": "SAFRANE",
          "value": "safrane"
      },
      {
          "label": "SPORT SPIDER",
          "value": "sportspider"
      },
      {
          "label": "TWINGO",
          "value": "twingo"
      },
      {
          "label": "WIND",
          "value": "wind"
      },
      {
          "label": "FLUENCE",
          "value": "fluence"
      },
      {
          "label": "CAPTUR",
          "value": "captur"
      },
      {
          "label": "TWIZY",
          "value": "twizy"
      },
      {
          "label": "ZOE",
          "value": "zoe"
      }
  ],
  "ROLLS-ROYCE": [
      {
          "label": "GHOST",
          "value": "ghost"
      },
      {
          "label": "PHANTOM",
          "value": "phantom"
      },
      {
          "label": "CORNICHE",
          "value": "corniche"
      },
      {
          "label": "LIMOUSINE",
          "value": "limousine"
      },
      {
          "label": "PARK WARD",
          "value": "parkward"
      },
      {
          "label": "SILVER CLOUD",
          "value": "silvercloud"
      },
      {
          "label": "SILVER DAWN",
          "value": "silverdawn"
      },
      {
          "label": "SILVER SERAPH",
          "value": "silverseraph"
      },
      {
          "label": "SILVER SHADOW",
          "value": "silvershadow"
      },
      {
          "label": "SILVER SPIRIT",
          "value": "silverspirit"
      },
      {
          "label": "SILVER SPUR",
          "value": "silverspur"
      },
      {
          "label": "SILVER WRAITH",
          "value": "silverwraith"
      },
      {
          "label": "TOURING LIMOUSINE",
          "value": "touringlimousine"
      },
      {
          "label": "CAMARGUE",
          "value": "camargue"
      },
      {
          "label": "DAWN",
          "value": "dawn"
      },
      {
          "label": "CULLINAN",
          "value": "cullinan"
      }
  ],
  "ROVER": [
      {
          "label": "100",
          "value": "100"
      },
      {
          "label": "200",
          "value": "200"
      },
      {
          "label": "2600",
          "value": "2600"
      },
      {
          "label": "3500",
          "value": "3500"
      },
      {
          "label": "400",
          "value": "400"
      },
      {
          "label": "600",
          "value": "600"
      },
      {
          "label": "75",
          "value": "75"
      },
      {
          "label": "800",
          "value": "800"
      },
      {
          "label": "GSI ESTATE",
          "value": "gsiestate"
      },
      {
          "label": "MINI",
          "value": "mini"
      },
      {
          "label": "VANDEN PLAS",
          "value": "vandenplas"
      },
      {
          "label": "TOURER",
          "value": "tourer"
      },
      {
          "label": "VITESSE",
          "value": "vitesse"
      },
      {
          "label": "P4 60",
          "value": "p460"
      }
  ],
  "SAAB": [
      {
          "label": "900",
          "value": "900"
      },
      {
          "label": "600",
          "value": "600"
      },
      {
          "label": "9000",
          "value": "9000"
      },
      {
          "label": "9-2",
          "value": "9-2"
      },
      {
          "label": "9-3",
          "value": "9-3"
      },
      {
          "label": "9-5",
          "value": "9-5"
      },
      {
          "label": "96",
          "value": "96"
      },
      {
          "label": "99",
          "value": "99"
      },
      {
          "label": "TURBO X",
          "value": "turbox"
      }
  ],
  "SHENYANG BRILLIANCE": [
      {
          "label": "ERA JINBEI",
          "value": "erajinbei"
      }
  ],
  "SKODA": [
      {
          "label": "OCTAVIA",
          "value": "octavia"
      },
      {
          "label": "FABIA",
          "value": "fabia"
      },
      {
          "label": "SUPERB",
          "value": "superb"
      },
      {
          "label": "YETI",
          "value": "yeti"
      },
      {
          "label": "ROOMSTER",
          "value": "roomster"
      }
  ],
  "SMART": [
      {
          "label": "FORTWO",
          "value": "fortwo"
      },
      {
          "label": "FORFOUR",
          "value": "forfour"
      },
      {
          "label": "ROADSTER",
          "value": "roadster"
      }
  ],
  "SSANGYONG": [
      {
          "label": "KYRON",
          "value": "kyron"
      },
      {
          "label": "REXTON",
          "value": "rexton"
      },
      {
          "label": "STAVIC",
          "value": "stavic"
      },
      {
          "label": "ACTYON",
          "value": "actyon"
      },
      {
          "label": "KORANDO",
          "value": "korando"
      },
      {
          "label": "CHAIRMAN",
          "value": "chairman"
      },
      {
          "label": "MUSSO",
          "value": "musso"
      },
      {
          "label": "RODIUS",
          "value": "rodius"
      }
  ],
  "SUBARU": [
      {
          "label": "IMPREZA",
          "value": "impreza"
      },
      {
          "label": "FORESTER",
          "value": "forester"
      },
      {
          "label": "LEGACY",
          "value": "legacy"
      },
      {
          "label": "WRX",
          "value": "wrx"
      },
      {
          "label": "360",
          "value": "360"
      },
      {
          "label": "ALCRYONE",
          "value": "alcryone"
      },
      {
          "label": "BIGHORN",
          "value": "bighorn"
      },
      {
          "label": "DEX",
          "value": "dex"
      },
      {
          "label": "DIAS WAGON",
          "value": "diaswagon"
      },
      {
          "label": "DOMINGO",
          "value": "domingo"
      },
      {
          "label": "EXIGA",
          "value": "exiga"
      },
      {
          "label": "JUSTY",
          "value": "justy"
      },
      {
          "label": "LEONE",
          "value": "leone"
      },
      {
          "label": "LUCRA",
          "value": "lucra"
      },
      {
          "label": "OUTBACK",
          "value": "outback"
      },
      {
          "label": "PLEO",
          "value": "pleo"
      },
      {
          "label": "R1",
          "value": "r1"
      },
      {
          "label": "R2",
          "value": "r2"
      },
      {
          "label": "REX",
          "value": "rex"
      },
      {
          "label": "STELLA",
          "value": "stella"
      },
      {
          "label": "TRAVIQ",
          "value": "traviq"
      },
      {
          "label": "TREZIA",
          "value": "trezia"
      },
      {
          "label": "VIVIO",
          "value": "vivio"
      },
      {
          "label": "BRZ",
          "value": "brz"
      },
      {
          "label": "XV",
          "value": "xv"
      },
      {
          "label": "BRAT",
          "value": "brat"
      },
      {
          "label": "LEVORG",
          "value": "levorg"
      }
  ],
  "SUZUKI": [
      {
          "label": "SWIFT",
          "value": "swift"
      },
      {
          "label": "SX4",
          "value": "sx4"
      },
      {
          "label": "GRAND VITARA",
          "value": "grandvitara"
      },
      {
          "label": "JIMNY",
          "value": "jimny"
      },
      {
          "label": "VITARA",
          "value": "vitara"
      },
      {
          "label": "ERV",
          "value": "erv"
      },
      {
          "label": "ALTO",
          "value": "alto"
      },
      {
          "label": "KIZASHI",
          "value": "kizashi"
      },
      {
          "label": "AERIO",
          "value": "aerio"
      },
      {
          "label": "APV",
          "value": "apv"
      },
      {
          "label": "CAPPUCCINO",
          "value": "cappuccino"
      },
      {
          "label": "CARA",
          "value": "cara"
      },
      {
          "label": "CERVO",
          "value": "cervo"
      },
      {
          "label": "CULTUS",
          "value": "cultus"
      },
      {
          "label": "ESCUDO",
          "value": "escudo"
      },
      {
          "label": "EVERY",
          "value": "every"
      },
      {
          "label": "FRONTE",
          "value": "fronte"
      },
      {
          "label": "GRAND ESCUDO",
          "value": "grandescudo"
      },
      {
          "label": "IGNIS",
          "value": "ignis"
      },
      {
          "label": "KEI",
          "value": "kei"
      },
      {
          "label": "LANDY",
          "value": "landy"
      },
      {
          "label": "LIANA",
          "value": "liana"
      },
      {
          "label": "MIGHTY BOY",
          "value": "mightyboy"
      },
      {
          "label": "MR WAGON",
          "value": "mrwagon"
      },
      {
          "label": "PALETTE",
          "value": "palette"
      },
      {
          "label": "SOLIO",
          "value": "solio"
      },
      {
          "label": "SPLASH",
          "value": "splash"
      },
      {
          "label": "TWIN",
          "value": "twin"
      },
      {
          "label": "X-90",
          "value": "x-90"
      },
      {
          "label": "CARRY",
          "value": "carry"
      }
  ],
  "TD2000": [
      {
          "label": "SILVERSTONE",
          "value": "silverstone"
      }
  ],
  "TESLA": [
      {
          "label": "MODEL S 70D",
          "value": "models70d"
      },
      {
          "label": "MODEL S 85",
          "value": "models85"
      },
      {
          "label": "MODEL S 70",
          "value": "models70"
      },
      {
          "label": "MODEL S 85D",
          "value": "models85d"
      },
      {
          "label": "MODEL S P85D",
          "value": "modelsp85d"
      },
      {
          "label": "MODEL S 90D",
          "value": "models90d"
      },
      {
          "label": "MODEL S P90D",
          "value": "modelsp90d"
      },
      {
          "label": "MODEL X 70D",
          "value": "modelx70d"
      },
      {
          "label": "MODEL X 90D",
          "value": "modelx90d"
      },
      {
          "label": "MODEL X P90D",
          "value": "modelxp90d"
      },
      {
          "label": "ROADSTER",
          "value": "roadster"
      },
      {
          "label": "MODEL S 75",
          "value": "models75"
      },
      {
          "label": "MODEL S 75D",
          "value": "models75d"
      },
      {
          "label": "MODEL S P85",
          "value": "modelsp85"
      },
      {
          "label": "MODEL 3",
          "value": "model3"
      },
      {
          "label": "MODEL S 60",
          "value": "models60"
      },
      {
          "label": "MODEL S 100D",
          "value": "models100d"
      },
      {
          "label": "MODEL S P100D",
          "value": "modelsp100d"
      },
      {
          "label": "MODEL X 60D",
          "value": "modelx60d"
      },
      {
          "label": "MODEL X 75D",
          "value": "modelx75d"
      },
      {
          "label": "MODEL X P100D",
          "value": "modelxp100d"
      }
  ],
  "VOLVO": [
      {
          "label": "S40",
          "value": "s40"
      },
      {
          "label": "S70",
          "value": "s70"
      },
      {
          "label": "V50",
          "value": "v50"
      },
      {
          "label": "V70",
          "value": "v70"
      },
      {
          "label": "C30",
          "value": "c30"
      },
      {
          "label": "C70",
          "value": "c70"
      },
      {
          "label": "S80",
          "value": "s80"
      },
      {
          "label": "XC60",
          "value": "xc60"
      },
      {
          "label": "XC90",
          "value": "xc90"
      },
      {
          "label": "240",
          "value": "240"
      },
      {
          "label": "740",
          "value": "740"
      },
      {
          "label": "760",
          "value": "760"
      },
      {
          "label": "850",
          "value": "850"
      },
      {
          "label": "940",
          "value": "940"
      },
      {
          "label": "960",
          "value": "960"
      },
      {
          "label": "S60",
          "value": "s60"
      },
      {
          "label": "240GL",
          "value": "240gl"
      },
      {
          "label": "245",
          "value": "245"
      },
      {
          "label": "V40",
          "value": "v40"
      },
      {
          "label": "XC70",
          "value": "xc70"
      },
      {
          "label": "144",
          "value": "144"
      },
      {
          "label": "1800",
          "value": "1800"
      },
      {
          "label": "244",
          "value": "244"
      },
      {
          "label": "260",
          "value": "260"
      },
      {
          "label": "264",
          "value": "264"
      },
      {
          "label": "340",
          "value": "340"
      },
      {
          "label": "343",
          "value": "343"
      },
      {
          "label": "345",
          "value": "345"
      },
      {
          "label": "360",
          "value": "360"
      },
      {
          "label": "440",
          "value": "440"
      },
      {
          "label": "460",
          "value": "460"
      },
      {
          "label": "480",
          "value": "480"
      },
      {
          "label": "780",
          "value": "780"
      },
      {
          "label": "AMAZON",
          "value": "amazon"
      },
      {
          "label": "CROSS COUNTRY",
          "value": "crosscountry"
      },
      {
          "label": "S90",
          "value": "s90"
      },
      {
          "label": "V60",
          "value": "v60"
      },
      {
          "label": "V90",
          "value": "v90"
      },
      {
          "label": "121",
          "value": "121"
      },
      {
          "label": "122S",
          "value": "122s"
      },
      {
          "label": "123GT",
          "value": "123gt"
      },
      {
          "label": "142",
          "value": "142"
      },
      {
          "label": "164",
          "value": "164"
      },
      {
          "label": "P1800",
          "value": "p1800"
      },
      {
          "label": "XC40",
          "value": "xc40"
      }
  ],
  "YANGTSE": [
      {
          "label": "WG6600CQ4",
          "value": "wg6600cq4"
      }
  ],
  "AC": [
      {
          "label": "COBRA 427",
          "value": "cobra427"
      },
      {
          "label": "COBRA CHALLENGER",
          "value": "cobrachallenger"
      }
  ],
  "ARIEL": [
      {
          "label": "ATOM",
          "value": "atom"
      }
  ],
  "ASIA MOTORS": [
      {
          "label": "ASIA ROCSTA",
          "value": "asiarocsta"
      }
  ],
  "BAW": [
      {
          "label": "HIACE",
          "value": "hiace"
      }
  ],
  "BISON": [
      {
          "label": "SAVANNA",
          "value": "savanna"
      }
  ],
  "BOON KOON": [
      {
          "label": "CERGAS",
          "value": "cergas"
      }
  ],
  "BORGWARD": [
      {
          "label": "BX5",
          "value": "bx5"
      }
  ],
  "BUGATTI": [
      {
          "label": "B110",
          "value": "b110"
      },
      {
          "label": "GRAND SPORT",
          "value": "grandsport"
      },
      {
          "label": "SUPER SPORT",
          "value": "supersport"
      },
      {
          "label": "VEYRON",
          "value": "veyron"
      }
  ],
  "BUICK": [
      {
          "label": "APOLLO",
          "value": "apollo"
      },
      {
          "label": "CENTURY",
          "value": "century"
      },
      {
          "label": "ELECTRA",
          "value": "electra"
      },
      {
          "label": "LESABRE",
          "value": "lesabre"
      },
      {
          "label": "PARK AVENUE",
          "value": "parkavenue"
      },
      {
          "label": "REATTA",
          "value": "reatta"
      },
      {
          "label": "REGAL",
          "value": "regal"
      },
      {
          "label": "RIVIERA",
          "value": "riviera"
      },
      {
          "label": "ROADMASTER",
          "value": "roadmaster"
      },
      {
          "label": "RUXSUS",
          "value": "ruxsus"
      },
      {
          "label": "SKYHAWK",
          "value": "skyhawk"
      },
      {
          "label": "SKYLARK",
          "value": "skylark"
      }
  ],
  "DAEWOO": [
      {
          "label": "LACETTI",
          "value": "lacetti"
      }
  ],
  "DAIMLER": [
      {
          "label": "LIMOUSINE",
          "value": "limousine"
      }
  ],
  "DATSUN": [
      {
          "label": "120Y",
          "value": "120y"
      },
      {
          "label": "620",
          "value": "620"
      },
      {
          "label": "240Z",
          "value": "240z"
      },
      {
          "label": "260Z",
          "value": "260z"
      },
      {
          "label": "280Z",
          "value": "280z"
      },
      {
          "label": "KE55",
          "value": "ke55"
      },
      {
          "label": "1600 SSS",
          "value": "1600sss"
      },
      {
          "label": "CEDRIC 260C",
          "value": "cedric260c"
      }
  ],
  "DS AUTOMOBILES": [
      {
          "label": "DS 3",
          "value": "ds3"
      },
      {
          "label": "DS 4",
          "value": "ds4"
      },
      {
          "label": "DS 4S",
          "value": "ds4s"
      },
      {
          "label": "DS 5",
          "value": "ds5"
      },
      {
          "label": "DS 5LS",
          "value": "ds5ls"
      },
      {
          "label": "DS 6",
          "value": "ds6"
      },
      {
          "label": "DS 7 CROSSBACK",
          "value": "ds7crossback"
      }
  ],
  "DURANT": [
      {
          "label": "RUGBY TOURER",
          "value": "rugbytourer"
      }
  ],
  "FACTORY FIVE": [
      {
          "label": "33 HOT ROD",
          "value": "33hotrod"
      },
      {
          "label": "33 HOT ROD PICK UP",
          "value": "33hotrodpickup"
      }
  ],
  "GMC": [
      {
          "label": "SAVANA",
          "value": "savana"
      }
  ],
  "HOLDEN": [
      {
          "label": "KINGSWOOD",
          "value": "kingswood"
      },
      {
          "label": "MONARO",
          "value": "monaro"
      },
      {
          "label": "GEMINI",
          "value": "gemini"
      },
      {
          "label": "STATESMAN",
          "value": "statesman"
      },
      {
          "label": "PREMIER",
          "value": "premier"
      },
      {
          "label": "BARINA",
          "value": "barina"
      },
      {
          "label": "CRUZE",
          "value": "cruze"
      },
      {
          "label": "ASTRA",
          "value": "astra"
      },
      {
          "label": "MALIBU",
          "value": "malibu"
      },
      {
          "label": "INSIGNIA",
          "value": "insignia"
      }
  ],
  "HUANGHAI": [
      {
          "label": "PLUTUS",
          "value": "plutus"
      }
  ],
  "KING LONG": [
      {
          "label": "KINGO",
          "value": "kingo"
      },
      {
          "label": "PLACER",
          "value": "placer"
      }
  ],
  "LANCIA": [
      {
          "label": "BETA",
          "value": "beta"
      },
      {
          "label": "FULVIA",
          "value": "fulvia"
      }
  ],
  "MORGAN": [
      {
          "label": "3 WHEELER",
          "value": "3wheeler"
      },
      {
          "label": "4-4",
          "value": "4-4"
      },
      {
          "label": "PLUS 4",
          "value": "plus4"
      },
      {
          "label": "ROADSTER",
          "value": "roadster"
      },
      {
          "label": "PLUS 8",
          "value": "plus8"
      },
      {
          "label": "AERO 8",
          "value": "aero8"
      }
  ],
  "OLDSMOBILE": [
      {
          "label": "CUTLASS",
          "value": "cutlass"
      }
  ],
  "OPEL": [
      {
          "label": "FRONTERA",
          "value": "frontera"
      },
      {
          "label": "OMEGA",
          "value": "omega"
      },
      {
          "label": "COMMODORE",
          "value": "commodore"
      },
      {
          "label": "KADETT",
          "value": "kadett"
      },
      {
          "label": "VECTRA",
          "value": "vectra"
      },
      {
          "label": "CALAIS",
          "value": "calais"
      },
      {
          "label": "ASTRA",
          "value": "astra"
      },
      {
          "label": "ZAFIRA",
          "value": "zafira"
      },
      {
          "label": "RECORD",
          "value": "record"
      },
      {
          "label": "GEMINI",
          "value": "gemini"
      },
      {
          "label": "ASCONA",
          "value": "ascona"
      },
      {
          "label": "CALIBRA",
          "value": "calibra"
      },
      {
          "label": "CORSA",
          "value": "corsa"
      },
      {
          "label": "MANTA",
          "value": "manta"
      },
      {
          "label": "MERIVA",
          "value": "meriva"
      },
      {
          "label": "SENATOR",
          "value": "senator"
      },
      {
          "label": "SIGNUM",
          "value": "signum"
      },
      {
          "label": "SPEED STER",
          "value": "speedster"
      },
      {
          "label": "TIGRA",
          "value": "tigra"
      },
      {
          "label": "VITA",
          "value": "vita"
      }
  ],
  "OTHERS": [
      {
          "label": "OTHERS",
          "value": "others"
      }
  ],
  "PONTIAC": [
      {
          "label": "FIREBIRD",
          "value": "firebird"
      }
  ],
  "SHELBY": [
      {
          "label": "TYPE 65 DAYTONA COUPE",
          "value": "type65daytonacoupe"
      },
      {
          "label": "TYPE 65 DAYTONE COUPE-R",
          "value": "type65daytonecoupe-r"
      }
  ],
  "SUNBEAM": [
      {
          "label": "ALPINE",
          "value": "alpine"
      }
  ],
  "SUTTON": [
      {
          "label": "CS3500",
          "value": "cs3500"
      }
  ],
  "TATA": [
      {
          "label": "XENON",
          "value": "xenon"
      },
      {
          "label": "TELCOLINE",
          "value": "telcoline"
      }
  ],
  "TRIUMPH": [
      {
          "label": "10",
          "value": "10"
      },
      {
          "label": "12",
          "value": "12"
      },
      {
          "label": "13",
          "value": "13"
      },
      {
          "label": "20",
          "value": "20"
      },
      {
          "label": "35",
          "value": "35"
      },
      {
          "label": "1300",
          "value": "1300"
      },
      {
          "label": "1500",
          "value": "1500"
      },
      {
          "label": "2000",
          "value": "2000"
      },
      {
          "label": "2.5 PI",
          "value": "2.5pi"
      },
      {
          "label": "12-6 SCORPION",
          "value": "12-6scorpion"
      },
      {
          "label": "2500 TC & S",
          "value": "2500tcs"
      },
      {
          "label": "ACCLAIM",
          "value": "acclaim"
      },
      {
          "label": "COURIER",
          "value": "courier"
      },
      {
          "label": "DOLOMITE",
          "value": "dolomite"
      },
      {
          "label": "DOVE GTR",
          "value": "dovegtr"
      },
      {
          "label": "FIFTEEN",
          "value": "fifteen"
      },
      {
          "label": "GLORIA",
          "value": "gloria"
      },
      {
          "label": "GT",
          "value": "gt"
      },
      {
          "label": "HERALD",
          "value": "herald"
      },
      {
          "label": "ITALIA",
          "value": "italia"
      },
      {
          "label": "MAYFLOWER",
          "value": "mayflower"
      },
      {
          "label": "RENOWN",
          "value": "renown"
      },
      {
          "label": "ROADSTER",
          "value": "roadster"
      },
      {
          "label": "SALOON",
          "value": "saloon"
      },
      {
          "label": "SOUTHERN CROSS",
          "value": "southerncross"
      },
      {
          "label": "SPITFIRE",
          "value": "spitfire"
      },
      {
          "label": "STAG",
          "value": "stag"
      },
      {
          "label": "SUPER",
          "value": "super"
      },
      {
          "label": "TOLEDO",
          "value": "toledo"
      },
      {
          "label": "TR",
          "value": "tr"
      },
      {
          "label": "VITESSE",
          "value": "vitesse"
      }
  ],
  "TVR": [
      {
          "label": "3000S",
          "value": "3000s"
      },
      {
          "label": "350SE",
          "value": "350se"
      },
      {
          "label": "390SE",
          "value": "390se"
      },
      {
          "label": "420 SEAC",
          "value": "420seac"
      },
      {
          "label": "450 SEAC",
          "value": "450seac"
      },
      {
          "label": "CERBERA",
          "value": "cerbera"
      },
      {
          "label": "CERBERA SPEED 12",
          "value": "cerberaspeed12"
      },
      {
          "label": "CHIMAERA",
          "value": "chimaera"
      },
      {
          "label": "GRANTURA",
          "value": "grantura"
      },
      {
          "label": "GRIFFITH",
          "value": "griffith"
      },
      {
          "label": "GRIFFITH 200",
          "value": "griffith200"
      },
      {
          "label": "GRIFFITH 400",
          "value": "griffith400"
      },
      {
          "label": "M SERIES",
          "value": "mseries"
      },
      {
          "label": "S SERIES",
          "value": "sseries"
      },
      {
          "label": "SAGARIS",
          "value": "sagaris"
      },
      {
          "label": "T350",
          "value": "t350"
      },
      {
          "label": "TAMORA",
          "value": "tamora"
      },
      {
          "label": "TASMIN",
          "value": "tasmin"
      },
      {
          "label": "TUSCAN",
          "value": "tuscan"
      },
      {
          "label": "TUSCAN SPEED 6",
          "value": "tuscanspeed6"
      },
      {
          "label": "TYPHON",
          "value": "typhon"
      },
      {
          "label": "VIXEN",
          "value": "vixen"
      }
  ],
  "WOLSELEY": [
      {
          "label": "1660",
          "value": "1660"
      }
  ],
  "ZENVO": [
      {
          "label": "TS1-GT",
          "value": "ts1-gt"
      },
      {
          "label": "TSR",
          "value": "tsr"
      },
      {
          "label": "TSR-S",
          "value": "tsr-s"
      }
  ],
  "ZNA": [
      {
          "label": "SUCCESS",
          "value": "success"
      }
  ],
  "ZXAUTO": [
      {
          "label": "GRANDTIGER",
          "value": "grandtiger"
      }
  ]
}

export default modelMap;