import { firebaseDB, firebase, db, callableFunctions } from "./index";
import { generateRandomID, getFormattedResults } from "../helper";
import {
  getDocumentMetadata,
  commitBatch,
  removeUndefined,
  preprocessStockPhotoInput,
  manageImageStorage,
} from "./helper";

const customer = {
  createCustomer: async function (data) {
    var batch = db.batch();

    const customerID = generateRandomID();
    const documentMetadata = getDocumentMetadata(1);
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(
      data,
      ["customerPhoto"],
      "create"
    );
    batch.set(firebaseDB.customerCollection().doc(customerID), {
      ...removeUndefined(newData),
      ...documentMetadata,
    });
    let response = await commitBatch(batch, undefined, undefined, customerID);
    if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
      await manageImageStorage(createPhoto, deletePhoto);
    }
    return response;
  },
  updateCustomer: async function (data) {
    var batch = db.batch();

    const { customerID } = data;
    // const inputDataTemp = { ...data };
    const {
      newData: inputData,
      createPhoto,
      deletePhoto,
    } = preprocessStockPhotoInput(data, ["customerPhoto"], "update");
    delete inputData.customerID;
    const documentMetadata = getDocumentMetadata(0);

    batch.update(firebaseDB.customerCollection().doc(customerID), {
      ...removeUndefined(inputData),
      ...documentMetadata,
    });
    let response = await commitBatch(batch, undefined, undefined, customerID);
    if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
      await manageImageStorage(createPhoto, deletePhoto);
    }
    return response;
  },
  deleteCustomer: async function (data) {
    var batch = db.batch();

    const { customerID } = data;
    await firebaseDB
      .customerCollection()
      .doc(customerID)
      .collection("comment")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const commentID = doc.id;
          batch.delete(
            firebaseDB
              .customerCollection()
              .doc(customerID)
              .collection("comment")
              .doc(commentID)
          );
        });
      });
    await firebaseDB
      .customerCollection()
      .doc(customerID)
      .collection("activity")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const activityID = doc.id;
          batch.delete(
            firebaseDB
              .customerCollection()
              .doc(customerID)
              .collection("activity")
              .doc(activityID)
          );
        });
      });
    await firebaseDB
      .customerCollection()
      .doc(customerID)
      .collection("task")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const taskID = doc.id;
          batch.delete(
            firebaseDB
              .customerCollection()
              .doc(customerID)
              .collection("task")
              .doc(taskID)
          );
        });
      });
    await firebaseDB
      .customerCollection()
      .doc(customerID)
      .get()
      .then(async (doc) => {
        await manageImageStorage([], doc.data().customerPhoto || []);
      });
    batch.delete(firebaseDB.customerCollection().doc(customerID));
    return await commitBatch(batch, undefined, undefined, customerID);
  },

  createCustomerComment: async function (data) {
    var batch = db.batch();

    const { customerID } = data;
    const inputData = { ...data };
    delete inputData.customerID;
    const commentID = generateRandomID();
    const documentMetadata = getDocumentMetadata(1);

    batch.set(
      firebaseDB
        .customerCollection()
        .doc(customerID)
        .collection("comment")
        .doc(commentID),
      {
        ...removeUndefined(inputData),
        ...documentMetadata,
      }
    );
    return await commitBatch(batch, undefined, commentID, customerID);
  },
  updateCustomerComment: async function (data) {
    var batch = db.batch();

    const { customerID, commentID } = data;
    const inputData = { ...data };
    delete inputData.customerID;
    delete inputData.commentID;
    const documentMetadata = getDocumentMetadata(0);

    batch.update(
      firebaseDB
        .customerCollection()
        .doc(customerID)
        .collection("comment")
        .doc(commentID),
      {
        ...removeUndefined(inputData),
        ...documentMetadata,
      }
    );
    return await commitBatch(batch, undefined, commentID, customerID);
  },
  deleteCustomerComment: async function (data) {
    var batch = db.batch();

    const { customerID, commentID } = data;
    batch.delete(
      firebaseDB
        .customerCollection()
        .doc(customerID)
        .collection("comment")
        .doc(commentID)
    );
    return await commitBatch(batch, undefined, commentID, customerID);
  },

  createCustomerActivity: async function (data) {
    var batch = db.batch();

    const { customerID } = data;
    const inputData = { ...data };
    delete inputData.customerID;
    const activityID = generateRandomID();
    const documentMetadata = getDocumentMetadata(1);

    batch.set(
      firebaseDB
        .customerCollection()
        .doc(customerID)
        .collection("activity")
        .doc(activityID),
      {
        ...removeUndefined(inputData),
        ...documentMetadata,
      }
    );
    return await commitBatch(batch, undefined, activityID, customerID);
  },
  updateCustomerActivity: async function (data) {
    var batch = db.batch();

    const { customerID, activityID } = data;
    const inputData = { ...data };
    delete inputData.customerID;
    delete inputData.activityID;
    const documentMetadata = getDocumentMetadata(0);

    batch.update(
      firebaseDB
        .customerCollection()
        .doc(customerID)
        .collection("activity")
        .doc(activityID),
      {
        ...removeUndefined(inputData),
        ...documentMetadata,
      }
    );
    return await commitBatch(batch, undefined, activityID, customerID);
  },
  deleteCustomerActivity: async function (data) {
    var batch = db.batch();

    const { customerID, activityID } = data;
    batch.delete(
      firebaseDB
        .customerCollection()
        .doc(customerID)
        .collection("activity")
        .doc(activityID)
    );
    return await commitBatch(batch, undefined, activityID, customerID);
  },

  createCustomerTask: async function (data) {
    var batch = db.batch();

    const { customerID } = data;
    const inputData = { ...data };
    delete inputData.customerID;
    const taskID = generateRandomID();
    const documentMetadata = getDocumentMetadata(1);

    batch.set(
      firebaseDB
        .customerCollection()
        .doc(customerID)
        .collection("task")
        .doc(taskID),
      {
        ...removeUndefined(inputData),
        ...documentMetadata,
      }
    );
    return await commitBatch(batch, undefined, taskID, customerID);
  },
  updateCustomerTask: async function (data) {
    var batch = db.batch();

    const { customerID, taskID } = data;
    const inputData = { ...data };
    delete inputData.customerID;
    delete inputData.taskID;
    const documentMetadata = getDocumentMetadata(0);

    batch.update(
      firebaseDB
        .customerCollection()
        .doc(customerID)
        .collection("task")
        .doc(taskID),
      {
        ...removeUndefined(inputData),
        ...documentMetadata,
      }
    );
    return await commitBatch(batch, undefined, taskID, customerID);
  },
  deleteCustomerTask: async function (data) {
    var batch = db.batch();

    const { customerID, taskID } = data;
    batch.delete(
      firebaseDB
        .customerCollection()
        .doc(customerID)
        .collection("task")
        .doc(taskID)
    );
    return await commitBatch(batch, undefined, taskID, customerID);
  },
  writeCustomerChat: async function (data) {
    let inputData = { ...data };
    inputData.custID = data.customerID;
    inputData.to = data.to;
    inputData.message = data.content;
    const documentMetadata = getDocumentMetadata(0);
    callableFunctions.chat({
      ...removeUndefined(inputData),
      ...documentMetadata,
    });
    // batch.set(firebaseDB.customerCollection().doc(customerID)
    //   .collection("chat").doc(chatID), {
    //     ...removeUndefined(inputData),
    //     // ...documentMetadata
    //   })
    // return await commitBatch(batch, undefined, chatID, customerID)
  },
};

export default customer;
