export const LIST_BANK_FLOOR_STOCK = "LIST_BANK_FLOOR_STOCK";
export const GET_BANK_FLOOR_STOCK = "GET_BANK_FLOOR_STOCK";
export const CREATE_BANK_FLOOR_STOCK = "CREATE_BANK_FLOOR_STOCK";
export const UPDATE_BANK_FLOOR_STOCK = "UPDATE_BANK_FLOOR_STOCK";
export const DELETE_BANK_FLOOR_STOCK = "DELETE_BANK_FLOOR_STOCK";
export const UPDATE_ACTIVE_BANK_FLOOR_STOCK = "UPDATE_ACTIVE_BANK_FLOOR_STOCK";

export const TOGGLE_CREATE_BANK_FLOOR_STOCK = "TOGGLE_CREATE_BANK_FLOOR_STOCK";
export const TOGGLE_UPDATE_BANK_FLOOR_STOCK = "TOGGLE_UPDATE_BANK_FLOOR_STOCK";
export const TOGGLE_DELETE_BANK_FLOOR_STOCK = "TOGGLE_DELETE_BANK_FLOOR_STOCK";
export const TOGGLE_DETAIL_BANK_FLOOR_STOCK = "TOGGLE_DETAIL_BANK_FLOOR_STOCK";

export const RESET_BANK_FLOOR_STOCK_PARAMETERES =
  "RESET_BANK_FLOOR_STOCK_PARAMETERES";
export const UPDATE_BANK_FLOOR_STOCK_PARAMETERS =
  "UPDATE_BANK_FLOOR_STOCK_PARAMETERS";
export const DELETE_BANK_FLOOR_STOCK_PARAMETERS =
  "DELETE_BANK_FLOOR_STOCK_PARAMETERS";
export const DETAIL_BANK_FLOOR_STOCK_PARAMETERS =
  "DETAIL_BANK_FLOOR_STOCK_PARAMETERS";

export const HANDLE_CHANGE_BANK_FLOOR_STOCK = "HANDLE_CHANGE_BANK_FLOOR_STOCK";
