import { floorStock } from "../actionTypes"

const floorStockDefault = "Bank Floor Stock"
const floorStockOptions = [
  { label: "Bank Floor Stock", value: "Bank Floor Stock" },
  { label: "MyTukar Floor Stock", value: "MyTukar Floor Stock" }
];

const initialState = {
  floorStock: floorStockDefault,
  floorStockDefault,
  floorStockOptions
};

export default (state = initialState, action) => {
  switch (action.type) {
    case floorStock.HANDLE_CHANGE_FLOOR_STOCK: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
}