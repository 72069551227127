import { global } from "../actionTypes"

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case global.LIST_DROPDOWN: {
      const { dropdown, visibility } = action.payload;
      return { ...state, ...dropdown, ...visibility };
    }
    default:
      return state;
  }
}