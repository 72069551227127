export const LIST_SALES_PERSON = "LIST_SALES_PERSON";
export const CREATE_SALES_PERSON = "CREATE_SALES_PERSON";
export const UPDATE_SALES_PERSON = "UPDATE_SALES_PERSON";
export const DELETE_SALES_PERSON = "DELETE_SALES_PERSON";
export const UPDATE_ACTIVE_SALES_PERSON = "UPDATE_ACTIVE_SALES_PERSON";

export const TOGGLE_CREATE_SALES_PERSON = "TOGGLE_CREATE_SALES_PERSON";
export const EMPTY_STOCKEXISTANCE_SALES_PERSON =
  "EMPTY_STOCKEXISTANCE_SALES_PERSON";
export const TOGGLE_UPDATE_SALES_PERSON = "TOGGLE_UPDATE_SALES_PERSON";
export const TOGGLE_DELETE_SALES_PERSON = "TOGGLE_DELETE_SALES_PERSON";
export const TOGGLE_DETAIL_SALES_PERSON = "TOGGLE_DETAIL_SALES_PERSON";

export const RESET_SALES_PERSON_PARAMETERES = "RESET_SALES_PERSON_PARAMETERES";
export const UPDATE_SALES_PERSON_PARAMETERS = "UPDATE_SALES_PERSON_PARAMETERS";
export const DELETE_SALES_PERSON_PARAMETERS = "DELETE_SALES_PERSON_PARAMETERS";
export const DETAIL_SALES_PERSON_PARAMETERS = "DETAIL_SALES_PERSON_PARAMETERS";

export const HANDLE_CHANGE_SALES_PERSON = "HANDLE_CHANGE_SALES_PERSON";
