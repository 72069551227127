import { helper } from "../actionTypes";

const initialState = {
  form: {},
  submitButtonDisabled: false,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case helper.HANDLE_CHANGE: {
      const { object, objectKey, value } = action.payload;
      if (state.form[object] == undefined) {
        state.form[object] = {}
      }
      state.form[object][objectKey] = value;
      return { ...state, form: state.form };
    }
    case helper.HANDLE_SUBMIT_BUTTON: {
      const { buttonState } = action.payload;
      return { ...state, submitButtonDisabled: buttonState };
    }
    case helper.HANDLE_LOADING: {
      const { loadingState } = action.payload;
      return { ...state, loading: loadingState };
    }
    default:
      return state;
  }
};
