export const LOGIN = "LOGIN_USER";
export const LOGOUT = "LOGOUT_USER";
export const GOTO_HOME = "GOTO_HOME";
export const GOTO_LOGIN = "GOTO_LOGIN";
export const RESET_PARAM = "RESET_PARAM";
export const HANDLE_CHANGE_CHOOSE_USER_PRIVILEGE_TYPE =
  "HANDLE_CHANGE_CHOOSE_USER_PRIVILEGE_TYPE";

export const FETCH_USER = "FETCH_USER";
export const LIST_USER = "LIST_USER";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER_PERM = "UPDATE_USER_PERM";
export const GET_USER_COUNTER = "GET_USER_COUNTER";
export const HANDLE_ADD_USER_TO_TABLE = "HANDLE_ADD_USER_TO_TABLE";

export const TOGGLE_CREATE_USER = "TOGGLE_CREATE_USER";
export const TOGGLE_ADD_ASSIGNED_USER_TO_STOCK =
  "TOGGLE_ADD_ASSIGNED_USER_TO_STOCK";
export const TOGGLE_ADD_ASSIGNED_USER_TO_SALESTOCK =
  "TOGGLE_ADD_ASSIGNED_USER_TO_SALESTOCK";
export const TOGGLE_ADD_ASSIGNED_USER_TO_CUSTOMER =
  "TOGGLE_ADD_ASSIGNED_USER_TO_CUSTOMER";
export const TOGGLE_UPDATE_USER = "TOGGLE_UPDATE_USER";
export const TOGGLE_DELETE_USER = "TOGGLE_DELETE_USER";
export const TOGGLE_DETAIL_USER = "TOGGLE_DETAIL_USER";

export const RESET_USER_PARAMETERES = "RESET_USER_PARAMETERES";
export const UPDATE_USER_PARAMETERS = "UPDATE_USER_PARAMETERS";
export const DELETE_USER_PARAMETERS = "DELETE_USER_PARAMETERS";
export const DETAIL_USER_PARAMETERS = "DETAIL_USER_PARAMETERS";
export const REMOVE_USER_ASSIGN = "REMOVE_USER_ASSIGN";

export const HANDLE_CHANGE_USER = "HANDLE_CHANGE_USER";
