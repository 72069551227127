import { costStock } from "../actionTypes";
import { resetStockData, extractStatusDisplay } from "../actions/helper";
import { extractStockData } from "../../firebase/helper";

const initialState = {
  tableItems: [],
  start: undefined,
  startSnapshot: undefined,
  searching: false,

  createCostStockToggle: false,
  updateCostStockToggle: false,
  deleteCostStockToggle: false,
  detailCostStockToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case costStock.LIST_COSTSTOCK: {
      const {
        tableItems,
        start,
        startSnapshot,
        query,
        searching,
      } = action.payload;
      var newTableItems = tableItems;
      if (start != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return {
        ...state,
        tableItems: newTableItems,
        startSnapshot,
        query,
        searching,
      };
    }
    case costStock.GET_COSTSTOCK: {
      const { variables } = action.payload;
      return { ...state, variables };
    }
    case costStock.CREATE_COSTSTOCK: {
      const { result } = action.payload;
      const { stockData } = extractStockData(result, "costStock");
      result.stockData = stockData;
      var newTableItems = [result, ...state.tableItems];
      return { ...state, tableItems: newTableItems };
    }
    case costStock.UPDATE_COSTSTOCK: {
      const { result } = action.payload;
      result.stockData = state.stockData;
      result.floorStockStatus = state.stockData.floorStockStatus;
      result.myTukarFundStatus = state.stockData.myTukarFundStatus;
      result.repairStatus = state.stockData.repairStatus;
      result.estimateRepairCost = state.stockData.estimateRepairCost;
      result.biddingStatus = state.stockData.biddingStatus;
      result.originalCarCard = state.stockData.originalCarCard;
      result.spareKey = state.stockData.spareKey;
      result.ownerDoc = state.stockData.ownerDoc;
      result.eAuto = state.stockData.eAuto;
      result.settlementDetails = state.stockData.settlementDetails;
      result.assignedTo = state.stockData.assignedTo;
      result.inActive = state.stockData.inActive;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      newTableItems[index] = result;
      return { ...state, tableItems: newTableItems };
    }
    case costStock.UPDATE_ACTIVE_COSTSTOCK: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];
      console.log(newTableItems);
      var index = newTableItems.findIndex((element) => {
        return element.id == result.id;
      });
      newTableItems[index] = { ...newTableItems[index], ...result };

      return { ...state, tableItems: newTableItems };
    }
    case costStock.DELETE_COSTSTOCK: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex((element) => {
        return element.stockDataID == result.stockDataID;
      });
      const {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      } = state.tableItems[index];
      newTableItems[index] = {
        stockDataID,
        status,
        statusDisplay,
        carInDate,
        brand,
        model,
        specs,
        numberPlate,
        interchange,
        yearMake,
        photo,
      };
      return { ...state, tableItems: newTableItems };
    }
    case costStock.TOGGLE_CREATE_COSTSTOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        createCostStockToggle: toggle ? !state.createCostStockToggle : false,
      };
    }
    case costStock.TOGGLE_UPDATE_COSTSTOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateCostStockToggle: toggle ? !state.updateCostStockToggle : false,
      };
    }
    case costStock.TOGGLE_DELETE_COSTSTOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteCostStockToggle: toggle ? !state.deleteCostStockToggle : false,
      };
    }
    case costStock.TOGGLE_DETAIL_COSTSTOCK: {
      const { toggle } = action.payload;
      return {
        ...state,
        detailCostStockToggle: toggle ? !state.detailCostStockToggle : false,
      };
    }
    case costStock.RESET_COSTSTOCK_PARAMETERES: {
      return {
        ...state,
        ...resetStockData(),
        id: undefined,
        owner: undefined,
        broker: undefined,
        otherCost: undefined,
        sql: undefined,
        repairInfoTotal: undefined,
        managerCom: undefined,
        companyCost: undefined,
        takeBy: undefined,
        fromSalesPerson: undefined,
        remark: undefined,
      };
    }
    case costStock.UPDATE_COSTSTOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case costStock.DELETE_COSTSTOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case costStock.DETAIL_COSTSTOCK_PARAMETERS: {
      return { ...state, ...action.payload };
    }
    case costStock.HANDLE_CHANGE_COSTSTOCK: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
