export const LIST_CUSTOMER = "LIST_CUSTOMER"
export const GET_CUSTOMER = "GET_CUSTOMER"
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const DELETE_CUSTOMER = "DELETE_CUSTOMER"

export const LIST_CUSTOMER_SUB = "LIST_CUSTOMER_SUB"
export const GET_CUSTOMER_SUB = "GET_CUSTOMER_SUB"
export const CREATE_CUSTOMER_SUB = "CREATE_CUSTOMER_SUB"
export const UPDATE_CUSTOMER_SUB = "UPDATE_CUSTOMER_SUB"
export const DELETE_CUSTOMER_SUB = "DELETE_CUSTOMER_SUB"

export const TOGGLE_CREATE_CUSTOMER = "TOGGLE_CREATE_CUSTOMER"
export const TOGGLE_UPDATE_CUSTOMER = "TOGGLE_UPDATE_CUSTOMER";
export const TOGGLE_DELETE_CUSTOMER = "TOGGLE_DELETE_CUSTOMER";
export const TOGGLE_DETAIL_CUSTOMER = "TOGGLE_DETAIL_CUSTOMER";

export const TOGGLE_CREATE_CUSTOMER_COMMENT = "TOGGLE_CREATE_CUSTOMER_COMMENT"
export const TOGGLE_UPDATE_CUSTOMER_COMMENT = "TOGGLE_UPDATE_CUSTOMER_COMMENT"
export const TOGGLE_DELETE_CUSTOMER_COMMENT = "TOGGLE_DELETE_CUSTOMER_COMMENT"
export const TOGGLE_DETAIL_CUSTOMER_COMMENT = "TOGGLE_DETAIL_CUSTOMER_COMMENT";

export const TOGGLE_CREATE_CUSTOMER_ACTIVITY = "TOGGLE_CREATE_CUSTOMER_ACTIVITY"
export const TOGGLE_UPDATE_CUSTOMER_ACTIVITY = "TOGGLE_UPDATE_CUSTOMER_ACTIVITY"
export const TOGGLE_DELETE_CUSTOMER_ACTIVITY = "TOGGLE_DELETE_CUSTOMER_ACTIVITY"
export const TOGGLE_DETAIL_CUSTOMER_ACTIVITY = "TOGGLE_DETAIL_CUSTOMER_ACTIVITY"

export const TOGGLE_CREATE_CUSTOMER_TASK = "TOGGLE_CREATE_CUSTOMER_TASK"
export const TOGGLE_UPDATE_CUSTOMER_TASK = "TOGGLE_UPDATE_CUSTOMER_TASK"
export const TOGGLE_DELETE_CUSTOMER_TASK = "TOGGLE_DELETE_CUSTOMER_TASK"
export const TOGGLE_DETAIL_CUSTOMER_TASK = "TOGGLE_DETAIL_CUSTOMER_TASK";

export const TOGGLE_CHAT_CUSTOMER = "TOGGLE_CHAT_CUSTOMER"

export const RESET_CUSTOMER_PARAMETERES = "RESET_CUSTOMER_PARAMETERES"
export const RESET_CUSTOMER_COMMENT_PARAMETERES = "RESET_CUSTOMER_COMMENT_PARAMETERES"
export const RESET_CUSTOMER_ACTIVITY_PARAMETERES = "RESET_CUSTOMER_ACTIVITY_PARAMETERES"
export const RESET_CUSTOMER_TASK_PARAMETERES = "RESET_CUSTOMER_TASK_PARAMETERES"

export const RESET_CUSTOMER_SUB_TABLE = "RESET_CUSTOMER_SUB_TABLE"

export const UPDATE_CUSTOMER_PARAMETERS = "UPDATE_CUSTOMER_PARAMETERS"

export const HANDLE_CHANGE_CUSTOMER = "HANDLE_CHANGE_CUSTOMER";

export const LOAD_CHAT_HISTORY = "LOAD_CHAT_HISTORY"
export const WRITE_CUSTOMER_CHAT = "WRITE_CUSTOMER_CHAT"
export const UPDATE_CHAT_STATUS = "UPDATE_CHAT_STATUS"
export const RECEIVE_NEW_CHAT = "RECEIVE_NEW_CHAT"