import { firebaseDB, firebase, db } from "./index";
import { generateRandomID, getFormattedResults } from "../helper";
import {
  extractStockData,
  getStockCheckQuery,
  getDocumentMetadata,
  commitBatch,
  preprocessStockPhotoInput,
  manageImageStorage,
} from "./helper";

const bankFloorStock = {
  createBankFloorStock: async function (data) {
    var response = null;
    var batch = db.batch();

    var stockDataID = generateRandomID();
    var id = generateRandomID();
    const query = getStockCheckQuery(data);
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(
      data,
      ["photo"],
      "create"
    );
    const { stockData, additionalData } = extractStockData(
      newData,
      "bankFloorStock",
      true
    );
    const documentMetadata = getDocumentMetadata(1);

    await query.get().then(async (snapshot) => {
      if (snapshot.empty) {
        batch.set(firebaseDB.stockCollection().doc(stockDataID), {
          ...stockData,
          bankFloorStockID: id,
          ...documentMetadata,
        });
        batch.set(firebaseDB.bankFloorStockCollection().doc(id), {
          ...additionalData,
          stockDataID,
          ...documentMetadata,
        });
        response = await commitBatch(batch, stockDataID, id);
        if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
          await manageImageStorage(createPhoto, deletePhoto);
        }
      } else {
        response = getFormattedResults("error", "Stock already exists");
      }
    });

    return response;
  },
  updateBankFloorStock: async function (data) {
    var response = null;
    var batch = db.batch();

    var { stockDataID, id } = data;
    const { newData, createPhoto, deletePhoto } = preprocessStockPhotoInput(
      data,
      ["photo"],
      "update",
      "withID"
    );
    const { stockData, additionalData } = extractStockData(
      newData,
      "bankFloorStock",
      true
    );
    const documentMetadataUpdate = getDocumentMetadata(0);
    const documentMetadataCreate = getDocumentMetadata(1);

    if (id) {
      batch.update(firebaseDB.stockCollection().doc(stockDataID), {
        ...stockData,
        bankFloorStockID: id,
        ...documentMetadataUpdate,
      });
      batch.update(firebaseDB.bankFloorStockCollection().doc(id), {
        ...additionalData,
        stockDataID,
        ...documentMetadataUpdate,
      });
    } else {
      var id = generateRandomID();
      batch.update(firebaseDB.stockCollection().doc(stockDataID), {
        ...stockData,
        bankFloorStockID: id,
        ...documentMetadataUpdate,
      });
      batch.set(firebaseDB.bankFloorStockCollection().doc(id), {
        ...additionalData,
        stockDataID,
        ...documentMetadataCreate,
      });
    }
    response = await commitBatch(batch, stockDataID, id);
    if (response.statusCode == 200 && (createPhoto || deletePhoto)) {
      await manageImageStorage(createPhoto, deletePhoto);
    }

    return response;
  },
  updateActiveBankFloorStock: async function (data) {
    let response = null;
    let batch = db.batch();

    let { stockDataID, id, ...inActive } = data;
    const documentMetadataUpdate = getDocumentMetadata(0);
    if (id) {
      batch.update(firebaseDB.stockCollection().doc(stockDataID), {
        ...inActive,
        ...documentMetadataUpdate,
      });
    }
    response = await commitBatch(batch, stockDataID, id);
    return response;
  },
  deleteBankFloorStock: async function (data) {
    var batch = db.batch();

    const { stockDataID, id } = data;

    batch.update(firebaseDB.stockCollection().doc(stockDataID), {
      bankFloorStockID: firebase.firestore.FieldValue.delete(),
    });
    batch.delete(firebaseDB.bankFloorStockCollection().doc(id));

    return await commitBatch(batch, stockDataID, id);
  },
};

export default bankFloorStock;
